import { theme } from "../../components/theme";
import { motion } from "framer-motion";
import { Typography, Box } from "@mui/material";

interface ProgressBarProps {
  percents: number;
  duration?: number;
  delay?: number;
  easing?: string; // You can define a more specific type for the easing
  barWidth?: number;
  barHeight?: number;
  progressColor?: string;
  baseColor?: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  percents,
  duration = 2,
  delay = 0.25,
  easing = "easeOutBounce",
  barWidth = "100%",
  barHeight = 28,
}) => {
  const transition = {
    duration,
    delay,
    ease: easing,
    type: "spring",
  };

  const variants = {
    enter: {
      x: `100%`,
    },
    animate: {
      x: [`-100%`, `-${percents}%`],
      transition,
    },
  };

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      sx={{
        position: "relative",
        width: "50%",
      }}
    >
      <Box
        width={barWidth}
        height={barHeight}
        bgcolor={theme.palette.primary.main}
        position="relative"
        borderRadius={2}
        sx={{ border: `1px solid ${theme.palette.success.main}` }}
        overflow="hidden"
      >
        <motion.div
          variants={variants}
          initial="enter"
          animate={"animate"}
          exit="enter"
          style={{
            backgroundColor: theme.palette.success.main,
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
        />
        <Box
          position="absolute"
          top={0}
          right={theme.spacing(1)}
          height="100%"
          width="93%"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          px={1}
        >
          <Typography
            variant="caption"
            textAlign="center"
            sx={{ color: theme.palette.text.primary, width: "100%" }}
          >
            {(100 - percents || 0).toFixed(2)}%
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
