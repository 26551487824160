import Home from ".";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../components/store";
import { openModal, selectToken } from "../../components/slices/userSlice";
import { MODALTYPE } from "../../types/index.d";
import { useAppSelector } from "../../components/store/hooks";

export const HomeReferralWrapper = () => {
  const location = useLocation();
  const referredBy = location.pathname.replace("/r/", "");
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useAppSelector(selectToken);
  const timeOutRef = useRef<any>();

  useEffect(() => {
    if (!accessToken) {
      timeOutRef.current = setTimeout(() => {
        localStorage.setItem("referredBy", JSON.stringify(referredBy));
        dispatch(openModal(MODALTYPE.REGISTER));
      }, 200);
    }
    if (accessToken) {
      clearInterval(timeOutRef.current);
    }
    return () => {
      clearInterval(timeOutRef.current);
    };
  }, []);

  return <Home />;
};
