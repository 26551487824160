import { FC, useState, useEffect } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { TokenIcon } from "../../components/tokenIcon";
import { IWager, IWinner } from "../../components/slices/wagerSlice";
import { useAppSelector } from "../../components/store/hooks";
import { selectCurrentUser } from "../../components/slices/userSlice";
import { isModUser } from "../../components/chat/textBubble";
import { parseHiddenUsername } from "../../constants";
import { theme } from "../../components/theme";

interface WagerRaceTableProps {
  rawData: IWager | null;
}

const WagerRaceTable: FC<WagerRaceTableProps> = ({ rawData }) => {
  const [data, setData] = useState<IWinner[]>([]);
  const [mine, setMine] = useState<IWinner>();
  const user = useAppSelector(selectCurrentUser);
  const isAdmin = isModUser(user.userType);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    if (rawData) {
      // wageredPeople

      const wageredUsers = rawData.winners.filter(
        (winner, i) => winner.wagered > 0 && i < 15
      );
      // Create additional entries to make rawData length 15
      if (wageredUsers.length < 15) {
        const additionalEntries: IWinner[] = Array.from(
          { length: 15 - wageredUsers.length },
          (_, index) => ({
            rank: index + 1 + wageredUsers.length,
            userId: `id-${index + 1}`,
            userName: "...",
            level: 0,
            wagered: 0,
          })
        );

        // Concatenate additional entries with existing rawData
        const updatedData = [...wageredUsers, ...additionalEntries];
        setData(updatedData);
      } else {
        setData(wageredUsers);
      }

      const isUserTop15 = wageredUsers.find(
        (winner) => winner.userId === user._id
      );
      if (!isUserTop15) {
        const myDataInWinners = rawData.winners.find(
          (winner) => winner.userId === user._id
        );

        if (myDataInWinners) {
          const myRank = [...rawData.winners]
            .sort((a, b) => b.wagered - a.wagered)
            .findIndex((winner) => winner.userId === user._id);
          if (myRank) {
            setMine({
              userId: myDataInWinners.userId,
              userName: myDataInWinners.userName,
              level: 1,
              rank: myRank + 15,
              wagered: 0,
            });
          }
        }
        if (!myDataInWinners) {
          const myData = rawData.records.find(
            (winner) => winner.userId === user._id
          );

          if (myData) {
            const myRank = [...rawData.records]
              .sort((a, b) => b.totalWagered - a.totalWagered)
              .findIndex((winner) => winner.userId === user._id);
            if (myRank) {
              setMine({
                userId: myData.userId,
                userName: myData.userName,
                level: 1,
                rank: myRank + 15,
                wagered: 0,
              });
            }
          }
        }
      }
    }
  }, [rawData]);
  const distribution = rawData?.division?.split(",") || [];

  return (
    <Card sx={{ mb: 6 }}>
      <TableContainer>
        <Table size={matchesMD ? "medium" : "small"}>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#292929" }}>
              <TableCell
                size="small"
                sx={{
                  borderColor: "#292929",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                  fontSize: { md: "14px", xs: "12px" },
                }}
              >
                #
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "#292929",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                  fontSize: { md: "14px", xs: "12px" },
                  width: "20%",
                }}
                width="20%"
              >
                Username
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "#292929",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                  fontSize: { md: "14px", xs: "12px" },
                }}
              >
                Reward
              </TableCell>
              <TableCell
                sx={{
                  borderColor: "#292929",
                  fontWeight: "bold",
                  backgroundColor: "transparent",
                  fontSize: { md: "14px", xs: "12px" },
                }}
              >
                Wagered
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              (mine ? [...data, mine] : data)?.map((promo, index) => {
                return (
                  <TableRow
                    key={promo?.userId}
                    sx={{
                      backgroundColor: index % 2 ? "#19191A" : "#202020",
                    }}
                  >
                    <TableCell sx={{ borderColor: "#292929" }} width="10%">
                      <Typography
                        variant={matchesMD ? "inherit" : "caption"}
                        sx={{
                          color:
                            promo?.userId === user._id
                              ? "#32c932 !important"
                              : "text.primary",
                        }}
                        gutterBottom
                        noWrap
                      >
                        {promo?.rank}
                      </Typography>
                    </TableCell>
                    <TableCell
                      sx={{ borderColor: "#292929", width: "20%" }}
                      width="20%"
                    >
                      <Typography
                        variant={matchesMD ? "inherit" : "caption"}
                        sx={{
                          color:
                            promo?.userId === user._id
                              ? "#32c932 !important"
                              : "text.primary",
                        }}
                        gutterBottom
                        noWrap
                      >
                        {parseHiddenUsername(promo?.userName, isAdmin) || ""}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderColor: "#292929" }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={{ md: 1, xs: 0 }}
                      >
                        <TokenIcon />
                        <Typography
                          variant={matchesMD ? "inherit" : "caption"}
                          sx={{
                            color:
                              promo?.userId === user._id
                                ? "#32c932 !important"
                                : "text.primary",
                          }}
                          noWrap
                        >
                          {Number(distribution[index]) *
                            (rawData?.payout || 0) || 0}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell sx={{ borderColor: "#292929" }}>
                      {promo?.wagered !== 0 ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={{ md: 1, xs: 0 }}
                        >
                          <TokenIcon />
                          <Typography
                            variant={matchesMD ? "inherit" : "caption"}
                            sx={{
                              color:
                                promo?.userId === user._id
                                  ? "#32c932 !important"
                                  : "text.primary",
                            }}
                            noWrap
                          >
                            {promo?.wagered?.toFixed(2) || 0}
                          </Typography>
                        </Stack>
                      ) : (
                        <Typography
                          variant={matchesMD ? "inherit" : "caption"}
                          sx={{
                            color:
                              promo?.userId === user._id
                                ? "#32c932 !important"
                                : "text.primary",
                          }}
                          noWrap
                        >
                          ...
                        </Typography>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default WagerRaceTable;
