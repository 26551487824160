import React, { useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import Order from "./order";
import { useNavigate, useParams } from "react-router-dom";
import Transactions from "./transaction";
import { Profile } from "./profile";
import { Settings } from "./settings";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { getColor } from "../../pages/fairness";
interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3, px: { md: 3, xs: 1 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const tabsData = [
  {
    label: "Profile",
    icons: <PersonIcon />,
    endpoint: "info",
    value: 0,
  },
  {
    label: "Orders",
    icons: <EditNoteIcon />,
    endpoint: "order",
    value: 1,
  },
  {
    label: "Transactions",
    icons: <ReceiptIcon />,
    endpoint: "transaction",
    value: 2,
  },
  {
    label: "Settings",
    icons: <SettingsIcon />,
    endpoint: "settings",
    value: 3,
  },
];

export const AccountTabs = () => {
  const theme = useTheme();
  const { tab } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(
    tabsData.find((tabData) => tabData.endpoint === tab)?.value || 0
  );
  const matchesXS = useMediaQuery(theme.breakpoints.down("sm"));
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const findRightTab = tabsData.find((tabData) => tabData.value === newValue);

    if (findRightTab) {
      navigate(`/profile/${findRightTab.endpoint}`);
      setValue(newValue);
    }
  };
  useEffect(() => {
    if (tab) {
      const newValue =
        tabsData.find((tabData) => tabData.endpoint === tab)?.value || 0;

      if (newValue) {
        setValue(newValue);
      }
    }
  }, [tab]);

  return (
    <Box sx={{ width: "100%" }}>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="inherit"
          variant="fullWidth"
          allowScrollButtonsMobile
          sx={{
            boxShadow: 3,
            "& .MuiTabs-indicator": {
              background: "#33EC3D",
              backgroundColor: "#33EC3D",
            },
          }}
        >
          {tabsData.map((tabData) => (
            <Tab
              label={matchesXS ? tabData.icons : tabData.label}
              sx={{
                px: 0,
                minWidth: { sm: "auto", xs: "79px" },
                color: getColor(value === tabData.value),
              }}
              {...a11yProps(tabData.value)}
            />
          ))}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} dir={theme.direction}>
        <Profile />
      </TabPanel>
      <TabPanel value={value} index={1} dir={theme.direction}>
        <Order />
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        <Transactions />
      </TabPanel>
      <TabPanel value={value} index={3} dir={theme.direction}>
        <Settings />
      </TabPanel>
    </Box>
  );
};
