import { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useAppSelector } from "../../store/hooks";
import { format } from "date-fns";
import { Button } from "../../button";
import {
  getAllTransactions,
  selectAllTransaction,
} from "../../slices/transactionSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { selectToken } from "../../slices/userSlice";
import { formatTransactionType } from "../../../utils";
import { theme } from "../../theme";

const columns = [
  { id: "description", label: "Description", minWidth: 70 },
  {
    id: "amount",
    label: "Amount",
    minWidth: 50,
    align: "right",
  },
  { id: "createdAt", label: "Date", minWidth: 70 },
];

const getColor = (id: string, value: number) => {
  if (id === "amount") {
    return value > 0 ? theme.palette.success.main : theme.palette.error.main;
  }
  return theme.palette.text.primary;
};

export default function Transactions() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const transactions = useAppSelector(selectAllTransaction);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useAppSelector(selectToken);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleValue = (key: string, value: string | number) => {
    if (key === "createdAt" && value) {
      const checkDate = new Date(value);
      if (checkDate instanceof Date && !isNaN(checkDate.getTime())) {
        return format(checkDate, "HH:mm, M/d/yy");
      }
    }
    if (key === "description") {
      return formatTransactionType(value as string);
    }
    return value;
  };

  useEffect(() => {
    if (accessToken) {
      dispatch(getAllTransactions());
    }
  }, [accessToken]);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#292929" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    sx={{
                      borderColor: "#292929",
                      fontWeight: "bold",
                      backgroundColor: "transparent",
                    }}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions?.length
                ? transactions
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((transaction: any, index) => {
                      return (
                        <TableRow
                          role="checkbox"
                          tabIndex={-1}
                          key={transaction._id}
                          sx={{
                            backgroundColor: index % 2 ? "#19191A" : "#202020",
                          }}
                        >
                          {columns.map((column) => {
                            const value = transaction[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                sx={{
                                  color: getColor(column.id, value),
                                  borderColor: "#292929",
                                }}
                                align={"center"}
                              >
                                {handleValue(column.id, value)}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage=""
          component="div"
          count={transactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          sx={{
            "& .MuiSelect-select": {
              display: "none !important",
            },
            "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
              display: "none !important",
            },
          }}
        />
      </Paper>
    </>
  );
}
