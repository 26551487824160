import { CircularProgress, Container, Stack } from "@mui/material";
import GameFeed from "../../../components/gameFeed";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import Plinko from "../../../components/plinko";
import { GAMES } from "../../../constants/games";
import { useEffect, useMemo, useState } from "react";
import useSound from "use-sound";
import Plinko1Sound from "../../../assets/audio/plinko/bottom_hit1.mp3";
import Plinko2Sound from "../../../assets/audio/plinko/bottom_hit2.mp3";
import Plinko3Sound from "../../../assets/audio/plinko/bottom_hit3.mp3";
import Plinko4Sound from "../../../assets/audio/plinko/bottom_hit4.mp3";
import Plinko5Sound from "../../../assets/audio/plinko/bottom_hit5.mp3";
import Plinko6Sound from "../../../assets/audio/plinko/bottom_hit6.mp3";
import Plinko7Sound from "../../../assets/audio/plinko/bottom_hit7.mp3";
import Plinko8Sound from "../../../assets/audio/plinko/bottom_hit8.mp3";
import Plinko9Sound from "../../../assets/audio/plinko/bottom_hit9.mp3";
import Plinko10Sound from "../../../assets/audio/plinko/bottom_hit10.mp3";
import Plinko11Sound from "../../../assets/audio/plinko/bottom_hit11.mp3";
import Plinko12Sound from "../../../assets/audio/plinko/bottom_hit12.mp3";
import Plinko13Sound from "../../../assets/audio/plinko/bottom_hit13.mp3";
import { useAppSelector } from "../../../components/store/hooks";
import { selectVolume } from "../../../components/slices/userSlice";

const PlinkoPage = () => {
  const volume = useAppSelector(selectVolume);
  const [playPlinko1Sound] = useSound(Plinko1Sound, { volume: volume / 100 });
  const [playPlinko2Sound] = useSound(Plinko2Sound, { volume: volume / 100 });
  const [playPlinko3Sound] = useSound(Plinko3Sound, { volume: volume / 100 });
  const [playPlinko4Sound] = useSound(Plinko4Sound, { volume: volume / 100 });
  const [playPlinko5Sound] = useSound(Plinko5Sound, { volume: volume / 100 });
  const [playPlinko6Sound] = useSound(Plinko6Sound, { volume: volume / 100 });
  const [playPlinko7Sound] = useSound(Plinko7Sound, { volume: volume / 100 });
  const [playPlinko8Sound] = useSound(Plinko8Sound, { volume: volume / 100 });
  const [playPlinko9Sound] = useSound(Plinko9Sound, { volume: volume / 100 });
  const [playPlinko10Sound] = useSound(Plinko10Sound, { volume: volume / 100 });
  const [playPlinko11Sound] = useSound(Plinko11Sound, { volume: volume / 100 });
  const [playPlinko12Sound] = useSound(Plinko12Sound, { volume: volume / 100 });
  const [playPlinko13Sound] = useSound(Plinko13Sound, { volume: volume / 100 });
  const [isLoading, setIsloading] = useState(true);

  const audioData = [
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko6Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko6Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko6Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko6Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko7Sound,
      playPlinko6Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko6Sound,
      playPlinko7Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko7Sound,
      playPlinko6Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko6Sound,
      playPlinko7Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko8Sound,
      playPlinko7Sound,
      playPlinko6Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko6Sound,
      playPlinko7Sound,
      playPlinko8Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko8Sound,
      playPlinko7Sound,
      playPlinko6Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko6Sound,
      playPlinko7Sound,
      playPlinko8Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
    [
      playPlinko11Sound,
      playPlinko12Sound,
      playPlinko10Sound,
      playPlinko9Sound,
      playPlinko8Sound,
      playPlinko7Sound,
      playPlinko6Sound,
      playPlinko1Sound,
      playPlinko5Sound,
      playPlinko1Sound,
      playPlinko6Sound,
      playPlinko7Sound,
      playPlinko8Sound,
      playPlinko9Sound,
      playPlinko10Sound,
      playPlinko12Sound,
      playPlinko11Sound,
    ],
  ];

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (e.keyCode === 32 && e.target === document.body) {
        e.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsloading(false);
    }, 1000);
  }, []);
  return (
    <Container maxWidth="md">
      {!isLoading ? (
        <Plinko audioData={audioData} />
      ) : (
        <Stack alignItems="center">
          <CircularProgress color="info" />
        </Stack>
      )}
      <DescWrapper game={GAMES.PLINKO} />
      <GameFeed />
    </Container>
  );
};

export default PlinkoPage;
