import { Box, Stack, Typography } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import { theme } from "../theme";
import { BetField } from "../betField";
import { InputFieldWithStartAdornment } from "../inputField";
import { RoundNumber } from "../../utils";
import { useState } from "react";
import { Button } from "../button";
import { useAppSelector } from "../store/hooks";
import {
  selectBalance,
  selectCurrentUser,
  selectToken,
} from "../slices/userSlice";
import Users from "../../services/user";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 200, md: 300 },
  zIndex: 10,
  py: 4,
  px: 10,
  borderRadius: 4,
};

interface ITipRain {
  handleClose: () => void;
  open: boolean;
}

const TipRain = ({ handleClose, open }: ITipRain) => {
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const userBalance = useAppSelector(selectBalance);
  const user = useAppSelector(selectCurrentUser);
  const accessToken = useAppSelector(selectToken);
  const [error, setError] = useState("");

  const handleSend = async () => {
    if (!amount) {
      setError("Please enter a valid amount");
      return;
    }
    if (amount < 0.01) {
      setError("Amount should be greater than 0.01");
      return;
    }

    if (userBalance < amount) {
      setError("Amount should be less than your balance");
      return;
    }
    try {
      setLoading(true);
      setError("");
      const response = await Users.tipRain(accessToken, amount);
      if (response.status === 200) {
        handleClose();
      }
      if (response?.message) {
        setError(response.message);
      }
    } catch (e: any) {
      setError(e?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalComponent open={open} onClose={handleClose} disableScrollLock={true}>
      <Box sx={modalStyle}>
        <Stack gap={3}>
          <Typography color="text.primary" variant="h4" fontWeight={600}>
            Tip Rain
          </Typography>
          <Stack>
            <InputFieldWithStartAdornment
              value={!amount ? null : amount}
              type="number"
              placeholder="0.01"
              pattern="[0-9]*[.]?[0-9]*"
              name="bettingField"
              onKeyDown={(e: any) => {
                if (
                  !(e.key >= "0" && e.key <= "9") &&
                  !(e.ctrlKey && e.key === "v") &&
                  !(e.key === "Backspace" || e.key === ".")
                ) {
                  e.preventDefault();
                }

                if (
                  e.key === "e" ||
                  e.key === "E" ||
                  e.key === "-" ||
                  e.key === "+"
                ) {
                  e.preventDefault();
                }
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setAmount(RoundNumber(Number(e.target.value)))
              }
              // inputRef={betFieldRef}
              sx={{
                backgroundColor: theme.palette.primary.main,
                "& .MuiInputBase-input": {
                  paddingLeft: "calc(1em + 20px)",
                },
              }}
              inputProps={{
                min: 0,
              }}
            />
            {error ? (
              <Typography sx={{ p: 0, m: 0 }} color="error">
                {error}
              </Typography>
            ) : null}
          </Stack>

          <Button
            disabled={loading || !amount || !user.isVerified}
            variantType="success"
            onClick={handleSend}
          >
            Send
          </Button>
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default TipRain;
