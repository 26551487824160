import { User } from "../components/slices/userSlice";
import { minesPayoutData } from "../constants/minesData";
import { userRankData } from "../constants/userRanks";
import { USERTYPE } from "../types/index.d";

export const RoundNumberFourDecimalPoints = (num: number) => {
  return Math.floor(num * 10000) / 10000;
};

export const RoundNumberThreeDecimalPoints = (num: number) => {
  return Math.floor(num * 1000) / 1000;
};

export const RoundNumberFiveDecimalPoints = (num: number) => {
  return Math.floor(num * 100000) / 100000;
};

export const RoundNumber = (num: number) => {
  return Math.floor(num * 100) / 100;
};

// export const RoundNumberCondition = (num: number) => {
//   const is2dp = Math.floor(num * 100) / 100;
//   if (is2dp) {
//     return is2dp;
//   }
//   const is3dp = Math.floor(num * 1000) / 1000;
//   if (is3dp) {
//     return is3dp;
//   }
//   return Math.floor(num * 10000) / 10000;
// };

export const RoundProfitOnWin = (num: number) => {
  return Number(num.toFixed(5));
};
export const RoundNumberCondition = (num: number) => {
  const is2dp = parseFloat(num.toFixed(2));
  if (is2dp !== Math.floor(num)) {
    return is2dp;
  }

  const is3dp = parseFloat(num.toFixed(3));
  if (is3dp !== Math.floor(num * 100) / 100) {
    return is3dp;
  }

  return parseFloat(num.toFixed(4));
};

export const getPayout = (mines: number, diamonds: number) => {
  const multiplierData = minesPayoutData.find(
    (data) => data.totalMines === mines && data.gemsFound === diamonds
  );
  return RoundNumber(multiplierData?.multiplier || 1);
};

export const getPlayerLevelData = (level: number | undefined) => {
  return userRankData.find((rank) => rank.level === level) || userRankData[0];
};

export const getPlayerImg = (name: string | undefined) => {
  return (
    userRankData.find(
      (rank) => rank.name.toLocaleUpperCase() === name?.toLocaleUpperCase()
    ) || userRankData[0]
  );
};

export const canUserCancel = (user: User) => {
  console.log(user);
};

export const hasCashierPermission = (userType: string) => {
  return (
    userType === USERTYPE.ADMIN ||
    userType === USERTYPE.OWNER ||
    userType === USERTYPE.CASHIER ||
    userType === USERTYPE.DEV
  );
};

export const formatTransactionType = (type: string): string => {
  return type
    .split("_")
    .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
    .join(" ");
};

export const isBetGreaterthanSetValue = (
  bet: number,
  balance: number,
  value: number | undefined
) => {
  if (!value) {
    return false;
  }
  return (bet / balance) * 100 > value;
};
