import { Card, Stack, Typography } from "@mui/material";
import { selectRain } from "../slices/chatSlice";
import { useAppSelector } from "../store/hooks";
import { TokenIcon } from "../tokenIcon";
import { theme } from "../theme";
import { RoundNumber } from "../../utils";
import TipRain from "./tipRainModel";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
export const ChatRain = () => {
  const rainData = useAppSelector(selectRain);
  const [open, setOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const updateCountdown = () => {
      if (rainData?.expireAt) {
        const now = new Date().getTime();
        const expireTime = new Date(rainData?.expireAt).getTime();
        const distance = expireTime - now;

        if (distance < 0) {
          setTimeLeft(`00m 00s`);
          return;
        }

        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        const paddedMinutes = String(minutes).padStart(2, "0");
        const paddedSeconds = String(seconds).padStart(2, "0");

        setTimeLeft(`${paddedMinutes}m ${paddedSeconds}s`);
      }
    };

    const interval = setInterval(updateCountdown, 1000);
    return () => clearInterval(interval);
  }, [rainData]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TipRain open={open} handleClose={handleClose} />
      <Stack direction="row" sx={{ p: 0.5, width: "100%", my: 1 }} gap={1}>
        <Stack
          sx={{
            background: theme.palette.primary.light,
            boxShadow: "inset 0 0 5px 0px rgba(0, 255, 0, 0.75)",
            p: 1,
            borderRadius: 2,
            width: "100%",
          }}
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Stack>
            <Typography color="text.primary">LIVE RAIN POOL</Typography>
            <Stack direction="row" gap={0.5}>
              <TokenIcon />
              <Typography color="text.primary">
                {RoundNumber(rainData?.totalRained || 0)}
              </Typography>
            </Stack>
          </Stack>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Typography color="text.primary">{timeLeft}</Typography>
          </motion.div>
          {/* <motion.div
            key={timeLeft} // This key will change each time timeLeft updates, triggering the animation
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4, ease: "easeInOut" }}
          >
            <Typography color="text.primary">{timeLeft}</Typography>
          </motion.div> */}
        </Stack>
        <Stack
          sx={{
            background: theme.palette.primary.light,
            boxShadow: "inset 0 0 5px 0px rgba(0, 255, 0, 0.75)",
            p: 1,
            borderRadius: 2,
          }}
          justifyContent="center"
          onClick={() => setOpen(true)}
        >
          <Typography color="text.primary">Tip Rain</Typography>
        </Stack>
      </Stack>
    </>
  );
};
