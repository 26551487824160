import { useMotionValue, useTransform, animate, motion } from "framer-motion";
import { useEffect, useState } from "react";

interface IAnimateNumber {
  currentNumber: number;
  fixedPoints?: number;
  startingCount?: number;
}

// export const AnimateNumber = (props: IAnimateNumber) => {
//   const { currentNumber, fixedPoints = 2, startingCount = 15.0 } = props;

//   console.log(currentNumber);
//   const count = useMotionValue(startingCount);
//   const rounded = useTransform(count, (value) =>
//     Number(value.toFixed(fixedPoints))
//   );

//   useEffect(() => {
//     const animation = animate(count, currentNumber, {
//       duration: 1.05,
//       ease: "easeInOut",
//     });

//     return animation.stop;
//     // eslint-disable-next-line
//   }, [currentNumber]);

//   return <motion.div>{rounded}</motion.div>;
// };

export const AnimateNumber: React.FC<IAnimateNumber> = ({
  currentNumber,
  fixedPoints = 2,
  startingCount = 15.0,
}) => {
  const [debouncedNumber, setDebouncedNumber] = useState<number>(currentNumber);
  const [timeoutId, setTimeoutId] = useState<any | null>(null);

  const debounce = (func: Function, delay: number) => {
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const id = setTimeout(() => {
        func(...args);
      }, delay);
      setTimeoutId(id);
    };
  };

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      setDebouncedNumber(currentNumber);
    }, 300);
    debouncedUpdate();
    return () => {
      clearTimeout(timeoutId as number);
    };
  }, [currentNumber]);

  const count = useMotionValue<number>(currentNumber);
  const rounded = useTransform<number, string>(count, (value) =>
    value.toFixed(fixedPoints)
  );

  useEffect(() => {
    const animation = animate(count, debouncedNumber, {
      duration: 1,
      ease: "linear",
    });

    return () => {
      animation.stop();
    };
  }, [debouncedNumber]);

  return (
    <motion.div style={{ padding: "auto", width: 70 }}>{rounded}</motion.div>
  );
};
