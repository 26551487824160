export const AMLPolicy = [
  {
    title: "",
    text: [
      "The AML program of runewager.com is designed to be compliant with:",
      `EU: “Directive 2015/849 of the European Parliament and of The Council of 20 May 2015 on the prevention of the use of the financial system for the purposes of money laundering”`,
      `EU: “Regulation 2015/847 on information accompanying transfers of funds”`,
      `EU: Various regulations imposing sanctions or restrictive measures against persons and embargo on certain goods and technology, including all dual-use goods`,
      `BE: “Law of 18 September 2017 on the prevention of money laundering limitation of the use`,
    ],
  },
  {
    title: "Transaction Monitoring:",
    text: [
      `Runewager.com has implemented systems to monitor transactions for unusual or suspicious activity. <br/> Set thresholds for reporting and investigate transactions that exceed these thresholds.`,
      `Record Keeping: <br/> Runewager.com has established protocols for maintaining accurate and up-to-date records of customer information, transactions, and due diligence efforts. <br/> Keep records for a specified period, as required by local regulations.`,
      `Employee Training: <br/>Runewager has trained employees on AML policies, procedures, and the importance of reporting suspicious activity. <br/> Provide ongoing training to keep staff informed about changes in regulations and emerging risks.`,
    ],
  },
  {
    title: "Auditing",
    text: [
      `Internal audit regularly establishes missions and reports about AML activities.`,
      `Data Security: <br/>All data given by any user/customer will be kept secure and will not be sold or given to anyone else. Only if forced by law, or to prevent money laundering, data may be shared with the AML-authority of the affected state.`,
      `Runewager.com will follow all guidelines and rules of the data protection directive (officially Directive 95/46/EC)`,
      `Contact us: <br/> If you have any questions about our AML and KYC Policy, please contact us:<br/> support@runewager.com`,
    ],
  },
];
