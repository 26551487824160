import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface PlinkoDocument {
  bet: number;
  payout: number;
  result: number;
  lines: number;
  createdAt: Date;
  clientSeed: string;
  serverSeed: string;
  risk: string;
  gameNonce: number;
  multiplier: number;
  isMax: boolean;
  multiplierIndex: number;
  _id: string;
}

interface PlinkoState {
  plinko: PlinkoDocument | null;
  pastResults: PlinkoDocument[];
  errorMessage: string;
}

const initialState: PlinkoState = {
  plinko: null,
  pastResults: [],
  errorMessage: "",
};

// Slice definition
export const plinkoReducer = createSlice({
  name: "plinko",
  initialState,
  reducers: {
    updatePlinkoGame(state, action: PayloadAction<PlinkoDocument>) {
      state.errorMessage = "";
      state.plinko = action.payload;
      // state.pastResult.push(action.payload);
    },
    updatePlinkoErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    updatePlinkoPastGame(state, action: PayloadAction<PlinkoDocument>) {
      state.pastResults.push(action.payload);
    },
    updatePlinko(state, action: PayloadAction<PlinkoDocument>) {
      state.pastResults.push(action.payload);
      if (state?.plinko && action.payload._id === state.plinko._id) {
        state.plinko = null;
      }
    },
  },
});

// Expose the actions
export const {
  updatePlinkoGame,
  updatePlinkoErrorMessage,
  updatePlinkoPastGame,
  updatePlinko,
} = plinkoReducer.actions;

// Selectors
export const selectPlinkoGames = (state: RootState) => state.plinko;
export const selectPlinkoGame = (state: RootState) => state.plinko.plinko;
export const selectError = (state: RootState) => state.plinko.errorMessage;

// Reducer
export default plinkoReducer.reducer;
