import { RoundNumber } from ".";
import { DEFAULT_MIN_BET } from "../constants";

export const handleDoubleBet = (balance: number, currentBet: number) => {
  const isBalance = balance || 0;
  return currentBet * 2 > isBalance ? RoundNumber(isBalance) : currentBet * 2;
};

export const handleHalfBet = (
  currentBet: number,
  defaultMin = DEFAULT_MIN_BET
) => {
  return currentBet / 2 < defaultMin ? defaultMin : RoundNumber(currentBet / 2);
};
