import { Stack, Typography } from "@mui/material";
import { DiceDocument } from "../slices/diceSlice";
import styles from "./index.module.scss";
import diceImg from "../../assets/dice-bg.svg";
import useSound from "use-sound";
import WinSound from "../../assets/audio/common/win.mp3";
import LossSound from "../../assets/audio/common/loss.mp3";
import AnimationSound from "../../assets/audio/dice/animation.mp3";
import { useAppSelector } from "../store/hooks";
import { selectVolume } from "../slices/userSlice";
import { useEffect } from "react";

interface ISliderWithLabel {
  game?: DiceDocument | null;
}

export default function Tear({ game }: ISliderWithLabel) {
  const volume = useAppSelector(selectVolume);
  const [playAnimationSound] = useSound(AnimationSound, {
    volume: volume / 100,
  });
  const [playWinSound] = useSound(WinSound, { volume: volume / 100 });
  const [playLossSound] = useSound(LossSound, { volume: volume / 100 });

  useEffect(() => {
    playAnimationSound();
  }, [game]);

  if (!game) return <></>;

  return (
    <Stack
      sx={{
        position: "absolute",
        left: `${game.result}%`,
        transform: {
          md: "translate(-50%, -80%)",
          xs: "translate(-50%, -65%)",
        },

        transition: "all 0.75s",
        backgroundImage: `url(${diceImg})`,
      }}
      onTransitionEnd={() => {
        if (game.isWin) {
          playWinSound();
        } else {
          playLossSound();
        }
      }}
      justifyContent="center"
      alignItems="center"
      className={styles.teardrop}
    >
      <Typography
        fontWeight={600}
        className={styles.teardrop__content}
        color="text.primary"
      >
        {game.result.toFixed(2)}
      </Typography>
    </Stack>
  );
}
