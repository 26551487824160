import { Stack, IconButton, Typography } from "@mui/material";
import { FormEvent, useContext, useRef, useState, memo } from "react";
import ChatContext from "../../contexts/chat/context";
import { InputFieldStyled } from "../inputField";
import {
  selectCurrentUser,
  selectPlayer,
  selectToken,
  updateIsTyping,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import Picker, { Theme } from "emoji-picker-react";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import { theme } from "../theme";
import { CHARACTER_LIMIT, CHATTYPES } from "../../constants/chat";
import { ADMIN_READ_AT_UPDATE } from "../../constants/socket";
import { selectAdminChat } from "../slices/chatSlice";
import { IMessage } from "../../types";
import { useDispatch } from "react-redux";

export const MessageInput = ({
  chatType,
  orderId,
  selectedChat,
}: {
  chatType: CHATTYPES;
  orderId?: string;
  selectedChat: string;
}) => {
  const [newMessage, setNewMessage] = useState("");
  const accessToken = useAppSelector(selectToken);
  const user = useAppSelector(selectCurrentUser);
  const { socket } = useContext(ChatContext);
  const [showPicker, setShowPicker] = useState(false);
  const timerRef = useRef<number>(0);
  const onEmojiClick = (emojiObject: any) => {
    setNewMessage((prevInput: string) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };
  const dispatch = useDispatch();
  const chatData = useAppSelector(selectAdminChat);

  const handleSendMessage = (event: FormEvent) => {
    event.preventDefault();
    const currentTime = Date.now();
    const startTime = timerRef?.current;
    if (
      newMessage &&
      (!startTime || currentTime - startTime > 1000) &&
      chatType === CHATTYPES.Community
    ) {
      socket?.emit("new-message", {
        text: newMessage,
        accessToken,
      });
      timerRef.current = currentTime;
      setNewMessage("");
      return;
    }

    if (
      newMessage &&
      (!startTime || currentTime - startTime > 1000) &&
      (chatType === CHATTYPES.WITHDRAWAL || chatType === CHATTYPES.DEPOSIT)
    ) {
      socket?.emit("new-message-admin", {
        text: newMessage,
        accessToken,
        orderId,
      });
      timerRef.current = currentTime;
      setNewMessage("");
    }

    if (newMessage && chatType === CHATTYPES.ADMIN) {
      socket?.emit("new-message-admin", {
        text: newMessage,
        accessToken,
        orderId: selectedChat,
      });
      timerRef.current = currentTime;
      setNewMessage("");
    }
  };

  const sendSocketReadAt = () => {
    if (selectedChat && chatType === CHATTYPES.ADMIN) {
      const selected = chatData.find((chat) => chat.order._id === selectedChat);
      const unReadMessages =
        selected?.messages?.filter(
          (data: IMessage) => !data?.readAt && !data.isAdmin
        ) || [];

      if (unReadMessages?.length) {
        socket?.emit(ADMIN_READ_AT_UPDATE, {
          accessToken,
          orderId: selectedChat,
        });
      }
    }
  };

  const handleOnFocus = () => {
    if (selectedChat && chatType === CHATTYPES.ADMIN) {
      sendSocketReadAt();
    }
    if (showPicker) {
      setShowPicker((val) => !val);
    }
    dispatch(updateIsTyping(true));
  };

  const loseFocus = () => {
    dispatch(updateIsTyping(false));
  };
  return (
    <form onSubmit={handleSendMessage}>
      <Stack direction="column">
        <Stack direction="row" gap={1} sx={{ position: "relative" }}>
          <Stack sx={{ width: "100%", position: "relative" }}>
            <InputFieldStyled
              fullWidth
              disabled={
                (!selectedChat && chatType === CHATTYPES.ADMIN) ||
                (chatType === CHATTYPES.Community && user.isMuted)
              }
              onFocus={() => handleOnFocus()}
              onBlur={() => loseFocus()}
              value={newMessage}
              inputProps={{ maxLength: CHARACTER_LIMIT }}
              onChange={(e) => {
                setNewMessage(e.target.value);
                e.stopPropagation();
              }}
            />
          </Stack>
          <IconButton onClick={() => setShowPicker((val) => !val)}>
            <EmojiEmotionsOutlinedIcon
              sx={{
                color: theme.palette.text.primary,
                fontSize: "0.78em",
              }}
            />
          </IconButton>
          {showPicker && <EmojiPicker onEmojiClick={onEmojiClick} />}
        </Stack>
        <Typography
          sx={{
            fontSize: ".65em",
            zIndex: 1,
            textAlign: "end",
            marginRight: "15%",
          }}
          variant="subtitle1"
        >{`${newMessage.length}/${CHARACTER_LIMIT}`}</Typography>
      </Stack>
    </form>
  );
};
const EmojiPicker = memo(
  ({ onEmojiClick }: { onEmojiClick: (emojiObject: any) => void }) => (
    <Picker
      lazyLoadEmojis
      style={{
        position: "absolute",
        width: "300px",
        height: "250px",
        transform: "translate(2.5%,-102%)",
      }}
      searchDisabled={true}
      skinTonesDisabled={true}
      theme={Theme.DARK}
      onEmojiClick={onEmojiClick}
    />
  )
);
