import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Users from "../../services/user";
import { useDispatch } from "react-redux";
import {
  selectVerificationModal,
  updateVerificationModal,
} from "../../components/slices/userSlice";
import { useAppSelector } from "../../components/store/hooks";
import VerificationModal from ".";

const CheckVerification = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");
  const isVerification = useAppSelector(selectVerificationModal);
  const verificationCode = query.get("verificationCode");
  const isVerfiedDone = useRef(false);
  const navigate = useNavigate();

  const handleVerifyUser = async (code: string) => {
    try {
      if (isVerfiedDone.current) return;
      const response = await Users.verificationCode(code);
      isVerfiedDone.current = true;
      if (response) {
        setMessage(response.messsage);
      }
    } catch (e: any) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isVerification && verificationCode && !isLoading && !message) {
      dispatch(updateVerificationModal(true));
    }
  }, []);

  useEffect(() => {
    if (isVerification && verificationCode && !message) {
      setIsLoading(true);
      handleVerifyUser(verificationCode);
    }
  }, [isVerification]);

  const handleClose = () => {
    dispatch(updateVerificationModal(false));
    navigate("/");
  };

  if (isVerification) {
    return (
      <VerificationModal
        handleClose={() => handleClose()}
        open={isVerification}
        isLoading={isLoading}
        message={message}
      />
    );
  }
  return null;
};

export default CheckVerification;
