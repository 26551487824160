import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { chatDrawerWidthMin, chatDrawerWidth } from "../../constants";

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  backgroundColor: theme.palette.secondary.main,
  // color: theme.palette.text.primary,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: chatDrawerWidth,
  minWidth: chatDrawerWidthMin,
  transition: theme.transitions.create(["width", "backgroundColor"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.primary.main,
  [theme.breakpoints.up("md")]: {
    width: chatDrawerWidth,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    // position: "absolute",
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create(["width", "backgroundColor"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "none",
  overflowX: "hidden",
  width: `0px`,

  [theme.breakpoints.down("md")]: {
    width: 0,
    border: 0,
    position: "absolute",
  },
});

export const ChatDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: chatDrawerWidth,
  minWidth: chatDrawerWidthMin,
  backgroundColor: theme.palette.primary.main,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
  [theme.breakpoints.up("md")]: {
    width: chatDrawerWidth,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));
