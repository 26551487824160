export const Terms = [
  {
    title: "",
    text: [
      "Please read these terms and conditions carefully before using Our Service runewager.com is operated by OffTick Limited ",
      `Making an account on Runewager.com means you fully understand and agree to these terms`,
      `INTERPRETATION AND DEFINITIONS <br/> Interpretation <br/> The words of which the initial letter is capitalized have meanings defined under the following conditions. <br/> The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.`,
      `Definitions <br/> For the purposes of these Terms and Conditions: <br/> - <b>Account</b> means a unique account created for You to access our Service or parts of our Service. <br/> - <b>Company</b> (referred to as either "the Company," "We," "Us," or "Our" in this Agreement) refers to GapWin. <br/> - <b>Tokens</b> refer to the funds/credits offered for sale on the website. <br/>- <b>Orders</b> mean a request by You to purchase tokens from Us. <br/> - <b>Service</b> refers to the Website. <br/> - <b>Terms and Conditions</b> (also referred to as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.`,
    ],
  },
  {
    title: "1)  BUG BOUNTIES",
    text: [
      `*Runewager.com and the Company reserves the right to void any balance we deem mischievously gotten through exploits or bugs found on our website. Instead we recommend you report these bugs for bounty rewards. Reward sizes will be determined based on the severity of the exploit and the exposure it may cause.`,
    ],
  },
  {
    title: "2)  ACKNOWLEDGEMENT",
    text: [
      `These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.`,
      `Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions apply to all visitors, users, and others who access or use the Service.`,
      `By accessing or using the Service, You agree to be bound by these Terms and Conditions. If  You disagree with any part of these Terms and Conditions, then You may not access the Service.`,
      `By accessing or using the Services, You agree that you are 18 years or older. If You are not 18 years or older, Runewager and all its offerings are not accessible. `,
      `Registration is prohibited from the following places: Afghanistan, Algeria, Angola, Bangladesh, Bolivia, Burkina Faso, Burundi, Cambodia, Cameroon, Central African Republic, Chad, Colombia, Congo Democratic, Côte d’Ivoire, Cyprus, Egypt, Eritrea, Ethiopia, Gambia, Guinea, Guinea Bissau, Haiti, India, Indonesia, Iran, Iraq, Israel, Kenya, Laos, Lebanon, Lesotho, Liberia, Libya, Madagascar, Mali, Mozambique, Myanmar, Nepal, Nigeria, North Korea, Pakistan, Palestinian Territory, Panama, Paraguay, Philippines, Russia, Sao Tome and Principe, Saudi Arabia, Sierra Leone, Somalia, Sri Lanka, Sudan, Syria, Tajikistan, Tanzania, Tunisia, Turkey, Uganda, Ukraine, United Kingdom, Vanuatu, Venezuela, Washington State of the United States, Yemen, Zambia, Zimbabwe.  We reserve the right to refuse customers from any other countries over and above the aforementioned jurisdictions at our own discretion.`,
      `For legal reasons, some or all residents or persons located in certain countries, in addition to those listed in the paragraph above, may be prohibited from accessing certain services on the Website. This Website is not intended to be used by persons in countries where such activities are prohibited. You are responsible for researching the laws applicable in your locality or country. You must ensure that you are complying with the law in the jurisdiction in which you are using the Website or any Service.`,
      `Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use, and disclosure of Your personal information when You use the Application or the Website and tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.`,
    ],
  },
  {
    title: "3) GRANT OF LICENSE",
    text: [
      `We grant You a non-exclusive, personal, non-transferable right to use Our Service on any device able to connect the internet in your possession. All Games and Services will be only available online on the Website.`,
      `The Service is only for Adults; therefore, Minors below the age of 18 are not permitted to access the Website or use any Services on the Website. We do not allow gambling below the age of 18, even if it is allowed under Your jurisdiction.`,
      `You shall notify Us without delay upon becoming aware that an unauthorized person (every person, except the account owner is unauthorized), was on your account and make sure that this shall not happen again. If no notice is sent right away, We shall see it as a violation of our Terms of Service and may terminate your Account without notice or delay.`,
      `PLACING ORDERS FOR TOKENS <br/> By placing an Order for TOKENS through the Service, You warrant that You are legally capable of entering into binding contracts.`,
    ],
  },
  {
    title: "4) YOUR INFORMATION",
    text: [
      `If You wish to place an Order for tokens available on the Service, You may be asked to supply certain information relevant to Your Order, including, without limitation, Your credit card number, the expiration date of Your credit card, and Your billing address.`,
      `You represent and warrant that: (i) You have the legal right to use any credit or debit card(s) or other payment method(s) in connection with any Order; and that (ii) the information You supply to us is true, correct, and complete, and you are at the age of 18 or older.`,
      `By submitting such information, You grant us the right to provide the information to payment processing third parties for purposes of facilitating the completion of Your Order.`,
    ],
  },
  {
    title: "5) ORDER CANCELATION",
    text: [
      `We reserve the right to refuse or cancel Your Order at any time for certain reasons, including but not limited to:`,
      `- Tokens availability <br/>- Errors in the description or prices for tokens <br/>- Errors in Your Order <br/>We reserve the right to refuse or cancel Your Order if fraud or an unauthorized or illegal transaction is suspected.`,
      `Your Order Cancellation Rights <br/> Any tokens you purchase can only be returned in accordance with these Terms and Conditions and Our Returns Policy.`,
      `Our Returns Policy forms a part of these Terms and Conditions. Please read our Returns Policy to learn more about your right to cancel Your Order.`,
      `Your right to cancel an Order only applies to tokens that are returned in the same condition as You received them. You should also include all of the products' instructions and documents. tokens that are partially used or not in the same condition as You received them or are used in any way will not be refunded. You should, therefore, take reasonable care of the purchased tokens while they are in Your possession.`,
      `You will not have any right to cancel an Order for the supply of any of the following tokens:      `,
      `- The supply of tokens which are, after delivery, according to their nature, inseparably mixed with other items. <br/> - The supply of digital content which is not supplied on a tangible medium if the performance has begun with Your prior express consent and You have acknowledged Your loss of cancellation right.`,
      `Availability, Errors and Inaccuracies <br/> We are constantly updating Our offerings of tokens on the Service. The tokens available on Our Service may be mispriced, described inaccurately, or unavailable, and We may experience delays in updating information regarding our tokens on the Service and in Our advertising on other websites.`,
      `We cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability, and services. We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.`,
    ],
  },
  {
    title: "6) PRICES POLICY",
    text: [
      `The Company reserves the right to revise its prices at any time prior to accepting an Order.`,
      `The prices quoted may be revised by the Company subsequent to accepting an Order in the event of any occurrence affecting delivery caused by government action, variation in customs duties, increased shipping charges, higher foreign exchange costs, and any other matter beyond the control of the Company. In that event, You will have the right to cancel Your Order.`,
      `For further information, investigate our Privacy Policy.`,
      `Payments <br/> All tokens purchased are subject to a one-time payment. Payment can be made through various payment methods we have available, such as Visa, MasterCard, Affinity Card, American Express cards, or online payment methods (PayPal, for example).`,
      `Payment cards (credit cards or debit cards) are subject to validation checks and authorization by Your card issuer. If we do not receive the required authorization, We will not be liable for any delay or non-delivery of Your Order.`,
    ],
  },
  {
    title: "7) USER ACCOUNTS",
    text: [
      `When You create an account with Us, You must provide Us information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of Your account on Our Service. You are responsible for safeguarding the password that You use to access the Service and for any activities or actions under Your password, whether Your password is with Our Service or a Third-Party Social Media Service.`,
      `You agree not to disclose Your password to any third party. You must notify Us immediately upon becoming aware of any breach of security or unauthorized use of Your account.`,
      `You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than You without appropriate authorization, or a name that is otherwise offensive, vulgar or obscene.`,
      `You cannot transfer, sell, or pledge Your Account to another person. This prohibition includes the transfer of any assets of value of any kind, including but not limited to ownership of accounts, winnings, deposits, bets, rights, and/or claims in connection with these assets, legal, commercial, or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation, and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, foundation, and/or association in any way shape or form.`,
      `We may ask for additional Information according to our AML/KYC Policy for KYC purposes or for legal purposes (Money laundering prevention).`,
    ],
  },
  {
    title: "8) INTELLECTUAL PROPERTY",
    text: [
      `The Service and its original content (excluding Content provided by You or other users), features, and functionality are and will remain the exclusive property of the Company and its licensors.`,
      `The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.`,
      `Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.`,
      `LINKS TO OTHER WEBSITES <br/> Our Service may contain links to third-party websites or services that are not owned or controlled by the Company.`,
      `The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that the Company shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.`,
      `We strongly advise You to read the terms and conditions and privacy policies of any third-party websites or services that You visit.`,
    ],
  },
  {
    title: "9) TERMINATION",
    text: [
      `We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.`,
      `Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.`,
    ],
  },
  {
    title: "10) LIMITATION OF LIABILITY",
    text: [
      `Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by You through the Service or 50 USD if You haven't purchased anything through the Service.`,
      `To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, loss of data or other information, for business interruption, for personal injury, loss of privacy arising out of or in any way related to the use of or inability to use the Service, third-party software, and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms), even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.`,
      `Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply. In these states, each party's liability will be limited to the greatest extent permitted by law.`,
    ],
  },
  {
    title: `11) "AS IS" AND "AS AVAILABLE" DISCLAIMER`,
    text: [
      `The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its Shareholders and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory, or otherwise, with respect to the Service, including all implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement, and warranties that may arise out of the course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking and makes no representation of any kind that the Service will meet Your requirements, achieve any intended results, be compatible or work with any other software, applications, systems, or services, operate without interruption, meet any performance or reliability standards, or be error-free or that any errors or defects can or will be corrected.`,
      `Without limiting the foregoing, neither the Company nor any of the company's providers makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs, or other harmful components.`,
      `Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case, the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.`,
    ],
  },
  {
    title: "12) RWT & TOKEN PURCHASE",
    text: [
      `First and foremost, our tokens have no real world value. Both monetary or otherwise. It is understood when someone purchases our tokens that you are buying an item that has no value.`,
    ],
  },
  {
    title: "12a) RWT subsection A",
    text: [
      `Real world trading is against our TOS, and you are subject to immediate ban for participating in and/or discussing it in our provided chats or around us in anyway`,
    ],
  },
  {
    title: "13) PROMO ABUSE",
    text: [
      `Runewager reserves the right AT ANY TIME to ban anyone we deem promo abusing or similar. `,
    ],
  },
  {
    title: "14) THREATS",
    text: [
      `We have zero tolerance for anything we deem as a serious threat towards us, our staff and our associates. <br/> -With this said, we hold the right to ban anyone we deem poses a threat and/or threatens us. <br/>-We also hold the right to limit any and all functionality to our businesses, including but not limited to stopping affiliate payouts. `,
    ],
  },
  {
    title: "15) GUIDELINES",
    text: [
      `Runewager reserves the right to ban or mute someone on our platform that we deem is harming our business. This is a very general and broad term. Entering into our discord server, or using our website, you automatically agree that Runewager staff have the right but not limited to changing and/or fully disabling use of our platform as we see fit. This is regardless if you have funds on our platforms or not, although we will make best efforts IF POSSIBLE to fully payout any gold or tokens you have as balance. We reserve the right to ban if we've made best efforts to withdraw the affected party and they refuse to accept the withdraw`,
    ],
  },
  {
    title: "16) Min Deposits / Min Withdraws",
    text: [
      `You automatically agree when you sign up to Runewager.com that you understand our minimum withdrawal and deposit amounts. As of May 15, 2024 they are as follows:`,
      `Min deposit: 1 token value <br/> Min withdraw: 5 token value`,
    ],
  },
  {
    title: "16.1) Token value / Gold value ",
    text: [
      `Token value is zero; however, we can trade in tokens for in-game gold which holds ZERO in real life value. `,
      `RW (Runewager) decides (at any time) the value of the tokens. `,
      `RW (Runewager) reserves the right to change these amounts at any time as we see fit.`,
      `RW (Runewager) reserves the right to also change the token value at any time as we see fit. If we change the token value, you will be compensated to receive the same token value in RSGP. However, this is not guaranteed as RSGP rapidly changes in value daily. It is understood that RSGP changes in value, and RW reserves the right to pay according to those changes. `,
      `EXAMPLE: If you deposit GP valued at $160 dollars and when you deposit, the GP is valued at $145, RW is not responsible to make-up the difference OR pay you the difference. This is the value we deem necessary and we are only responsible for providing the current market price. It is the duty of the customer to understand these terms and you automatically agree to them when you sign up. `,
      `We (Runewager) cannot be expected to pay the difference of a changing market and we will not be taken advantage of because of the market changing in value. `,
      `We (Runewager) reserve the right to take any actions against people who may try and exploit us financially up to but not excluding account termination. `,
    ],
  },
  {
    title: "16.2) Muling ",
    text: [
      `Anyone attempting to mule or otherwise “safely” hold RSGP in Runewager wallet system to then be withdraw later, is subject to receiving the SAME value they deposited UNLESS:`,
      `- If our systems detect a person to be muling, you will be subject to wager 100% of the value before a withdrawal will be permitted <br/>- The RSGP gets banned before you wager 100% of the value <br/>- As an example: If depositing crypto and attempting to withdraw gold, you will be subject to the VALUE at time of deposit. This means that if you “gain” RSGP by withdrawing, you will receive the amount of VALUE you deposited in crypto, NOT the updated GP VALUE at time of withdrawal. This example applies every different way with whatever currency we offer. <br/>`,
    ],
  },
  {
    title: "17) Affiliate Abuse",
    text: [
      `We (Runewager) reserve the right to limit or fully discontinue the affiliate program for any person or but not limited to groups of people we deem are abusing the affiliate system. This could include hard capping your affiliate rewards percent at the beginning 10% or but not limited to removing any affiliate rewards accrued. `,
      `- We do not require proof to do this. <br/>- We do not need to notify the affected party(s) involved. <br/>- We are not required to disclose further affiliate information, we will do this AT WILL basis only. <br/>- “Affiliate abuse” is at staff discretion.`,
    ],
  },
];
