import { Stack, Typography, Avatar } from "@mui/material";
import { getPlayerLevelData, RoundNumber } from "../../utils";
import { DuelDocument } from "../slices/duelSlice";
import { theme } from "../theme";
import { useEffect, useState, useRef } from "react";
import { Button } from "../button";
import { TokenIcon } from "../tokenIcon";
import WhipImg from "../../assets/duel/whip.png";
import DDSImg from "../../assets/duel/dds.png";
import { DUELTYPE } from "../../constants";
import { TOKENTYPE } from "../../types/index.d";
export enum DUELSTATUS {
  ACTIVE = "ACTIVE",
  COMPLETE = "COMPLETE",
  CANCELLED = "CANCELLED",
}

const PlayerCard = ({
  name,
  rsn,
  payout,
  level,
  streak,
  textColor,
  isFree,
}: {
  name: string;
  rsn: string;
  payout: number;
  level: number;
  streak: number;
  textColor: any;
  isFree: boolean;
}) => {
  const getPlayerLevel = getPlayerLevelData(level);
  return (
    <Stack
      sx={{
        p: { md: 2, xs: 1.5 },
        pb: { md: 1, xs: 0.5 },
        backgroundColor: "#292929",
        borderRadius: 2,
        height: "max-content",
        position: "relative",
        width: "6em",
      }}
      gap={0.75}
      alignItems="center"
    >
      <Typography
        fontWeight={600}
        sx={{ position: "absolute", top: "2.5%", right: "5%" }}
        color={streak > 0 ? theme.palette.success.main : "error"}
      >
        {streak > 0 && "+"}
        {streak}
      </Typography>

      <Typography color="text.primary">{name}</Typography>
      <Avatar
        variant="square"
        src={getPlayerLevel.imgSrc}
        sx={{
          "& .MuiAvatar-img": {
            objectFit: "contain",
          },
        }}
      />
      <Typography
        color="text.primary"
        sx={{
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          p: 0.5,
          px: 1,
          borderRadius: 2,
          textAlign: "center",
        }}
      >
        {rsn}
      </Typography>
      <Stack direction="row" gap={0.5} alignItems="center">
        <TokenIcon width={15} freeCash={isFree} />
        <Typography color={textColor}>
          {textColor === theme.palette.success.main && "+"}
          {payout}
        </Typography>
      </Stack>
    </Stack>
  );
};

const getOpacity = (showWin: boolean, isWin: boolean) => {
  if (!showWin) {
    return 1;
  }
  if (showWin && isWin) {
    return 1;
  }
  return 0.5;
};

const getTextColor = (showWin: boolean, isWin: boolean) => {
  if (!showWin) {
    return theme.palette.text.primary;
  }
  if (isWin) {
    return theme.palette.success.main;
  }
  return theme.palette.error.main;
};

const getPayout = (
  showWin: boolean,
  bet: number,
  payout: number,
  isBot: boolean,
  isWin: boolean
) => {
  if (!showWin) {
    return bet;
  }
  if (showWin) {
    if (isBot) {
      if (isWin) {
        return payout;
      }
      return bet - payout;
    }
    if (isWin) {
      return payout;
    }
  }
  return -1 * bet;
};

export const MatchCard = ({
  duel,
  userId,
  handleCancel,
}: {
  duel: DuelDocument;
  userId: string;
  handleCancel: (duel: DuelDocument) => void;
}) => {
  const { status, isWin, bet, tokenType } = duel;
  const [timer, setTimer] = useState("");
  const timerIntervalRef = useRef<NodeJS.Timeout>();

  function formatTime(milliseconds: any) {
    const minutes = Math.floor(milliseconds / (60 * 1000));
    const seconds = Math.floor((milliseconds % (60 * 1000)) / 1000);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }

  useEffect(() => {
    if (status === DUELSTATUS.ACTIVE) {
      const expireAt = new Date(duel.expireAt).getTime(); // Convert expireAt to milliseconds
      timerIntervalRef.current = setInterval(() => {
        const timeLeft = expireAt - Date.now(); // Calculate remaining time
        setTimer(formatTime(timeLeft)); // Display remaining time

        if (timeLeft <= 0) {
          clearInterval(timerIntervalRef.current);
          setTimer("0");
        }
      }, 1000);
    }

    return () => {
      if (timerIntervalRef.current) {
        clearInterval(timerIntervalRef.current);
      }
    };
  }, [duel]);

  const showWin = status === DUELSTATUS.COMPLETE;
  const isFree = tokenType === TOKENTYPE.FREE;
  return (
    <Stack
      key={duel._id}
      sx={{
        p: 2,
        backgroundColor: theme.palette.primary.main,
        height: "fit-content",
        transition: "all 250ms",
        "&:hover": {
          transform: "scale(1.025)",
          boxShadow: "3px 5px 10px 1px rgba(0,0,0,0.5)",
        },
      }}
      gap={1}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack sx={{ opacity: getOpacity(showWin, isWin) }}>
        <PlayerCard
          name={duel.userName}
          level={duel.userLevel || 0}
          rsn={duel.user === userId ? duel.userRsn : "Hidden"}
          payout={RoundNumber(
            getPayout(showWin, bet, duel.payout, false, isWin)
          )}
          textColor={getTextColor(showWin, isWin)}
          streak={duel.userStreak || 0}
          isFree={isFree}
        />
      </Stack>
      <Stack
        justifyContent="space-between"
        alignItems="center"
        gap={3}
        sx={{ width: { sm: "10%", xs: "7.5%" } }}
      >
        {status === DUELSTATUS.ACTIVE && (
          <Typography color="text.primary" variant="h5">
            {timer}
          </Typography>
        )}
        <Stack sx={{ width: "3em" }}>
          <img src={duel.duelType === DUELTYPE.WHIP ? WhipImg : DDSImg} />
        </Stack>
        {duel.user === userId &&
          status === DUELSTATUS.ACTIVE &&
          duel.canCancel && (
            <Button
              variantType="error"
              sx={{ width: "70%", p: 0.5 }}
              onClick={() => handleCancel(duel)}
            >
              Cancel
            </Button>
          )}
      </Stack>
      <Stack sx={{ opacity: getOpacity(showWin, !isWin) }}>
        <PlayerCard
          name={duel.staker}
          rsn={duel.user === userId ? duel.stakerRsn : "Hidden"}
          level={duel.stakerLevel || 0}
          payout={RoundNumber(
            getPayout(showWin, bet, duel.payout, true, !isWin)
          )}
          textColor={getTextColor(showWin, !isWin)}
          streak={duel.stakerStreak || 0}
          isFree={isFree}
        />
      </Stack>
    </Stack>
  );
};
