import { Checkbox, Stack, Typography } from "@mui/material";
import { InputField } from "../inputField";
import { Formik, Form } from "formik";
import { initialValues, validationSchema } from "./constants";
import { loginUser, selectPlayer, setErrorMessage } from "../slices/userSlice";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "../store";
import { useAppSelector } from "../store/hooks";
import { Button } from "../button";
import { useRef, useEffect, useState } from "react";
import ForgetPassword from "./forgetPassword";
import HCaptcha from "../HCaptcha";
import { useTurnstile } from "react-turnstile";

const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, errorMessage } = useAppSelector(selectPlayer);
  const [token, setToken] = useState<any>(null);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const turnstile = useTurnstile();

  const submitHandler = async (values: {
    userName: string;
    password: string;
  }) => {
    if (!token) {
      dispatch(setErrorMessage("Please complete the HCaptcha"));
      return;
    }

    const { password, userName } = values;
    const userData = { password, userName, token, stayLoggedIn };
    dispatch(loginUser(userData));
  };
  useEffect(() => {
    if (errorMessage && token) {
      setToken(null);
      turnstile.reset();
    }
  }, [errorMessage]);

  if (isForgetPassword) {
    return <ForgetPassword setIsForgetPassword={setIsForgetPassword} />;
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, submitForm, handleSubmit, errors }) => (
          <Form>
            <Stack gap={1.5}>
              <Stack direction="column">
                <Typography fontWeight={500} color="text.primary">
                  Username
                </Typography>
                <InputField
                  inputProps={{ maxLength: 12 }}
                  id="userName"
                  placeholder="Username"
                  name="userName"
                  type="userName"
                />
              </Stack>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography fontWeight={500} color="text.primary">
                  Password
                </Typography>
                <InputField
                  fullWidth
                  type="password"
                  name="password"
                  placeholder="Password"
                />
              </Stack>
              <HCaptcha onVerify={setToken} forceNormal />
            </Stack>

            {errorMessage && (
              <Typography sx={{ py: 2 }} fontWeight={500} color="error">
                {errorMessage}
              </Typography>
            )}
            <Stack direction="row" gap={0.25} alignItems="center">
              <Checkbox
                color="success"
                sx={{
                  pl: 0,
                  color: "#32c932",
                  "&.Mui-checked": {
                    color: "#32c932",
                  },
                }}
                checked={stayLoggedIn}
                onChange={(_e, value) => setStayLoggedIn(value)}
              />
              <Typography color="text.primary">
                Stay logged in for 30 days
              </Typography>
            </Stack>

            <Stack gap={1} sx={{ mt: 2 }}>
              <Button
                disabled={loading || !token}
                variantType="success"
                fullWidth
                type="submit"
              >
                Sign In
              </Button>
              <Button
                color="error"
                fullWidth
                onClick={() => setIsForgetPassword(true)}
              >
                Forgot Password
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Login;
