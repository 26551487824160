import { Container } from "@mui/material";
import Dice from "../../../components/dice";
import GameFeed from "../../../components/gameFeed";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import { GAMES } from "../../../constants/games";

const DicePage = () => {
  return (
    <Container maxWidth="md">
      <Dice />
      <DescWrapper game={GAMES.DICE} />
      <GameFeed />
    </Container>
  );
};

export default DicePage;
