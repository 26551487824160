import { theme } from "../theme";
import { Stack, Typography, Box, Modal } from "@mui/material";

import { Button } from "../button";
import { InputFieldStyled } from "../inputField";
import { RoundNumber } from "../../utils";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 200, md: 350 },
  zIndex: 10,
  p: 2,
  borderRadius: 4,
};

export const RematchModal = ({
  currentBet,
  setCurrentBet,
  open,
  handleClose,
  handleRematch,
  stakerName,
  handleDoubleBetButton,
}: any) => {
  return (
    <Modal open={open} onClose={handleClose} disableScrollLock={true}>
      <Box sx={modalStyle}>
        <Stack
          sx={{
            p: 3,
            backgroundColor: theme.palette.primary.main,
          }}
          gap={3}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-Between"
          >
            <Typography variant="h5" color="text.primary">
              Rematch {stakerName}
            </Typography>
          </Stack>
          <Stack
            direction={{ md: "row", xs: "column" }}
            alignItems="center"
            justifyContent="space-Between"
            gap={1}
          >
            <InputFieldStyled
              type="number"
              value={!currentBet ? null : currentBet}
              placeholder="0.01"
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
              onChange={(e) =>
                setCurrentBet(RoundNumber(Number(e.target.value)))
              }
              inputProps={{
                min: 0,
              }}
              // inputRef={betFieldRef}
              // error={currentBet > player.balance || !currentBet}
              // disabled={!accessToken || isAutoPlaying}
            />
            <Stack
              direction="row"
              gap={1}
              sx={{ width: { xs: "100%", md: "auto" } }}
              justifyContent={{ xs: "space-between" }}
            >
              <Button onClick={() => handleDoubleBetButton()}>x2</Button>
              <Button
                onClick={() =>
                  setCurrentBet(
                    currentBet / 2 < 1 ? 1 : RoundNumber(currentBet / 2)
                  )
                }
              >
                /2
              </Button>
            </Stack>
          </Stack>
          <Stack direction="column" gap={1.25}>
            <Stack direction="row" gap={1}>
              <Button fullWidth variantType="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button fullWidth variantType="success" onClick={handleRematch}>
                Rematch
              </Button>
            </Stack>
            <Stack
              sx={{ display: { md: "none", sm: "block", xs: "block" } }}
            ></Stack>
            {/* {errorMessage && (
        <Typography color="error">{errorMessage}</Typography>
      )} */}
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};
