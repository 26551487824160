import * as Yup from "yup";

export const initialValues = {
  userName: "",
  password: "",
};

export const validationSchema = Yup.object({
  userName: Yup.string()
    .max(255, "Username must be at most 255 characters")
    .required("Username is required"),
  password: Yup.string().required("Password is a required field"),
});
