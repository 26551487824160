import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Stack, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/WarningAmber";
import { Button } from "../button";
import { theme } from "../theme";

interface IConfirmationDialog {
  open: boolean;
  handleConfirm: () => void;
  handleCancel: () => void;
  text: string;
  title?: string;
}

const dialogPaperStyle = {
  backgroundColor: theme.palette.primary.main, // Change the background color
  borderRadius: 6,
  boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)", // Custom box-shadow
  // Add more custom styles as needed
};

export const ConfirmationDialog = ({
  open,
  handleConfirm,
  handleCancel,
  text,
  title = "Are you sure you want to continue?",
}: IConfirmationDialog) => {
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  React.useEffect(() => {
    if (open && isLoading) {
      setIsLoading(false);
    }
  }, [open]);
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      disableScrollLock={true}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <Stack
        sx={{
          bgcolor: theme.palette.primary.main,
          boxShadow: 24,
          py: 1,
          px: { md: 10, sm: 5, xs: 3 },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ py: 2 }}
          gap={5}
          justifyContent="space-between"
        >
          <Typography variant="h5">{title}</Typography>
          <WarningIcon fontSize="large" />
        </Stack>
        <DialogContent sx={{ px: 0 }}>
          <Typography variant="h6" color="text.primary">
            {text}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variantType="error" onClick={handleCancel}>
            No
          </Button>
          <Button
            variantType="success"
            disabled={isLoading}
            onClick={() => {
              setIsLoading(true);
              handleConfirm();
            }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
