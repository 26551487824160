import { Stack, Button } from "@mui/material";
import { theme } from "../theme";

interface IModal {
  buttonData: any[];
  changeModalType: (type: any) => void;
  modalType: string;
}

const SelectionComponent = ({
  changeModalType,
  buttonData,
  modalType,
}: IModal) => {
  return (
    <>
      <Stack direction="row" gap={2}>
        {buttonData.map((button) => (
          <Button
            sx={{
              border: "1px solid white",
              width: "100px",
              fontWeight: 400,
              p: 1,
              borderRadius: "5px",
              bgcolor: modalType === button.type ? "#292929" : "#202020",
              color: modalType === button.type ? "#33EC3D" : "white",
            }}
            onClick={() => changeModalType(button.type)}
          >
            {button.label}
          </Button>
        ))}
      </Stack>
    </>
  );
};

export default SelectionComponent;
