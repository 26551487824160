import * as Yup from "yup";

export const initialValues = {
  userName: "",
  referral: "",
  email: "",
  password: "",
  reEnterPassword: "",
};

export const validationSchema = Yup.object({
  userName: Yup.string()
    .max(255, "Username must be at most 255 characters")
    .required("Username is required"),
  email: Yup.string()
    .email("Email must be valid")
    .max(255, "Email should be less than 255 characters")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password should be at least 8 characters")
    .required("Password is a required field")
    .max(24, "Password should be less than 24 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
      "Password must be at least 8 characters long and contain at least one uppercase letter and at least one number."
    )
    .oneOf([Yup.ref("reEnterPassword")], "Passwords must match"),
  reEnterPassword: Yup.string()
    .min(8, "Password should be at least 8 characters")
    .required("Repeat password is a required field")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  // referral: Yup.string(),
});
