import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { CHATTYPES } from "../../constants/chat";
import { exchangeData } from "../../constants/exchange";
import ExchangeService from "../../services/exchange";
import { IMessage, ORDERSTATUS } from "../../types/index.d";
import { RootState } from "../store";

export interface Order {
  _id: string;
  user: { _id: string };
  type: string;
  amount: number;
  tokens: number;
  status: ORDERSTATUS;
  inGameUserName: string;
  createdAt: string;
  updatedAt: string;
  cryptoType: string;
  cashierUserName: string;
  freeToken?: number;
  accounts?: number;
  pastDeposits?: number;

  // Crypto
  payment_id?: string;
  payment_status?: string;
  pay_address?: string;
  price_amount?: number;
  price_currency?: string;
  pay_amount?: number;
  amount_received?: number;
  pay_currency?: string;
  expiration_estimate_date?: string;
  order_created_at?: string;
}
interface OrderState {
  orderChat: IMessage[];
  loading: boolean;
  openOrder: Order;
  errorMessage: string;
  orderType: CHATTYPES | null;
  allOrders: any[];
}

const initialState: OrderState = {
  orderChat: [],
  loading: false,
  openOrder: {} as Order,
  errorMessage: "",
  orderType: null,
  allOrders: [],
};

export const getAllOrder = createAsyncThunk(
  "order/allOrders",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const orders = await ExchangeService.getAllOrders(accessToken);

      return orders;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const getOpenOrder = createAsyncThunk(
  "order/openOrder",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const orders = await ExchangeService.getAllOrders(accessToken, false);

      return orders;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export interface NewOrderInputData {
  type: string;
  amount: number;
  inGameUsername: string;
  cryptoType?: string;
}

export const createOrder = createAsyncThunk(
  "order/newOrder",
  async (orderInputData: NewOrderInputData, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const order = await ExchangeService.post(
        orderInputData.type,
        orderInputData.amount,
        accessToken,
        orderInputData.inGameUsername,
        orderInputData.cryptoType
      );

      return order;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export interface UpdateStatusInputData {
  id: string;
  newStatus: string;
}

export const updateStatus = createAsyncThunk(
  "order/updateStatus",
  async (orderInputData: UpdateStatusInputData, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const order = await ExchangeService.updateStatus(
        orderInputData.id,
        orderInputData.newStatus,
        accessToken
      );

      return order;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

export const orderReducer = createSlice({
  name: "order",
  initialState,
  reducers: {
    addOrderMessage(state, action: PayloadAction<IMessage>) {
      if (state.orderChat) {
        state.orderChat.push(action.payload);
      } else {
        state.orderChat = [action.payload];
      }
    },
    clearErrorMessage(state) {
      state.errorMessage = "";
    },
    handleOrder(
      state,
      action: PayloadAction<{ messages: IMessage[]; order: Order }>
    ) {
      const order = action.payload.order;

      if (order) {
        if (
          order.status === ORDERSTATUS.COMPLETED ||
          order.status === ORDERSTATUS.CANCELLED
        ) {
          state.orderChat = [];
          state.openOrder = {} as Order;
          state.orderType = null;
        } else {
          state.openOrder = order;
          const typeOfOrder = exchangeData.find(
            (data) => data.type === order.type
          );
          state.orderType =
            (typeOfOrder?.exchangeType as unknown as CHATTYPES) || null;
          state.orderChat = action.payload.messages;
        }
      }
      if (!order) {
        state.orderChat = [];
        state.openOrder = {} as Order;
        state.orderType = null;
      }
      state.loading = false;
    },
    handleOrderUpdateState(state, action) {
      if (state.openOrder?._id === action.payload._id) {
        state.orderType = null;
        state.openOrder = {} as Order;
        state.orderChat = [];
      }
      if (action.payload && state.allOrders?.length) {
        const type = exchangeData.find(
          (data) => data.type === action.payload.type
        );
        state.allOrders = state.allOrders.map((order) =>
          order._id === action.payload._id
            ? { ...action.payload, ...type }
            : order
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOrder.fulfilled, (state, action) => {
        const order = action.payload.order;
        state.openOrder = order;
        const typeOfOrder = exchangeData.find(
          (data) => data.type === order.type
        );
        state.orderType =
          (typeOfOrder?.exchangeType as unknown as CHATTYPES) || null;
        state.allOrders = [{ ...order, ...typeOfOrder }, ...state.allOrders];
        state.loading = false;
        state.errorMessage = "";
      })
      .addCase(createOrder.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(createOrder.pending, (state) => {
        state.loading = true;
        state.errorMessage = "";
      })
      .addCase(getAllOrder.fulfilled, (state, action) => {
        if (action.payload.length) {
          const formatedOrders = action.payload.map((order: any) => {
            const type = exchangeData.find((data) => data.type === order.type);
            return { ...order, ...type };
          });
          state.allOrders = formatedOrders;
        }
        state.loading = false;
      })
      .addCase(getAllOrder.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(getAllOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOpenOrder.fulfilled, (state, action) => {
        const order = action.payload.order;
        if (order) {
          state.openOrder = order;
          const typeOfOrder = exchangeData.find(
            (data) => data.type === order.type
          );
          state.orderType =
            (typeOfOrder?.exchangeType as unknown as CHATTYPES) || null;
        }
        state.orderChat = action.payload.messages;
        state.loading = false;
      })
      .addCase(getOpenOrder.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getOpenOrder.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStatus.fulfilled, (state, action) => {
        if (state.openOrder?._id === action.payload._id) {
          state.orderType = null;
          state.openOrder = {} as Order;
          state.orderChat = [];
        }
        if (action.payload && state.allOrders?.length) {
          const type = exchangeData.find(
            (data) => data.type === action.payload.type
          );
          state.allOrders = state.allOrders.map((order) =>
            order._id === action.payload._id
              ? { ...action.payload, ...type }
              : order
          );
        }

        state.loading = false;
      })
      .addCase(updateStatus.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateStatus.pending, (state) => {
        state.loading = true;
      });
  },
});

// Expose the actions
export const {
  addOrderMessage,
  handleOrderUpdateState,
  handleOrder,
  clearErrorMessage,
} = orderReducer.actions;

// Selectors
export const selectOrderChat = (state: RootState) => state.order.orderChat;
export const selectOpenOrder = (state: RootState) => state.order.openOrder;
export const selectOrderType = (state: RootState) => state.order.orderType;
export const selectAllOrders = (state: RootState) => state.order.allOrders;
export const selectOrderErrorMessage = (state: RootState) =>
  state.order.errorMessage;

export const selectOrderLoading = (state: RootState) => state.order.loading;
// other selectors

// Reducer
export default orderReducer.reducer;
