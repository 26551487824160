import {
  Card,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Container,
  Tabs,
  Tab,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../components/store";
import { useState } from "react";
import styles from "./index.module.scss";
import FairnessTable from "./fairnessTable";
import { PastGame } from "./pastGame";
import { useParams } from "react-router-dom";
import { GameData, GAMES } from "../../constants/games";

export const getColor = (isTrue: boolean) => {
  if (isTrue) {
    return "#33EC3D !important";
  }
  return "grey !important";
};

export enum FAIRNESSGAMES {
  DICE = "DICE",
  LIMBO = "LIMBO",
  COIN_FLIP = "COIN_FLIP",
  MINES = "MINES",
  KENO = "KENO",
  PLINKO = "PLINKO",
  SLIDE = "SLIDE",
  BLACKJACK = "BLACKJACK",
  HILO = "HILO",
}

function getKeyFromUrl(url: string) {
  for (const key in GameData) {
    if (GameData[key as GAMES]?.url === url) {
      return key as FAIRNESSGAMES;
    }
  }
  return null; // URL not found in the game data
}

const Fairness = () => {
  const gamesArray = Object.values(FAIRNESSGAMES);
  const { tab } = useParams();
  const [selection, setSelection] = useState(
    tab ? getKeyFromUrl(tab as GAMES) || gamesArray[0] : gamesArray[0]
  );
  const [selectedGame, setSelectedGame] = useState<any>();

  const handleChange = (
    _event: React.SyntheticEvent,
    newAlignment: FAIRNESSGAMES
  ) => {
    setSelectedGame(null);
    setSelection(newAlignment);
  };
  return (
    <Container maxWidth="md">
      <Stack gap={2} sx={{ minHeight: "65vh", height: "100%" }}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Fairness Verification
        </Typography>
        <Stack gap={3}>
          <Tabs
            variant="scrollable"
            scrollButtons={true}
            allowScrollButtonsMobile
            value={selection}
            // exclusive
            sx={{
              boxShadow: 3,
              "& .MuiTabs-indicator": {
                background: "#33EC3D",
                backgroundColor: "#33EC3D",
              },
            }}
            onChange={handleChange}
            TabScrollButtonProps={{
              sx: { color: "#33EC3D !important" },
            }}
            selectionFollowsFocus
          >
            {gamesArray.map((each, index) => (
              <Tab
                sx={{
                  width: "100%",
                  color: getColor(selection === each),
                }}
                label={each?.replaceAll("_", " ") || ""}
                value={each}
                wrapped
              />
            ))}
          </Tabs>
          <FairnessTable
            selection={selection}
            setSelectedGame={setSelectedGame}
            selectedGame={selectedGame}
          />

          <PastGame game={selectedGame} selection={selection} />
        </Stack>
      </Stack>
    </Container>
  );
};

export default Fairness;
