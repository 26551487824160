import { Box, Stack } from "@mui/material";
import { useEffect } from "react";
import { Button } from "../button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { MODALTYPE } from "../../types/index.d";
import { openModal, selectBalance } from "../slices/userSlice";
import {
  BLACKGAMESTATE,
  Card,
  calculateHigherProbability,
  calculateHigherProbabilityWithoutSame,
  calculateLowerProbability,
  calculateLowerProbabilityWithoutSame,
  calculateMultiplier,
} from "./constants";
import { useAppSelector } from "../store/hooks";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DragHandleIcon from "@mui/icons-material/DragHandle";
interface GameProgressButtonProps {
  onClickNext: () => void;
  isTheLastGame?: boolean;
}
interface IButtons {
  accessToken: string;
  gameStatus: BLACKGAMESTATE | null;
  payout: number;
  disabled: boolean;
  isBalanceError: boolean;
  handleCreateGame: () => void;
  handlePayout: () => void;
  showPayout: boolean;
}
export const Buttons = ({
  accessToken,
  gameStatus,
  payout,
  isBalanceError,
  handleCreateGame,
  disabled,
  showPayout,
  handlePayout,
}: IButtons) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  if (!accessToken) {
    return (
      <Button onClick={() => handleLogin()} fullWidth>
        Login
      </Button>
    );
  }
  return (
    <>
      {gameStatus !== BLACKGAMESTATE.START ? (
        <Button
          variantType="success"
          disabled={isBalanceError || disabled}
          fullWidth
          onClick={() => handleCreateGame()}
        >
          {"Bet"}
        </Button>
      ) : (
        <Button
          variantType="success"
          disabled={!showPayout}
          fullWidth
          onClick={() => handlePayout()}
        >
          {"Payout"}
        </Button>
      )}
    </>
  );
};

interface IHiLoButtons {
  gameStatus: BLACKGAMESTATE | null;
  disabled: boolean;
  isBalanceError: boolean;
  currentCard: Card | undefined;
  doHigh: () => void;
  doLow: () => void;
  doSkip: () => void;
  doSame: () => void;
}

export const HighLowButtons = ({
  gameStatus,
  isBalanceError,
  disabled,
  currentCard,
  doHigh,
  doLow,
  doSkip,
  doSame,
}: IHiLoButtons) => {
  const high = currentCard ? calculateHigherProbability(currentCard) : null;
  const low = currentCard ? calculateLowerProbability(currentCard) : null;

  if (gameStatus !== BLACKGAMESTATE.START) return null;
  if (currentCard && currentCard.rank === "A") {
    const highWithoutSame = currentCard
      ? calculateHigherProbabilityWithoutSame(currentCard)
      : null;
    return (
      <Stack gap={1}>
        <Button
          disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
          sx={{ alignItems: "center", display: "flex" }}
          fullWidth
          onClick={doSame}
        >
          {"Same"} <DragHandleIcon /> {low}%
        </Button>
        <Button
          disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
          fullWidth
          sx={{ alignItems: "center", display: "flex" }}
          onClick={doHigh}
        >
          {"High"} <KeyboardDoubleArrowUpIcon /> {highWithoutSame}%
        </Button>

        <Button
          disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
          sx={{ alignItems: "center", display: "flex" }}
          fullWidth
          onClick={doSkip}
        >
          {"Skip Card"} <KeyboardDoubleArrowRightIcon />
        </Button>
      </Stack>
    );
  }

  if (currentCard && currentCard.rank === "K") {
    const lowWithoutSame = currentCard
      ? calculateLowerProbabilityWithoutSame(currentCard)
      : null;
    return (
      <Stack gap={1}>
        <Button
          disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
          sx={{ alignItems: "center", display: "flex" }}
          fullWidth
          onClick={doSame}
        >
          {"Same"} <DragHandleIcon /> {high}%
        </Button>
        <Button
          disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
          fullWidth
          sx={{ alignItems: "center", display: "flex" }}
          onClick={doLow}
        >
          {"Lower"} <KeyboardDoubleArrowDownIcon /> {lowWithoutSame}%
        </Button>

        <Button
          disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
          sx={{ alignItems: "center", display: "flex" }}
          fullWidth
          onClick={doSkip}
        >
          {"Skip Card"} <KeyboardDoubleArrowRightIcon />
        </Button>
      </Stack>
    );
  }
  return (
    <Stack gap={1}>
      <Button
        disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
        fullWidth
        sx={{ alignItems: "center", display: "flex" }}
        onClick={doHigh}
      >
        {"High or Same"} <KeyboardDoubleArrowUpIcon /> {high}%
      </Button>
      <Button
        disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
        sx={{ alignItems: "center", display: "flex" }}
        fullWidth
        onClick={doLow}
      >
        {"Low or Same"} <KeyboardDoubleArrowDownIcon /> {low}%
      </Button>

      <Button
        disabled={isBalanceError || gameStatus !== BLACKGAMESTATE.START}
        sx={{ alignItems: "center", display: "flex" }}
        fullWidth
        onClick={doSkip}
      >
        {"Skip Card"} <KeyboardDoubleArrowRightIcon />
      </Button>
    </Stack>
  );
};
