import { RoundNumber } from "../../utils";

export type Card = {
  suit: string;
  rank: string;
};

export enum BLACKGAMESTATE {
  INIT = "INIT",
  START = "START",
  END = "END",
}

export const getDeck = (numberOfDeck: number = 1): Card[] => {
  const suits = ["♠", "♣", "❤", "♦"];
  const ranks = [
    "A",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
  ];
  const deck: Card[] = [];

  for (let i = 0; i < numberOfDeck; i++) {
    suits.forEach((suit) => ranks.forEach((rank) => deck.push({ suit, rank })));
  }
  return deck;
};

const rankOrder = [
  "A",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "J",
  "Q",
  "K",
];

export const isAce = (card: Card): boolean => {
  return card?.rank === "A";
};

export const isKing = (card: Card) => card.rank === "K";

export const calculateHigherProbability = (card: Card): number => {
  const cardIndex = rankOrder.indexOf(card.rank);
  const higherCards = rankOrder.length - cardIndex; // Include the current card itself
  return RoundNumber((higherCards / rankOrder.length) * 100);
};

export const calculateLowerProbability = (card: Card): number => {
  const cardIndex = rankOrder.indexOf(card.rank);
  const lowerCards = cardIndex + 1; // Include the current card itself
  return RoundNumber((lowerCards / rankOrder.length) * 100);
};

export const calculateHigherProbabilityWithoutSame = (card: Card): number => {
  const cardIndex = rankOrder.indexOf(card.rank) + 1;
  const higherCards = rankOrder.length - cardIndex - 1; // Exclude the current card itself
  return RoundNumber((higherCards / (rankOrder.length - 1)) * 100);
};

export const calculateLowerProbabilityWithoutSame = (card: Card): number => {
  const cardIndex = rankOrder.indexOf(card.rank);
  const lowerCards = cardIndex - 1; // Exclude the current card
  return RoundNumber((lowerCards / (rankOrder.length - 1)) * 100);
};

export const calculateMultiplier = (probability: number): number => {
  const adjustedProbability = probability / 100 / (1 - 0.06);
  return RoundNumber(1 / adjustedProbability);
};
