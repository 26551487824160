import redIcons from "../../assets/slide/slide-red.svg";
import greenIcons from "../../assets/slide/slide-green.svg";
import blackIcons from "../../assets/slide/slide-black.svg";

export const slideImagesArray = [
  redIcons,
  blackIcons,
  redIcons,
  blackIcons,
  redIcons,
  blackIcons,
  redIcons,
  greenIcons,
  blackIcons,
  redIcons,
  blackIcons,
  redIcons,
  blackIcons,
  redIcons,
  blackIcons,
];

export const imageByType = [redIcons, blackIcons, greenIcons];

export const sliderImagesNumber = [0, 1, 0, 1, 0, 1, 0, 2, 1, 0, 1, 0, 1, 0, 1];
