import { Card, IconButton, Stack, Typography } from "@mui/material";
import { useAppSelector } from "../store/hooks";
import { selectOpenOrder, updateStatus } from "../slices/orderSlice";
import QRCode from "react-qr-code";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { closeModal } from "../slices/userSlice";
import { ORDERSTATUS } from "../../types/index.d";
import { AppDispatch } from "../store";
import { ConfirmationDialog } from "../confirmationModal";
import { Button } from "../button";
import { getOrderData } from "../../constants/exchange";

interface CountdownTimerProps {
  createdAt: string;
  expirationEstimateDate: string;
}

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  createdAt,
  expirationEstimateDate,
}) => {
  const calculateTimeLeft = (): TimeLeft | null => {
    const expirationTime = new Date(expirationEstimateDate).getTime();
    const createdTime = new Date(createdAt).getTime();
    const now = new Date().getTime();

    // Adjust the current time relative to the createdAt time.
    const elapsedTime = now - createdTime;
    const remainingTime = expirationTime - (createdTime + elapsedTime);

    if (remainingTime > 0) {
      return {
        days: Math.floor(remainingTime / (1000 * 60 * 60 * 24)),
        hours: Math.floor((remainingTime / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((remainingTime / (1000 * 60)) % 60),
        seconds: Math.floor((remainingTime / 1000) % 60),
      };
    }

    return null;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft | null>(
    calculateTimeLeft()
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, createdAt, expirationEstimateDate]);

  return (
    <div>
      {timeLeft ? (
        <Stack direction="row">
          <Typography color="text.primary">
            Expires In: {timeLeft.days > 0 ? `${timeLeft.days}d ` : ""}
            {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}:
            {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}:
            {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
          </Typography>
        </Stack>
      ) : (
        <Typography color="text.primary">Expired</Typography>
      )}
    </div>
  );
};

export const CryptoDepositModal = () => {
  const openOrder = useAppSelector(selectOpenOrder);
  const dispatch = useDispatch<AppDispatch>();
  const [openModal, setOpenModal] = useState(false);

  const handleCancelOrder = () => {
    if (openOrder) {
      setOpenModal(false);

      dispatch(
        updateStatus({ id: openOrder._id, newStatus: ORDERSTATUS.CANCELLED })
      );
    }
  };

  const copyLink = () => {
    navigator.clipboard
      .writeText(openOrder?.pay_address || "")
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };
  const copyAmount = () => {
    navigator.clipboard
      .writeText(`${openOrder?.pay_amount}` || "")
      .then(() => {})
      .catch((error) => {
        console.error("Failed to copy: ", error);
      });
  };

  useEffect(() => {
    if (!openOrder?.payment_status) {
      dispatch(closeModal());
    }
  }, [openOrder]);

  return (
    <>
      <ConfirmationDialog
        handleConfirm={handleCancelOrder}
        open={openModal}
        handleCancel={() => setOpenModal(false)}
        text="Are you sure you want to cancel?"
        title={`${getOrderData(openOrder?.type || "")?.label || ""} - ${
          openOrder?.amount || ""
        }m - ${openOrder?.tokens || ""} Tokens - ${
          openOrder?.inGameUserName || ""
        }`}
      />
      <Stack gap={2}>
        <Typography variant="h5" color="text.primary">
          Crypto Purchase
        </Typography>
        <Stack gap={0.5}>
          <Typography color="text.primary">
            Payment Status: {openOrder.payment_status}
          </Typography>
          {openOrder?.expiration_estimate_date &&
            openOrder.order_created_at && (
              <CountdownTimer
                createdAt={openOrder.order_created_at}
                expirationEstimateDate={openOrder.expiration_estimate_date}
              />
            )}
          {/* <Typography color="text.primary">
          Payment Amount: {openOrder.pay_amount}
        </Typography> */}
          <Typography color="text.primary">
            Payment Currency: {openOrder.pay_currency?.toLocaleUpperCase()}
          </Typography>
          <Typography color="text.primary" sx={{ mt: 1 }}>
            Deposit Address:
          </Typography>

          <Card sx={{ p: 1, bgcolor: "#202020" }}>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Typography color="text.primary">
                {openOrder.pay_address}
              </Typography>
              <IconButton onClick={copyLink} sx={{ color: "white" }}>
                <ContentCopyTwoToneIcon />
              </IconButton>
            </Stack>
          </Card>

          <Typography color="text.primary" sx={{ mt: 1 }}>
            Payment Amount:
          </Typography>

          <Card sx={{ p: 1, bgcolor: "#202020" }}>
            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
            >
              <Typography color="text.primary">
                {openOrder.pay_amount}
              </Typography>
              <IconButton onClick={copyAmount} sx={{ color: "white" }}>
                <ContentCopyTwoToneIcon />
              </IconButton>
            </Stack>
          </Card>

          <Stack sx={{ p: 5 }} alignItems="center">
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "60%", width: "60%" }}
              value={openOrder.pay_address || ""}
              viewBox={`0 0 50 50`}
            />
          </Stack>
          <Button
            fullWidth
            variantType="error"
            onClick={() => setOpenModal(true)}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
