import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Chip, Typography, Stack } from "@mui/material";
import Card from "./card";
import * as BlackJackUtilities from "../../../../components/blackjack/constants";
import { motion } from "framer-motion";
import { theme } from "../../../../components/theme";
import { SideBet } from "./sideBets";
import {
  PAIRTYPES,
  PERFECTPAIRPAYOUTS,
  TWENTYONEPLUSTHREEPAYOUTS,
  TWENTYONEPLUSTHREETYPES,
} from "../../../../constants/blackJack";

interface PlayAreaProps {
  dealersHand: BlackJackUtilities.Card[] | undefined;
  playersHands: any[][] | undefined;
  isTurnEnd: boolean | undefined;
  currentPlayerHandIndex: number | undefined;
  perfectPairSideBetPayout: any;
  otherSideBetPayout: any;
  insurance: any;
}
const getBlackJackColor = (text: string) => {
  switch (text) {
    case "WIN":
      return "#32c932 !important";
    case "BLACK JACK":
      return "#32c932 !important";
    case "LOSE":
      return "#D92324 !important";
    case "PUSH":
      return "#ffa000 !important";
    default:
      return "white !important";
  }
};

const BlackJackGame: React.FC<PlayAreaProps> = (props) => {
  const playerCardsRef = useRef<any>();
  const {
    dealersHand,
    playersHands,
    isTurnEnd,
    currentPlayerHandIndex,
    perfectPairSideBetPayout,
    otherSideBetPayout,
    insurance,
  } = props;

  return (
    <Box>
      <Stack gap={1} sx={{ position: "absolute", left: "1%", top: "1%" }}>
        {perfectPairSideBetPayout && (
          <SideBet
            payout={perfectPairSideBetPayout.payout}
            typeOfBet={`${perfectPairSideBetPayout.typeOfBet} (1:${
              PERFECTPAIRPAYOUTS[
                perfectPairSideBetPayout.typeOfBet as PAIRTYPES
              ]
            })`}
          />
        )}
        {otherSideBetPayout && (
          <SideBet
            payout={otherSideBetPayout.payout}
            typeOfBet={`${otherSideBetPayout.typeOfBet} (1:${
              TWENTYONEPLUSTHREEPAYOUTS[
                otherSideBetPayout.typeOfBet as TWENTYONEPLUSTHREETYPES
              ]
            })`}
          />
        )}

        {insurance?.bet &&
        dealersHand &&
        BlackJackUtilities.isBlackJack(dealersHand) ? (
          <SideBet payout={insurance?.bet * 2} typeOfBet={`Insurance (1:2)`} />
        ) : (
          <></>
        )}
      </Stack>
      <Box sx={{ py: 3 }}>
        <Grid
          container
          direction="column"
          spacing={1}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <Stack alignItems="center" sx={{ pb: 1 }}>
              {dealersHand && (
                <Stack
                  sx={{
                    bgcolor: "#202020",
                    borderRadius: "5px",
                    minWidth: "2.5em",
                    p: 0.5,
                  }}
                >
                  <Typography
                    color="text.primary"
                    textAlign="center"
                    fontWeight={600}
                  >
                    {dealersHand &&
                      dealersHand?.length !== 0 &&
                      (isTurnEnd
                        ? BlackJackUtilities.getLastScoreForDealer(dealersHand)
                        : BlackJackUtilities.getScoreForDisplayDealer(
                            dealersHand
                          ))}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Grid container direction="row" flexWrap="nowrap">
              {dealersHand &&
                dealersHand.map((card, index) => {
                  const marginLeft = index === 0 ? "0px" : "-50px";
                  const marginTop = index === 0 ? "0px" : `${index * 0}px`;
                  const hide = index === 1 && !props.isTurnEnd;
                  return (
                    <Grid
                      item
                      key={index}
                      style={{
                        marginLeft: marginLeft,
                        marginTop: marginTop,
                        zIndex: index + 1,
                        overflow: "visible",
                      }}
                    >
                      <Card card={card} hide={hide} />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{ p: 1, mt: 2 }}
          >
            <Typography sx={{ color: "grey", opacity: 0.7 }} fontWeight={600}>
              BLACKJACK PAYS 3 TO 2
            </Typography>
            <Typography sx={{ color: "grey", opacity: 0.7 }} fontWeight={600}>
              INSURANCE PAYS 2 TO 1
            </Typography>
          </Stack>

          <Grid item sx={{ pt: 0 }}>
            <Stack direction="row" gap={3} ref={playerCardsRef}>
              {playersHands &&
                playersHands.map(
                  (playersHand: BlackJackUtilities.Card[], index: number) => {
                    let bgColor = "#202020";
                    let color = theme.palette.text.primary;
                    if (isTurnEnd && dealersHand) {
                      bgColor = getBlackJackColor(
                        BlackJackUtilities.judge(dealersHand, playersHand)
                      );
                      color = "";
                    }
                    return (
                      <Stack direction="column" justifyContent="center">
                        <Stack alignItems="center" sx={{ pb: 1 }}>
                          <Stack
                            sx={{
                              bgcolor: bgColor,
                              borderRadius: "5px",
                              minWidth: "2.5em",
                              p: 0.5,
                            }}
                          >
                            <Typography
                              sx={{ color: color }}
                              textAlign="center"
                              fontWeight={600}
                            >
                              {playersHand.length !== 0 &&
                                BlackJackUtilities.getScoreForDisplay(
                                  playersHand
                                )}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Grid
                          container
                          direction="row"
                          flexWrap="nowrap"
                          sx={{
                            opacity: index === currentPlayerHandIndex ? 1 : 0.5,
                          }}
                        >
                          {dealersHand &&
                            playersHand.map(
                              (
                                card: BlackJackUtilities.Card,
                                index: number
                              ) => {
                                const marginLeft =
                                  index === 0 ? "0px" : "-50px";
                                const marginTop =
                                  index === 0 ? "0px" : `${index * 0}px`;
                                const border = `3.5px solid ${getBlackJackColor(
                                  BlackJackUtilities.judge(
                                    dealersHand,
                                    playersHand
                                  )
                                )?.replace("!important", "")}`;
                                return (
                                  <Grid
                                    item
                                    key={index}
                                    style={{
                                      marginLeft: marginLeft,
                                      marginTop: marginTop,
                                      zIndex: index + 1,
                                    }}
                                  >
                                    <Card
                                      card={card}
                                      hide={false}
                                      border={isTurnEnd ? border : ""}
                                    />
                                  </Grid>
                                );
                              }
                            )}
                        </Grid>
                      </Stack>
                    );
                  }
                )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BlackJackGame;
