import { Card, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import Slider, { SliderProps } from "@mui/material/Slider";
import { theme } from "../theme";
import { DICETYPE } from "../../types/index.d";
import { DiceDocument } from "../slices/diceSlice";
import Tear from "./tear";
const marks = [
  {
    value: 0,
    label: 0,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 75,
    label: 75,
  },
  {
    value: 100,
    label: 100,
  },
];

interface PrettoSliderProps extends SliderProps {
  rollOver: boolean;
}

const PrettoSlider = styled(Slider)<PrettoSliderProps>(({ rollOver }) => ({
  color: rollOver ? theme.palette.error.main : theme.palette.success.main,
  height: 8,
  borderRadius: "3px",
  "& .MuiSlider-mark": {
    width: "0px",
    height: "5px",
  },
  "& .MuiSlider-markLabel": {
    color: rollOver ? theme.palette.success.main : theme.palette.error.main,
    fontWeight: 600,
  },
  "& .MuiSlider-markLabelActive": {
    color: rollOver ? theme.palette.error.main : theme.palette.success.main,
  },
  "& .MuiSlider-track": {
    border: rollOver && "1px solid #D92324",
    opacity: 1,
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid #32C932",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-rail": {
    border: rollOver && "1px solid #32C932",
    opacity: 1,
    color: rollOver ? theme.palette.success.main : theme.palette.error.main,
  },
}));

interface ISliderWithLabel {
  rollOver: number;
  setRollOver: (value: number) => void;
  game?: DiceDocument | null;
  gameType: DICETYPE;
  isAutoplaying: boolean;
}

export default function SliderWithLabel({
  rollOver,
  setRollOver,
  game,
  isAutoplaying,
  gameType,
}: ISliderWithLabel) {
  const handleUpdate = (value: any) => {
    if (isAutoplaying) return;
    if (gameType === DICETYPE.ROLLUNDER && value > 95) {
      setRollOver(95 * 100);
      return;
    }
    if (gameType === DICETYPE.ROLLUNDER && value < 1) {
      setRollOver(1 * 100);
      return;
    }
    if (gameType === DICETYPE.ROLLOVER && value < 5) {
      setRollOver(5 * 100);
      return;
    }
    if (gameType === DICETYPE.ROLLOVER && value > 99) {
      setRollOver(99 * 100);
      return;
    }
    setRollOver((value * 100) as number);
  };
  return (
    <Card
      sx={{
        p: 2,
        px: 5,
        overflow: "visible",
        bgcolor: "#202020",
        position: "relative",
      }}
    >
      <Stack sx={{ position: "relative" }}>
        <Tear game={game} />
        <PrettoSlider
          value={rollOver / 100}
          onChange={(_e: any, v: any) => handleUpdate(v)}
          step={1}
          marks={marks}
          rollOver={gameType === DICETYPE.ROLLOVER}
          min={0}
        />
      </Stack>
    </Card>
  );
}
