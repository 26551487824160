import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  selectPasswordResetModal,
  updatePasswordResetModal,
} from "../../components/slices/userSlice";
import { useAppSelector } from "../../components/store/hooks";
import VerificationModal from ".";

const CheckPasswordReset = () => {
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const dispatch = useDispatch();
  const isVerification = useAppSelector(selectPasswordResetModal);
  const verificationCode = query.get("passwordVerificationToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (!isVerification && verificationCode) {
      dispatch(updatePasswordResetModal(true));
    }
  }, []);

  const handleClose = () => {
    dispatch(updatePasswordResetModal(false));
    navigate("/");
  };

  if (isVerification && verificationCode) {
    return (
      <VerificationModal
        verificationCode={verificationCode}
        handleClose={() => handleClose()}
        open={isVerification}
      />
    );
  }
  return null;
};

export default CheckPasswordReset;
