import { Box, Stack } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { theme } from "../theme";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectJackpots, selectWinners } from "../slices/slideSlice";
import {
  getPlayerLevelData,
  RoundNumber,
  RoundNumberFourDecimalPoints,
} from "../../utils";
import { TokenIcon } from "../tokenIcon";
import { useState } from "react";
import { JACKPOTOPTIONSTYPES } from "../../types/index.d";
import SelectionComponent from "../selectionComponent";
import { formatDistanceToNow, parseISO } from "date-fns";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 250, md: 350 },
  zIndex: 10,
  p: 2,
  // px: 10,
  borderRadius: 4,
};
const JackpotSelection = [
  {
    type: JACKPOTOPTIONSTYPES.WINNERS,
    label: "Winners",
  },
  {
    type: JACKPOTOPTIONSTYPES.JACKPOTS,
    label: "Jackpots",
  },
];

interface IModal {
  handleClose: () => void;
  open: boolean;
}

const JackpotWinners = ({ handleClose, open }: IModal) => {
  const winners = useAppSelector(selectWinners);
  const jackpots = useAppSelector(selectJackpots);

  const [selected, setSelected] = useState(JACKPOTOPTIONSTYPES.WINNERS);

  const renderTimeDifference = (timestamp: string) => {
    return formatDistanceToNow(parseISO(timestamp), { addSuffix: true });
  };

  return (
    <ModalComponent open={open} onClose={handleClose} disableScrollLock={true}>
      <Box sx={modalStyle}>
        <Stack gap={3} alignItems="center">
          <SelectionComponent
            buttonData={JackpotSelection}
            modalType={selected}
            changeModalType={setSelected}
          />

          {selected === JACKPOTOPTIONSTYPES.WINNERS && (
            <List sx={{ width: "100%", maxHeight: "30vh", overflow: "auto" }}>
              {winners &&
                winners.map((winner) => {
                  const labelId = `checkbox-list-secondary-label-${winner.value}`;
                  const getPlayerLevel = getPlayerLevelData(winner.userLevel);
                  return (
                    <ListItem key={winner.value} disablePadding>
                      <ListItemButton>
                        <ListItemAvatar
                          sx={{
                            display: {
                              md: "flex",
                              sm: "none",
                              xs: "none",
                            },
                          }}
                        >
                          <Avatar
                            alt={"player level icon"}
                            src={getPlayerLevel.imgSrc}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          id={labelId}
                          sx={{ color: theme.palette.text.primary }}
                          primary={
                            <Typography variant="h6">
                              {winner.userName}{" "}
                            </Typography>
                          }
                          secondary={
                            <Stack
                              sx={{ width: "100%" }}
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Stack
                                direction="row"
                                gap={0.5}
                                alignItems="center"
                              >
                                <TokenIcon />
                                <Typography color="text.primary">
                                  {RoundNumberFourDecimalPoints(winner.value)}
                                </Typography>
                              </Stack>
                              <Typography color="text.primary">
                                {winner.sequenceNumber &&
                                  `JACKPOT #${winner.sequenceNumber}`}
                              </Typography>
                            </Stack>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          )}

          {selected === JACKPOTOPTIONSTYPES.JACKPOTS && (
            <List sx={{ width: "100%", maxHeight: "30vh", overflow: "auto" }}>
              {jackpots &&
                jackpots.map((jackpot) => {
                  return (
                    <ListItem key={jackpot._id} disablePadding>
                      <Stack
                        direction="row"
                        justifyContent="flex-start"
                        sx={{ width: "100%" }}
                      >
                        <Typography
                          color="text.primary"
                          sx={{ width: { md: "100px", xs: "170px" } }}
                        >
                          {jackpot?.sequenceNumber
                            ? `JACKPOT #${jackpot.sequenceNumber}`
                            : `JACKPOT #1`}
                        </Typography>
                        <Stack
                          direction="row"
                          gap={0.5}
                          alignItems="center"
                          sx={{ width: "100px" }}
                        >
                          <TokenIcon />
                          <Typography
                            color="text.primary"
                            sx={{ width: "100px" }}
                          >
                            {RoundNumber(jackpot.amount)}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            display: { md: "flex", sm: "none", xs: "none" },
                          }}
                        >
                          <Typography color="text.primary">
                            {renderTimeDifference(jackpot.updatedAt)}
                          </Typography>
                        </Stack>
                      </Stack>
                    </ListItem>
                  );
                })}
            </List>
          )}
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default JackpotWinners;
