import { Container } from "@mui/material";
import DuelWager from "../../../components/duelWager";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import ChatContext from "../../../contexts/chat/context";
import { AppDispatch } from "../../../components/store";
import {
  EMIT_DUEL_STAKER,
  UPDATE_DUEL_GAME,
  UPDATE_DUEL_STAKER,
} from "../../../constants/socket";
import {
  addDuelGame,
  updateAllDuelResults,
  updateStakers,
} from "../../../components/slices/duelSlice";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import { GAMES } from "../../../constants/games";
import GameFeed from "../../../components/gameFeed";

const DuelWagerPage = () => {
  const { socket } = useContext(ChatContext);
  const dispatch = useDispatch<AppDispatch>();

  const handleStakerData = (data: any) => {
    if (data.stakers) {
      dispatch(updateStakers(data.stakers));
    }
    if (data.matches) {
      dispatch(updateAllDuelResults(data.matches));
    }
  };

  const handleUpdateGame = (data: any) => {
    if (data?.duel) {
      dispatch(addDuelGame(data.duel));
    }
    if (data?._id) {
      dispatch(addDuelGame(data));
    }
  };

  useEffect(() => {
    if (socket) {
      // SLIDE
      socket.emit(UPDATE_DUEL_STAKER);
      socket.on(EMIT_DUEL_STAKER, handleStakerData);
      socket.on(UPDATE_DUEL_GAME, handleUpdateGame);
    }
    return () => {
      if (socket) {
        socket.off(EMIT_DUEL_STAKER, handleStakerData);
        socket.off(UPDATE_DUEL_GAME, handleUpdateGame);
      }
    };
  }, [socket]);
  return (
    <Container maxWidth="md">
      <DuelWager />
      <DescWrapper game={GAMES.DUEL} showLuckyWins={false} />
      <GameFeed />
    </Container>
  );
};

export default DuelWagerPage;
