import { useState } from "react";
import styles from "./index.module.scss";
import { Stack } from "@mui/material";
import redIcons from "../../assets/coinFlip/fire.svg";
import blackIcons from "../../assets/coinFlip/water.svg";
import { COINFLIP } from "../../types/index.d";
import WinSound from "../../assets/audio/common/win.mp3";
import LossSound from "../../assets/audio/common/loss.mp3";
import AnimationSound from "../../assets/audio/coinflip/animation.mp3";
import { useAppSelector } from "../store/hooks";
import { selectVolume } from "../slices/userSlice";
import useSound from "use-sound";

const Coin = ({ setCoinClass, coinClass, setDisableButton, coinFlip }: any) => {
  const [toShow, setToShow] = useState("");
  const volume = useAppSelector(selectVolume);
  const [playAnimationSound] = useSound(AnimationSound, {
    volume: volume / 100,
  });
  const [playWinSound] = useSound(WinSound, { volume: volume / 100 });
  const [playLossSound] = useSound(LossSound, { volume: volume / 100 });

  return (
    <Stack alignItems="center">
      <div
        className={styles.coin}
        data-type={coinClass}
        onAnimationStart={() => {
          playAnimationSound();
          setToShow("");
        }}
        onAnimationEnd={() => {
          setToShow(coinClass);
          setCoinClass("");
          setDisableButton(false);
          if (coinFlip?.isWin) {
            playWinSound();
          } else {
            playLossSound();
          }
        }}
      >
        <img
          className={styles.heads}
          style={{ display: toShow === COINFLIP.TAILS ? "none" : "block" }}
          src={redIcons}
          alt="heads"
        />
        <img
          className={styles.tails}
          style={{
            display: toShow === COINFLIP.HEADS ? "none" : "block",
            transform: toShow === COINFLIP.TAILS ? "none" : "rotateY(180deg)",
          }}
          src={blackIcons}
          alt="tails"
        />
      </div>
    </Stack>
  );
};

export default Coin;
