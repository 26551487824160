export const minesPayoutData = [
  {
    totalMines: 1,
    gemsFound: 1,
    multiplier: 0.97,
  },
  {
    totalMines: 1,
    gemsFound: 2,
    multiplier: 1.01,
  },
  {
    totalMines: 1,
    gemsFound: 3,
    multiplier: 1.06,
  },
  {
    totalMines: 1,
    gemsFound: 4,
    multiplier: 1.1,
  },
  {
    totalMines: 1,
    gemsFound: 5,
    multiplier: 1.14,
  },
  {
    totalMines: 1,
    gemsFound: 6,
    multiplier: 1.17,
  },
  {
    totalMines: 1,
    gemsFound: 7,
    multiplier: 1.23,
  },
  {
    totalMines: 1,
    gemsFound: 8,
    multiplier: 1.31,
  },
  {
    totalMines: 1,
    gemsFound: 9,
    multiplier: 1.39,
  },
  {
    totalMines: 1,
    gemsFound: 10,
    multiplier: 1.48,
  },
  {
    totalMines: 1,
    gemsFound: 11,
    multiplier: 1.59,
  },
  {
    totalMines: 1,
    gemsFound: 12,
    multiplier: 1.71,
  },
  {
    totalMines: 1,
    gemsFound: 13,
    multiplier: 1.85,
  },
  {
    totalMines: 1,
    gemsFound: 14,
    multiplier: 2.07,
  },
  {
    totalMines: 1,
    gemsFound: 15,
    multiplier: 2.27,
  },
  {
    totalMines: 1,
    gemsFound: 16,
    multiplier: 2.53,
  },
  {
    totalMines: 1,
    gemsFound: 17,
    multiplier: 2.84,
  },
  {
    totalMines: 1,
    gemsFound: 18,
    multiplier: 3.25,
  },
  {
    totalMines: 1,
    gemsFound: 19,
    multiplier: 3.79,
  },
  {
    totalMines: 1,
    gemsFound: 20,
    multiplier: 4.55,
  },
  {
    totalMines: 1,
    gemsFound: 21,
    multiplier: 5.69,
  },
  {
    totalMines: 1,
    gemsFound: 22,
    multiplier: 7.02,
  },
  {
    totalMines: 1,
    gemsFound: 23,
    multiplier: 9.87,
  },
  {
    totalMines: 1,
    gemsFound: 24,
    multiplier: 22.5,
  },
  {
    totalMines: 2,
    gemsFound: 1,
    multiplier: 1.01,
  },
  {
    totalMines: 2,
    gemsFound: 2,
    multiplier: 1.05,
  },
  {
    totalMines: 2,
    gemsFound: 3,
    multiplier: 1.09,
  },
  {
    totalMines: 2,
    gemsFound: 4,
    multiplier: 1.22,
  },
  {
    totalMines: 2,
    gemsFound: 5,
    multiplier: 1.36,
  },
  {
    totalMines: 2,
    gemsFound: 6,
    multiplier: 1.51,
  },
  {
    totalMines: 2,
    gemsFound: 7,
    multiplier: 1.7,
  },
  {
    totalMines: 2,
    gemsFound: 8,
    multiplier: 1.91,
  },
  {
    totalMines: 2,
    gemsFound: 9,
    multiplier: 2.17,
  },
  {
    totalMines: 2,
    gemsFound: 10,
    multiplier: 2.49,
  },
  {
    totalMines: 2,
    gemsFound: 11,
    multiplier: 2.86,
  },
  {
    totalMines: 2,
    gemsFound: 12,
    multiplier: 3.42,
  },
  {
    totalMines: 2,
    gemsFound: 13,
    multiplier: 4.05,
  },
  {
    totalMines: 2,
    gemsFound: 14,
    multiplier: 4.86,
  },
  {
    totalMines: 2,
    gemsFound: 15,
    multiplier: 5.94,
  },
  {
    totalMines: 2,
    gemsFound: 16,
    multiplier: 7.41,
  },
  {
    totalMines: 2,
    gemsFound: 17,
    multiplier: 9.54,
  },
  {
    totalMines: 2,
    gemsFound: 18,
    multiplier: 12.7,
  },
  {
    totalMines: 2,
    gemsFound: 19,
    multiplier: 17.8,
  },
  {
    totalMines: 2,
    gemsFound: 20,
    multiplier: 26,
  },
  {
    totalMines: 2,
    gemsFound: 21,
    multiplier: 44,
  },
  {
    totalMines: 2,
    gemsFound: 22,
    multiplier: 89.1,
  },
  {
    totalMines: 2,
    gemsFound: 23,
    multiplier: 267.3,
  },
  {
    totalMines: 3,
    gemsFound: 1,
    multiplier: 1.05,
  },
  {
    totalMines: 3,
    gemsFound: 2,
    multiplier: 1.13,
  },
  {
    totalMines: 3,
    gemsFound: 3,
    multiplier: 1.3,
  },
  {
    totalMines: 3,
    gemsFound: 4,
    multiplier: 1.55,
  },
  {
    totalMines: 3,
    gemsFound: 5,
    multiplier: 1.76,
  },
  {
    totalMines: 3,
    gemsFound: 6,
    multiplier: 2.06,
  },
  {
    totalMines: 3,
    gemsFound: 7,
    multiplier: 2.45,
  },
  {
    totalMines: 3,
    gemsFound: 8,
    multiplier: 2.94,
  },
  {
    totalMines: 3,
    gemsFound: 9,
    multiplier: 3.66,
  },
  {
    totalMines: 3,
    gemsFound: 10,
    multiplier: 4.1,
  },
  {
    totalMines: 3,
    gemsFound: 11,
    multiplier: 5.63,
  },
  {
    totalMines: 3,
    gemsFound: 12,
    multiplier: 7.16,
  },
  {
    totalMines: 3,
    gemsFound: 13,
    multiplier: 9.27,
  },
  {
    totalMines: 3,
    gemsFound: 14,
    multiplier: 12.42,
  },
  {
    totalMines: 3,
    gemsFound: 15,
    multiplier: 17.07,
  },
  {
    totalMines: 3,
    gemsFound: 16,
    multiplier: 23.17,
  },
  {
    totalMines: 3,
    gemsFound: 17,
    multiplier: 34.75,
  },
  {
    totalMines: 3,
    gemsFound: 18,
    multiplier: 52.69,
  },
  {
    totalMines: 3,
    gemsFound: 19,
    multiplier: 91.53,
  },
  {
    totalMines: 3,
    gemsFound: 20,
    multiplier: 165.24,
  },
  {
    totalMines: 3,
    gemsFound: 21,
    multiplier: 460,
  },
  {
    totalMines: 3,
    gemsFound: 22,
    multiplier: 1844,
  },
  {
    totalMines: 4,
    gemsFound: 1,
    multiplier: 1.08,
  },
  {
    totalMines: 4,
    gemsFound: 2,
    multiplier: 1.24,
  },
  {
    totalMines: 4,
    gemsFound: 3,
    multiplier: 1.53,
  },
  {
    totalMines: 4,
    gemsFound: 4,
    multiplier: 1.88,
  },
  {
    totalMines: 4,
    gemsFound: 5,
    multiplier: 2.32,
  },
  {
    totalMines: 4,
    gemsFound: 6,
    multiplier: 2.9,
  },
  {
    totalMines: 4,
    gemsFound: 7,
    multiplier: 3.68,
  },
  {
    totalMines: 4,
    gemsFound: 8,
    multiplier: 4.73,
  },
  {
    totalMines: 4,
    gemsFound: 9,
    multiplier: 6.19,
  },
  {
    totalMines: 4,
    gemsFound: 10,
    multiplier: 8.25,
  },
  {
    totalMines: 4,
    gemsFound: 11,
    multiplier: 11.25,
  },
  {
    totalMines: 4,
    gemsFound: 12,
    multiplier: 15.76,
  },
  {
    totalMines: 4,
    gemsFound: 13,
    multiplier: 22.77,
  },
  {
    totalMines: 4,
    gemsFound: 14,
    multiplier: 34.15,
  },
  {
    totalMines: 4,
    gemsFound: 15,
    multiplier: 53.67,
  },
  {
    totalMines: 4,
    gemsFound: 16,
    multiplier: 89.45,
  },
  {
    totalMines: 4,
    gemsFound: 17,
    multiplier: 161.01,
  },
  {
    totalMines: 4,
    gemsFound: 18,
    multiplier: 322.02,
  },
  {
    totalMines: 4,
    gemsFound: 19,
    multiplier: 751.41,
  },
  {
    totalMines: 4,
    gemsFound: 20,
    multiplier: 2253.6,
  },
  {
    totalMines: 4,
    gemsFound: 21,
    multiplier: 11270.7,
  },
  {
    totalMines: 5,
    gemsFound: 1,
    multiplier: 1.18,
  },
  {
    totalMines: 5,
    gemsFound: 2,
    multiplier: 1.48,
  },
  {
    totalMines: 5,
    gemsFound: 3,
    multiplier: 1.9,
  },
  {
    totalMines: 5,
    gemsFound: 4,
    multiplier: 2.45,
  },
  {
    totalMines: 5,
    gemsFound: 5,
    multiplier: 3.22,
  },
  {
    totalMines: 5,
    gemsFound: 6,
    multiplier: 4.29,
  },
  {
    totalMines: 5,
    gemsFound: 7,
    multiplier: 5.83,
  },
  {
    totalMines: 5,
    gemsFound: 8,
    multiplier: 8.07,
  },
  {
    totalMines: 5,
    gemsFound: 9,
    multiplier: 11.43,
  },
  {
    totalMines: 5,
    gemsFound: 10,
    multiplier: 16.64,
  },
  {
    totalMines: 5,
    gemsFound: 11,
    multiplier: 24.95,
  },
  {
    totalMines: 5,
    gemsFound: 12,
    multiplier: 38.82,
  },
  {
    totalMines: 5,
    gemsFound: 13,
    multiplier: 59.77,
  },
  {
    totalMines: 5,
    gemsFound: 14,
    multiplier: 102.46,
  },
  {
    totalMines: 5,
    gemsFound: 15,
    multiplier: 187.84,
  },
  {
    totalMines: 5,
    gemsFound: 16,
    multiplier: 375.7,
  },
  {
    totalMines: 5,
    gemsFound: 17,
    multiplier: 845.33,
  },
  {
    totalMines: 5,
    gemsFound: 18,
    multiplier: 2254.23,
  },
  {
    totalMines: 5,
    gemsFound: 19,
    multiplier: 7889.81,
  },
  {
    totalMines: 5,
    gemsFound: 20,
    multiplier: 47338.83,
  },
  {
    totalMines: 6,
    gemsFound: 1,
    multiplier: 1.2,
  },
  {
    totalMines: 6,
    gemsFound: 2,
    multiplier: 1.6,
  },
  {
    totalMines: 6,
    gemsFound: 3,
    multiplier: 2.16,
  },
  {
    totalMines: 6,
    gemsFound: 4,
    multiplier: 2.82,
  },
  {
    totalMines: 6,
    gemsFound: 5,
    multiplier: 4.15,
  },
  {
    totalMines: 6,
    gemsFound: 6,
    multiplier: 5.94,
  },
  {
    totalMines: 6,
    gemsFound: 7,
    multiplier: 8.97,
  },
  {
    totalMines: 6,
    gemsFound: 8,
    multiplier: 13.46,
  },
  {
    totalMines: 6,
    gemsFound: 9,
    multiplier: 20.8,
  },
  {
    totalMines: 6,
    gemsFound: 10,
    multiplier: 33.28,
  },
  {
    totalMines: 6,
    gemsFound: 11,
    multiplier: 52.54,
  },
  {
    totalMines: 6,
    gemsFound: 12,
    multiplier: 91.95,
  },
  {
    totalMines: 6,
    gemsFound: 13,
    multiplier: 170.77,
  },
  {
    totalMines: 6,
    gemsFound: 14,
    multiplier: 341.55,
  },
  {
    totalMines: 6,
    gemsFound: 15,
    multiplier: 751.41,
  },
  {
    totalMines: 6,
    gemsFound: 16,
    multiplier: 1878.53,
  },
  {
    totalMines: 6,
    gemsFound: 17,
    multiplier: 5635.58,
  },
  {
    totalMines: 6,
    gemsFound: 18,
    multiplier: 22542.3,
  },
  {
    totalMines: 6,
    gemsFound: 19,
    multiplier: 157796.1,
  },
  {
    totalMines: 7,
    gemsFound: 1,
    multiplier: 1.26,
  },
  {
    totalMines: 7,
    gemsFound: 2,
    multiplier: 1.82,
  },
  {
    totalMines: 7,
    gemsFound: 3,
    multiplier: 2.51,
  },
  {
    totalMines: 7,
    gemsFound: 4,
    multiplier: 3.65,
  },
  {
    totalMines: 7,
    gemsFound: 5,
    multiplier: 5.83,
  },
  {
    totalMines: 7,
    gemsFound: 6,
    multiplier: 8.97,
  },
  {
    totalMines: 7,
    gemsFound: 7,
    multiplier: 14.2,
  },
  {
    totalMines: 7,
    gemsFound: 8,
    multiplier: 23.25,
  },
  {
    totalMines: 7,
    gemsFound: 9,
    multiplier: 39.52,
  },
  {
    totalMines: 7,
    gemsFound: 10,
    multiplier: 66.56,
  },
  {
    totalMines: 7,
    gemsFound: 11,
    multiplier: 124.79,
  },
  {
    totalMines: 7,
    gemsFound: 12,
    multiplier: 249.6,
  },
  {
    totalMines: 7,
    gemsFound: 13,
    multiplier: 540.78,
  },
  {
    totalMines: 7,
    gemsFound: 14,
    multiplier: 1297.89,
  },
  {
    totalMines: 7,
    gemsFound: 15,
    multiplier: 3569.19,
  },
  {
    totalMines: 7,
    gemsFound: 16,
    multiplier: 11897.33,
  },
  {
    totalMines: 7,
    gemsFound: 17,
    multiplier: 53537.95,
  },
  {
    totalMines: 7,
    gemsFound: 18,
    multiplier: 66.56,
  },
  {
    totalMines: 8,
    gemsFound: 1,
    multiplier: 1.39,
  },
  {
    totalMines: 8,
    gemsFound: 2,
    multiplier: 2.07,
  },
  {
    totalMines: 8,
    gemsFound: 3,
    multiplier: 3.18,
  },
  {
    totalMines: 8,
    gemsFound: 4,
    multiplier: 4.99,
  },
  {
    totalMines: 8,
    gemsFound: 5,
    multiplier: 8.08,
  },
  {
    totalMines: 8,
    gemsFound: 6,
    multiplier: 13.46,
  },
  {
    totalMines: 8,
    gemsFound: 7,
    multiplier: 23.24,
  },
  {
    totalMines: 8,
    gemsFound: 8,
    multiplier: 39.64,
  },
  {
    totalMines: 8,
    gemsFound: 9,
    multiplier: 74.88,
  },
  {
    totalMines: 8,
    gemsFound: 10,
    multiplier: 149.76,
  },
  {
    totalMines: 8,
    gemsFound: 11,
    multiplier: 320.9,
  },
  {
    totalMines: 8,
    gemsFound: 12,
    multiplier: 748.78,
  },
  {
    totalMines: 8,
    gemsFound: 13,
    multiplier: 1946.83,
  },
  {
    totalMines: 8,
    gemsFound: 14,
    multiplier: 5840.5,
  },
  {
    totalMines: 8,
    gemsFound: 15,
    multiplier: 21415.18,
  },
  {
    totalMines: 8,
    gemsFound: 16,
    multiplier: 107075.92,
  },
  {
    totalMines: 8,
    gemsFound: 17,
    multiplier: 963683.32,
  },
  {
    totalMines: 9,
    gemsFound: 1,
    multiplier: 1.47,
  },
  {
    totalMines: 9,
    gemsFound: 2,
    multiplier: 2.35,
  },
  {
    totalMines: 9,
    gemsFound: 3,
    multiplier: 3.86,
  },
  {
    totalMines: 9,
    gemsFound: 4,
    multiplier: 6.54,
  },
  {
    totalMines: 9,
    gemsFound: 5,
    multiplier: 11.44,
  },
  {
    totalMines: 9,
    gemsFound: 6,
    multiplier: 20.8,
  },
  {
    totalMines: 9,
    gemsFound: 7,
    multiplier: 39.52,
  },
  {
    totalMines: 9,
    gemsFound: 8,
    multiplier: 74.88,
  },
  {
    totalMines: 9,
    gemsFound: 9,
    multiplier: 159.12,
  },
  {
    totalMines: 9,
    gemsFound: 10,
    multiplier: 363.69,
  },
  {
    totalMines: 9,
    gemsFound: 11,
    multiplier: 909.234,
  },
  {
    totalMines: 9,
    gemsFound: 12,
    multiplier: 2545.85,
  },
  {
    totalMines: 9,
    gemsFound: 13,
    multiplier: 8274.05,
  },
  {
    totalMines: 9,
    gemsFound: 14,
    multiplier: 33096.19,
  },
  {
    totalMines: 9,
    gemsFound: 15,
    multiplier: 182029.06,
  },
  {
    totalMines: 9,
    gemsFound: 16,
    multiplier: 1921417.99,
  },
  {
    totalMines: 10,
    gemsFound: 1,
    multiplier: 1.57,
  },
  {
    totalMines: 10,
    gemsFound: 2,
    multiplier: 2.69,
  },
  {
    totalMines: 10,
    gemsFound: 3,
    multiplier: 4.75,
  },
  {
    totalMines: 10,
    gemsFound: 4,
    multiplier: 8.71,
  },
  {
    totalMines: 10,
    gemsFound: 5,
    multiplier: 16.64,
  },
  {
    totalMines: 10,
    gemsFound: 6,
    multiplier: 33.28,
  },
  {
    totalMines: 10,
    gemsFound: 7,
    multiplier: 66.56,
  },
  {
    totalMines: 10,
    gemsFound: 8,
    multiplier: 149.76,
  },
  {
    totalMines: 10,
    gemsFound: 9,
    multiplier: 363.69,
  },
  {
    totalMines: 10,
    gemsFound: 10,
    multiplier: 969.85,
  },
  {
    totalMines: 10,
    gemsFound: 11,
    multiplier: 2909.56,
  },
  {
    totalMines: 10,
    gemsFound: 12,
    multiplier: 10183.45,
  },
  {
    totalMines: 10,
    gemsFound: 13,
    multiplier: 44128.26,
  },
  {
    totalMines: 10,
    gemsFound: 14,
    multiplier: 264769.56,
  },
  {
    totalMines: 10,
    gemsFound: 15,
    multiplier: 2912465.16,
  },
  {
    totalMines: 11,
    gemsFound: 1,
    multiplier: 1.68,
  },
  {
    totalMines: 11,
    gemsFound: 2,
    multiplier: 3.1,
  },
  {
    totalMines: 11,
    gemsFound: 3,
    multiplier: 5.95,
  },
  {
    totalMines: 11,
    gemsFound: 4,
    multiplier: 11.88,
  },
  {
    totalMines: 11,
    gemsFound: 5,
    multiplier: 24.96,
  },
  {
    totalMines: 11,
    gemsFound: 6,
    multiplier: 52.54,
  },
  {
    totalMines: 11,
    gemsFound: 7,
    multiplier: 124.79,
  },
  {
    totalMines: 11,
    gemsFound: 8,
    multiplier: 320.9,
  },
  {
    totalMines: 11,
    gemsFound: 9,
    multiplier: 909.23,
  },
  {
    totalMines: 11,
    gemsFound: 10,
    multiplier: 2909.55,
  },
  {
    totalMines: 11,
    gemsFound: 11,
    multiplier: 10910.83,
  },
  {
    totalMines: 11,
    gemsFound: 12,
    multiplier: 50917.22,
  },
  {
    totalMines: 11,
    gemsFound: 13,
    multiplier: 330961.95,
  },
  {
    totalMines: 11,
    gemsFound: 14,
    multiplier: 3971543.4,
  },
  {
    totalMines: 12,
    gemsFound: 1,
    multiplier: 1.81,
  },
  {
    totalMines: 12,
    gemsFound: 2,
    multiplier: 3.62,
  },
  {
    totalMines: 12,
    gemsFound: 3,
    multiplier: 7.56,
  },
  {
    totalMines: 12,
    gemsFound: 4,
    multiplier: 16.64,
  },
  {
    totalMines: 12,
    gemsFound: 5,
    multiplier: 38.83,
  },
  {
    totalMines: 12,
    gemsFound: 6,
    multiplier: 91.95,
  },
  {
    totalMines: 12,
    gemsFound: 7,
    multiplier: 249.6,
  },
  {
    totalMines: 12,
    gemsFound: 8,
    multiplier: 748.78,
  },
  {
    totalMines: 12,
    gemsFound: 9,
    multiplier: 2545.86,
  },
  {
    totalMines: 12,
    gemsFound: 10,
    multiplier: 10183.45,
  },
  {
    totalMines: 12,
    gemsFound: 11,
    multiplier: 50917.22,
  },
  {
    totalMines: 12,
    gemsFound: 12,
    multiplier: 356420.57,
  },
  {
    totalMines: 12,
    gemsFound: 13,
    multiplier: 4633467.3,
  },
  {
    totalMines: 13,
    gemsFound: 1,
    multiplier: 1.96,
  },
  {
    totalMines: 13,
    gemsFound: 2,
    multiplier: 4.28,
  },
  {
    totalMines: 13,
    gemsFound: 3,
    multiplier: 9.83,
  },
  {
    totalMines: 13,
    gemsFound: 4,
    multiplier: 24.04,
  },
  {
    totalMines: 13,
    gemsFound: 5,
    multiplier: 59.77,
  },
  {
    totalMines: 13,
    gemsFound: 6,
    multiplier: 170.78,
  },
  {
    totalMines: 13,
    gemsFound: 7,
    multiplier: 540.78,
  },
  {
    totalMines: 13,
    gemsFound: 8,
    multiplier: 1946.84,
  },
  {
    totalMines: 13,
    gemsFound: 9,
    multiplier: 8274.05,
  },
  {
    totalMines: 13,
    gemsFound: 10,
    multiplier: 44128.26,
  },
  {
    totalMines: 13,
    gemsFound: 11,
    multiplier: 330961.95,
  },
  {
    totalMines: 13,
    gemsFound: 12,
    multiplier: 4633467.3,
  },
  {
    totalMines: 14,
    gemsFound: 1,
    multiplier: 2.14,
  },
  {
    totalMines: 14,
    gemsFound: 2,
    multiplier: 5.13,
  },
  {
    totalMines: 14,
    gemsFound: 3,
    multiplier: 13.11,
  },
  {
    totalMines: 14,
    gemsFound: 4,
    multiplier: 36.05,
  },
  {
    totalMines: 14,
    gemsFound: 5,
    multiplier: 102.47,
  },
  {
    totalMines: 14,
    gemsFound: 6,
    multiplier: 341.55,
  },
  {
    totalMines: 14,
    gemsFound: 7,
    multiplier: 1297.89,
  },
  {
    totalMines: 14,
    gemsFound: 8,
    multiplier: 5840.51,
  },
  {
    totalMines: 14,
    gemsFound: 9,
    multiplier: 33096.2,
  },
  {
    totalMines: 14,
    gemsFound: 10,
    multiplier: 264769.56,
  },
  {
    totalMines: 14,
    gemsFound: 11,
    multiplier: 3971543.4,
  },
  {
    totalMines: 15,
    gemsFound: 1,
    multiplier: 2.35,
  },
  {
    totalMines: 15,
    gemsFound: 2,
    multiplier: 6.27,
  },
  {
    totalMines: 15,
    gemsFound: 3,
    multiplier: 18.02,
  },
  {
    totalMines: 15,
    gemsFound: 4,
    multiplier: 53.68,
  },
  {
    totalMines: 15,
    gemsFound: 5,
    multiplier: 187.85,
  },
  {
    totalMines: 15,
    gemsFound: 6,
    multiplier: 751.41,
  },
  {
    totalMines: 15,
    gemsFound: 7,
    multiplier: 3569.19,
  },
  {
    totalMines: 15,
    gemsFound: 8,
    multiplier: 21415.19,
  },
  {
    totalMines: 15,
    gemsFound: 9,
    multiplier: 182029.07,
  },
  {
    totalMines: 15,
    gemsFound: 10,
    multiplier: 2912465.16,
  },
  {
    totalMines: 16,
    gemsFound: 1,
    multiplier: 2.6125,
  },
  {
    totalMines: 16,
    gemsFound: 2,
    multiplier: 7.8375,
  },
  {
    totalMines: 16,
    gemsFound: 3,
    multiplier: 25.7545,
  },
  {
    totalMines: 16,
    gemsFound: 4,
    multiplier: 89.451,
  },
  {
    totalMines: 16,
    gemsFound: 5,
    multiplier: 375.705,
  },
  {
    totalMines: 16,
    gemsFound: 6,
    multiplier: 1878.525,
  },
  {
    totalMines: 16,
    gemsFound: 7,
    multiplier: 11897.325,
  },
  {
    totalMines: 16,
    gemsFound: 8,
    multiplier: 107075.925,
  },
  {
    totalMines: 16,
    gemsFound: 9,
    multiplier: 1820290.725,
  },
  {
    totalMines: 17,
    gemsFound: 1,
    multiplier: 2.93,
  },
  {
    totalMines: 17,
    gemsFound: 2,
    multiplier: 10.08,
  },
  {
    totalMines: 17,
    gemsFound: 3,
    multiplier: 38.63,
  },
  {
    totalMines: 17,
    gemsFound: 4,
    multiplier: 161.02,
  },
  {
    totalMines: 17,
    gemsFound: 5,
    multiplier: 845.33,
  },
  {
    totalMines: 17,
    gemsFound: 6,
    multiplier: 5635.58,
  },
  {
    totalMines: 17,
    gemsFound: 7,
    multiplier: 53537.96,
  },
  {
    totalMines: 17,
    gemsFound: 8,
    multiplier: 963683.33,
  },
  {
    totalMines: 18,
    gemsFound: 1,
    multiplier: 3.36,
  },
  {
    totalMines: 18,
    gemsFound: 2,
    multiplier: 13.43,
  },
  {
    totalMines: 18,
    gemsFound: 3,
    multiplier: 58.55,
  },
  {
    totalMines: 18,
    gemsFound: 4,
    multiplier: 322.03,
  },
  {
    totalMines: 18,
    gemsFound: 5,
    multiplier: 2254.23,
  },
  {
    totalMines: 18,
    gemsFound: 6,
    multiplier: 22542.3,
  },
  {
    totalMines: 18,
    gemsFound: 7,
    multiplier: 428303.7,
  },
  {
    totalMines: 19,
    gemsFound: 1,
    multiplier: 3.91,
  },
  {
    totalMines: 19,
    gemsFound: 2,
    multiplier: 18.81,
  },
  {
    totalMines: 19,
    gemsFound: 3,
    multiplier: 102.47,
  },
  {
    totalMines: 19,
    gemsFound: 4,
    multiplier: 751.41,
  },
  {
    totalMines: 19,
    gemsFound: 5,
    multiplier: 7889.81,
  },
  {
    totalMines: 19,
    gemsFound: 6,
    multiplier: 157796.1,
  },
  {
    totalMines: 20,
    gemsFound: 1,
    multiplier: 4.7,
  },
  {
    totalMines: 20,
    gemsFound: 2,
    multiplier: 28.22,
  },
  {
    totalMines: 20,
    gemsFound: 3,
    multiplier: 204.93,
  },
  {
    totalMines: 20,
    gemsFound: 4,
    multiplier: 2254.23,
  },
  {
    totalMines: 20,
    gemsFound: 5,
    multiplier: 47338.83,
  },
  {
    totalMines: 21,
    gemsFound: 1,
    multiplier: 5.88,
  },
  {
    totalMines: 21,
    gemsFound: 2,
    multiplier: 47.03,
  },
  {
    totalMines: 21,
    gemsFound: 3,
    multiplier: 512.33,
  },
  {
    totalMines: 21,
    gemsFound: 4,
    multiplier: 11271.15,
  },
  {
    totalMines: 22,
    gemsFound: 1,
    multiplier: 7.84,
  },
  {
    totalMines: 22,
    gemsFound: 2,
    multiplier: 89.1,
  },
  {
    totalMines: 22,
    gemsFound: 3,
    multiplier: 2049.3,
  },
  {
    totalMines: 23,
    gemsFound: 1,
    multiplier: 11.75,
  },
  {
    totalMines: 23,
    gemsFound: 2,
    multiplier: 267.3,
  },
  {
    totalMines: 24,
    gemsFound: 1,
    multiplier: 23.51,
  },
];
