import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Feed from "../../services/feed";
import { IGameFeed } from "../../types";
import { RootState } from "../store";
import { selectId } from "./userSlice";

interface GameData {
  higherRoller: IGameFeed[];
  luckyWin: IGameFeed[];
}

export interface CombineGameData {
  [game: string]: GameData;
}

interface FeedState {
  allGameFeed: IGameFeed[] | null;
  allHighRollerFeed: IGameFeed[];
  myBets: IGameFeed[] | null;
  combinedGamesFeed: CombineGameData;
}

const initialState: FeedState = {
  allGameFeed: [],
  allHighRollerFeed: [],
  myBets: null,
  combinedGamesFeed: {},
};

export const getMyBets = createAsyncThunk(
  "user/getMyBets",
  async (_, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;
      const accessToken = state.user.accessToken;
      const user = await Feed.getBets(accessToken);
      return user;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

// Slice definition
export const gameFeed = createSlice({
  name: "gameFeed",
  initialState,
  reducers: {
    updateAllGames: (state, action: PayloadAction<IGameFeed[]>) => {
      state.allGameFeed = action.payload;
    },
    updateAllGameFeed: (state, action: PayloadAction<IGameFeed>) => {
      if (state.allGameFeed) {
        state.allGameFeed = [action.payload, ...state.allGameFeed];
      }
      if (action.payload?.isHighRoller) {
        state.allHighRollerFeed = [action.payload, ...state?.allHighRollerFeed];
      }

      if (action.payload.isHighRoller || action.payload.isLuckyWin) {
        if (
          action.payload?.isHighRoller &&
          state.combinedGamesFeed[action.payload.game]?.higherRoller
        ) {
          state.combinedGamesFeed[action.payload.game].higherRoller = [
            action.payload,
            ...state.combinedGamesFeed[action.payload.game].higherRoller,
          ];
        }
        if (
          action.payload.isLuckyWin &&
          state.combinedGamesFeed[action.payload.game]?.luckyWin
        ) {
          state.combinedGamesFeed[action.payload.game].luckyWin = [
            action.payload,
            ...state.combinedGamesFeed[action.payload.game].luckyWin,
          ];
        }
      }
    },
    updateAllHighRollerFeed: (state, action: PayloadAction<IGameFeed[]>) => {
      state.allHighRollerFeed = action.payload;
    },
    updateCombineGameFeed: (state, action: PayloadAction<CombineGameData>) => {
      state.combinedGamesFeed = action.payload;
    },
    updateMyBets: (state, action: PayloadAction<IGameFeed>) => {
      if (state.myBets) {
        state.myBets = [action.payload, ...state.myBets];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyBets.rejected, (state, action) => {
        state.myBets = [];
      })
      .addCase(getMyBets.fulfilled, (state, action) => {
        state.myBets = action.payload.bets;
      });
  },
});

// Expose the actions
export const {
  updateAllGameFeed,
  updateAllHighRollerFeed,
  updateMyBets,
  updateCombineGameFeed,
  updateAllGames,
} = gameFeed.actions;

// Selectors
export const selectAllGameFeed = (state: RootState) =>
  state.gameFeed.allGameFeed;
export const selectAllHighRollerFeed = (state: RootState) =>
  state.gameFeed.allHighRollerFeed;

export const selectGameHighRollerFeed = (state: RootState, game: string) =>
  state.gameFeed.combinedGamesFeed[game]?.higherRoller;

export const selectGameLuckyWinFeed = (state: RootState, game: string) =>
  state.gameFeed.combinedGamesFeed[game]?.luckyWin;

export const selectMyBets = (state: RootState) => state.gameFeed.myBets;

// other selectors

// Reducer
export default gameFeed.reducer;
