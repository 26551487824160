import configuration from "../config";
import { WAGER_ROUTE } from "../constants";

const get = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(`${configuration.api.url}/${WAGER_ROUTE}`, {
    headers: {
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    credentials: "include",
    method: "GET",
  });

  if (response.status === 409) {
    throw new Error("Can't get all transactions");
  }

  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }
  const json = await response.json();
  return json;
};

const Wager = {
  get,
};

export default Wager;
