import { Stack, Typography, useMediaQuery } from "@mui/material";
import styles from "./index.module.scss";
import { useMotionValue, useTransform, animate, motion } from "framer-motion";
import { useEffect } from "react";
import { TokenIcon } from "../tokenIcon";
import { TOKENTYPE } from "../../types/index.d";
import { theme } from "../theme";

interface IWinModal {
  payout: number;
  multiplier: number;
  handleClose: () => void;
  tokenType?: TOKENTYPE;
}
export const WinModal = ({
  payout,
  multiplier,
  handleClose,
  tokenType,
}: IWinModal) => {
  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) => Number(value.toFixed(4)));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  useEffect(() => {
    const animation = animate(count, payout, {
      duration: 0.75,
      ease: "easeInOut",
    });

    return animation.stop;
    // eslint-disable-next-line
  }, [payout]);
  const isFunCash = tokenType === TOKENTYPE.FREE;
  return (
    <Stack className={styles.winModalBg} onClick={handleClose}>
      <Stack className={styles.windModalContainer} gap={2}>
        <Stack
          direction="row"
          sx={{
            p: 1,
            position: "relative",
            backgroundColor: "#32C932",
            borderRadius: "20px 20px 0px 0px",
            height: "max-content",
          }}
          alignItems="center"
        >
          <Typography color="text.primary" variant="h5">
            You win !
          </Typography>
          <img
            className={styles.winImg}
            alt="gift-icon"
            src={"https://img.icons8.com/fluency/48/gift--v2.png"}
          />
        </Stack>
        <Stack color="text.primary" alignItems="center">
          <Typography variant={isFunCash || !matchesMD ? "h4" : "h3"}>
            <Stack
              direction="row"
              color="text.primary"
              alignItems="center"
              gap={1}
            >
              <TokenIcon width={30} freeCash={isFunCash} />
              <motion.div>{rounded}</motion.div>
            </Stack>
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2, pb: 1 }}
        >
          <Typography color="text.primary" variant="h6">
            Multiplier
          </Typography>
          <Typography color="text.primary" variant="h6">
            x{multiplier}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
