import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Transaction from "../../services/transaction";
import Wager from "../../services/wager";
import { RootState } from "../store";

export interface IWager {
  payout: number;
  division: string;
  expired: boolean;
  expireAt: Date;
  records: IRecord[];
  winners: IWinner[];
  createdAt: string;
  _id: string;
}

export interface IRecord {
  userId: string;
  totalWagered: number;
  userName: string;
}
export interface IWinner {
  userId: string;
  userName: string;
  level: number;
  rank: number;
  wagered: number;
}

interface WagerState {
  currentWager: IWager | null;
  errorMessage: string;
  loading: boolean;
}

const initialState: WagerState = {
  currentWager: null,
  errorMessage: "",
  loading: false,
};

export const getWager = createAsyncThunk("wager/get", async (_, thunkAPI) => {
  try {
    const transaction = await Wager.get();
    return transaction;
  } catch (error) {
    return thunkAPI.rejectWithValue(
      error instanceof Error ? error.message : "An unknown error occurred"
    );
  }
});

// Slice definition
export const wagerReducer = createSlice({
  name: "wager",
  initialState,
  reducers: {
    updateWager(state, action: PayloadAction<IWager>) {
      state.currentWager = action.payload;
    },
    updateErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWager.fulfilled, (state, action) => {
        state.currentWager = action.payload;
        state.loading = false;
      })
      .addCase(getWager.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(getWager.pending, (state) => {
        state.loading = true;
      });
  },
});

// Expose the actions
export const { updateWager, updateErrorMessage } = wagerReducer.actions;

// Selectors
export const selectWager = (state: RootState) => state.wager.currentWager;
export const selectLoading = (state: RootState) => state.wager.loading;

// Reducer
export default wagerReducer.reducer;
