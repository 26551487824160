import { Stack, Typography, Avatar } from "@mui/material";
import styles from "./index.module.scss";
import { useMotionValue, useTransform, animate, motion } from "framer-motion";
import { useEffect } from "react";
import ModalComponent from "@mui/material/Modal";
import { theme } from "../theme";
import { useAppSelector } from "../store/hooks";
import {
  selectLevelUpModal,
  selectPlayer,
  selectTokensEarned,
  updateLevelUpModal,
} from "../slices/userSlice";
import { getPlayerLevelData } from "../../utils";
import { useDispatch } from "react-redux";
import { TokenIcon } from "../tokenIcon";

export const LevelUpModal = () => {
  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) => Number(value.toFixed(2)));
  const { player } = useAppSelector(selectPlayer);
  const dispatch = useDispatch();
  const isLevelUp = useAppSelector(selectLevelUpModal);
  const getPlayerLevel = getPlayerLevelData(player.level);
  const payout = useAppSelector(selectTokensEarned);

  //3, 13, 8
  useEffect(() => {
    const animation = animate(count, payout, {
      duration: 1.5,
      ease: "easeInOut",
    });

    return animation.stop;
    // eslint-disable-next-line
  }, [payout]);

  const handleClose = () => {
    dispatch(updateLevelUpModal(false));
  };

  return (
    <ModalComponent
      open={isLevelUp}
      onClose={handleClose}
      disableScrollLock={true}
    >
      <motion.div
        className={styles.windModalContainer}
        animate={{
          boxShadow: [
            "0",
            `0 0 10px ${getPlayerLevel.color}, 0 0 20px ${getPlayerLevel.color}, 0 0 30px ${getPlayerLevel.color}`,
            "0",
          ],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          repeatType: "mirror",
          ease: "linear",
        }}
      >
        <Stack gap={2}>
          <Stack
            direction="row"
            sx={{
              p: 1,
              position: "relative",
              backgroundColor: getPlayerLevel.color,
              borderRadius: "20px 20px 0px 0px",
              height: "max-content",
              px: 0,
              width: "100%",
            }}
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Typography
              sx={{
                pl: 2,
                color:
                  player.level !== 3 &&
                  player.level !== 13 &&
                  player.level !== 8
                    ? theme.palette.text.primary
                    : theme.palette.text.secondary,
              }}
              variant="h5"
            >
              Rank Unlocked: {getPlayerLevel.name}
            </Typography>
            <Avatar
              variant="square"
              src={getPlayerLevel.imgSrc}
              sx={{
                pr: 2,
                "& .MuiAvatar-img": {
                  objectFit: "contain",
                },
              }}
            />
          </Stack>
          <Stack color="text.primary" alignItems="center" sx={{ mb: 2 }}>
            <Typography variant="h3">
              <Stack
                direction="column"
                gap={0.5}
                color="text.primary"
                alignItems="center"
              >
                <Typography color="text.primary" variant="h5" sx={{ pl: 2 }}>
                  Bonus Reward
                </Typography>
                <Stack direction="row" alignItems="flex-end" gap={1}>
                  <TokenIcon width={30} />

                  <motion.div style={{ margin: 0, lineHeight: 1 }}>
                    {rounded}
                  </motion.div>
                </Stack>
              </Stack>
            </Typography>
          </Stack>
        </Stack>
      </motion.div>
    </ModalComponent>
  );
};
