import React, { useState, useEffect, useContext, useRef } from "react";
import { Stack, Typography, InputAdornment } from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import {
  InputFieldStyled,
  InputFieldWithStartAdornment,
  ProfitOnWinInput,
} from "../inputField";
import styles from "./index.module.scss";
import {
  isBetGreaterthanSetValue,
  RoundNumber,
  RoundProfitOnWin,
} from "../../utils";
import {
  openModal,
  selectCurrentUser,
  selectLevelUpModal,
  selectLimboToggle,
  selectToken,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import Result from "./result";
import CloseIcon from "@mui/icons-material/Close";
import PercentIcon from "@mui/icons-material/Percent";
import ChatContext from "../../contexts/chat/context";
import { PLAY_LIMBO_GAME } from "../../constants/socket";
import { DICEMODE, MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import DiceAutoButtons, {
  BetButton,
  GameTypeButtons,
  IAutoPlayStates,
} from "./diceButtons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { resetLimboGame, selectLimboGames } from "../slices/limboSlice";
import { DEFAULT_DISABLED_MESSAGE, SELECTIONSTATUS } from "../../constants";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import useSound from "use-sound";
import BetSound from "../../assets/audio/common/create-bet.mp3";

const totalOutcomes = 10000;
const houseEdge = 0.04;

const Limbo: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [multiplier, setMultiplier] = useState(1.92);
  const [winProbabilityInput, setWinProbabilityInput] = useState(50.0);
  const [updateSource, setUpdateSource] = useState("multiplier");
  const accessToken = useAppSelector(selectToken);
  const player = useAppSelector(selectCurrentUser);
  const { errorMessage, limbo, pastResult } = useAppSelector(selectLimboGames);
  const { socket } = useContext(ChatContext);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [playType, setPlayType] = useState<DICEMODE>(DICEMODE.MANUAL);
  const [confirmation, setConfirmation] = useState(false);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const toggle = useAppSelector(selectLimboToggle);
  const betRef = useRef<number>(0);
  const [autoPlayStates, setAutoPlayStates] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });
  const [title, setTitle] = useState("");
  const isLevelUp = useAppSelector(selectLevelUpModal);
  const [autoPlayStatesRef, setAutoPlayStatesRef] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const tokenType = useAppSelector(selectTokenSelection);
  const tokenTypeRef = useRef(tokenType);

  const [isAutoPlaying, setIsAutoPlaying] = useState(false);

  const [inputRef, setInputRef] = useState({
    multiplier: "1.92",
    winProbabilityInput: "50.0",
  });

  const [currentBet, setCurrentBet] = useState(0);

  const updateFromMultiplier = () => {
    const adjustedWinProbability = ((1 - houseEdge) / multiplier) * 100;
    const winProb = parseFloat(adjustedWinProbability.toFixed(6));
    setInputRef({
      ...inputRef,
      winProbabilityInput: winProb.toString(),
    });
    setWinProbabilityInput(winProb);
    if (winProb < 0.1) {
      setUpdateSource("winProbability");
    }
  };

  // Update states based on the win probability
  const updateFromWinProbability = () => {
    let multiplyerValue = 0;
    const rollNum = (100 - winProbabilityInput) * 100;

    const winningOutcomes = totalOutcomes - rollNum;
    const newMultiplier = ((1 - houseEdge) / winningOutcomes) * 10000;
    multiplyerValue = parseFloat(newMultiplier.toFixed(2));

    setInputRef({
      ...inputRef,
      multiplier: multiplyerValue.toString(),
    });
    setMultiplier(multiplyerValue);
  };

  // Handle changes to the multiplier
  useEffect(() => {
    if (updateSource === "multiplier") {
      updateFromMultiplier();
    }
  }, [multiplier, updateSource]);

  // Handle changes to the win probability
  useEffect(() => {
    if (updateSource === "winProbability") {
      updateFromWinProbability();
    }
  }, [winProbabilityInput, updateSource]);

  // Handle changes to the multiplier input
  const handleMultiplierRefChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setInputRef({
      ...inputRef,
      multiplier: value,
    });
  };

  const handleMultiplierChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdateSource("multiplier");
    let value = parseFloat(Number(inputRef.multiplier).toFixed(2));
    if (value < 1.0105) {
      value = 1.0105;
    }
    if (value > 1000000) {
      value = 1000000;
    }
    setInputRef({
      ...inputRef,
      multiplier: value.toString(),
    });
    setMultiplier(value);
  };

  // Handle changes to the win probability input

  const handleWinProbabilityRefChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setInputRef({
      ...inputRef,
      winProbabilityInput: value,
    });
  };

  const handleWinProbabilityChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdateSource("winProbability");
    let value = parseFloat(Number(inputRef.winProbabilityInput).toFixed(6));

    if (value < 0.01) {
      value = 0.01;
    }
    if (value > 95) {
      value = 95;
    }
    setInputRef({
      ...inputRef,
      winProbabilityInput: value.toString(),
    });
    setWinProbabilityInput(value);
  };

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  // Handle changes to the roll over number input

  const playGame = (bet = currentBet, askConfirmation = true) => {
    if (title) {
      setTitle(title);
      return;
    }
    if (!toggle) {
      setIsAutoPlaying(false);
      return;
    }
    if ((bet > player.balance && tokenType === TOKENTYPE.SWEEP) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }

    if ((bet > player.freeCash && tokenType === TOKENTYPE.FREE) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }

    if (
      tokenType === TOKENTYPE.SWEEP &&
      !isAutoPlaying &&
      askConfirmation &&
      ((player.isBetConfirmation &&
        isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          player?.betConfirmationValue
        )) ||
        isBetGreaterthanSetValue(currentBet, player.balance, 95))
    ) {
      setConfirmation(true);
      return;
    }

    if (!askConfirmation && dontShowConfirmation) {
      let payload: Partial<User> = {
        isBetConfirmation: false,
        betConfirmationValue: 0,
      };
      dispatch(updateSettings(payload));
    }

    const gameData = {
      currentBet: bet,
      multiplier,
      tokenType,
    };
    tokenTypeRef.current = tokenType;
    if (playType === DICEMODE.MANUAL) {
      playBetSound();
      socket?.emit(PLAY_LIMBO_GAME, {
        accessToken,
        gameData,
      });
      setDisableButton(true);
    }

    if (playType === DICEMODE.AUTO) {
      if (!isAutoPlaying) {
        setAutoPlayStatesRef({
          ...autoPlayStates,
          stopNetGain: 0,
          stopNetLoss: 0,
        });
        betRef.current = bet;
      }
      playBetSound();
      setIsAutoPlaying(true);
      socket?.emit(PLAY_LIMBO_GAME, {
        accessToken,
        gameData,
      });
      setDisableButton(true);
    }

    setTimeout(() => {
      setDisableButton(false);
    }, 750);
  };

  useEffect(() => {
    return () => {
      dispatch(resetLimboGame());
    };
  }, []);

  useEffect(() => {
    if (pastResult.length && isAutoPlaying) {
      const getLastGame: any = pastResult[pastResult.length - 1];
      let shouldContinue = isLevelUp ? false : true;
      let newBet = currentBet;
      let letLoss = autoPlayStatesRef.stopNetLoss;
      let netGain = autoPlayStatesRef.stopNetLoss;

      //Stop when token changes
      if (tokenTypeRef.current !== tokenType) {
        shouldContinue = false;
      }

      // Check Number of Games
      if (autoPlayStates.numberOfGames) {
        if (autoPlayStatesRef.numberOfGames > 1) {
          setAutoPlayStatesRef({
            ...autoPlayStatesRef,
            numberOfGames: autoPlayStatesRef.numberOfGames - 1,
          });
        } else {
          shouldContinue = false;
        }
      }

      if (autoPlayStates.onWin && getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onWin / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onWin / 100)).toFixed(2)
        );
      }

      if (
        autoPlayStates.winStatus === SELECTIONSTATUS.RESET &&
        getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (
        autoPlayStates.lossStatus === SELECTIONSTATUS.RESET &&
        !getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (autoPlayStates.onLoss && !getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onLoss / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onLoss / 100)).toFixed(2)
        );
      }

      // Net Loss
      if (!getLastGame.isWin && autoPlayStates.stopNetLoss) {
        letLoss = letLoss + getLastGame.bet;
        if (letLoss >= autoPlayStates.stopNetLoss) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetLoss: letLoss,
          stopNetGain: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      // Net Gain
      if (getLastGame.isWin && autoPlayStates.stopNetGain) {
        netGain = netGain + (getLastGame.payout - getLastGame.bet);
        if (netGain >= autoPlayStates.stopNetGain) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetGain: netGain,
          stopNetLoss: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      if (
        shouldContinue &&
        ((currentBet <= player.balance &&
          getLastGame.tokenType === TOKENTYPE.SWEEP) ||
          (currentBet <= player.freeCash &&
            getLastGame.tokenType === TOKENTYPE.FREE))
      ) {
        playGame(newBet);
      } else {
        setIsAutoPlaying(false);
      }
    }
  }, [pastResult]);

  useEffect(() => {
    if (errorMessage && isAutoPlaying) {
      setIsAutoPlaying(false);
    }
  }, [errorMessage, isAutoPlaying]);

  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        title="Bet Confirmation"
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          playGame(currentBet, false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          95
        )}
      />
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Limbo
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
          sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <GameTypeButtons
                  playType={playType}
                  setPlayType={setPlayType}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
                <BetButton
                  accessToken={accessToken}
                  disableButton={disableButton || !toggle}
                  playGame={playGame}
                  playType={playType}
                  handleLogin={handleLogin}
                  stopPlaying={() => setIsAutoPlaying(false)}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  disabled={isAutoPlaying}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.LIMBO}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={!accessToken || isAutoPlaying}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  multiplier={multiplier}
                  title={title}
                  balance={player.balance}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    disabled={isAutoPlaying}
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                  >
                    x2
                  </Button>
                  <Button
                    disabled={isAutoPlaying}
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" gap={1.25}>
                <DiceAutoButtons
                  playType={playType}
                  autoPlayStates={autoPlayStates}
                  setAutoPlayStates={setAutoPlayStates}
                  isAutoPlaying={isAutoPlaying}
                  currentNumberOfGames={autoPlayStatesRef.numberOfGames}
                />
                <Stack
                  gap={1.25}
                  sx={{
                    display: playType === DICEMODE.MANUAL ? "flex" : "none",
                  }}
                >
                  <Typography color="text.primary">Profit on Win</Typography>
                  <ProfitOnWinInput
                    value={RoundProfitOnWin(
                      currentBet * multiplier - currentBet
                    )}
                    name="profit-on-win"
                  />
                </Stack>

                <Stack
                  sx={{ display: { md: "block", sm: "none", xs: "none" } }}
                >
                  <BetButton
                    accessToken={accessToken}
                    disableButton={disableButton || !toggle}
                    playGame={playGame}
                    playType={playType}
                    handleLogin={handleLogin}
                    stopPlaying={() => setIsAutoPlaying(false)}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                <Stack
                  sx={{ display: { md: "none", sm: "block", xs: "block" } }}
                >
                  <GameTypeButtons
                    playType={playType}
                    setPlayType={setPlayType}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                {!toggle && (
                  <Typography variant="h6" color="error">
                    {DEFAULT_DISABLED_MESSAGE}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <GameOptions game={GAMES.LIMBO} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
            }}
            gap={{ md: 0, sm: 4, xs: 4 }}
            justifyContent="space-between"
          >
            <Stack
              direction="row-reverse"
              gap={1}
              justifyContent={{ md: "flex-start", xs: "space-between" }}
              sx={{ height: "2em" }}
            >
              {pastResult.length ? (
                <>
                  {[...pastResult]?.reverse().map((result, index) => {
                    if (index > 4) return null;
                    return (
                      <Stack
                        key={result._id}
                        sx={{
                          bgcolor: result.isWin
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                          borderRadius: "5px",
                          width: "max-content",
                          p: 0.75,
                        }}
                      >
                        <Typography
                          sx={{ color: "black" }}
                          textAlign="center"
                          fontWeight={600}
                        >
                          {result.result.toFixed(2)}
                        </Typography>
                      </Stack>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </Stack>
            <Result game={limbo} />
            <Stack
              direction="row"
              justifyContent="space-between"
              gap={{ md: 2, xs: 0 }}
            >
              <Stack alignItems="flex-start" sx={{ width: "100%" }}>
                <Typography color="text.primary">Multiplier</Typography>
                <InputFieldStyled
                  fullWidth
                  inputProps={{
                    min: 0,
                  }}
                  endAdornment={
                    <InputAdornment position="start">
                      <CloseIcon sx={{ width: { md: "auto", xs: "18px" } }} />
                    </InputAdornment>
                  }
                  type="number"
                  disabled={isAutoPlaying}
                  value={inputRef.multiplier}
                  onBlur={handleMultiplierChange}
                  onChange={handleMultiplierRefChange}
                />
              </Stack>
              <Stack alignItems="flex-start" sx={{ width: "100%" }}>
                <Typography color="text.primary">Win Chance</Typography>
                <InputFieldStyled
                  inputProps={{
                    min: 0,
                  }}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="start">
                      <PercentIcon sx={{ width: { md: "auto", xs: "18px" } }} />
                    </InputAdornment>
                  }
                  disabled={isAutoPlaying}
                  type="number"
                  value={inputRef.winProbabilityInput}
                  onChange={handleWinProbabilityRefChange}
                  onBlur={handleWinProbabilityChange}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Limbo;
