import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { Stack, Typography } from "@mui/material";
import styles from "./index.module.scss";
import { useAppSelector } from "../store/hooks";
import {
  selectSlideLoading,
  selectSlideOffset,
  selectTimestamps,
} from "../slices/slideSlice";
import { SLIDESTATE } from "../../constants/slide";
import { slideImagesArray } from "./constants";
import { theme } from "../theme";
import { AnimateNumber } from "../animateNumber";
import AnimationSound from "../../assets/audio/slide/animation.mp3";
import useSound from "use-sound";
import { selectVolume } from "../slices/userSlice";

const imageWidth = 100;

const Slider = ({
  triggerAnimation,
  targetIndex,
  resetAnimation,
  timer,
  gameState,
}: any) => {
  const sliderRef = useRef<any>(null);
  const timestamps = useAppSelector(selectTimestamps);
  const loading = useAppSelector(selectSlideLoading);
  const offset = useAppSelector(selectSlideOffset);
  const [viewWidth, setViewWidth] = useState(0);
  const [extendedImages, setExtendedImages] = useState([
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
    ...slideImagesArray,
  ]);
  const [randomNumber, setRandomNumber] = useState(0);
  const volume = useAppSelector(selectVolume);
  const [playAnimationSound, { stop: stopAnimationSound, sound }] = useSound(
    AnimationSound,
    {
      volume: volume / 100,
    }
  );
  const handleResize = () => {
    if (sliderRef.current) {
      setViewWidth(sliderRef.current.offsetWidth);
    }
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);

    if (sliderRef.current) {
      resizeObserver.observe(sliderRef.current);
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);

      resizeObserver.disconnect();
    };
  }, [sliderRef]);

  useEffect(() => {
    if (triggerAnimation) {
      if (
        targetIndex + slideImagesArray.length * 2 >=
        extendedImages.length - 10
      ) {
        setExtendedImages((prevImages) => [...prevImages, ...slideImagesArray]);
      }
    }
  }, [targetIndex, slideImagesArray]);

  // Calculate the target position to center the target image
  const totalImagesBeforeTarget = targetIndex + slideImagesArray.length;
  const targetPosition =
    -(totalImagesBeforeTarget * imageWidth) + viewWidth / 2 - imageWidth / 2;

  const duration = timestamps?.[SLIDESTATE.ANIMATING]?.timeStamp ?? 6;

  useEffect(() => {
    if (gameState === SLIDESTATE.ANIMATING) {
      playAnimationSound();

      if (duration < 6 && sound) {
        sound.seek(6 - duration);

        const stopTimeout = setTimeout(() => {
          stopAnimationSound();
        }, 6000);

        return () => {
          clearTimeout(stopTimeout);
          stopAnimationSound();
        };
      }
    }

    return () => {
      stopAnimationSound();
    };
  }, [gameState, sound]);

  useEffect(() => {
    if (gameState === SLIDESTATE.COUNTDOWN) {
      setRandomNumber(offset);
    }
  }, [setRandomNumber, gameState]);
  const durationReset = timer > 11 ? timer - 11 : 0;

  function fastStartSlowEnd(x: number): number {
    return Math.sqrt(1 - (x = x - 1) * x);
  }

  const sliderVariants = {
    [SLIDESTATE.ANIMATING]: {
      x: targetPosition + randomNumber,
      transition: {
        duration: duration > 0 ? duration : 0,
        ease: fastStartSlowEnd,
      },
    },
    [SLIDESTATE.COUNTDOWN]: {
      x: targetPosition,
      transition: { type: "tween", duration: durationReset },
    },
    [SLIDESTATE.RESULT]: {
      x: targetPosition + randomNumber,
      transition: { type: "tween", duration: 0 },
    },
  };

  useEffect(() => {
    if (gameState === SLIDESTATE.COUNTDOWN && targetIndex) {
      resetAnimation();
    }
  }, [gameState]);

  return (
    <div
      style={{
        backgroundColor: theme.palette.primary.main,
      }}
      className={styles.sliderContainer}
    >
      <Stack
        ref={sliderRef}
        sx={{
          opacity: loading ? 0 : 1,
          padding: "25px 5px",
          maxWidth: "100%",
          position: "relative",
          overflow: "hidden",
          filter:
            gameState !== SLIDESTATE.COUNTDOWN
              ? "blur(0px)"
              : "brightness(0.4)",
        }}
      >
        <motion.div
          animate={gameState}
          variants={sliderVariants}
          style={{ display: "flex", width: "100px" }}
        >
          {extendedImages.map((image, index) => (
            <motion.img
              key={`img - ${index}`}
              src={image}
              className={styles.sliderImg}
              data-selected-red={
                index === totalImagesBeforeTarget &&
                gameState === SLIDESTATE.RESULT &&
                image.includes("red")
              }
              data-selected-black={
                index === totalImagesBeforeTarget &&
                gameState === SLIDESTATE.RESULT &&
                image.includes("black")
              }
              data-selected-green={
                index === totalImagesBeforeTarget &&
                gameState === SLIDESTATE.RESULT &&
                image.includes("green")
              }
            />
          ))}
        </motion.div>

        <hr className={styles.mark} />
      </Stack>
      {gameState === SLIDESTATE.COUNTDOWN && (
        <Stack className={styles.sliderTimer} alignItems="center">
          <Typography color="text.primary" variant="h4" fontWeight={600}>
            Rolling In
          </Typography>
          {timer !== null && (
            <Typography color="text.primary" variant="h4">
              <AnimateNumber currentNumber={timer || 0} />
            </Typography>
          )}
        </Stack>
      )}
    </div>
  );
};

export default Slider;
