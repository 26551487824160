import { Stack, Typography } from "@mui/material";
import { Button } from "../button";
import { selectSlideBets } from "../slices/slideSlice";
import { useAppSelector } from "../store/hooks";
import BetList from "./betList";
import redIcons from "../../assets/slide/slide-red.svg";
import greenIcons from "../../assets/slide/slide-green.svg";
import blackIcons from "../../assets/slide/slide-black.svg";
import { SLIDESTATE } from "../../constants/slide";
import { theme } from "../theme";
import { selectId } from "../slices/userSlice";

export const imagesOrder = [redIcons, greenIcons, blackIcons];
export const getSelection = (selection: number) => {
  if (selection === 1) {
    return 2;
  }
  if (selection === 2) {
    return 1;
  }
  return selection;
};

interface IBetButton {
  disabled: boolean;
  handleBet: (index: number) => void;
  gameState: SLIDESTATE;
  slideResult: number;
  placedBet: number[];
}

export const imageData = [
  {
    src: redIcons,
    selection: 0,
    multiplier: 2,
    color: "#770000",
    hover: "#cc0000",
    textColor: theme.palette.text.primary,
  },
  {
    src: greenIcons,
    selection: 2,
    multiplier: 14,
    color: "#1a7501",
    hover: "#2dcd01",
    textColor: theme.palette.text.primary,
  },
  {
    src: blackIcons,
    selection: 1,
    multiplier: 2,
    color: "#1d1d1d",
    hover: "#101010",
    textColor: theme.palette.text.primary,
  },
];
export const BetButton = ({
  disabled,
  handleBet,
  gameState,
  slideResult,
  placedBet,
}: IBetButton) => {
  const bets = useAppSelector(selectSlideBets);
  const playerId = useAppSelector(selectId);

  return (
    <Stack
      direction={{ md: "row", sm: "column" }}
      sx={{ width: "100%" }}
      gap={2}
    >
      {imageData.map((imgSrc, index) => {
        const { selection, color, hover, textColor } = imgSrc;
        const myBets = bets.find(
          (bet) => bet.selection === selection && bet.user === playerId
        );
        return (
          <Stack sx={{ width: "100%" }} gap={1.25} key={`image-${selection}`}>
            <Button
              sx={{
                backgroundColor: hover,
                color: textColor,
                "&:hover": {
                  boxShadow: `0 5px 15px ${index !== 2 ? hover : "white"}`,
                  backgroundColor: `${hover} !important`,
                },
                "&:disabled": {
                  color: `grey !important`,
                  backgroundColor: color,
                },
              }}
              disabled={
                disabled ||
                placedBet.includes(selection) ||
                !!myBets ||
                gameState !== SLIDESTATE.COUNTDOWN
              }
              fullWidth
              onClick={() => handleBet(selection)}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Typography variant="body1" fontWeight={600}>
                  Bet
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  {`${imgSrc.multiplier}x`}
                </Typography>
              </Stack>
            </Button>
            <BetList
              showResult={gameState === SLIDESTATE.RESULT}
              slideResult={slideResult}
              icon={imgSrc.src}
              isWin={selection === slideResult}
              selection={selection}
              bets={bets.filter((bet) => bet.selection === selection)}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};
