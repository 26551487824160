import * as React from "react";
import List from "@mui/material/List";
import {
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItem,
  Stack,
  Avatar,
  Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { theme } from "../theme";
import { SlideBet } from "../slices/slideSlice";
import { getPlayerLevelData, RoundNumber } from "../../utils";
import { TokenIcon } from "../tokenIcon";
import { TOKENTYPE } from "../../types/index.d";

interface IBetList {
  icon: string;
  bets: SlideBet[];
  showResult: boolean;
  slideResult: number;
  isWin: boolean;
  selection: number;
}

const getColor = (isWin: boolean, showResult: boolean) => {
  if (!showResult) return theme.palette.text.primary;
  if (isWin) {
    return theme.palette.success.main;
  }
  return theme.palette.error.main;
};
export default function BetList({
  icon,
  bets,
  showResult,
  selection,
  slideResult,
  isWin,
}: IBetList) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const calculatePayout = (bet: number) => {
    return isWin
      ? `+${RoundNumber(bet * (selection === 2 ? 14 : 2))}`
      : `-${bet}`;
  };
  const RoundNumber = (number: number, decimals = 2) => {
    const factor = Math.pow(10, decimals);
    return Math.round(number * factor) / factor;
  };

  const totalBet = RoundNumber(
    bets
      .filter((item) => item.tokenType === TOKENTYPE.SWEEP)
      .reduce((sum, item) => sum + item.bet, 0)
  );
  const totalBetFree = RoundNumber(
    bets
      .filter((item) => item.tokenType === TOKENTYPE.FREE)
      .reduce((sum, item) => sum + item.bet, 0)
  );
  const payout = calculatePayout(totalBet);
  const payoutFree = calculatePayout(totalBetFree);

  return (
    <Stack
      sx={{
        borderRadius: 2,
        border: "1px solid black",
        background: theme.palette.primary.main,
      }}
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <img src={icon} style={{ width: 25 }} />
        </ListItemIcon>
        <ListItemText
          sx={{ color: theme.palette.text.primary, marginLeft: "-15px" }}
          primary={`${bets.length} Bet${bets.length === 1 ? "" : "s"}`}
        />
        <Stack
          sx={{ color: theme.palette.text.primary }}
          direction="row"
          gap={1.5}
        >
          <Stack direction="row" gap={0.5} alignItems="center">
            <TokenIcon width={20} />

            <Typography
              sx={{ color: getColor(isWin, showResult), mr: 1 }}
              fontWeight={500}
            >
              {showResult ? payout : totalBet}
            </Typography>
            <TokenIcon width={20} freeCash />

            <Typography
              sx={{ color: getColor(isWin, showResult) }}
              fontWeight={500}
            >
              {showResult ? payoutFree : totalBetFree}
            </Typography>
          </Stack>
          {/* {bets.length ? <>{open ? <ExpandLess /> : <ExpandMore />}</> : <></>} */}
        </Stack>
      </ListItemButton>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        sx={{ borderRadius: "0px 0px 10px 10px", borderTop: "1px solid black" }}
      >
        <List component="div" disablePadding>
          {bets.map((bet, i) => {
            const getPlayerLevel = getPlayerLevelData(bet.userLevel);
            return (
              <ListItem key={`bet-${i}`}>
                <ListItemIcon>
                  <Avatar
                    variant="square"
                    src={getPlayerLevel.imgSrc}
                    sx={{
                      width: 25,
                      "& .MuiAvatar-img": {
                        objectFit: "contain",
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    color: theme.palette.text.primary,
                    marginLeft: "-15px",
                  }}
                  primary={
                    <Stack
                      direction="row"
                      sx={{ width: "100%" }}
                      justifyContent="space-between"
                    >
                      <Typography>{bet.userName}</Typography>
                      <Stack direction="row" gap={0.5} alignItems="center">
                        <TokenIcon
                          width={20}
                          freeCash={bet.tokenType === TOKENTYPE.FREE}
                        />
                        <Typography
                          fontWeight={500}
                          sx={{ color: getColor(isWin, showResult) }}
                        >
                          {showResult ? calculatePayout(bet.bet) : bet.bet}
                        </Typography>
                      </Stack>
                    </Stack>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </Stack>
  );
}
