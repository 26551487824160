import { Stack, useMediaQuery } from "@mui/material";
import configuration from "../../config";
import { theme } from "../theme";
import styles from "./index.module.scss";
import Turnstile from "react-turnstile";

const HCaptcha = ({ onVerify, forceNormal = false }: any) => {
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Stack
      sx={{ width: "100%" }}
      justifyContent="center"
      className={styles.hCaptcha}
    >
      <Turnstile
        sitekey={configuration.turnstileKey}
        theme="dark"
        size={matchesMD || forceNormal ? "normal" : "compact"}
        onVerify={onVerify}
      />
    </Stack>
  );
};

export default HCaptcha;
