import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useState } from "react";
import styles from "../index.module.scss";
import BombImg from "../../../assets/mines/bomb.svg";
import DiamondImg from "../../../assets/mines/diamond.svg";

interface ITile {
  cellType: string;
  index: number;
  isRevealed: boolean;
  inPlay: boolean;
  isLastBomb: boolean;
}
const tileVariants = {
  initial: {
    y: -50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
};

const MinesTile = React.memo((props: ITile) => {
  const { isRevealed, index, cellType, inPlay, isLastBomb } = props;

  return (
    <motion.div
      variants={tileVariants}
      initial="initial"
      animate="animate"
      className={styles.tileContainer}
      key={index}
      data-islastbomb={isLastBomb}
      data-gameend={isRevealed && !inPlay && !isLastBomb}
      data-filled={true}
    >
      <motion.div className={styles.tile} data-idle={!inPlay}>
        <motion.div className={styles.tileContent} data-filled={true}>
          {cellType === "mine" ? (
            <img src={BombImg} style={{ width: "100%" }} alt="bomb" />
          ) : (
            <img src={DiamondImg} style={{ width: "100%" }} alt="diamond" />
          )}
        </motion.div>
      </motion.div>
    </motion.div>
  );
});

export default MinesTile;
