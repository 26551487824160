import { Stack, Typography, InputAdornment } from "@mui/material";
import { useState } from "react";
import { DICEMODE } from "../../types/index.d";
import { InputFieldStyled } from "../inputField";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Button } from "../button";
import PercentIcon from "@mui/icons-material/Percent";

export interface IAutoPlayStates {
  numberOfGames: number;
  onWin: number;
  onLoss: number;
  stopNetGain: number;
  stopNetLoss: number;
  winStatus: SELECTIONSTATUS;
  lossStatus: SELECTIONSTATUS;
}

interface ISliderWithLabel {
  playType: DICEMODE;
  autoPlayStates: IAutoPlayStates;
  setAutoPlayStates: (states: IAutoPlayStates) => void;
  isAutoPlaying: boolean;
  currentNumberOfGames: number;
}

enum SELECTIONSTATUS {
  RESET = "rest",
  VALUE = "value",
}

export default function DiceAutoButtons({
  playType,
  autoPlayStates,
  setAutoPlayStates,
  isAutoPlaying,
  currentNumberOfGames,
}: ISliderWithLabel) {
  const [winStatus, setWinStatus] = useState(SELECTIONSTATUS.RESET);
  const [loseStatus, setLoseStatus] = useState(SELECTIONSTATUS.RESET);

  const updateField = (key: string, value: number | any) => {
    let currentValue = value;
    if (currentValue !== null && currentValue < 0) {
      return;
    }
    if (key === "numberOfGames" && currentValue && currentValue > 999999999) {
      currentValue = 999999999;
    }
    setAutoPlayStates({
      ...autoPlayStates,
      [key]: currentValue,
    });
  };

  const handleWinStatus = (type: SELECTIONSTATUS) => {
    if (type === SELECTIONSTATUS.RESET) {
      updateField("onWin", 0);
    }
    setWinStatus(type);
    updateField("winStatus", type);
  };

  const handleLossStatus = (type: SELECTIONSTATUS) => {
    if (type === SELECTIONSTATUS.RESET) {
      updateField("onLoss", 0);
    }
    setLoseStatus(type);
    updateField("lossStatus", type);
  };

  const numberOfGames = !isAutoPlaying
    ? autoPlayStates.numberOfGames
    : currentNumberOfGames;
  return (
    <Stack
      gap={0.5}
      sx={{ display: playType === DICEMODE.AUTO ? "flex" : "none" }}
    >
      <Stack alignItems="flex-start">
        <Typography color="text.primary">Number of Games</Typography>
        <InputFieldStyled
          inputProps={{
            min: 0,
          }}
          endAdornment={
            <InputAdornment
              position="start"
              sx={{
                display: autoPlayStates.numberOfGames && "none",
                color: "white",
                ml: 0.5,
              }}
            >
              <AllInclusiveIcon sx={{ width: { md: "auto", xs: "18px" } }} />
            </InputAdornment>
          }
          disabled={isAutoPlaying}
          fullWidth
          type="number"
          value={!numberOfGames ? null : numberOfGames}
          onChange={(e) => updateField("numberOfGames", Number(e.target.value))}
        />
      </Stack>

      <Stack alignItems="flex-start">
        <Typography color="text.primary">On Win</Typography>
        <Stack direction="row" gap={0.5}>
          <GameTypeButtonReset
            status={winStatus}
            setStatus={handleWinStatus}
            isAutoPlaying={isAutoPlaying}
          />
          <InputFieldStyled
            inputProps={{
              min: 0,
            }}
            disabled={winStatus === SELECTIONSTATUS.RESET || isAutoPlaying}
            endAdornment={
              <InputAdornment position="start" sx={{ color: "white" }}>
                <PercentIcon sx={{ width: { md: "auto", xs: "18px" } }} />
              </InputAdornment>
            }
            type="number"
            value={autoPlayStates.onWin || ""}
            onChange={(e) => updateField("onWin", Number(e.target.value))}
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-start">
        <Typography color="text.primary">On Loss</Typography>
        <Stack direction="row" gap={0.5}>
          <GameTypeButtonReset
            status={loseStatus}
            setStatus={handleLossStatus}
            isAutoPlaying={isAutoPlaying}
          />
          <InputFieldStyled
            inputProps={{
              min: 0,
            }}
            disabled={loseStatus === SELECTIONSTATUS.RESET || isAutoPlaying}
            endAdornment={
              <InputAdornment position="start">
                <PercentIcon sx={{ width: { md: "auto", xs: "18px" } }} />
              </InputAdornment>
            }
            value={autoPlayStates.onLoss || ""}
            onChange={(e) => updateField("onLoss", Number(e.target.value))}
            type="number"
          />
        </Stack>
      </Stack>
      <Stack alignItems="flex-start">
        <Typography color="text.primary">Stop on Net Gain</Typography>
        <InputFieldStyled
          inputProps={{
            min: 0,
          }}
          disabled={isAutoPlaying}
          fullWidth
          type="number"
          value={
            !autoPlayStates.stopNetGain ? null : autoPlayStates.stopNetGain
          }
          onChange={(e) => updateField("stopNetGain", Number(e.target.value))}
        />
      </Stack>
      <Stack alignItems="flex-start">
        <Typography color="text.primary">Stop on Net Loss</Typography>
        <InputFieldStyled
          inputProps={{
            min: 0,
          }}
          disabled={isAutoPlaying}
          fullWidth
          type="number"
          value={
            !autoPlayStates.stopNetLoss ? null : autoPlayStates.stopNetLoss
          }
          onChange={(e) => updateField("stopNetLoss", Number(e.target.value))}
        />
      </Stack>
    </Stack>
  );
}

export const GameTypeButtons = ({
  playType,
  setPlayType,
  isAutoPlaying,
}: {
  playType: DICEMODE;
  setPlayType: (type: DICEMODE) => void;
  isAutoPlaying: boolean;
}) => {
  const handleClick = (type: DICEMODE) => {
    setPlayType(type);
  };
  return (
    <Stack direction="row" gap={2}>
      <Button
        variantType={playType === DICEMODE.MANUAL ? "selected" : "selection"}
        onClick={() => handleClick(DICEMODE.MANUAL)}
        disabled={isAutoPlaying}
      >
        Manual
      </Button>
      <Button
        variantType={playType === DICEMODE.AUTO ? "selected" : "selection"}
        onClick={() => handleClick(DICEMODE.AUTO)}
        disabled={isAutoPlaying}
      >
        Auto
      </Button>
    </Stack>
  );
};

export const GameTypeButtonReset = ({
  status,
  setStatus,
  isAutoPlaying,
}: {
  status: SELECTIONSTATUS;
  setStatus: (type: SELECTIONSTATUS) => void;
  isAutoPlaying: boolean;
}) => {
  return (
    <Stack direction="row" gap={0.5} sx={{ width: "100%" }}>
      <Button
        variantType={
          status === SELECTIONSTATUS.RESET ? "selected" : "selection"
        }
        onClick={() => setStatus(SELECTIONSTATUS.RESET)}
        sx={{ fontSize: "12px", p: 0.25 }}
        fullWidth
        disabled={isAutoPlaying}
      >
        Reset
      </Button>
      <Button
        disabled={isAutoPlaying}
        variantType={
          status === SELECTIONSTATUS.VALUE ? "selected" : "selection"
        }
        onClick={() => setStatus(SELECTIONSTATUS.VALUE)}
        sx={{ fontSize: "12px", p: 0.25 }}
        fullWidth
      >
        Increase by:
      </Button>
    </Stack>
  );
};

interface IBetButton {
  accessToken: string;
  disableButton: boolean;
  playGame: () => void;
  handleLogin: () => void;
  playType: DICEMODE;
  stopPlaying: () => void;
  isAutoPlaying: boolean;
}

export const BetButton = ({
  accessToken,
  disableButton,
  playGame,
  playType,
  handleLogin,
  stopPlaying,
  isAutoPlaying,
}: IBetButton) => {
  if (isAutoPlaying) {
    return (
      <Button fullWidth onClick={() => stopPlaying()} variantType="error">
        Stop Playing
      </Button>
    );
  }
  if (accessToken) {
    return (
      <Button
        fullWidth
        disabled={disableButton}
        onClick={() => playGame()}
        variantType="success"
      >
        {playType === DICEMODE.MANUAL ? "Bet" : "Start Autoplay"}
      </Button>
    );
  }
  if (!accessToken) {
    return (
      <Button onClick={() => handleLogin()} fullWidth>
        Login
      </Button>
    );
  }
  return <></>;
};
