import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface DiceDocument {
  user: string;
  bet: number;
  payout: number;
  result: number;
  createdAt: Date;
  clientSeed: string;
  serverSeed: string;
  gameType: string;
  gameNonce: number;
  multiplier: number;
  roll: number;
  isMax: boolean;
  isWin: boolean;
  tokenType: string;
  _id: string;
}

interface DiceState {
  dice: DiceDocument | null;
  pastResult: DiceDocument[];
  errorMessage: string;
}

const initialState: DiceState = {
  dice: null,
  pastResult: [],
  errorMessage: "",
};

// Slice definition
export const diceReducer = createSlice({
  name: "dice",
  initialState,
  reducers: {
    updateGame(state, action: PayloadAction<DiceDocument>) {
      state.errorMessage = "";
      state.dice = action.payload;
      // state.pastResult.push(action.payload);
    },
    updateErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    updatePastGame(state, action: PayloadAction<DiceDocument>) {
      state.pastResult.push(action.payload);
    },
  },
});

// Expose the actions
export const { updateGame, updateErrorMessage, updatePastGame } =
  diceReducer.actions;

// Selectors
export const selectDiceGames = (state: RootState) => state.dice;

// other selectors

// Reducer
export default diceReducer.reducer;
