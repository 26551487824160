import { Container, Stack, Typography, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../components/store";
import { useEffect } from "react";
import styles from "./index.module.scss";
import {
  getWager,
  selectLoading,
  selectWager,
} from "../../components/slices/wagerSlice";
import WagerRaceTable from "./wagerRaceTable";
import CountdownTimer from "./countdownTimer";
import { TokenIcon } from "../../components/tokenIcon";
// import { selectToken } from "../../components/slices/userSlice";
// import ExchangeService from "../../services/exchange";
// import { EXCHANGETYPE } from "../../types/index.d";

const DEFAULT_WAGER_PAYOUT = 4000;
const WagerRace = () => {
  const dispatch = useDispatch<AppDispatch>();
  const wager = useSelector(selectWager);
  const loading = useSelector(selectLoading);
  // const [potentialTokens, setPotentialTokens] = useState("");
  // const accessToken = useSelector(selectToken);

  useEffect(() => {
    dispatch(getWager());
  }, []);

  // useEffect(() => {
  //   const getTokenValue = async () => {
  //     const getToken = await ExchangeService.getBalance(
  //       EXCHANGETYPE.DEPOSITOSRS,
  //       DEFAULT_WAGER_PAYOUT,
  //       true,
  //       accessToken
  //     );
  //     if (getToken) {
  //       setPotentialTokens(getToken.potentialTokens);
  //     }
  //   };

  //   getTokenValue();
  // }, []);

  return (
    <Container maxWidth="md" sx={{ p: { md: "auto", xs: 0 } }}>
      {/* <Stack gap={2}> */}

      <Stack gap={3}>
        {!loading && wager ? (
          <Stack gap={2}>
            <Typography
              className={styles.heading}
              variant="h1"
              color="text.primary"
            >
              Wager Race
              <TokenIcon width={40} sx={{ margin: "0px 5px", width: "40px" }} />
              4000
              {/* {potentialTokens ? ` (${potentialTokens}M 07 Gold)` : ""} */}
            </Typography>
            <Stack gap={1}>
              <CountdownTimer expireTime={new Date(wager.expireAt)} />
              <WagerRaceTable rawData={wager} />
            </Stack>
          </Stack>
        ) : (
          <Stack alignItems="center">
            <CircularProgress color="info" />
          </Stack>
        )}
      </Stack>
    </Container>
  );
};

export default WagerRace;
