import { BLACKJACKACTION } from "../../types/index.d";

export type Card = {
  suit: string;
  rank: string;
};

export enum BLACKGAMESTATE {
  INIT = "INIT",
  START = "START",
  END = "END",
}

export const getDeck = (numberOfDeck: number = 1): Card[] => {
  const suits = ["♠", "♣", "❤", "♦"];
  const ranks = [
    "A",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "J",
    "Q",
    "K",
  ];
  const deck: Card[] = [];

  for (let i = 0; i < numberOfDeck; i++) {
    suits.forEach((suit) => ranks.forEach((rank) => deck.push({ suit, rank })));
  }
  return deck;
};

export const getRankNum = (rank: string): number => {
  switch (rank) {
    case "A":
      return 1;
    case "J":
    case "Q":
    case "K":
      return 10;
    default:
      return Number(rank);
  }
};

export const getTotal = (hand: Card[]): number => {
  let total = 0;
  if (!hand) return total;
  for (const card of hand) {
    total += getRankNum(card.rank);
  }
  return total;
};

export const hasAce = (hand: Card[]): boolean => {
  for (const card of hand) {
    if (card.rank === "A") return true;
  }
  return false;
};

export const checkDealersScore = (hand: Card[]): boolean => {
  let total = getTotal(hand);
  if (isSoftHand(hand)) {
    total += 10;
  }
  return total < 17;
};

export const isAce = (card: Card): boolean => {
  return card?.rank === "A";
};

export const isFaceCardOrTen = (card: Card): boolean => {
  return getRankNum(card?.rank) === 10;
};

export const isSoftHand = (hand: Card[]): boolean => {
  if (isBlackJack(hand)) {
    return false;
  }
  if (!hasAce(hand)) {
    return false;
  }
  return getTotal(hand) + 10 < 21;
};

export const isBlackJack = (hand: Card[]): boolean => {
  if (!hand) return false;
  const [firstCard, secondCard] = hand;
  const total = getScoreForDisplayDealer(hand);
  return (
    (isAce(firstCard) && isFaceCardOrTen(secondCard)) ||
    (isFaceCardOrTen(firstCard) && isAce(secondCard)) ||
    total === 21
  );
};

export const getScore = (hand: Card[]): number[] => {
  if (isBlackJack(hand)) {
    return [21];
  }
  if (isSoftHand(hand)) {
    return [getTotal(hand), getTotal(hand) + 10];
  }
  return [getTotal(hand)];
};

export const showInsurance = (
  hand: Card[] | undefined,
  insurance?: {
    bet: number;
    isShown: boolean;
  },
  currentState?: string
): boolean => {
  if ((currentState as BLACKGAMESTATE) !== BLACKGAMESTATE.START) return false;
  if (!hand) return false;
  const [firstCard] = hand;
  if (!isAce(firstCard)) return false;

  if (!insurance) return true;
  if (insurance.isShown) return false;

  return true;
};

export const getScoreForDisplay = (hand: Card[]): string => {
  if (!hand) return "0";
  const score = getScore(hand);
  if (isSoftHand(hand)) {
    return `${score[0]} | ${score[1]}`;
  }
  return score[0].toString();
};

// export const getScoreForDisplayDealer = (hand: Card[]): string => {
//   if (!hand) return "0";
//   let newHand = hand;
//   if (hand[1].rank === "U") {
//     newHand = hand.slice(0, 1);
//   }
//   const score = getScore(newHand);
//   if (isSoftHand(newHand)) {
//     return `${score[0]} | ${score[1]}`;
//   }
//   return score[0].toString();
// };

export const getTotalDealer = (hand: Card[]): number => {
  return hand.reduce((sum, card) => {
    if (isAce(card)) {
      return sum + 1;
    }
    if (["J", "Q", "K"].includes(card.rank)) {
      return sum + 10;
    }
    return sum + parseInt(card.rank, 10);
  }, 0);
};

export const getScoreForDisplayDealerBeforeEnd = (hand: Card[]): string => {
  if (!hand) return "0";
  let newHand = hand;

  if (hand[1] && hand[1].rank === "U") {
    newHand = hand.slice(0, 1);
  }

  const total = getTotal(newHand);
  const hasAceInHand = hasAce(newHand);

  if (hasAceInHand && total <= 11) {
    return `${total} | ${total + 10}`;
  }

  return total.toString();
};

export const getScoreForDisplayDealer = (hand: Card[]): number => {
  if (!hand) return 0;
  let newHand = hand;

  if (hand[1] && hand[1].rank === "U") {
    newHand = hand.slice(0, 1);
  }

  const total = getTotal(newHand);
  const hasAceInHand = hasAce(newHand);

  if (hasAceInHand && total <= 11) {
    const totalWithAceAs11 = total + 10;
    return totalWithAceAs11 <= 21 ? totalWithAceAs11 : total;
  }

  return total;
};

export const getLastScoreForDealer = (hand: Card[]): number => {
  if (!hand) return 0;
  let newHand = hand;
  if (hand[1].rank === "U") {
    newHand = hand.slice(0, 1);
  }
  const score = getScore(newHand);
  if (isSoftHand(newHand)) {
    return score[1];
  }
  return score[0];
};

export const getLastScore = (hand: Card[]): number => {
  const score = getScore(hand);
  if (isSoftHand(hand)) {
    return score[1];
  }
  return score[0];
};

export const judge = (dealersHand: Card[], playersHand: Card[]): string => {
  const dealersScore = getScoreForDisplayDealer(dealersHand);
  const playersScore = getScoreForDisplayDealer(playersHand);

  if (getTotal(playersHand) > 21) {
    return "LOSE";
  }
  if (dealersScore === playersScore) {
    return "PUSH";
  }
  if (isBlackJack(playersHand)) {
    return "BLACK JACK";
  }
  if (isBlackJack(dealersHand)) {
    return "LOSE";
  }
  if (dealersScore > 21) {
    return "WIN";
  }
  if (dealersScore < playersScore) {
    return "WIN";
  }
  if (dealersScore > playersScore) {
    return "LOSE";
  }

  return "WIN";
};

type State = {
  deck: Card[];
  minimumNumber: number;
  dealersHand: Card[];
  playersHands: Card[][];
  isTurnEnd: boolean;
  currentState: BLACKGAMESTATE;
  currentPlayerHandIndex: number;
  hasSplit: boolean;
};

const getMinimumNumber = (deck: Card[], penetration: number): number => {
  return deck.length - Math.floor(deck.length * penetration);
};

const deal = (deck: Card[], hand: Card[], time: number): [Card[], Card[]] => {
  const newDeck = deck.slice();
  const newHand = hand.slice();
  for (let i = 0; i < time; i++) {
    // const index = Math.floor(Math.random() * newDeck.length);
    const index = 0;
    newHand.push(newDeck[index]);
    newDeck.splice(index, 1);
  }
  return [newDeck, newHand];
};

const dealForDealer = (deck: Card[], hand: Card[]): [Card[], Card[]] => {
  const newDeck = deck.slice();
  const newHand = hand.slice();
  while (checkDealersScore(newHand)) {
    // const index = Math.floor(Math.random() * newDeck.length);
    const index = 0;

    newHand.push(newDeck[index]);
    newDeck.splice(index, 1);
  }
  return [newDeck, newHand];
};

const initDealersHand = (state: State): State => {
  const [newDeck, newHand] = deal(state.deck, [], 2);
  return { ...state, deck: newDeck, dealersHand: newHand };
};

const initPlayersHand = (state: State): State => {
  const [newDeck, newHand] = deal(state.deck, [], 2);
  return { ...state, deck: newDeck, playersHands: [newHand] };
};

const splitHand = (state: State): State => {
  const currentHand = state.playersHands[state.currentPlayerHandIndex];
  const newHands = [...state.playersHands];

  if (currentHand.length === 2 && currentHand[0].rank === currentHand[1].rank) {
    const [newDeck1, hand1] = deal(state.deck, [currentHand[0]], 1);
    const [newDeck2, hand2] = deal(newDeck1, [currentHand[1]], 1);

    newHands.splice(state.currentPlayerHandIndex, 1, hand1, hand2);

    return {
      ...state,
      deck: newDeck2,
      playersHands: newHands,
      hasSplit: true,
      currentPlayerHandIndex: 0,
    };
  }
  return state;
};

const doubleDown = (state: State): State => {
  const currentHand = state.playersHands[state.currentPlayerHandIndex];
  const [newDeck, newHand] = deal(state.deck, currentHand, 1);

  const newHands = [...state.playersHands];
  newHands[state.currentPlayerHandIndex] = newHand;

  return { ...state, deck: newDeck, playersHands: newHands, isTurnEnd: true };
};

const initialDeck = getDeck(8);
// const initialDeck = fakeDeck;
const penetration = 0.8;

export const blackJackInitialState: State = {
  deck: initialDeck,
  minimumNumber: getMinimumNumber(initialDeck, penetration),
  dealersHand: [],
  playersHands: [[]],
  isTurnEnd: false,
  currentState: BLACKGAMESTATE.INIT,
  currentPlayerHandIndex: 0,
  hasSplit: false,
};

export const blackJackReducer = (
  state: State,
  action: BLACKJACKACTION
): State => {
  switch (action) {
    case BLACKJACKACTION.INIT:
      return { ...state, currentState: BLACKGAMESTATE.INIT };
    case BLACKJACKACTION.SPLIT:
      return splitHand(state);
    case BLACKJACKACTION.DOUBLE:
      return doubleDown(state);
    case BLACKJACKACTION.START:
      const currentPlayerHandIndex = 0;
      state = initDealersHand(state);
      state = initPlayersHand(state);
      return {
        ...state,
        currentState: BLACKGAMESTATE.START,
        isTurnEnd: false,
        currentPlayerHandIndex,
      };
    case BLACKJACKACTION.HIT: {
      const currentHand = state.playersHands[state.currentPlayerHandIndex];
      const [newDeck, newHand] = deal(state.deck, currentHand, 1);
      const newHands = [...state.playersHands];
      newHands[state.currentPlayerHandIndex] = newHand;
      return { ...state, deck: newDeck, playersHands: newHands };
    }
    case BLACKJACKACTION.STAND: {
      if (state.currentPlayerHandIndex < state.playersHands.length - 1) {
        return {
          ...state,
          currentPlayerHandIndex: state.currentPlayerHandIndex + 1,
        };
      } else {
        const [newDeck, newHand] = dealForDealer(state.deck, state.dealersHand);
        return {
          ...state,
          deck: newDeck,
          dealersHand: newHand,
          isTurnEnd: true,
        };
      }
    }
    case BLACKJACKACTION.CHECK:
      const currentHand = state.playersHands[state.currentPlayerHandIndex];
      if (isBlackJack(state.dealersHand) || isBlackJack(currentHand)) {
        return { ...state, isTurnEnd: true };
      }
      if (getTotal(currentHand) >= 21) {
        if (state.currentPlayerHandIndex < state.playersHands.length - 1) {
          return {
            ...state,
            currentPlayerHandIndex: state.currentPlayerHandIndex + 1,
          };
        } else {
          const [newDeck, newHand] = dealForDealer(
            state.deck,
            state.dealersHand
          );
          return {
            ...state,
            deck: newDeck,
            dealersHand: newHand,
            isTurnEnd: true,
          };
        }
      }
      return { ...state };
    default:
      return state;
  }
};
