export const PrivacyPolicyData = [
  {
    title: "Data Usage",
    text: [
      "Data usage is automatically collected during your use of the service. This may encompass details such as your device's Internet Protocol (IP) address, browser type, browser version, the specific pages of our service you visit, the date and time of your visit, the duration of time spent on those pages, unique device identifiers, and other diagnostic data.",
      "When you access the service via a mobile device, we may automatically gather certain information, including (but not limited to) the type of mobile device you utilize, your mobile device's unique ID, your mobile device's IP address, your mobile operating system, the type of mobile internet browser you use, unique device identifiers, and other diagnostic data.",
      "Additionally, we may collect information that your browser transmits whenever you visit our service or access it via a mobile device.",
    ],
  },
  {
    title: "Retention of Your Personal Data",
    text: [
      `We will retain your Personal Data only for as long as it is necessary for the purposes outlined in this Privacy Policy. This includes using your Personal Data to fulfill our legal obligations, such as retaining your data to comply with relevant laws, resolving disputes, and enforcing our legal agreements and policies.`,
      `Additionally, we may retain Usage Data for internal analysis purposes. Usage Data is typically retained for a shorter duration, unless its retention is necessary to enhance the security or functionality of Our Service, or if legal requirements mandate its retention for longer periods.`,
    ],
  },
  {
    title: "Security of Your Personal Data",
    text: [
      "While we prioritize the security of your Personal Data, it's essential to recognize that no method of transmission over the Internet or electronic storage is entirely secure. Despite our efforts to employ commercially acceptable methods to safeguard your Personal Data, we cannot ensure its absolute security.",
    ],
  },
];
