import { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { Button } from "../button";
import RWTokenIcon from "../../assets/rwgoldcoin.svg";

const columns = [
  {
    id: "level",
    label: "LEVEL",
    // minWidth: 60,
    align: "center",
  },
  {
    id: "requirement",
    label: "REQUIREMENT",
    // minWidth: 60,
    align: "center",
  },
  {
    id: "reward",
    label: "REWARD",
    // minWidth: 60,
    align: "center",
  },
  // {
  //   id: "status",
  //   label: "Status",
  //   minWidth: 60,
  //   align: "right",
  // },
  // { id: "view", label: "", minWidth: 60 },
];

export default function SimpleTable({ data }: any) {
  return (
    <TableContainer sx={{ maxHeight: 440 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow sx={{ backgroundColor: "#292929" }}>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={"center"}
                sx={{
                  borderColor: "#292929",
                  fontWeight: "bold",
                  backgroundColor: "#292929",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((text: any, index: any) => {
            return (
              <TableRow
                role="checkbox"
                tabIndex={-1}
                key={text.toString()}
                sx={{
                  backgroundColor: index % 2 ? "#19191A" : "#202020",
                }}
              >
                {text.map((column: any) => {
                  const text = column.replace(
                    "[TOKEN]",
                    `<img src=${RWTokenIcon}  alt="Token Image" style="width: 1.5em; vertical-align: middle;"/>`
                  );
                  return (
                    <TableCell
                      key={column}
                      align={"center"}
                      sx={{ borderColor: "#292929" }}
                      dangerouslySetInnerHTML={{ __html: text }}
                    >
                      {/* {column} */}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export const getColor = (isTrue: boolean) => {
  if (isTrue) {
    return "#33EC3D !important";
  }
  return "white !important";
};
