import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { TOKENTYPE } from "../../types/index.d";

export interface KenoDocument {
  user: string;
  bet: number;
  selection: number[];
  results: number[];
  payout: number;
  multiplier: number;
  risk: string;
  isMax: boolean;
  createdAt: string;
  matches: number;
  _id: string;
  tokenType: TOKENTYPE;
}

interface KenoState {
  keno: KenoDocument | null;
  pastResults: KenoDocument[];
  errorMessage: string;
}

const initialState: KenoState = {
  keno: null,
  pastResults: [],
  errorMessage: "",
};

// Slice definition
export const kenoReducer = createSlice({
  name: "keno",
  initialState,
  reducers: {
    updateKenoGame(state, action: PayloadAction<KenoDocument>) {
      state.errorMessage = "";
      state.keno = action.payload;
      // state.pastResult.push(action.payload);
    },
    resetKeno(state) {
      state.errorMessage = "";
      state.keno = null;
      // state.pastResult.push(action.payload);
    },
    updateKenoErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    updateKenoPastGame(state, action: PayloadAction<KenoDocument>) {
      state.errorMessage = "";
      state.pastResults.push(action.payload);
    },
  },
});

// Expose the actions
export const {
  updateKenoGame,
  updateKenoErrorMessage,
  updateKenoPastGame,
  resetKeno,
} = kenoReducer.actions;

// Selectors
export const selectKenoGames = (state: RootState) => state.keno;

// Reducer
export default kenoReducer.reducer;
