import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import { MODALTYPE } from "../../../types/index.d";
import { theme } from "../../theme";
import { Button } from "../../button";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { InputField } from "../../inputField";
import { useRef, useEffect, useState } from "react";
import Users from "../../../services/user";
import { useAppSelector } from "../../store/hooks";
import { selectToken } from "../../slices/userSlice";
import HCaptcha from "../../HCaptcha";
import { useTurnstile } from "react-turnstile";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 200, md: 300 },
  zIndex: 10,
  py: 4,
  px: 10,
  borderRadius: 4,
};

const initialValues = {
  password: "",
  reEnterPassword: "",
  currentPassword: "",
};

const validationSchema = Yup.object({
  currentPassword: Yup.string()
    .min(8, "Password should be at least 8 characters")
    .required("Repeat password is a required field"),
  password: Yup.string()
    .min(8, "Password should be at least 8 characters")
    .required("Password is a required field")
    .max(24, "Password should be less than 24 characters")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
      "Password must be at least 8 characters long and contain at least one uppercase letter and at least one number."
    )
    .oneOf([Yup.ref("reEnterPassword")], "Passwords must match"),
  reEnterPassword: Yup.string()
    .min(8, "Password should be at least 8 characters")
    .required("Repeat password is a required field")
    .oneOf([Yup.ref("password")], "Passwords must match"),
  // referral: Yup.string(),
});

interface IModal {
  handleClose: () => void;
  open: boolean;
}

const ChangePasswordModal = ({ handleClose, open }: IModal) => {
  const [token, setToken] = useState<any>(null);
  const [loading, setLoading] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const accessToken = useAppSelector(selectToken);
  const turnstile = useTurnstile();
  const submitHandler = async (values: {
    password: string;
    currentPassword: string;
  }) => {
    if (!token) {
      turnstile.reset();
      setErrorMessage("Please complete the HCaptcha");
      return;
    }
    try {
      if (loading) return;
      setLoading(true);
      const response = await Users.changePassword(
        values.password,
        values.currentPassword,
        accessToken,
        token
      );
      if (response) {
        setMessage(response.messsage);
      }
    } catch (e: any) {
      if (e?.message) {
        setErrorMessage(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (errorMessage && token) {
      setToken(null);
      turnstile.reset();
    }
  }, [errorMessage]);

  return (
    <ModalComponent
      open={open}
      onClose={() => handleClose()}
      disableScrollLock={true}
    >
      <Box sx={modalStyle}>
        <Stack gap={3}>
          <Typography variant="h4" color="text.primary">
            Change Password
          </Typography>
          {!message ? (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={submitHandler}
            >
              {({ isSubmitting, submitForm, handleSubmit, errors }) => (
                <Form>
                  <Stack gap={1.5}>
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <Typography fontWeight={500} color="text.primary">
                        Current Password
                      </Typography>
                      <InputField
                        fullWidth
                        type="password"
                        name="currentPassword"
                        label="Password"
                        placeholder="Current Password"
                      />
                    </Stack>
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <Typography fontWeight={500} color="text.primary">
                        New Password
                      </Typography>
                      <InputField
                        fullWidth
                        type="password"
                        name="password"
                        label="Password"
                        placeholder="Password"
                      />
                    </Stack>
                    <Stack direction="column" sx={{ width: "100%" }}>
                      <Typography fontWeight={500} color="text.primary">
                        Confirm New Password
                      </Typography>
                      <InputField
                        name="reEnterPassword"
                        label="Confirm Password"
                        type="password"
                        placeholder="Confirm Password"
                      />
                    </Stack>
                    <HCaptcha onVerify={setToken} />
                    {errorMessage && (
                      <Typography fontWeight={500} color="error">
                        {errorMessage}
                      </Typography>
                    )}
                    <Button
                      disabled={loading}
                      variantType="success"
                      fullWidth
                      type="submit"
                    >
                      Change Password
                    </Button>
                    <Button
                      disabled={loading}
                      variantType="error"
                      fullWidth
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          ) : (
            <Stack gap={3}>
              <Typography color="text.primary">{message}</Typography>
              <Button
                disabled={loading}
                variantType="success"
                fullWidth
                onClick={handleClose}
              >
                Close
              </Button>
            </Stack>
          )}
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default ChangePasswordModal;
