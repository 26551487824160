import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { USERTYPE } from "../../types/index.d";
import RWTokenIcon from "../../assets/runeScapeCoin.png";

export const UserTypeIcon = ({
  userType,
}: {
  userType: string | undefined;
}) => {
  if (userType === USERTYPE.ADMIN) {
    return <OwnerIcon />;
  }

  if (userType === USERTYPE.STREAMER) {
    return <StreamerIcon />;
  }

  if (userType === USERTYPE.MOD) {
    return <ModIcon />;
  }

  if (userType === USERTYPE.CASHIER) {
    return <RuneScapeChatIcon />;
  }
  return null;
};

export const OwnerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1.25em"
    height="1.25em"
    viewBox="0 0 24 24"
  >
    <path
      fill="yellow"
      d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11zm14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14z"
    ></path>
  </svg>
);

export const StreamerIcon = () => {
  return (
    <CheckCircleOutlineIcon
      sx={{
        color: "cyan",
        fontSize: "1.25em",
      }}
    />
  );
};

export const ModIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25em"
      height="1.25em"
      viewBox="0 0 24 24"
    >
      <path
        fill="silver"
        d="M5 16L3 5l5.5 5L12 4l3.5 6L21 5l-2 11zm14 3c0 .6-.4 1-1 1H6c-.6 0-1-.4-1-1v-1h14z"
      ></path>
    </svg>
  );
};

export const RuneScapeChatIcon = () => {
  return <img src={RWTokenIcon} style={{ width: "1.25em" }} />;
};
