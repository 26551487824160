import { Container, Stack, Card, Avatar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AccountTabs } from "../../components/accountTabs";
import { getAllOrder } from "../../components/slices/orderSlice";
import { selectPlayer, selectToken } from "../../components/slices/userSlice";
import { AppDispatch } from "../../components/store";
import { useEffect } from "react";
import { ProgressBar } from "../../components/progressBar";
import { getPlayerLevelData, RoundNumber } from "../../utils";

const Account = () => {
  const { player } = useSelector(selectPlayer);
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useSelector(selectToken);
  const getPlayerLevel = getPlayerLevelData(player.level);

  const getNextLevel = getPlayerLevelData((player.level || 0) + 1);

  useEffect(() => {
    if (accessToken) {
      dispatch(getAllOrder());
    }
  }, [accessToken]);

  return (
    <Container maxWidth="md" sx={{ p: { md: "auto", xs: 0 } }}>
      <Stack gap={2} sx={{ minHeight: "65vh" }}>
        <Card sx={{ px: { md: 2, xs: 1 }, py: 4 }}>
          <Stack direction="row" gap={{ md: 4, xs: 1 }} alignItems="center">
            <Avatar
              src={getPlayerLevel.imgSrc}
              variant="square"
              sx={{
                width: { md: "100px", sm: "90px", xs: "80px" },
                height: { md: "100px", sm: "90px", xs: "80px" },
                "& .MuiAvatar-img": {
                  objectFit: "contain",
                },
              }}
            ></Avatar>
            <Stack sx={{ width: "100%", maxWidth: "400px" }}>
              <Typography variant="h3">{player.userName}</Typography>
              <ProgressBar
                rank={getPlayerLevel.name}
                percents={
                  player.level === 13
                    ? 0
                    : 100 - (player.totalWagered / getNextLevel.rankUp) * 100
                }
                caption={
                  player.level === 13
                    ? "Max Rank"
                    : `${RoundNumber(player.totalWagered || 0)} / ${
                        getNextLevel.rankUp
                      } until ${getNextLevel.name}`
                }
              />
            </Stack>
          </Stack>
        </Card>
        <Card sx={{ px: { md: 2, xs: 1 }, py: 4 }}>
          <AccountTabs />
        </Card>
      </Stack>
    </Container>
  );
};

export default Account;
