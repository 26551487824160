import { MODALTYPE } from "../../types/index.d";

export const GamesList = [
  {
    label: "Slots",
    url: "slots",
  },
  {
    label: "Duel Arena",
    url: "duel-arena",
  },
  {
    label: "Plinko",
    url: "plinko",
  },
  {
    label: "Keno",
    url: "keno",
  },
  {
    label: "Slide",
    url: "slide",
  },
  {
    label: "Dice",
    url: "dice",
  },
  {
    label: "Mines",
    url: "mines",
  },
  {
    label: "Limbo",
    url: "limbo",
  },
  {
    label: "Coin Flip",
    url: "coinflip",
  },

  {
    label: "Blackjack",
    url: "blackjack",
  },

  {
    label: "Hilo",
    url: "hilo",
  },
];

export const SupportList = [
  {
    label: "Affiliate",
    url: "affiliate",
  },
  {
    label: "Fairness",
    url: "fairness",
  },
  {
    label: "Self Excluding",
    url: "",
    modal: MODALTYPE.SELFEXCLUDING,
  },
  {
    label: "Live Support",
    url: "",
    modal: MODALTYPE.LIVESUPPORT,
  },
];

export const CommunityList = [
  {
    label: "Discord",
    url: "https://discord.gg/runewagers",
  },
  {
    label: "Sythe",
    url: "https://www.sythe.org/members/runewager.1563632/",
  },
  {
    label: "Twitter",
    url: " https://twitter.com/runewagercom",
  },
  {
    label: "Instagram",
    url: "https://www.instagram.com/runewager/",
  },
];

export const AboutUsList = [
  {
    label: "Terms of Service",
    url: "/terms-&-service",
  },
  {
    label: "AML Policy",
    url: "/aml-policy",
  },
  {
    label: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    label: "FAQ Page",
    url: "/faq",
  },
];
