import { Container } from "@mui/material";
import { Slide } from "../../../components/slide";
import { useContext, useEffect } from "react";
import ChatContext from "../../../contexts/chat/context";
import { AppDispatch } from "../../../components/store";
import { useDispatch } from "react-redux";
import { SlideIncomingSocket, SLIDESTATE } from "../../../constants/slide";
import {
  resetSlide,
  SlideBet,
  updateJackpot,
  updateJackpots,
  updateJackpotWinners,
  updateSlideBets,
  updateSlideErrorMessage,
  updateSlideResult,
  updateSlideState,
  updateSlideTimer,
} from "../../../components/slices/slideSlice";
import {
  EMIT_SLIDE_BET,
  RECEIVE_SLIDE_DATA,
  REQUEST_SLIDE_DATA,
  REQUEST_SLIDE_RESULT,
  REQUEST_SLIDE_TIMER,
} from "../../../constants/socket";
import { GAMES } from "../../../constants/games";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import GameFeed from "../../../components/gameFeed";

const SlidePage = () => {
  const { socket } = useContext(ChatContext);
  const dispatch = useDispatch<AppDispatch>();

  const handleSlideTimer = (data: SlideIncomingSocket) => {
    dispatch(updateSlideTimer(data));
  };

  const updateLocalState = (timer: number) => {
    setTimeout(() => {
      dispatch(updateSlideState(SLIDESTATE.RESULT));
    }, timer);
  };

  const handleSlideResult = (data: SlideIncomingSocket) => {
    if (data) {
      if (data.gameStates[SLIDESTATE.ANIMATING]) {
        let currentState = data.state;
        const currentTime = Date.now();
        let animaionState = data.gameStates[SLIDESTATE.ANIMATING] as {
          timeStamp: number;
          now?: number;
        };
        let difference =
          animaionState.timeStamp - (animaionState.now || currentTime);

        if (data.currentTimeDb) {
          difference = animaionState.timeStamp - Date.parse(data.currentTimeDb);
        }

        if (difference < 0) {
          currentState = SLIDESTATE.RESULT;
        }
        if (difference > 0) {
          updateLocalState(difference);
        }
        const finalData = {
          result: data.result,
          state: currentState,
          timer: 0,
          gameStates: {
            [SLIDESTATE.ANIMATING]: {
              timeStamp: difference / 1000 > 6 ? 6 : difference / 1000,
            },
          },
          pastResults: data.pastResults,
          bets: data.bets || [],
          offset: data.offset || 0,
          jackpot: data.jackpot,
        };
        dispatch(updateSlideResult(finalData));
      } else {
        dispatch(updateSlideResult(data));
      }
      if (data.jackpot) {
        dispatch(updateJackpot(data.jackpot));
      }
      if (data.jackpotWinners) {
        dispatch(updateJackpotWinners(data.jackpotWinners));
      }
      if (data.jackpots) {
        dispatch(updateJackpots(data.jackpots));
      }
    }
  };

  const handleSlideBet = (data: {
    bet: SlideBet[];
    jackpot: any;
    error: any;
  }) => {
    if (data.bet) {
      dispatch(updateSlideBets(data.bet));
    }
    if (data.jackpot) {
      dispatch(updateJackpot(data.jackpot));
    }
    if (data.error) {
      dispatch(updateSlideErrorMessage(data.error));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetSlide());
    };
  }, []);

  useEffect(() => {
    if (socket) {
      // SLIDE
      socket.emit(REQUEST_SLIDE_DATA);
      socket.on(REQUEST_SLIDE_TIMER, handleSlideTimer);
      socket.on(REQUEST_SLIDE_RESULT, handleSlideResult);
      socket.on(RECEIVE_SLIDE_DATA, handleSlideResult);
      socket.on(EMIT_SLIDE_BET, handleSlideBet);
    }
    return () => {
      if (socket) {
        socket.off(REQUEST_SLIDE_TIMER, handleSlideTimer);
        socket.off(REQUEST_SLIDE_RESULT, handleSlideResult);
        socket.off(RECEIVE_SLIDE_DATA, handleSlideResult);
        socket.off(EMIT_SLIDE_BET, handleSlideBet);
      }
    };
  }, [socket]);
  return (
    <Container maxWidth="md" sx={{ width: "100%" }}>
      <Slide />
      <DescWrapper game={GAMES.SLIDE} showLuckyWins={false} />
      <GameFeed />
    </Container>
  );
};

export default SlidePage;
