import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { useField } from "formik";
import { InputAdornment, Typography } from "@mui/material";
import { TokenIcon } from "../tokenIcon";
import { theme } from "../theme";
import { selectTokenSelection } from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import { TOKENTYPE } from "../../types/index.d";

export const InputFieldStyled = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-root.Mui-error": {
    border: "1.2px solid #f65d5e !important",
  },

  "&.Mui-error .MuiInputBase-input": {
    border: `2px solid ${theme.palette.error.main}`,
  },
  "&. MuiSelect-nativeInput": {
    backgroundColor: "#292929",
    "&:disabled": {
      backgroundColor: "#454545", // Light grey background
      color: "#292929", // Darker grey text
      cursor: "not-allowed", // 'Not allowed' cursor
      borderColor: "#d0d0d0", // Grey border
    },
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#202020",
    border: "1.2px solid",
    borderColor: "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
    "&:disabled": {
      backgroundColor: "#454545", // Light grey background
      color: "#292929", // Darker grey text
      WebkitTextFillColor: "black",
      cursor: "not-allowed", // 'Not allowed' cursor
    },
  },
}));

interface TextFieldWrapperProps {
  name: string;
  isFreeCash?: boolean;
  [x: string]: any; // For additional props that are not predefined
}

export const InputField: React.FC<TextFieldWrapperProps> = ({
  name,
  ref,
  ...otherProps
}) => {
  const [field, meta] = useField(name);

  const configTextField: any = {
    fullWidth: true,
    variant: "outlined",
    ...field,
    ...otherProps,
  };

  let helperText;

  if (meta && meta.touched && meta.error) {
    configTextField.error = true;
    helperText = meta.error;
  }

  return (
    <>
      <InputFieldStyled {...configTextField} ref={ref} />
      {helperText && <Typography color="error"> {helperText} </Typography>}
    </>
  );
};

export const InputFieldWithStartAdornment: React.FC<TextFieldWrapperProps> = ({
  name,
  isFreeCash = false,
  ...otherProps
}) => {
  return (
    <InputFieldStyled
      name={name}
      {...otherProps}
      startAdornment={
        <InputAdornment
          position="start"
          sx={{ position: "absolute", zIndex: 2, marginLeft: "0.5em" }}
        >
          <TokenIcon freeCash={isFreeCash} />
        </InputAdornment>
      }
    />
  );
};

export const ProfitOnWinInput: React.FC<TextFieldWrapperProps> = ({
  name,
  ...otherProps
}) => {
  const tokenSelection = useAppSelector(selectTokenSelection);
  return (
    <InputFieldStyled
      name={name}
      {...otherProps}
      disabled
      sx={{
        "& .MuiInputBase-input": {
          paddingLeft: "calc(1em + 20px)",
          WebkitTextFillColor: "white !important",
        },
      }}
      startAdornment={
        <InputAdornment
          position="start"
          sx={{ position: "absolute", zIndex: 2, marginLeft: "0.5em" }}
        >
          <TokenIcon freeCash={tokenSelection === TOKENTYPE.FREE} />
        </InputAdornment>
      }
    />
  );
};
