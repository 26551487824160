import { Container } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import GameFeed from "../../../components/gameFeed";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import Mines from "../../../components/mines";
import { getCurrentGame } from "../../../components/slices/mineSlice";
import { selectToken } from "../../../components/slices/userSlice";
import { AppDispatch } from "../../../components/store";
import { useAppSelector } from "../../../components/store/hooks";
import { GAMES } from "../../../constants/games";

const MinesPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useAppSelector(selectToken);

  useEffect(() => {
    if (accessToken) {
      dispatch(getCurrentGame());
    }
  }, [dispatch, accessToken]);

  return (
    <Container maxWidth="md">
      <Mines />
      <DescWrapper game={GAMES.MINES} />
      <GameFeed />
    </Container>
  );
};

export default MinesPage;
