import React, { useState, useEffect, useContext, useRef } from "react";
import { Stack, Typography, InputAdornment } from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import { InputFieldStyled, ProfitOnWinInput } from "../inputField";
import styles from "./index.module.scss";
import {
  isBetGreaterthanSetValue,
  RoundNumber,
  RoundProfitOnWin,
} from "../../utils";
import {
  openModal,
  selectCurrentUser,
  selectDiceToggle,
  selectLevelUpModal,
  selectToken,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import SliderWithLabel from "./slider";

import CloseIcon from "@mui/icons-material/Close";
import PercentIcon from "@mui/icons-material/Percent";
import ChatContext from "../../contexts/chat/context";
import CachedIcon from "@mui/icons-material/Cached";

import { PLAY_DICE_GAME } from "../../constants/socket";
import {
  DICETYPE,
  DICEMODE,
  MODALTYPE,
  TOKENTYPE,
  EXCHANGETYPE,
} from "../../types/index.d";
import { selectDiceGames } from "../slices/diceSlice";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import DiceAutoButtons, {
  BetButton,
  GameTypeButtons,
  IAutoPlayStates,
} from "./diceButtons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { DEFAULT_DISABLED_MESSAGE, SELECTIONSTATUS } from "../../constants";
import { GameOptions } from "../gameOptions";
import { GAMES } from "../../constants/games";
import { BetField } from "../betField";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import useSound from "use-sound";
import BetSound from "../../assets/audio/common/create-bet.mp3";

interface DiceProps {}

const totalOutcomes = 10000;
const houseEdge = 0.04;

const Dice: React.FC<DiceProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [rollOverNumber, setRollOverNumber] = useState(50);
  const [multiplier, setMultiplier] = useState(1.92);
  const [winProbabilityInput, setWinProbabilityInput] = useState(50);
  const [updateSource, setUpdateSource] = useState("multiplier");
  const isLevelUp = useAppSelector(selectLevelUpModal);
  const [gameType, setGameType] = useState(DICETYPE.ROLLOVER);
  const accessToken = useAppSelector(selectToken);
  const player = useAppSelector(selectCurrentUser);
  const { errorMessage, dice, pastResult } = useAppSelector(selectDiceGames);
  const { socket } = useContext(ChatContext);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [playType, setPlayType] = useState<DICEMODE>(DICEMODE.MANUAL);
  const [title, setTitle] = useState("");

  const [confirmation, setConfirmation] = useState(false);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const toggle = useAppSelector(selectDiceToggle);
  const betRef = useRef<number>(0);
  const [autoPlayStates, setAutoPlayStates] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });

  const [autoPlayStatesRef, setAutoPlayStatesRef] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });
  const tokenType = useAppSelector(selectTokenSelection);
  const tokenTypeRef = useRef(tokenType);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);

  const [inputRef, setInputRef] = useState({
    rollOverNumber: "50.0",
    multiplier: "1.92",
    winProbabilityInput: "50.0",
  });

  const [currentBet, setCurrentBet] = useState(0);

  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });

  const updateFromMultiplier = () => {
    let winProb = 0;
    let rollNumber = 0;
    if (gameType === DICETYPE.ROLLOVER) {
      const adjustedWinProbability = ((1 - houseEdge) / multiplier) * 100;
      winProb = parseFloat(adjustedWinProbability.toFixed(2));
      rollNumber = parseFloat(
        ((100 - adjustedWinProbability) * 100).toFixed(2)
      );
    } else {
      const adjustedWinProbability = ((1 - houseEdge) / multiplier) * 100;
      winProb = parseFloat(adjustedWinProbability.toFixed(2));
      rollNumber = parseFloat((adjustedWinProbability * 100).toFixed(0));
    }
    setInputRef({
      ...inputRef,
      winProbabilityInput: winProb.toString(),
      rollOverNumber: rollNumber.toString(),
    });
    setWinProbabilityInput(winProb);
    setRollOverNumber(RoundNumber(rollNumber / 100) * 100);
    setUpdateSource("winProbability");
    if (winProb < 0.1) {
      setUpdateSource("winProbability");
    }
  };

  // Update states based on the win probability
  const updateFromWinProbability = () => {
    let multiplyerValue = 0;
    let rollNumber = 0;
    if (gameType === DICETYPE.ROLLOVER) {
      const rollNum = (100 - winProbabilityInput) * 100;

      const winningOutcomes = totalOutcomes - rollNum;
      const newMultiplier = ((1 - houseEdge) / winningOutcomes) * 10000;
      multiplyerValue = parseFloat(newMultiplier.toFixed(4));
      rollNumber = parseFloat(rollNum.toFixed(2));
    } else {
      const rollNum = (100 - winProbabilityInput) * 100;
      const winningOutcomes = totalOutcomes - rollNum;
      const newMultiplier = ((1 - houseEdge) / winningOutcomes) * 10000;

      multiplyerValue = parseFloat(newMultiplier.toFixed(4));
      rollNumber = parseFloat((winProbabilityInput * 100).toFixed(2));
    }
    setInputRef({
      ...inputRef,
      rollOverNumber: rollNumber.toString(),
      multiplier: multiplyerValue.toString(),
    });
    setMultiplier(multiplyerValue);
    setRollOverNumber(rollNumber);
  };

  const updateFromRollOverNumber = () => {
    let multiplyerValue = 0;
    let winProbability = 0;
    if (gameType === DICETYPE.ROLLOVER) {
      const winningOutcomes = totalOutcomes - rollOverNumber;
      let adjustedWinProbability = winningOutcomes / totalOutcomes;

      const newMultiplier = ((1 - houseEdge) / winningOutcomes) * 10000;

      winProbability = parseFloat((adjustedWinProbability * 100).toFixed(2));
      multiplyerValue = parseFloat(newMultiplier.toFixed(4));
    } else {
      const winningOutcomes = totalOutcomes - (totalOutcomes - rollOverNumber);

      const newMultiplier = ((1 - houseEdge) / winningOutcomes) * 10000;

      winProbability = parseFloat((rollOverNumber / 100).toFixed(2));
      multiplyerValue = parseFloat(newMultiplier.toFixed(4));
    }
    setInputRef({
      ...inputRef,
      winProbabilityInput: winProbability.toString(),
      multiplier: multiplyerValue.toString(),
    });
    setWinProbabilityInput(winProbability);
    setMultiplier(multiplyerValue);
  };

  // Handle changes to the multiplier
  useEffect(() => {
    if (updateSource === "multiplier") {
      updateFromMultiplier();
    }
  }, [multiplier, updateSource]);

  // Handle changes to the win probability
  useEffect(() => {
    if (updateSource === "winProbability") {
      updateFromWinProbability();
    }
  }, [winProbabilityInput, updateSource]);

  // Handle changes to the roll over number
  useEffect(() => {
    if (updateSource === "rollOverNumber") {
      updateFromRollOverNumber();
    }
  }, [rollOverNumber, updateSource]);

  // Handle changes to the multiplier input
  const handleMultiplierRefChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setInputRef({
      ...inputRef,
      multiplier: value,
    });
  };

  const handleMultiplierChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdateSource("multiplier");
    const value = parseFloat(Number(inputRef.multiplier).toFixed(4));
    if (gameType === DICETYPE.ROLLOVER && value < 1.0105) {
      setWinProbabilityInput(1.0105);
      setInputRef({
        ...inputRef,
        multiplier: "1.0105",
      });
      return;
    }

    if (gameType === DICETYPE.ROLLOVER && value > 9600) {
      setWinProbabilityInput(9600);
      setInputRef({
        ...inputRef,
        multiplier: "9600",
      });
      return;
    }

    setInputRef({
      ...inputRef,
      multiplier: value.toString(),
    });
    setMultiplier(value);
  };

  // Handle changes to the win probability input

  const handleWinProbabilityRefChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setInputRef({
      ...inputRef,
      winProbabilityInput: value,
    });
  };

  const handleWinProbabilityChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setUpdateSource("winProbability");
    let value = parseFloat(Number(inputRef.winProbabilityInput).toFixed(2));

    if (value < 0.01) {
      value = 0.01;
    }
    if (value > 95) {
      value = 95;
    }
    setInputRef({
      ...inputRef,
      winProbabilityInput: value.toString(),
    });
    setWinProbabilityInput(value);
  };

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  // Handle changes to the roll over number input
  const handleRollOverNumberChange = (newRollOverNumber: number) => {
    setUpdateSource("rollOverNumber");
    if (newRollOverNumber >= 1.0 && newRollOverNumber <= totalOutcomes) {
      setRollOverNumber(parseFloat(newRollOverNumber.toFixed(2)));
    }
  };
  const playGame = (bet = currentBet, askConfirmation = true) => {
    if (title) {
      setTitle(title);
      return;
    }
    if (!toggle) {
      setIsAutoPlaying(false);
      return;
    }
    if ((bet > player.balance && tokenType === TOKENTYPE.SWEEP) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }

    if ((bet > player.freeCash && tokenType === TOKENTYPE.FREE) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }

    if (
      tokenType === TOKENTYPE.SWEEP &&
      !isAutoPlaying &&
      askConfirmation &&
      ((player.isBetConfirmation &&
        isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          player?.betConfirmationValue
        )) ||
        isBetGreaterthanSetValue(currentBet, player.balance, 95))
    ) {
      setConfirmation(true);
      return;
    }

    if (!askConfirmation && dontShowConfirmation) {
      let payload: Partial<User> = {
        isBetConfirmation: false,
        betConfirmationValue: 0,
      };
      dispatch(updateSettings(payload));
    }

    const gameData = {
      rollOverNumber,
      currentBet: bet,
      gameType,
      multiplier,
      tokenType,
    };
    tokenTypeRef.current = tokenType;
    if (playType === DICEMODE.MANUAL) {
      playBetSound();
      socket?.emit(PLAY_DICE_GAME, {
        accessToken,
        gameData,
      });
      setDisableButton(true);
    }

    if (playType === DICEMODE.AUTO) {
      if (!isAutoPlaying) {
        setAutoPlayStatesRef({
          ...autoPlayStates,
          stopNetGain: 0,
          stopNetLoss: 0,
        });
        betRef.current = currentBet;
      }
      setIsAutoPlaying(true);
      playBetSound();
      socket?.emit(PLAY_DICE_GAME, {
        accessToken,
        gameData,
      });
      setDisableButton(true);
    }

    setTimeout(() => {
      setDisableButton(false);
    }, 750);
  };

  useEffect(() => {
    if (pastResult.length && isAutoPlaying) {
      const getLastGame = pastResult[pastResult.length - 1];
      let shouldContinue = isLevelUp ? false : true;
      let newBet = currentBet;
      let letLoss = autoPlayStatesRef.stopNetLoss;
      let netGain = autoPlayStatesRef.stopNetLoss;

      //Stop when token changes
      if (tokenTypeRef.current !== tokenType) {
        shouldContinue = false;
      }
      // Check Number of Games
      if (autoPlayStates.numberOfGames) {
        if (autoPlayStatesRef.numberOfGames > 1) {
          setAutoPlayStatesRef({
            ...autoPlayStatesRef,
            numberOfGames: autoPlayStatesRef.numberOfGames - 1,
          });
        } else {
          shouldContinue = false;
        }
      }

      if (autoPlayStates.onWin && getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onWin / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onWin / 100)).toFixed(2)
        );
      }

      if (autoPlayStates.onLoss && !getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onLoss / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onLoss / 100)).toFixed(2)
        );
      }

      if (
        autoPlayStates.winStatus === SELECTIONSTATUS.RESET &&
        getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (
        autoPlayStates.lossStatus === SELECTIONSTATUS.RESET &&
        !getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      // Net Loss
      if (!getLastGame.isWin && autoPlayStates.stopNetLoss) {
        letLoss = letLoss + getLastGame.bet;
        if (letLoss >= autoPlayStates.stopNetLoss) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetLoss: letLoss,
          stopNetGain: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      // Net Gain
      if (getLastGame.isWin && autoPlayStates.stopNetGain) {
        netGain = netGain + (getLastGame.payout - getLastGame.bet);
        if (netGain >= autoPlayStates.stopNetGain) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetGain: netGain,
          stopNetLoss: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      if (
        shouldContinue &&
        ((currentBet <= player.balance &&
          getLastGame.tokenType === TOKENTYPE.SWEEP) ||
          (currentBet <= player.freeCash &&
            getLastGame.tokenType === TOKENTYPE.FREE))
      ) {
        playGame(newBet);
      } else {
        setIsAutoPlaying(false);
      }
    }
  }, [pastResult]);

  const handleGameTypeChange = () => {
    setGameType(
      gameType === DICETYPE.ROLLOVER ? DICETYPE.ROLLUNDER : DICETYPE.ROLLOVER
    );
    const newNum =
      gameType !== DICETYPE.ROLLOVER
        ? 100 - winProbabilityInput
        : winProbabilityInput;
    handleRollOverNumberChange(newNum * 100);
  };

  useEffect(() => {
    if (errorMessage && isAutoPlaying) {
      setIsAutoPlaying(false);
    }
  }, [errorMessage, isAutoPlaying]);

  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        title="Bet Confirmation"
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          playGame(currentBet, false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          95
        )}
      />
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Dice
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
          sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <GameTypeButtons
                  playType={playType}
                  setPlayType={setPlayType}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
                <BetButton
                  accessToken={accessToken}
                  disableButton={disableButton || !toggle}
                  playGame={playGame}
                  playType={playType}
                  handleLogin={handleLogin}
                  stopPlaying={() => setIsAutoPlaying(false)}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  disabled={isAutoPlaying}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.DICE}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={!accessToken || isAutoPlaying}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  multiplier={multiplier}
                  title={title}
                  balance={player.balance}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    disabled={isAutoPlaying}
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                  >
                    x2
                  </Button>
                  <Button
                    disabled={isAutoPlaying}
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" gap={1.25}>
                <DiceAutoButtons
                  playType={playType}
                  autoPlayStates={autoPlayStates}
                  setAutoPlayStates={setAutoPlayStates}
                  isAutoPlaying={isAutoPlaying}
                  currentNumberOfGames={autoPlayStatesRef.numberOfGames}
                />
                <Stack
                  gap={1.25}
                  sx={{
                    display: playType === DICEMODE.MANUAL ? "flex" : "none",
                  }}
                >
                  <Typography color="text.primary">Profit on Win</Typography>
                  <ProfitOnWinInput
                    value={RoundProfitOnWin(
                      currentBet * multiplier - currentBet
                    )}
                    name="profit-on-win"
                  />
                </Stack>

                <Stack
                  sx={{ display: { md: "block", sm: "none", xs: "none" } }}
                >
                  <BetButton
                    accessToken={accessToken}
                    disableButton={disableButton || !toggle}
                    playGame={playGame}
                    playType={playType}
                    handleLogin={handleLogin}
                    stopPlaying={() => setIsAutoPlaying(false)}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                <Stack
                  sx={{ display: { md: "none", sm: "block", xs: "block" } }}
                >
                  <GameTypeButtons
                    playType={playType}
                    setPlayType={setPlayType}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                {!toggle && (
                  <Typography variant="h6" color="error">
                    {DEFAULT_DISABLED_MESSAGE}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <GameOptions game={GAMES.DICE} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
            }}
            gap={{ md: 0, sm: 4, xs: 4 }}
            justifyContent="space-between"
          >
            <Stack
              direction="row-reverse"
              gap={1}
              justifyContent={{ md: "flex-start", xs: "space-between" }}
              sx={{ height: "2em" }}
            >
              {pastResult.length ? (
                <>
                  {[...pastResult]?.reverse().map((result, index) => {
                    if (index > 4) return null;
                    return (
                      <Stack
                        key={result._id}
                        sx={{
                          bgcolor: result.isWin
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                          borderRadius: "5px",
                          width: "2.5em",
                          p: 0.75,
                        }}
                      >
                        <Typography
                          sx={{ color: "black" }}
                          textAlign="center"
                          fontWeight={600}
                        >
                          {result.result.toFixed(2)}
                        </Typography>
                      </Stack>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </Stack>

            <SliderWithLabel
              rollOver={rollOverNumber}
              setRollOver={handleRollOverNumberChange}
              game={dice}
              gameType={gameType}
              isAutoplaying={isAutoPlaying}
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              gap={{ md: 2, xs: 0 }}
            >
              <Stack alignItems="flex-start">
                <Typography color="text.primary">Multiplier</Typography>
                <InputFieldStyled
                  inputProps={{
                    min: 0,
                  }}
                  endAdornment={
                    <InputAdornment position="start" sx={{ color: "white" }}>
                      <CloseIcon sx={{ width: { md: "auto", xs: "18px" } }} />
                    </InputAdornment>
                  }
                  type="number"
                  disabled={isAutoPlaying}
                  value={inputRef.multiplier}
                  onBlur={handleMultiplierChange}
                  onChange={handleMultiplierRefChange}
                />
              </Stack>
              <Stack alignItems="flex-start">
                <Typography color="text.primary">
                  {gameType === DICETYPE.ROLLOVER ? "Roll Over" : "Roll Under"}
                </Typography>
                <InputFieldStyled
                  inputProps={{
                    min: 0,
                  }}
                  disabled={true}
                  type="number"
                  value={rollOverNumber / 100}
                  endAdornment={
                    <InputAdornment
                      position="start"
                      sx={{ cursor: "pointer", color: "white" }}
                      onClick={() => handleGameTypeChange()}
                    >
                      <CachedIcon sx={{ width: { md: "auto", xs: "18px" } }} />
                    </InputAdornment>
                  }
                  onChange={(e) =>
                    handleRollOverNumberChange(Number(e.target.value) * 100)
                  }
                />
              </Stack>
              <Stack alignItems="flex-start">
                <Typography color="text.primary">Win Chance</Typography>
                <InputFieldStyled
                  inputProps={{
                    min: 0,
                  }}
                  endAdornment={
                    <InputAdornment position="start" sx={{ color: "white" }}>
                      <PercentIcon sx={{ width: { md: "auto", xs: "18px" } }} />
                    </InputAdornment>
                  }
                  disabled={isAutoPlaying}
                  type="number"
                  value={inputRef.winProbabilityInput}
                  onChange={handleWinProbabilityRefChange}
                  onBlur={handleWinProbabilityChange}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Dice;
