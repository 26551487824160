import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled } from "@mui/material/styles";
import { chatDrawerWidth, chatDrawerWidthMin } from "../../constants";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  navBar?: boolean;
}
export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open, navBar }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `calc(${theme.spacing(7)} + 1px)`,
  marginLeft: navBar
    ? `min(${chatDrawerWidth}, ${chatDrawerWidthMin}px)`
    : `calc(${theme.spacing(7)} + 1px)`,
  width: navBar
    ? `calc(100% - 200px)`
    : `calc(100% - calc(${theme.spacing(7)} + 1px))`,

  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    width: "100%",
  },
  ...(open && {
    width: navBar
      ? `calc(100% - max(${chatDrawerWidth}, ${chatDrawerWidthMin}px) - 200px)`
      : `calc(100% - max(${chatDrawerWidth}, ${chatDrawerWidthMin}px) - calc(${theme.spacing(
          7
        )} + 1px))`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: `max(${chatDrawerWidth}, ${chatDrawerWidthMin}px)`,
    // marginLeft: `calc(${theme.spacing(7)} + 1px)`,
    marginLeft: navBar ? `200px` : `calc(${theme.spacing(7)} + 1px)`,
  }),
}));

// export const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open" && prop !== "navBar",
// })<AppBarProps>(({ theme, open, navBar }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: navBar
//     ? `min(-${chatDrawerWidth}, -${chatDrawerWidthMin}px)`
//     : `calc(${theme.spacing(7)} + 1px)`,
//   width: navBar
//     ? `calc(100% + min(${chatDrawerWidth}, ${chatDrawerWidthMin}px))`
//     : `calc(100% - calc(${theme.spacing(7)} + 1px))`,

//   [theme.breakpoints.down("md")]: {
//     marginLeft: 0,
//     width: "100%",
//   },
//   ...(open && {
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: navBar
//       ? `min(-${chatDrawerWidth}, -${chatDrawerWidthMin}px)`
//       : `calc(${theme.spacing(7)} + 1px)`,
//     width: navBar
//       ? `calc(100% + min(${chatDrawerWidth}, ${chatDrawerWidthMin}px))`
//       : `calc(100% - max(${chatDrawerWidth}, ${chatDrawerWidthMin}px) - calc(${theme.spacing(
//           7
//         )} + 1px))`,
//   }),
// }));

// export const AppBar = styled(MuiAppBar, {
//   shouldForwardProp: (prop) => prop !== "open" && prop !== "navBar",
// })<AppBarProps>(({ theme, open, navBar }) => ({
//   transition: theme.transitions.create(["margin", "width"], {
//     easing: theme.transitions.easing.sharp,
//     duration: theme.transitions.duration.leavingScreen,
//   }),
//   marginLeft: navBar
//     ? `calc(${chatDrawerWidth}px + ${chatDrawerWidthMin}px)`
//     : `calc(${theme.spacing(7)} + 1px)`,
//   width: navBar
//     ? `calc(100% - calc(${chatDrawerWidth}px + ${chatDrawerWidthMin}px))`
//     : `calc(100% - calc(${theme.spacing(7)} + 1px))`,

//   [theme.breakpoints.down("md")]: {
//     marginLeft: 0,
//     width: "100%",
//   },
//   ...(open && {
//     transition: theme.transitions.create(["margin", "width"], {
//       easing: theme.transitions.easing.easeOut,
//       duration: theme.transitions.duration.enteringScreen,
//     }),
//     marginLeft: navBar
//       ? `calc(${theme.spacing(7)} + 1px)`
//       : `calc(${chatDrawerWidth}px + ${chatDrawerWidthMin}px + ${theme.spacing(
//           7
//         )} + 1px)`,
//     width: navBar
//       ? `calc(100% - calc(${theme.spacing(7)} + 1px))`
//       : `calc(100% - calc(${chatDrawerWidth}px + ${chatDrawerWidthMin}px) - calc(${theme.spacing(
//           7
//         )} + 1px))`,
//   }),
// }));
