import { Typography, Container, Stack } from "@mui/material";
import { Terms } from "./constant";

const CollapsableCard = ({ question, answer }: any) => {
  return (
    <Stack key={question}>
      <Typography color="text.primary" variant="h6">
        {question}
      </Typography>
      {answer.map((text: string, index: number) => (
        <Typography
          color="text.primary"
          key={index}
          sx={{ py: 1.5 }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      ))}
    </Stack>
  );
};

const TermsServicePage = () => {
  return (
    <Container maxWidth="md">
      <Typography
        className="heading"
        sx={{ mb: 2 }}
        variant="h1"
        color="text.primary"
      >
        TERMS AND CONDITIONS
      </Typography>
      <Stack gap={2}>
        {Terms.map(({ title, text }) => (
          <CollapsableCard key={title} question={title} answer={text} />
        ))}
      </Stack>
    </Container>
  );
};

export default TermsServicePage;
