import {
  Stack,
  Box,
  Toolbar,
  Container,
  Tooltip,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Divider,
  BottomNavigationAction,
  BottomNavigation,
  Paper,
  Select,
} from "@mui/material";
import LogoImgMobile from "../../assets/runewagerMobile.svg";
import LogoImg from "../../assets/runewager.svg";
import Modal from "../modal";
import { useState, useEffect } from "react";
import { MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { useAppSelector } from "../store/hooks";
import {
  closeModal,
  logoutUser,
  openModal,
  selectChatDrawer,
  selectIsModalOpen,
  selectIsPlayingSlots,
  selectModalType,
  selectNavDrawer,
  selectPlayer,
  selectPlayerLevel,
  selectToken,
  selectTokenSelection,
  updateChatDrawer,
  updateInitialLoading,
  updateModalType,
  updateNavDrawer,
  updateTokenSelection,
  updateUser,
} from "../slices/userSlice";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsAccessibilityIcon from "@mui/icons-material/SettingsAccessibility";
import { Button } from "../button";
import { Link, useNavigate } from "react-router-dom";
import { theme } from "../theme";
import { useTheme } from "@mui/material/styles";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { Drawer, DrawerHeader } from "../drawer";
import { AppBar } from "./appBar";
import ChatBubbleRoundedIcon from "@mui/icons-material/ChatBubbleOutlineRounded";
import AddIcon from "@mui/icons-material/Add";
import { Balance, BalanceSelection, FreeCash } from "./balance";
import useMediaQuery from "@mui/material/useMediaQuery";
import { getPlayerLevelData } from "../../utils";
import styles from "./index.module.scss";
import SideDrawerList from "./sideDrawer";
import Users from "../../services/user";
import MenuIcon from "../../assets/navIcons/menu-icon-mobile.svg";
const bottomNavigationBar = [
  {
    label: "Menu",
    icon: <img src={MenuIcon} style={{ width: "2em" }} alt="menu-icon" />,
  },
  // {
  //   label: "Referrals",
  //   icon: <SettingsAccessibilityIcon />,
  // },
  {
    label: "Chat",
    icon: <ChatBubbleRoundedIcon />,
  },
];

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 2;
const MenuProps = {
  PaperProps: {
    sx: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: { md: "16em", xs: "13em" }, // Set width directly on PaperProps
    },
  },
  disableScrollLock: true, // Add this property to disable scroll lock
  anchorOrigin: {
    vertical: "bottom" as const,
    horizontal: "left" as const,
  },
  transformOrigin: {
    vertical: "top" as const,
    horizontal: "left" as const,
  },
};

const Navbar = () => {
  const drawerOpen = useAppSelector(selectNavDrawer);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const playerLevel = useAppSelector(selectPlayerLevel);
  const accessToken = useAppSelector(selectToken);
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const modalType = useAppSelector(selectModalType);
  const dispatch = useDispatch<AppDispatch>();
  const [drawerListOpen, setDrawerListOpen] = useState(false);
  const isChatDrawerOpen = useAppSelector(selectChatDrawer);
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const navigate = useNavigate();
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const getPlayerLevel = getPlayerLevelData(playerLevel);
  const tokenSelection = useAppSelector(selectTokenSelection);
  const isPlayingSlots = useAppSelector(selectIsPlayingSlots);

  const setTokenSelection = (value: TOKENTYPE) => {
    dispatch(updateTokenSelection(value));
  };

  const setDrawerOpen = (value: boolean) => {
    dispatch(updateNavDrawer(value));
  };

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  const handleClose = () => {
    dispatch(closeModal());
  };

  const setModalType = (type: MODALTYPE) => {
    dispatch(updateModalType(type));
  };
  const handleSignUp = () => {
    dispatch(openModal(MODALTYPE.REGISTER));
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleDrawerOpen = (index = 0) => {
    setDrawerOpen(true);

    if (index === 2) {
      setDrawerListOpen(!drawerListOpen);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleToggleChatDrawer = () => {
    dispatch(updateChatDrawer(!isChatDrawerOpen));
  };
  const openExchange = () => {
    dispatch(openModal(MODALTYPE.EXCHANGE));
  };

  useEffect(() => {
    if ((isChatDrawerOpen || drawerOpen) && !matchesMD) {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isChatDrawerOpen, matchesMD, drawerOpen]);

  const settingsData = [
    {
      label: "Profile",
      endpoint: "/profile",
    },
    {
      label: "Logout",
      endpoint: "",
    },
  ];

  const handleLogout = async () => {
    try {
      dispatch(updateInitialLoading(true));
      const response = await Users.logout();
      if (response) {
        dispatch(logoutUser());
        window.location.href = "/";
      }
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(updateInitialLoading(true));
    }
  };

  const handleAccountMenu = (endpoint: string) => {
    if (!endpoint) {
      handleLogout();
    }
    if (endpoint) {
      navigate(endpoint);
    }
    handleCloseUserMenu();
  };

  const handleDrawerItem = (key: string) => {
    if (key === "Chat") {
      handleToggleChatDrawer();
    }
    if (key === "Menu") {
      handleDrawerOpen();
    }
  };

  return (
    <>
      <AppBar navBar={drawerOpen} position="fixed" open={isChatDrawerOpen}>
        <Container
          maxWidth="md"
          sx={{ p: { md: "0px !important", xs: "auto" } }}
        >
          <Toolbar disableGutters>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              <Link to="/" style={{ minWidth: "20%" }}>
                <Stack
                  direction="row"
                  sx={{ height: matchesMD ? "3em" : "2.5em" }}
                >
                  <img
                    src={matchesMD ? LogoImg : LogoImgMobile}
                    alt="logo-img"
                    style={{ cursor: "pointer" }}
                  />
                </Stack>
              </Link>
              {accessToken && (
                <Stack
                  direction="row"
                  gap={1}
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  <Stack
                  // sx={
                  //   {
                  //     py: 0.5,
                  //     px: 1.25,
                  //     borderRadius: "5px",
                  //     backgroundColor: "#292929",
                  //     position: "relative",
                  //   }
                  // }
                  // alignItems="center"
                  // gap={0.5}
                  // direction="row"
                  >
                    <Select
                      disabled={isPlayingSlots}
                      sx={{
                        height: "auto",
                        p: 0,
                        width: { md: "auto", xs: "auto" },
                        "& .MuiInputBase-input": {
                          paddingRight: { xs: "12px !important", md: "auto" },
                        },
                        "& .MuiSelect-select": {
                          p: 0,
                        },
                        "& .MuiSelect-icon": {
                          // ml: { xs: 0.5 },
                          fontSize: { xs: "1.5em" },
                        },
                        "& .MuiSelect-root": {
                          width: "20em",
                        },
                      }}
                      // inputProps={{ MenuProps: { disableScrollLock: true } }}
                      MenuProps={MenuProps}
                      onChange={(e) => {
                        setTokenSelection(e.target.value as TOKENTYPE);
                      }}
                      renderValue={(e) => {
                        if (tokenSelection === TOKENTYPE.SWEEP) {
                          return <Balance />;
                        } else {
                          return <FreeCash />;
                        }
                      }}
                      value={tokenSelection}
                    >
                      <BalanceSelection
                        setTokenSelection={setTokenSelection}
                        tokenSelection={tokenSelection}
                      />
                    </Select>
                  </Stack>
                  <Button
                    onClick={() => openExchange()}
                    variantType="success"
                    sx={{ display: { sm: "none", xs: "none", md: "block" } }}
                  >
                    Wallet
                  </Button>
                  <IconButton
                    onClick={() => openExchange()}
                    className={styles.exchangeButton}
                    sx={{
                      display: { sm: "block", md: "none" },
                      bgcolor: theme.palette.success.main,
                      color: theme.palette.text.secondary,
                      borderRadius: "5px",
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Stack>
              )}
              <Stack direction="row" gap={1}>
                {accessToken ? (
                  <Box sx={{ flexGrow: 0 }}>
                    <Stack direction="row" gap={2} alignItems="center">
                      <Tooltip title="Open settings">
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                          <Avatar
                            variant="square"
                            src={getPlayerLevel.imgSrc}
                            sx={{
                              "& .MuiAvatar-img": {
                                objectFit: "contain",
                              },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      disableScrollLock={true}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settingsData.map((setting) => (
                        <MenuItem
                          key={setting.label}
                          onClick={() => handleAccountMenu(setting.endpoint)}
                        >
                          <Typography textAlign="center">
                            {setting.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                ) : (
                  <Stack direction="row" gap={1}>
                    <Button onClick={handleLogin} fullWidth>
                      Login
                    </Button>
                    <Button
                      onClick={handleSignUp}
                      variantType="success"
                      fullWidth
                    >
                      Register
                    </Button>
                  </Stack>
                )}
                <IconButton
                  onClick={handleToggleChatDrawer}
                  sx={{
                    p: 1.25,
                    background: "#292929",
                    display: { md: "flex", sm: "none", xs: "none" },
                  }}
                >
                  <ChatBubbleRoundedIcon
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: "0.78em",
                    }}
                  />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </Container>
      </AppBar>
      <Paper
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          display: { md: "none", sm: "block", xs: "block" },
        }}
        elevation={3}
      >
        <BottomNavigation
          showLabels
          sx={{
            backgroundColor: theme.palette.primary.main,
            pb: isIOS && isSmallScreen ? "env(safe-area-inset-bottom)" : 0,
          }}
        >
          {bottomNavigationBar.map((item, index) => (
            <BottomNavigationAction
              key={item.label}
              label={item.label}
              onClick={() => handleDrawerItem(item.label)}
              icon={item.icon}
              sx={{ color: theme.palette.text.primary }}
            />
          ))}
        </BottomNavigation>
      </Paper>
      <Drawer
        variant="permanent"
        open={drawerOpen}
        // sx={{ display: { md: "block", sm: "none", xs: "none" } }}
        sx={{ display: { md: "block" } }}
      >
        <DrawerHeader>
          <Stack
            onClick={() => {
              if (drawerOpen) {
                handleDrawerClose();
              } else {
                handleDrawerOpen();
              }
            }}
            sx={{ width: "100%", pl: 2, display: drawerOpen ? "flex" : "none" }}
          >
            <Typography variant="h6"> Menu</Typography>
          </Stack>
          <IconButton
            onClick={() => {
              if (drawerOpen) {
                handleDrawerClose();
              } else {
                handleDrawerOpen();
              }
            }}
          >
            {!drawerOpen ? (
              <ChevronRightIcon sx={{ color: theme.palette.text.primary }} />
            ) : (
              <ChevronLeftIcon sx={{ color: theme.palette.text.primary }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box sx={{ overflowY: "auto" }}>
          <SideDrawerList
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            drawerListOpen={drawerListOpen}
            setDrawerListOpen={setDrawerListOpen}
          />
        </Box>
      </Drawer>
      <Modal
        handleClose={handleClose}
        open={isModalOpen}
        modalType={modalType || undefined}
        setModalType={setModalType}
      />
    </>
  );
};

export default Navbar;
