import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Stack, Typography, Box, useMediaQuery } from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import {
  selectBlackJackToggle,
  selectMinesToggle,
  selectPlayer,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import styles from "./index.module.scss";
import type { AppDispatch } from "../store";
import useSound from "use-sound";
import { useDispatch } from "react-redux";
import { setErrorMesage } from "../slices/mineSlice";
import { DEFAULT_DISABLED_MESSAGE } from "../../constants";
import { BLACKJACKACTION, TOKENTYPE } from "../../types/index.d";
import { isBetGreaterthanSetValue, RoundNumber } from "../../utils";
import { BlackJackButtons, Buttons } from "./buttons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import {
  CHECK_BLACKJACK_GAME,
  PLAY_BLACKJACK_GAME,
} from "../../constants/socket";
import ChatContext from "../../contexts/chat/context";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import {
  BLACKGAMESTATE,
  getTotal,
  isBlackJack,
  showInsurance,
} from "./constants";
import PlayArea from "./playArea";
import {
  clearBlackJackGame,
  selectBlackJackError,
  selectBlackJackGame,
  selectBlackJackLoading,
  updateLoading,
} from "../slices/blackJackslice";
import { SideBet } from "./sideBets";
import CardImages, {
  PAIRTYPES,
  PERFECTPAIRPAYOUTS,
  TWENTYONEPLUSTHREEPAYOUTS,
  TWENTYONEPLUSTHREETYPES,
} from "../../constants/blackJack";
import { SideBetField } from "./sideBetField";
import BackCard from "../../assets/blackjack/blackjack-back.svg";

const preloadImage = (src: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
  });
};

const preloadImages = (imagePaths: string[]) => {
  return Promise.all(imagePaths.map(preloadImage));
};

const BlackJack = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { player, accessToken } = useAppSelector(selectPlayer);
  const state = useAppSelector(selectBlackJackGame);
  const errorMessage = useAppSelector(selectBlackJackError);
  const loading = useAppSelector(selectBlackJackLoading);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [confirmation, setConfirmation] = useState(false);
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const [title, setTitle] = useState("");
  const [titlePerfectPair, setTitlePerfectPair] = useState("");
  const [titleOtherBet, setTitleOtherBet] = useState("");
  const [currentBet, setCurrentBet] = useState(0);
  const [twentyOnePlusThree, setTwentyOnePlusThree] = useState(0);
  const [perfectPair, setPerfectPair] = useState(0);
  const { socket } = useContext(ChatContext);
  const toggle = useAppSelector(selectBlackJackToggle);
  const deckRef = useRef<any>();
  const [showButtons, setShowButtons] = useState(false);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const matchesXS = useMediaQuery(theme.breakpoints.up("sm"));
  const tokenType = useAppSelector(selectTokenSelection);
  const handleCreateGame = (askConfirmation = true) => {
    if (title) {
      dispatch(setErrorMesage(title));
      return;
    }
    if (titlePerfectPair) {
      dispatch(setErrorMesage(titlePerfectPair));
      return;
    }
    if (titleOtherBet) {
      dispatch(setErrorMesage(titleOtherBet));
      return;
    }
    const sumOfBets = currentBet + twentyOnePlusThree + perfectPair;
    if (sumOfBets > player.balance && tokenType === TOKENTYPE.SWEEP) {
      betFieldRef.current?.focus();
      return;
    }

    if (sumOfBets > player.freeCash && tokenType === TOKENTYPE.FREE) {
      betFieldRef.current?.focus();
      return;
    }
    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be negative"));
      return;
    }
    if (currentBet === 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be 0"));
      return;
    }
    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet can't be lower than balance"));
      return;
    }

    if (currentBet && currentBet > 0) {
      if (
        askConfirmation &&
        currentBet &&
        tokenType === TOKENTYPE.SWEEP &&
        ((player.isBetConfirmation &&
          isBetGreaterthanSetValue(
            currentBet,
            player.balance,
            player?.betConfirmationValue
          )) ||
          isBetGreaterthanSetValue(currentBet, player.balance, 95))
      ) {
        setConfirmation(true);
        return;
      }
      if (!askConfirmation && dontShowConfirmation) {
        let payload: Partial<User> = {
          isBetConfirmation: false,
          betConfirmationValue: 0,
        };
        dispatch(updateSettings(payload));
      }

      const gameData = {
        currentBet,
        perfectPairSideBet: perfectPair,
        otherSideBet: twentyOnePlusThree,
        tokenType,
      };

      playBetSound();

      dispatch(clearBlackJackGame());
      socket?.emit(PLAY_BLACKJACK_GAME, {
        accessToken,
        gameData,
      });

      return;
    }
  };

  const isBalanceError = !accessToken;

  const handleUpdate = useCallback(
    (action: BLACKJACKACTION) => {
      if (loading) return;
      dispatch(updateLoading());
      socket?.emit(CHECK_BLACKJACK_GAME, {
        accessToken,
        action,
        // action: "asdasd",
      });
    },
    [loading]
  );

  const doHit = () => {
    handleUpdate(BLACKJACKACTION.HIT);
  };

  const doStand = () => {
    handleUpdate(BLACKJACKACTION.STAND);
  };

  const doSplit = () => {
    handleUpdate(BLACKJACKACTION.SPLIT);
  };

  const doDouble = () => {
    handleUpdate(BLACKJACKACTION.DOUBLE);
  };
  const handleInsurance = (isInsurance = true) => {
    if (isInsurance) {
      handleUpdate(BLACKJACKACTION.TAKE_INSURANCE);
      return;
    }
    handleUpdate(BLACKJACKACTION.NO_INSURANCE);
  };

  useEffect(() => {
    const imagePaths = [
      BackCard,
      ...Object.values(CardImages.Clubs),
      ...Object.values(CardImages.Diamonds),
      ...Object.values(CardImages.Hearts),
      ...Object.values(CardImages.Spades),
    ];

    preloadImages(imagePaths as string[]).then(() => {});
  }, []);

  const canSplit = () => {
    if (!state) return;
    const hand = state.playersHands[state.currentPlayerHandIndex];
    return (
      hand.length === 2 && hand[0].rank === hand[1].rank && !state.hasSplit
    );
  };

  const canDouble = () => {
    if (!state) return;

    const hand = state.playersHands[state.currentPlayerHandIndex];
    return hand.length === 2;
  };

  const canHit = () => {
    if (!state) return;

    const hand = state.playersHands[state.currentPlayerHandIndex];
    return getTotal(hand) < 21;
  };

  const canStand = () => {
    return true;
  };

  useEffect(() => {
    if (!state) {
      setShowButtons(false);
      return;
    }
    if (
      showInsurance(state?.dealersHand, state?.insurance, state?.currentState)
    ) {
      setShowButtons(false);
      return;
    }

    const currentHand = state.playersHands[state.currentPlayerHandIndex];
    if (!(getTotal(currentHand) > 21 || state.isTurnEnd)) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }, [state]);
  const getButtons = () => {
    if (!state) {
      return;
    }
    if (
      showInsurance(state?.dealersHand, state?.insurance, state?.currentState)
    ) {
      return;
    }

    const currentHand = state.playersHands[state.currentPlayerHandIndex];
    if (!(getTotal(currentHand) > 21 || state.isTurnEnd)) {
      return (
        <BlackJackButtons
          disable={loading}
          canHit={canHit()}
          canStand={canStand()}
          canSplit={canSplit()}
          canDouble={canDouble()}
          onClickHit={doHit}
          onClickStand={doStand}
          onClickSplit={doSplit}
          onClickDouble={doDouble}
          bet={state.bet}
          tokenType={state.tokenType}
        />
      );
    }
  };

  const isButtonDisabled = state?.currentState === BLACKGAMESTATE.START;
  const disableButtons =
    !accessToken || state?.currentState === BLACKGAMESTATE.START;

  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        title="Bet Confirmation"
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          handleCreateGame(false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet || 0,
          player.balance,
          95
        )}
      />
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Blackjack
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
          sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack
                sx={{
                  display: { md: "none", sm: "block", xs: "block" },
                  width: "100%",
                }}
              >
                <Buttons
                  accessToken={accessToken}
                  gameStatus={state?.currentState as BLACKGAMESTATE}
                  disabled={!toggle || isButtonDisabled}
                  payout={state?.payout || 0}
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  disabled={disableButtons}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.BLACKJACK}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={disableButtons}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  title={title}
                  balance={player.balance}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                    disabled={disableButtons}
                  >
                    x2
                  </Button>
                  <Button
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                    disabled={disableButtons}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <Stack gap={0.25}>
                  <Typography color="text.primary">21 + 3</Typography>
                  <SideBetField
                    setCurrentBet={setTwentyOnePlusThree}
                    disabled={disableButtons}
                    currentBet={twentyOnePlusThree}
                    setTitle={setTitleOtherBet}
                    title={titleOtherBet}
                    balance={player.balance}
                    isPerfectPair={false}
                  />
                </Stack>
                <Stack gap={0.25}>
                  <Typography color="text.primary">Perfect Pair</Typography>
                  <SideBetField
                    setCurrentBet={setPerfectPair}
                    disabled={disableButtons}
                    currentBet={perfectPair}
                    setTitle={setTitlePerfectPair}
                    title={titlePerfectPair}
                    balance={player.balance}
                    isPerfectPair={true}
                  />
                </Stack>
              </Stack>
              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <Buttons
                  accessToken={accessToken}
                  gameStatus={state?.currentState as BLACKGAMESTATE}
                  disabled={!toggle || isButtonDisabled}
                  payout={state?.payout || 0}
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                />
              </Stack>
              {errorMessage && (
                <Typography variant="h6" color="error">
                  {errorMessage}
                </Typography>
              )}

              {!toggle && (
                <Typography variant="h6" color="error">
                  {DEFAULT_DISABLED_MESSAGE}
                </Typography>
              )}
              {showInsurance(
                state?.dealersHand,
                state?.insurance,
                state?.currentState
              ) && (
                <Stack alignItems="center" gap={1}>
                  <Typography color="text.primary">Insurance?</Typography>
                  <Stack sx={{ width: "100%" }} direction="row" gap={1}>
                    <Button
                      disabled={(state?.bet || 0) > player?.balance}
                      fullWidth
                      onClick={() => handleInsurance(true)}
                    >
                      Accept insurance
                    </Button>
                    <Button fullWidth onClick={() => handleInsurance(false)}>
                      No insurance
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <GameOptions game={GAMES.BLACKJACK} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
              height: { md: "auto", xs: "40em" },
              position: "relative",
              overflow: "hidden",
            }}
            justifyContent="center"
          >
            <Stack className={styles.deck} ref={deckRef}>
              {Array.from({ length: 10 }).map((_, index) => (
                <img
                  src={BackCard}
                  className={styles.deckCard}
                  style={{
                    bottom: matchesXS ? `${6.9 * index}em` : `${6.2 * index}em`,
                  }}
                />
              ))}
            </Stack>
            <Stack
              justifyContent={{
                md: "center",
                xs: showButtons ? "center" : "center",
              }}
              sx={{ height: "100%" }}
            >
              <Stack
                gap={1}
                sx={{ position: "absolute", left: "1%", top: "1%" }}
              >
                {state?.perfectPairSideBetPayout && (
                  <SideBet
                    payout={state.perfectPairSideBetPayout.payout}
                    isFunCash={state.tokenType === TOKENTYPE.FREE}
                    typeOfBet={`${
                      state.perfectPairSideBetPayout.typeOfBet
                    } (1:${
                      PERFECTPAIRPAYOUTS[
                        state.perfectPairSideBetPayout.typeOfBet as PAIRTYPES
                      ]
                    })`}
                  />
                )}
                {state?.otherSideBetPayout && (
                  <SideBet
                    isFunCash={state.tokenType === TOKENTYPE.FREE}
                    payout={state.otherSideBetPayout.payout}
                    typeOfBet={`${state.otherSideBetPayout.typeOfBet} (1:${
                      TWENTYONEPLUSTHREEPAYOUTS[
                        state.otherSideBetPayout
                          .typeOfBet as TWENTYONEPLUSTHREETYPES
                      ]
                    })`}
                  />
                )}

                {state?.insurance?.bet && isBlackJack(state.dealersHand) ? (
                  <SideBet
                    isFunCash={state.tokenType === TOKENTYPE.FREE}
                    payout={state?.insurance?.bet * 2}
                    typeOfBet={`Insurance (1:2)`}
                  />
                ) : (
                  <></>
                )}
              </Stack>

              <PlayArea
                dealersHand={state?.dealersHand}
                playersHands={state?.playersHands}
                currentPlayerHandIndex={state?.currentPlayerHandIndex}
                isTurnEnd={state?.isTurnEnd}
                deckRef={deckRef}
                isGameEnd={state?.currentState === BLACKGAMESTATE.END}
              />

              {state?.currentState !== BLACKGAMESTATE.INIT && (
                <>
                  <Box className={styles.messageArea}>{getButtons()}</Box>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default BlackJack;
