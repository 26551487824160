// Import Clubs
import ClubsA from "../assets/blackjack/Clubs/A.svg";
import Clubs2 from "../assets/blackjack/Clubs/2.svg";
import Clubs3 from "../assets/blackjack/Clubs/3.svg";
import Clubs4 from "../assets/blackjack/Clubs/4.svg";
import Clubs5 from "../assets/blackjack/Clubs/5.svg";
import Clubs6 from "../assets/blackjack/Clubs/6.svg";
import Clubs7 from "../assets/blackjack/Clubs/7.svg";
import Clubs8 from "../assets/blackjack/Clubs/8.svg";
import Clubs9 from "../assets/blackjack/Clubs/9.svg";
import Clubs10 from "../assets/blackjack/Clubs/10.svg";
import ClubsJ from "../assets/blackjack/Clubs/J.svg";
import ClubsQ from "../assets/blackjack/Clubs/Q.svg";
import ClubsK from "../assets/blackjack/Clubs/K.svg";

// Import Diamonds
import DiamondsA from "../assets/blackjack/Diamonds/A.svg";
import Diamonds2 from "../assets/blackjack/Diamonds/2.svg";
import Diamonds3 from "../assets/blackjack/Diamonds/3.svg";
import Diamonds4 from "../assets/blackjack/Diamonds/4.svg";
import Diamonds5 from "../assets/blackjack/Diamonds/5.svg";
import Diamonds6 from "../assets/blackjack/Diamonds/6.svg";
import Diamonds7 from "../assets/blackjack/Diamonds/7.svg";
import Diamonds8 from "../assets/blackjack/Diamonds/8.svg";
import Diamonds9 from "../assets/blackjack/Diamonds/9.svg";
import Diamonds10 from "../assets/blackjack/Diamonds/10.svg";
import DiamondsJ from "../assets/blackjack/Diamonds/J.svg";
import DiamondsQ from "../assets/blackjack/Diamonds/Q.svg";
import DiamondsK from "../assets/blackjack/Diamonds/K.svg";

// Import Hearts
import HeartsA from "../assets/blackjack/Hearts/A.svg";
import Hearts2 from "../assets/blackjack/Hearts/2.svg";
import Hearts3 from "../assets/blackjack/Hearts/3.svg";
import Hearts4 from "../assets/blackjack/Hearts/4.svg";
import Hearts5 from "../assets/blackjack/Hearts/5.svg";
import Hearts6 from "../assets/blackjack/Hearts/6.svg";
import Hearts7 from "../assets/blackjack/Hearts/7.svg";
import Hearts8 from "../assets/blackjack/Hearts/8.svg";
import Hearts9 from "../assets/blackjack/Hearts/9.svg";
import Hearts10 from "../assets/blackjack/Hearts/10.svg";
import HeartsJ from "../assets/blackjack/Hearts/J.svg";
import HeartsQ from "../assets/blackjack/Hearts/Q.svg";
import HeartsK from "../assets/blackjack/Hearts/K.svg";

// Import Spades
import SpadesA from "../assets/blackjack/Spades/A.svg";
import Spades2 from "../assets/blackjack/Spades/2.svg";
import Spades3 from "../assets/blackjack/Spades/3.svg";
import Spades4 from "../assets/blackjack/Spades/4.svg";
import Spades5 from "../assets/blackjack/Spades/5.svg";
import Spades6 from "../assets/blackjack/Spades/6.svg";
import Spades7 from "../assets/blackjack/Spades/7.svg";
import Spades8 from "../assets/blackjack/Spades/8.svg";
import Spades9 from "../assets/blackjack/Spades/9.svg";
import Spades10 from "../assets/blackjack/Spades/10.svg";
import SpadesJ from "../assets/blackjack/Spades/J.svg";
import SpadesQ from "../assets/blackjack/Spades/Q.svg";
import SpadesK from "../assets/blackjack/Spades/K.svg";

export const CardImages: any = {
  Clubs: {
    A: ClubsA,
    "2": Clubs2,
    "3": Clubs3,
    "4": Clubs4,
    "5": Clubs5,
    "6": Clubs6,
    "7": Clubs7,
    "8": Clubs8,
    "9": Clubs9,
    "10": Clubs10,
    J: ClubsJ,
    Q: ClubsQ,
    K: ClubsK,
  },
  Diamonds: {
    A: DiamondsA,
    "2": Diamonds2,
    "3": Diamonds3,
    "4": Diamonds4,
    "5": Diamonds5,
    "6": Diamonds6,
    "7": Diamonds7,
    "8": Diamonds8,
    "9": Diamonds9,
    "10": Diamonds10,
    J: DiamondsJ,
    Q: DiamondsQ,
    K: DiamondsK,
  },
  Hearts: {
    A: HeartsA,
    "2": Hearts2,
    "3": Hearts3,
    "4": Hearts4,
    "5": Hearts5,
    "6": Hearts6,
    "7": Hearts7,
    "8": Hearts8,
    "9": Hearts9,
    "10": Hearts10,
    J: HeartsJ,
    Q: HeartsQ,
    K: HeartsK,
  },
  Spades: {
    A: SpadesA,
    "2": Spades2,
    "3": Spades3,
    "4": Spades4,
    "5": Spades5,
    "6": Spades6,
    "7": Spades7,
    "8": Spades8,
    "9": Spades9,
    "10": Spades10,
    J: SpadesJ,
    Q: SpadesQ,
    K: SpadesK,
  },
};

export default CardImages;

export enum TWENTYONEPLUSTHREETYPES {
  FLUSH = "FLUSH",
  STRAIGHT = "STRAIGHT",
  THREE_OF_A_KIND = "THREE_OF_A_KIND",
  STRAIGHT_FLUSH = "STRAIGHT_FLUSH",
  SUITED_THREE_OF_A_KIND = "SUITED_THREE_OF_A_KIND",
}
export const TWENTYONEPLUSTHREEPAYOUTS = {
  [TWENTYONEPLUSTHREETYPES.FLUSH]: 5,
  [TWENTYONEPLUSTHREETYPES.STRAIGHT]: 10,
  [TWENTYONEPLUSTHREETYPES.THREE_OF_A_KIND]: 33,
  [TWENTYONEPLUSTHREETYPES.STRAIGHT_FLUSH]: 35,
  [TWENTYONEPLUSTHREETYPES.SUITED_THREE_OF_A_KIND]: 100,
};

export enum PAIRTYPES {
  MIXED_PAIR = "MIXED_PAIR",
  COLORED_PAIR = "COLORED_PAIR",
  PERFECT_PAIR = "PERFECT_PAIR",
}
export const PERFECTPAIRPAYOUTS = {
  [PAIRTYPES.MIXED_PAIR]: 6,
  [PAIRTYPES.COLORED_PAIR]: 12,
  [PAIRTYPES.PERFECT_PAIR]: 25,
};
