import React, { useState, useEffect, useContext, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import { InputFieldWithStartAdornment } from "../inputField";
import styles from "./index.module.scss";
import { isBetGreaterthanSetValue, RoundNumber } from "../../utils";
import {
  openModal,
  selectCurrentUser,
  selectKenoToggle,
  selectLevelUpModal,
  selectToken,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import ChatContext from "../../contexts/chat/context";
import { PLAY_KENO_GAME } from "../../constants/socket";
import { DICEMODE, MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import DiceAutoButtons, {
  BetButton,
  GameTypeButtons,
  IAutoPlayStates,
  RiskButtons,
} from "./kenoButtons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { Payouts } from "./payouts";
import {
  generateSingleNumber,
  kenoMultipliers,
  KENORISK,
  KENO_AUTO_PLAY_DELAY,
  KENO_AUTO_PLAY_TURBO_DELAY,
} from "./constants";
import DiamondImg from "../../assets/keno/green.svg";
import {
  resetKeno,
  selectKenoGames,
  updateKenoErrorMessage,
  updateKenoPastGame,
} from "../slices/kenoSlice";
import { WinModal } from "./winModal";
import { DEFAULT_DISABLED_MESSAGE, SELECTIONSTATUS } from "../../constants";
import { GameOptions } from "../gameOptions";
import { GAMES } from "../../constants/games";
import { BetField } from "../betField";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import useSound from "use-sound";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import WinSound from "../../assets/audio/common/win.mp3";
import LossSound from "../../assets/audio/common/loss.mp3";
import SelectNumber from "../../assets/audio/keno/select-tile.mp3";
import UnSelectNumber from "../../assets/audio/keno/unselect tile.mp3";
import DiamondReveal from "../../assets/audio/keno/diamond-reveal.mp3";
import EmptyTile from "../../assets/audio/keno/empty-tile.mp3";

const Keno: React.FC = () => {
  const kenoRisk = localStorage.getItem("kenoRisk");

  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useAppSelector(selectToken);
  const player = useAppSelector(selectCurrentUser);
  const { errorMessage, keno, pastResults } = useAppSelector(selectKenoGames);
  const toggle = useAppSelector(selectKenoToggle);
  const { socket } = useContext(ChatContext);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [playType, setPlayType] = useState<DICEMODE>(DICEMODE.MANUAL);
  const [confirmation, setConfirmation] = useState(false);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const [risk, setRisk] = useState<KENORISK>(
    kenoRisk ? (kenoRisk as KENORISK) : KENORISK.CLASSIC
  );
  const isLevelUp = useAppSelector(selectLevelUpModal);
  const [selectedNumbers, setSelectedNumbers] = useState<number[]>([]);
  const [isAutoPicking, setIsAutoPicking] = useState(false);
  const betRef = useRef<number>(0);
  const [autoPlayStates, setAutoPlayStates] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });

  const [autoPlayStatesRef, setAutoPlayStatesRef] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });
  const tokenType = useAppSelector(selectTokenSelection);
  const tokenTypeRef = useRef(tokenType);

  const [isTurbo, setIsTurbo] = useState(false);
  const [kenoResult, setKenoResult] = useState<number[]>([]);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const resultInterval = useRef<any>();
  const [currentBet, setCurrentBet] = useState(0);
  const [showWinModal, setShowWinModal] = useState(false);
  const showWinModalRef = useRef(false);
  const [title, setTitle] = useState("");
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const [playWinSound] = useSound(WinSound, { volume: volume / 100 });
  const [playLossSound] = useSound(LossSound, { volume: volume / 100 });
  const [playSelectSound] = useSound(SelectNumber, { volume: volume / 100 });
  const [playDiamondRevealSound] = useSound(DiamondReveal, {
    volume: volume / 100,
  });
  const [playEmptyTileSound] = useSound(EmptyTile, { volume: volume / 100 });
  const [playUnSelectSound] = useSound(UnSelectNumber, {
    volume: volume / 100,
  });
  const [isAnimatingResults, setIsAnimatingResult] = useState(false);

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  useEffect(() => {
    localStorage.setItem("kenoRisk", risk);
  }, [risk]);

  useEffect(() => {
    let interval: any;
    if (keno && isAutoPlaying) {
      interval = setInterval(
        () => {
          dispatch(updateKenoPastGame(keno));
        },
        isTurbo ? KENO_AUTO_PLAY_TURBO_DELAY : KENO_AUTO_PLAY_DELAY
      );
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [keno, isAutoPlaying, isTurbo]);

  const playGame = (bet = currentBet, askConfirmation = true) => {
    if (title) {
      setTitle(title);
      return;
    }
    if (!toggle) {
      setIsAutoPlaying(false);
      return;
    }
    if ((bet > player.balance && tokenType === TOKENTYPE.SWEEP) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }
    if ((bet > player.freeCash && tokenType === TOKENTYPE.FREE) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }

    if (
      tokenType === TOKENTYPE.SWEEP &&
      !isAutoPlaying &&
      askConfirmation &&
      ((player.isBetConfirmation &&
        isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          player?.betConfirmationValue
        )) ||
        isBetGreaterthanSetValue(currentBet, player.balance, 95))
    ) {
      setConfirmation(true);
      return;
    }

    if (!askConfirmation && dontShowConfirmation) {
      let payload: Partial<User> = {
        isBetConfirmation: false,
        betConfirmationValue: 0,
      };
      dispatch(updateSettings(payload));
    }

    if (showWinModal) {
      setShowWinModal(false);
    }
    const gameData = {
      currentBet: bet,
      selections: selectedNumbers,
      risk,
      tokenType,
      isTurbo,
    };
    tokenTypeRef.current = tokenType;
    if (playType === DICEMODE.MANUAL) {
      if (resultInterval.current) {
        clearInterval(resultInterval.current);
      }
      setKenoResult([]);
      showWinModalRef.current = true;
      playBetSound();
      socket?.emit(PLAY_KENO_GAME, {
        accessToken,
        gameData,
      });
      setDisableButton(true);
    }

    if (playType === DICEMODE.AUTO) {
      if (!isAutoPlaying) {
        setAutoPlayStatesRef({
          ...autoPlayStates,
          stopNetGain: 0,
          stopNetLoss: 0,
        });
        betRef.current = currentBet;
      }
      if (resultInterval.current) {
        clearInterval(resultInterval.current);
      }
      if (showWinModal) {
        setShowWinModal(false);
      }
      showWinModalRef.current = true;
      setKenoResult([]);
      setIsAutoPlaying(true);
      dispatch(updateKenoErrorMessage(""));
      playBetSound();
      socket?.emit(PLAY_KENO_GAME, {
        accessToken,
        gameData,
      });
    }

    setTimeout(
      () => {
        setDisableButton(false);
      },
      isTurbo ? 750 : 1200
    );
  };

  // useEffect(() => {
  //   if (keno) {
  //     if (isTurbo) {
  //       setKenoResult(keno?.results);
  //       if (keno.payout > keno.bet) {
  //         playDiamondRevealSound();
  //       } else {
  //         playEmptyTileSound();
  //       }
  //     } else {
  //       // setKenoResult([keno?.results[0]]);
  //       // isRunning.current = [];
  //       setIsAnimatingResult(true);
  //       resultInterval.current = setInterval(() => {
  //         setKenoResult((prev) => {
  //           // if (
  //           //   isRunning.current.length !==
  //           //   [...prev, keno.results[prev.length]].length
  //           // ) {
  //           //   return prev;
  //           // }
  //           if (keno.results.length !== prev.length) {
  //             const isSelected = keno.selection.includes(
  //               keno.results[prev.length]
  //             );
  //             if (isSelected) {
  //               playDiamondRevealSound();
  //             } else {
  //               playEmptyTileSound();
  //             }
  //             // isRunning.current = [...prev, keno.results[prev.length]];
  //             return [...prev, keno.results[prev.length]];
  //           } else {
  //             // isRunning.current = [];
  //             setIsAnimatingResult(false);

  //             clearInterval(resultInterval.current);
  //           }
  //           return prev;
  //         });
  //       }, 100);
  //     }
  //   }
  // }, [isTurbo, keno]);

  useEffect(() => {
    if (!keno) return;

    if (isTurbo) {
      setKenoResult(keno.results);
      const soundToPlay =
        keno.payout > keno.bet ? playDiamondRevealSound : playEmptyTileSound;
      soundToPlay();
    } else {
      setIsAnimatingResult(true);
      resultInterval.current = setInterval(() => {
        setKenoResult((prev) => {
          if (keno.results.length === prev.length) {
            setIsAnimatingResult(false);
            clearInterval(resultInterval.current);
            return prev;
          }

          const currentResult = keno.results[prev.length];
          const soundToPlay = keno.selection.includes(currentResult)
            ? playDiamondRevealSound
            : playEmptyTileSound;
          soundToPlay();

          return [...prev, currentResult];
        });
      }, 90);
    }

    return () => {
      clearInterval(resultInterval.current);
    };
  }, [isTurbo, keno]);

  // useEffect(() => {
  //   function validateMatches(data: any) {
  //     const invalidMatches: any = [];

  //     data.forEach((entry: any) => {
  //       // Calculate the actual number of matches between selection and results
  //       const actualMatchesResults = entry.selection.filter((num: any) =>
  //         entry.results.includes(num)
  //       );
  //       const actualMatches = actualMatchesResults.length;
  //       console.log(
  //         actualMatches,
  //         entry.matches,
  //         actualMatchesResults,
  //         entry.gameNonce
  //       );
  //       // Compare calculated matches with the stored matches
  //       if (actualMatches !== entry.matches) {
  //         invalidMatches.push({
  //           id: entry._id.$oid,
  //           user: entry.user.$oid,
  //           actualMatches: actualMatches,
  //           reportedMatches: entry.matches,
  //         });
  //       }
  //     });

  //     return invalidMatches;
  //   }

  //   function checkDuplicateResults(data: any) {
  //     const duplicates: any = [];

  //     data.forEach((entry: any) => {
  //       const seen = new Set();
  //       const duplicateNumbers = new Set();

  //       // Check for duplicates in results
  //       entry.results.forEach((num: any) => {
  //         if (seen.has(num)) {
  //           duplicateNumbers.add(num);
  //         } else {
  //           seen.add(num);
  //         }
  //       });

  //       // If duplicates are found, add the entry to the result
  //       if (duplicateNumbers.size > 0) {
  //         duplicates.push({
  //           id: entry._id.$oid,
  //           user: entry.user.$oid,
  //           duplicateNumbers: Array.from(duplicateNumbers),
  //         });
  //       }
  //     });

  //     return duplicates;
  //   }

  //   // Example usage
  //   const duplicateEntries = checkDuplicateResults(JsonDataKeno);
  //   console.log(duplicateEntries, "duplicate");

  //   const data = validateMatches(JsonDataKeno);
  //   console.log(data, "wrong number of matches");
  // }, []);

  useEffect(() => {
    if (pastResults.length && isAutoPlaying) {
      const getLastGame = pastResults[pastResults.length - 1];
      let shouldContinue = isLevelUp ? false : true;
      let newBet = currentBet;
      let letLoss = autoPlayStatesRef.stopNetLoss;
      let netGain = autoPlayStatesRef.stopNetLoss;

      //Stop when token changes
      if (tokenTypeRef.current !== tokenType) {
        shouldContinue = false;
      }

      // Check Number of Games
      if (autoPlayStates.numberOfGames) {
        if (autoPlayStatesRef.numberOfGames > 1) {
          setAutoPlayStatesRef({
            ...autoPlayStatesRef,
            numberOfGames: autoPlayStatesRef.numberOfGames - 1,
          });
        } else {
          shouldContinue = false;
        }
      }

      if (autoPlayStates.onWin && getLastGame.payout) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onWin / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onWin / 100)).toFixed(2)
        );
      }

      if (
        autoPlayStates.winStatus === SELECTIONSTATUS.RESET &&
        getLastGame.payout
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (
        autoPlayStates.lossStatus === SELECTIONSTATUS.RESET &&
        !getLastGame.payout
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (autoPlayStates.onLoss && !getLastGame.payout) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onLoss / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onLoss / 100)).toFixed(2)
        );
      }

      // Net Loss
      if (!getLastGame.payout && autoPlayStates.stopNetLoss) {
        letLoss = letLoss + getLastGame.bet;
        if (letLoss >= autoPlayStates.stopNetLoss) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetLoss: letLoss,
          stopNetGain: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      // Net Gain
      if (getLastGame.payout && autoPlayStates.stopNetGain) {
        netGain = netGain + (getLastGame.payout - getLastGame.bet);
        if (netGain >= autoPlayStates.stopNetGain) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetGain: netGain,
          stopNetLoss: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      if (
        shouldContinue &&
        ((currentBet <= player.balance &&
          getLastGame.tokenType === TOKENTYPE.SWEEP) ||
          (currentBet <= player.freeCash &&
            getLastGame.tokenType === TOKENTYPE.FREE))
      ) {
        playGame(newBet);
      } else {
        setIsAutoPlaying(false);
      }
    }
  }, [pastResults]);

  let autoPickerRef = useRef<any>();
  const addRandomNumber = () => {
    setSelectedNumbers((prev) => {
      if (prev.length < 10) {
        const randomNumber = generateSingleNumber(prev);
        playSelectSound();
        return [...prev, randomNumber];
      } else {
        clearInterval(autoPickerRef.current);
        setIsAutoPicking(false);
      }
      return prev;
    });
  };
  const autoPickNumbers = () => {
    setIsAutoPicking(true);
    addRandomNumber();
    autoPickerRef.current = setInterval(() => {
      addRandomNumber();
    }, 250);
  };

  const clearTable = () => {
    dispatch(resetKeno());
    setKenoResult([]);
    setSelectedNumbers([]);
  };

  const handleAddNumber = (index: number) => {
    if (selectedNumbers.includes(index)) {
      var indexToRemove = selectedNumbers.indexOf(index);
      if (indexToRemove !== -1) {
        var newSelectedNumbers = [...selectedNumbers];
        newSelectedNumbers.splice(indexToRemove, 1);
        setSelectedNumbers(newSelectedNumbers);
        playUnSelectSound();
      }
      return;
    }
    if (selectedNumbers.length < 10) {
      setSelectedNumbers([...selectedNumbers, index]);
      playSelectSound();
      return;
    }
  };

  useEffect(() => {
    if (errorMessage && isAutoPlaying) {
      setIsAutoPlaying(false);
    }
  }, [errorMessage, isAutoPlaying]);

  useEffect(() => {
    if (
      kenoResult.length === 10 &&
      keno &&
      keno?.payout > 1 &&
      showWinModalRef.current &&
      !errorMessage
    ) {
      setShowWinModal(true);
      // playWinSound();
    }

    // if (
    //   kenoResult.length === 10 &&
    //   keno &&
    //   keno?.payout <= 1 &&
    //   showWinModalRef.current &&
    //   !errorMessage
    // ) {
    //   playLossSound();
    // }
  }, [keno, kenoResult]);

  useEffect(() => {
    return () => {
      dispatch(resetKeno());
    };
  }, []);

  const highestPayout = kenoMultipliers[risk][selectedNumbers.length - 1];
  const payoutWithCurentBet = highestPayout
    ? highestPayout[(highestPayout?.length || 0) - 1] * currentBet
    : null;
  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        title="Bet Confirmation"
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          playGame(currentBet, false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          95
        )}
      />
      {showWinModal ? (
        <WinModal
          tokenType={keno?.tokenType}
          payout={keno?.payout || 0}
          multiplier={keno?.multiplier || 0}
          handleClose={() => {
            showWinModalRef.current = false;
            setShowWinModal(false);
          }}
        />
      ) : null}
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Keno
        </Typography>
        <Stack
          direction={{
            lg: "row",
            md: "column-reverse",
            sm: "column-reverse",
            xs: "column-reverse",
          }}
          gap={2}
          justifyContent="space-Between"
          // sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            sx={{
              width: { lg: "35%", MozTextDecorationLine: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack
                sx={{
                  display: { lg: "block", md: "none", sm: "block", xs: "none" },
                }}
              >
                <GameTypeButtons
                  playType={playType}
                  setPlayType={setPlayType}
                  isAutoPlaying={isAutoPlaying || isAnimatingResults}
                  setIsTurbo={setIsTurbo}
                  isTurbo={isTurbo}
                />
              </Stack>
              <Stack
                sx={{
                  display: {
                    lg: "none",
                    md: "block",
                    sm: "block",
                    xs: "block",
                  },
                }}
              >
                <BetButton
                  accessToken={accessToken}
                  disableButton={
                    disableButton ||
                    !toggle ||
                    isAutoPicking ||
                    !selectedNumbers.length
                  }
                  playGame={playGame}
                  playType={playType}
                  handleLogin={handleLogin}
                  stopPlaying={() => setIsAutoPlaying(false)}
                  isAutoPlaying={isAutoPlaying}
                />
                <Button
                  disabled={
                    isAutoPicking || isAutoPlaying || isAnimatingResults
                  }
                  fullWidth
                  sx={{ my: 1 }}
                  onClick={() => clearTable()}
                >
                  Clear Table
                </Button>
                <Button
                  fullWidth
                  disabled={isAutoPicking || isAutoPlaying}
                  onClick={() => autoPickNumbers()}
                >
                  Auto Pick
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  disabled={isAutoPlaying || isAnimatingResults}
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.KENO}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={!accessToken || isAutoPlaying || isAnimatingResults}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  title={title}
                  risk={risk}
                  multiplier={
                    payoutWithCurentBet ? payoutWithCurentBet / currentBet : 1
                  }
                  maxPayout={payoutWithCurentBet}
                  balance={player.balance}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    disabled={isAutoPlaying || isAnimatingResults}
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                  >
                    x2
                  </Button>
                  <Button
                    disabled={isAutoPlaying || isAnimatingResults}
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" gap={1.25}>
                <DiceAutoButtons
                  playType={playType}
                  autoPlayStates={autoPlayStates}
                  setAutoPlayStates={setAutoPlayStates}
                  isAutoPlaying={isAutoPlaying}
                  currentNumberOfGames={autoPlayStatesRef.numberOfGames}
                />

                <Stack>
                  <RiskButtons
                    playType={risk}
                    setPlayType={setRisk}
                    disabled={isAutoPlaying || isAnimatingResults}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                <Stack
                  sx={{
                    display: {
                      lg: "block",
                      md: "none",
                      sm: "none",
                      xs: "none",
                    },
                  }}
                  gap={1}
                >
                  <Button
                    disabled={
                      isAutoPicking || isAutoPlaying || isAnimatingResults
                    }
                    fullWidth
                    sx={{ mb: 1 }}
                    onClick={() => clearTable()}
                  >
                    Clear Table
                  </Button>
                  <Button
                    fullWidth
                    sx={{ mb: 1 }}
                    disabled={
                      isAutoPicking || isAutoPlaying || isAnimatingResults
                    }
                    onClick={() => autoPickNumbers()}
                  >
                    Auto Pick
                  </Button>
                  <BetButton
                    accessToken={accessToken}
                    disableButton={
                      disableButton ||
                      !toggle ||
                      isAutoPicking ||
                      !selectedNumbers.length ||
                      isAnimatingResults
                    }
                    playGame={playGame}
                    playType={playType}
                    handleLogin={handleLogin}
                    stopPlaying={() => setIsAutoPlaying(false)}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>

                <Stack
                  sx={{ display: { md: "none", sm: "block", xs: "block" } }}
                >
                  <GameTypeButtons
                    playType={playType}
                    setPlayType={setPlayType}
                    isAutoPlaying={isAutoPlaying}
                    setIsTurbo={setIsTurbo}
                    isTurbo={isTurbo}
                  />
                </Stack>
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                {!toggle && (
                  <Typography variant="h6" color="error">
                    {DEFAULT_DISABLED_MESSAGE}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <GameOptions game={GAMES.KENO} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { lg: "80%", md: "auto" },
            }}
            gap={{ lg: 0, md: 4, sm: 4, xs: 4 }}
            justifyContent="space-between"
          >
            <div className={styles.grid}>
              {Array(40)
                .fill(1)
                .map((item, index) => {
                  const key = index;
                  const isSelected = selectedNumbers.includes(index + 1);
                  const resultsCompleted = kenoResult.length === 10;
                  const isKenoNumber = kenoResult?.includes(index + 1);
                  const isPrevSelection = keno?.selection?.includes(index + 1);

                  return (
                    <div
                      key={`${item}-${index}-${kenoResult.length}-${isKenoNumber}-${resultsCompleted}`}
                      data-incorrect={
                        isPrevSelection &&
                        !isKenoNumber &&
                        isSelected &&
                        resultsCompleted
                      }
                      data-correct={
                        isPrevSelection && isSelected && isKenoNumber
                      }
                      data-selected={isSelected}
                      data-iskeno={isKenoNumber}
                      className={styles.tileContainer}
                      onClick={() => handleAddNumber(index + 1)}
                    >
                      <Stack className={styles.tile} alignItems="center">
                        {isPrevSelection && isSelected && isKenoNumber ? (
                          <Stack
                            sx={{
                              backgroundImage: `url(${DiamondImg})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              width: { sm: "4em", xs: "1.5em" },
                              height: { sm: "4em", xs: "1.5em" },
                            }}
                          >
                            <Typography
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%,-50%)",
                                marginBottom: "2px",
                                fontSize: {
                                  md: "1.5rem",
                                  sm: "1.5rem",
                                  xs: "1rem",
                                },
                              }}
                              color="text.primary"
                              variant="h6"
                            >
                              {index + 1}
                            </Typography>
                          </Stack>
                        ) : (
                          <Typography
                            sx={{
                              fontSize: {
                                md: "1.5rem",
                                sm: "1.5rem",
                                xs: "1rem",
                              },
                            }}
                            key={key}
                            color="text.primary"
                            variant="h6"
                          >
                            {index + 1}
                          </Typography>
                        )}
                      </Stack>
                    </div>
                  );
                })}
            </div>
            <Payouts
              currentBet={currentBet}
              selectedNumbers={selectedNumbers}
              riskType={risk}
              kenoResult={kenoResult}
              keno={keno}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Keno;
