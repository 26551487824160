import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Card } from "../blackjack/constants";
import BlackJackServices from "../../services/blackjack";
import { TOKENTYPE } from "../../types/index.d";

export enum BLACKGAMESTATE {
  INIT = "INIT",
  START = "START",
  END = "END",
}

interface BlackJackModel {
  // user: string;
  _id: string;
  bet: number;
  payout: number;
  events: string[];
  clientSeed: string;
  serverSeed: string;
  gameNonce: number;
  isTurnEnd: boolean;
  currentState: string;
  currentPlayerHandIndex: number;
  deck: Card[];
  dealersHand: Card[];
  playersHands: Card[][];
  hasSplit: boolean;
  multiplier: number;
  isWin: boolean;
  perfectPairSideBet: number;
  perfectPairSideBetPayout?: {
    typeOfBet: string;
    payout: number;
  };
  tokenType: TOKENTYPE;
  otherSideBet: number;
  otherSideBetPayout?: {
    typeOfBet: string;
    payout: number;
  };

  insurance: {
    bet: number;
    isShown: boolean;
  };
}
interface InitialState {
  game: BlackJackModel | null;
  errorMessage: string;
  loading: boolean;
}

// Define initial state
const initialState: InitialState = {
  game: null,
  errorMessage: "",
  loading: false,
};

export const getCurrentGame = createAsyncThunk(
  "blackJack/currentGame",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const accessToken = state.user.accessToken;
    try {
      const mine = await BlackJackServices.getGame(accessToken);
      return mine;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

// Define slice
export const blackJackReducer = createSlice({
  name: "blackJack",
  initialState,
  reducers: {
    updateBlackjackGame(state, action: PayloadAction<any>) {
      state.game = action.payload;
      state.errorMessage = "";
      state.loading = false;
    },
    clearBlackJackGame(state) {
      state.game = null;
    },
    updateBlackjackErrorMessage(state, action: PayloadAction<string>) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    updateLoading(state) {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentGame.fulfilled, (state, action) => {
        state.game = action.payload;
        state.loading = false;
      })
      .addCase(getCurrentGame.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCurrentGame.pending, (state) => {
        state.loading = true;
      });
  },
});

export const {
  updateBlackjackGame,
  updateBlackjackErrorMessage,
  clearBlackJackGame,
  updateLoading,
} = blackJackReducer.actions;

// Export actions and reducer
export const selectBlackJackGame = (state: RootState) => state.blackJack.game;
export const selectBlackJackError = (state: RootState) =>
  state.blackJack.errorMessage;

export const selectBlackJackLoading = (state: RootState) =>
  state.blackJack.loading;

export default blackJackReducer.reducer;
