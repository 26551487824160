import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  Typography,
  Box,
  useMediaQuery,
  Card,
  IconButton,
} from "@mui/material";
import SlotServices from "../../services/slots";
import { Button } from "../button";
import {
  addSlotsToRecentlyPlayed,
  getImageEndpoint,
} from "../../constants/slots";
import { useAppSelector } from "../store/hooks";
import {
  openModal,
  selectBGamingToggle,
  selectToken,
  selectTokenSelection,
  updateIsPlayingSlots,
  updateTokenSelection,
} from "../slices/userSlice";
import { MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { selectSlotGames } from "../slices/slotsSlice";
import PanoramaWideAngleOutlinedIcon from "@mui/icons-material/PanoramaWideAngleOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import { theme } from "../theme";
import { useDispatch } from "react-redux";
import { CustomSwitch } from "../accountTabs/settings";
import { FavouriteButton } from "../gameOptions";

const Slots = ({ setIsTheater, isTheater }: any) => {
  const pathname = window.location.pathname;
  const slotName = pathname.split("/").pop();
  const [loading, setLoading] = useState(false);
  const [gameData, setGameData] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const token = useAppSelector(selectToken);
  const tokenSelection = useAppSelector(selectTokenSelection);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const slotGames = useAppSelector(selectSlotGames);
  const matchesMD = useMediaQuery(theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const [isDemo, setIsDemo] = useState(false);
  const [currentGameCurrency, setCurrentGameCurrency] =
    useState<TOKENTYPE | null>(null);
  const currentGameSelection = slotGames.find(
    (game) => game.identifier === slotName
  );
  const toggle = useAppSelector(selectBGamingToggle);
  const handleClickDemo = async () => {
    try {
      setLoading(true);
      if (loading) return;

      const response = await SlotServices.startDemo(
        slotName as string,
        matchesMD ? "desktop" : "mobile"
      );
      if (response) {
        setGameData(response);
        if (slotName) {
          addSlotsToRecentlyPlayed(slotName);
        }
      }
    } catch (e: any) {
      if (e?.message) {
        setErrorMessage(e.message);
      }
    } finally {
      setIsDemo(true);
      setLoading(false);
    }
  };

  const handleClickGame = async (
    tokenSelected = tokenSelection,
    isSwitch = false
  ) => {
    if (loading || !toggle) return;

    if (!gameData && isSwitch) {
      dispatch(updateTokenSelection(tokenSelected));
      return;
    }
    if (tokenSelected !== tokenSelection) {
      dispatch(updateTokenSelection(tokenSelected));
    }
    try {
      setLoading(true);
      if (loading) return;
      const response = await SlotServices.startSlotsGame(
        token,
        tokenSelected,
        slotName as string,
        matchesMD ? "desktop" : "mobile"
      );
      if (response) {
        setGameData(response);
        if (slotName) {
          addSlotsToRecentlyPlayed(slotName);
        }
        // setCurrentGameCurrency(tokenSelected);
      }
    } catch (e: any) {
      if (e?.message) {
        setErrorMessage(e.message);
      }
    } finally {
      setIsDemo(false);
      setLoading(false);
    }
  };

  const handleClickGameTest = async () => {
    try {
      setLoading(true);
      if (loading) return;
      const response = await SlotServices.startSlotsTestGame(
        token,
        tokenSelection as TOKENTYPE,
        slotName as string
      );
      if (response) {
        setGameData(response);
      }
    } catch (e: any) {
      if (e?.message) {
        setErrorMessage(e.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  const handleFullScreen = () => {
    if (!matchesMD) {
      setIsTheater(!isTheater);
      if (iframeRef.current) {
        iframeRef.current.style.overflow = "auto";

        if (!isTheater === true) {
          window.scrollTo({
            top: 10,
            behavior: "smooth",
          });
        }
      }
      return;
    }
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      }
    }
  };

  useEffect(() => {
    if (gameData?.launch_options?.game_url) {
      dispatch(updateIsPlayingSlots(true));
    }
    if (!gameData) {
      dispatch(updateIsPlayingSlots(false));
    }

    return () => {
      dispatch(updateIsPlayingSlots(false));
    };
  }, [gameData]);
  return (
    <>
      <Stack gap={2}>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-between"
        >
          <Box
            sx={{
              width: "100%",
              position: "relative",
              overflow: { md: "hidden", xs: isTheater ? "visible" : "hidden" },
            }}
          >
            <Card
              sx={{
                overflow: {
                  md: "hidden",
                  xs: isTheater ? "visible" : "hidden",
                },
              }}
            >
              {gameData && gameData.launch_options?.game_url ? (
                <>
                  <Box
                    sx={{
                      height: {
                        md: isTheater ? "700px" : "600px",
                        xs: isTheater ? "84vh" : "600px",
                      },

                      width: {
                        md: "100%",
                        xs: isTheater ? "99vw" : "100%",
                      },
                      position: "relative",
                      zIndex: {
                        md: 1,
                        xs: isTheater ? 100 : 1,
                      },
                      overflow: {
                        md: "hidden",
                        xs: isTheater ? "visible" : "hidden",
                      },
                      right: { xs: isTheater ? "22px" : "0px", md: 0 },
                    }}
                  >
                    <iframe
                      ref={iframeRef}
                      src={gameData.launch_options.game_url}
                      style={{
                        // position: isTheater && !matchesMD ? "absolute" : "relative",
                        width: "100%",
                        height: "100%",
                        border: "none",
                      }}
                      title="Game"
                    />
                  </Box>
                </>
              ) : (
                <Stack
                  sx={{
                    height: {
                      md: isTheater ? (matchesMD ? "700px" : "90vh") : "600px",
                      xs: "500px",
                    },
                  }}
                  alignItems="center"
                >
                  <Stack
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 100,
                      width: "300px",
                    }}
                    gap={2}
                    direction="row"
                  >
                    <Button
                      fullWidth
                      onClick={handleClickDemo}
                      variantType="success"
                    >
                      Demo
                    </Button>
                    {token ? (
                      <Button
                        fullWidth
                        disabled={!toggle || loading}
                        onClick={() => handleClickGame(tokenSelection)}
                        variantType="success"
                      >
                        {tokenSelection === TOKENTYPE.FREE
                          ? "Fun Play"
                          : "Real Play"}
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        onClick={handleLogin}
                        variantType="success"
                      >
                        Login
                      </Button>
                    )}

                    {/* <Button fullWidth onClick={handleClickGameTest}>
                    Test
                  </Button> */}
                  </Stack>
                  <img
                    src={
                      matchesMD
                        ? getImageEndpoint(currentGameSelection, "s2")
                        : getImageEndpoint(currentGameSelection, "s3")
                    }
                    style={{
                      width: isTheater ? "80%" : "100%",
                      opacity: 0.75,
                      height: "100%",
                    }}
                  />
                </Stack>
              )}

              <Stack
                sx={{ p: 3 }}
                gap={2}
                direction={{ md: "row", sm: "column" }}
                justifyContent="space-between"
              >
                <Stack
                  gap={4}
                  direction="row"
                  alignItems="center"
                  justifyContent={{
                    md: "flex-start",
                    sm: "flex-start",
                    xs: "flex-start",
                  }}
                >
                  {/* <Stack
                    direction="row"
                    sx={{ height: matchesMD ? "3em" : "2.5em" }}
                  >
                    <img
                      src={matchesMD ? LogoImg : LogoImgMobile}
                      alt="logo-img"
                      style={{ cursor: "pointer" }}
                    />
                  </Stack> */}
                  <Stack>
                    <Typography
                      variant="h5"
                      fontWeight={600}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {currentGameSelection?.title || ""}
                    </Typography>
                    <Typography fontWeight={400}>{"BGaming"}</Typography>
                  </Stack>
                </Stack>

                <Stack
                  gap={3}
                  direction="row"
                  alignItems="center"
                  justifyContent={{
                    md: "flex-start",
                    sm: "space-around",
                    xs: "space-around",
                  }}
                >
                  <Stack
                    direction="row"
                    gap={0.5}
                    sx={{
                      mt: { xs: 1, md: 0 },
                    }}
                  >
                    {currentGameSelection?.identifier ? (
                      <FavouriteButton
                        game={`Bgaming_${currentGameSelection?.identifier}`}
                      />
                    ) : null}
                    <IconButton
                      sx={{
                        display: { md: gameData ? "flex" : "none", xs: "none" },
                        color: "white",
                      }}
                      onClick={() => setIsTheater(!isTheater)}
                    >
                      <PanoramaWideAngleOutlinedIcon />
                    </IconButton>

                    <IconButton
                      sx={{
                        color: "white",
                        display: gameData ? "flex" : "none",
                      }}
                      disabled={!gameData}
                      onClick={() => {
                        if (!gameData) return;
                        handleFullScreen();
                      }}
                    >
                      <FullscreenOutlinedIcon />
                    </IconButton>

                    {/* <FullscreenOutlinedIcon
                      sx={{
                        color: !gameData
                          ? theme.palette.text.primary
                          : theme.palette.text.primary,
                        opacity: !gameData ? 0.5 : 1,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (!gameData) return;
                        handleFullScreen();
                      }}
                    /> */}
                  </Stack>

                  <Stack
                    direction="row"
                    gap={1}
                    sx={{ width: "100%" }}
                    alignItems="center"
                  >
                    <Typography variant="h6" fontWeight={600}>
                      {" "}
                      FC
                    </Typography>
                    <CustomSwitch
                      color="secondary"
                      checked={
                        token ? tokenSelection === TOKENTYPE.SWEEP : false
                      }
                      // disabled={isDemo || !token || !toggle}
                      disabled={!token || !toggle}
                      sx={{
                        pointerEvent: loading || isDemo ? "none" : "auto",
                        opacity: loading || isDemo ? 0.5 : 1,
                      }}
                      onChange={(_e, value) =>
                        handleClickGame(
                          value === true ? TOKENTYPE.SWEEP : TOKENTYPE.FREE,
                          true
                        )
                      }
                    />
                    <Typography variant="h6" fontWeight={600}>
                      {" "}
                      SC
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Card>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default Slots;
