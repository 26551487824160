import { FC, useState, useEffect, useRef } from "react";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Typography,
  Stack,
  TablePagination,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { TokenIcon } from "../../components/tokenIcon";
import { useAppSelector } from "../../components/store/hooks";
import {
  selectClientSeed,
  selectToken,
} from "../../components/slices/userSlice";
import Fairness from "../../services/fairness";
import { FAIRNESSGAMES } from ".";
import { Button } from "../../components/button";
import { theme } from "../../components/theme";
import { format } from "date-fns";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { isBlackJack } from "../../components/blackjack/constants";
import { TOKENTYPE } from "../../types/index.d";
interface WagerRaceTableProps {
  selection: FAIRNESSGAMES;
  setSelectedGame: any;
  selectedGame: any;
}

const FairnessTable: FC<WagerRaceTableProps> = ({
  selection,
  setSelectedGame,
  selectedGame,
}) => {
  const [data, setData] = useState<any[]>([]);
  const accesToken = useAppSelector(selectToken);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const selectionRef = useRef(selection);
  const gameRef = useRef<null | string>(null);
  const clientSeed = useAppSelector(selectClientSeed);

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await Fairness.get(accesToken, selection, page + 1);
      setTotalPages(result.totalPages);
      setData(result.games);

      if (result.games && gameRef.current) {
        const currentGame = result.games.find(
          (game: any) => (game._id = gameRef.current)
        );
        gameRef.current = null;
        if (currentGame) {
          setSelectedGame(currentGame);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (selectedGame) {
      gameRef.current = selectedGame._id;
    }
    fetchData();
  }, [clientSeed]);

  useEffect(() => {
    selectionRef.current = selection;
    if (page !== 0) {
      setPage(0);
    }
    fetchData();
  }, [selection]);

  const onHandleVerify = (game: any) => {
    setSelectedGame(game);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const isSlide = selection === FAIRNESSGAMES.SLIDE;
  const isBlackJackGame = selection === FAIRNESSGAMES.BLACKJACK;

  return (
    <Card>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#292929" }}>
              <TableCell
                align="center"
                sx={{
                  fontWeight: "bold",
                  borderColor: "#292929",
                  backgroundColor: "transparent",
                }}
              >
                Bet
              </TableCell>
              <TableCell
                align={isSlide ? "center" : "center"}
                sx={{
                  fontWeight: "bold",
                  borderColor: "#292929",
                  backgroundColor: "transparent",
                }}
              >
                {isSlide ? "Date" : "Payout"}
              </TableCell>
              <TableCell
                align={isSlide ? "center" : "center"}
                sx={{
                  fontWeight: "bold",
                  borderColor: "#292929",
                  backgroundColor: "transparent",
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ position: "relative" }}>
            {data && !loading ? (
              <>
                {data?.map((promo, index) => {
                  let totalBet = promo?.bet;
                  let totalPayout = promo?.payout;
                  if (isBlackJackGame) {
                    totalBet =
                      promo?.bet +
                      (promo?.insurance?.bet || 0) +
                      (promo?.perfectPairSideBet || 0) +
                      (promo?.otherSideBet || 0) +
                      (promo?.isSplitBet || 0);

                    totalPayout =
                      (promo?.isWin ? promo?.payout : 0) +
                      (promo?.perfectPairSideBetPayout?.payout || 0) +
                      (promo?.otherSideBetPayout?.payout || 0) +
                      (promo?.isSplitWin ? promo?.isSplitBet * 2 : 0);

                    if (promo?.insurance) {
                      if (isBlackJack(promo?.dealersHand)) {
                        totalPayout += promo?.insurance?.bet * 2;
                      }
                    }
                  }

                  return (
                    <TableRow
                      key={promo?.userId}
                      sx={{
                        backgroundColor: index % 2 ? "#19191A" : "#202020",
                      }}
                    >
                      <TableCell sx={{ borderColor: "#292929" }}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={1}
                          justifyContent="center"
                        >
                          <TokenIcon
                            freeCash={promo?.tokenType === TOKENTYPE.FREE}
                          />
                          <Typography
                            variant="body1"
                            color="text.primary"
                            noWrap
                          >
                            {totalBet}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell
                        sx={{ borderColor: "#292929" }}
                        align={isSlide ? "center" : "center"}
                      >
                        {!isSlide ? (
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            justifyContent="center"
                          >
                            <TokenIcon
                              freeCash={promo?.tokenType === TOKENTYPE.FREE}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                color:
                                  promo.isWin || totalBet < totalPayout
                                    ? theme.palette.success.main
                                    : theme.palette.error.main,
                              }}
                              noWrap
                            >
                              {totalPayout}
                            </Typography>
                          </Stack>
                        ) : (
                          <Typography
                            variant="body1"
                            color="text.primary"
                            noWrap
                          >
                            {format(new Date(promo.createdAt), "MMM d, p")}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell
                        sx={{
                          borderColor: "#292929",
                          display: { xs: "none", md: "table-cell" },
                        }}
                        align={isSlide ? "center" : "center"}
                      >
                        <Button
                          variantType="success"
                          onClick={() => onHandleVerify(promo)}
                          disabled={selectedGame?._id === promo._id}
                        >
                          Verify
                        </Button>
                      </TableCell>
                      <TableCell
                        sx={{
                          borderColor: "#292929",
                          display: { xs: "table-cell", md: "none" },
                        }}
                        align={isSlide ? "center" : "center"}
                      >
                        {}
                        <IconButton
                          // variantType="success"
                          sx={{ color: "white" }}
                          onClick={() => onHandleVerify(promo)}
                          disabled={selectedGame?._id === promo._id}
                        >
                          {selectedGame?._id === promo._id ? (
                            <CheckBoxIcon sx={{ color: "#32C932" }} />
                          ) : (
                            <CheckBoxOutlineBlankIcon
                              sx={{ color: "#32C932" }}
                            />
                          )}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <Stack sx={{ width: "100%", p: 4 }} alignItems="center">
                <CircularProgress
                  sx={{
                    color: "#33EC3D",
                    position: "absolute",
                    right: "50%",
                  }}
                />
              </Stack>
            )}
          </TableBody>
        </Table>
        <TablePagination
          labelRowsPerPage=""
          component="div"
          count={totalPages}
          rowsPerPage={5}
          page={page}
          onPageChange={handleChangePage}
          sx={{
            "& .MuiSelect-select": {
              display: "none !important",
            },
            "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
              display: "none !important",
            },
          }}
        />
      </TableContainer>
    </Card>
  );
};

export default FairnessTable;
