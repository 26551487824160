import { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../store/hooks";
import {
  selectAllGameFeed,
  selectAllHighRollerFeed,
  selectMyBets,
} from "../slices/gameFeeds";
import FeedTable from "./feedTable";
import { Card, Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { getBg, getColor } from "./descWrapper";

enum FEEDSELECTION {
  FEED,
  HIGHROLLER,
  MYBETS,
}

const GameFeed = () => {
  const [selection, setSelection] = useState(0);

  const allGameFeed = useAppSelector(selectAllGameFeed);
  const [isEven, setIsEven] = useState(0);
  const allGameFeedRef = useRef(allGameFeed);

  const allHighRollerFeed = useAppSelector(selectAllHighRollerFeed);
  const [highRollerIsEven, setHighRollerIsEven] = useState(0);
  const allHighRollerFeedRef = useRef(allHighRollerFeed);

  const myBets = useAppSelector(selectMyBets);
  const [myBetsIsEven, setMyBetsIsEven] = useState(0);
  const myBetsRef = useRef(myBets);

  useEffect(() => {
    if (
      allGameFeedRef.current?.length &&
      allGameFeed?.length &&
      allGameFeedRef.current[0]._id !== allGameFeed[0]._id
    ) {
      setIsEven((prev) => (prev === 0 ? 1 : 0));
      allGameFeedRef.current = allGameFeed;
    }
  }, [allGameFeed]);

  useEffect(() => {
    if (
      allHighRollerFeedRef.current?.length &&
      allHighRollerFeed?.length &&
      allHighRollerFeedRef.current[0]._id !== allHighRollerFeed[0]._id
    ) {
      setHighRollerIsEven((prev) => (prev === 0 ? 1 : 0));
      allHighRollerFeedRef.current = allHighRollerFeed;
    }
  }, [allHighRollerFeed]);

  useEffect(() => {
    if (
      myBetsRef.current?.length &&
      myBets?.length &&
      myBetsRef.current[0]._id !== myBets[0]._id
    ) {
      setMyBetsIsEven((prev) => (prev === 0 ? 1 : 0));
      myBetsRef.current = myBets;
    }
  }, [myBets]);

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newAlignment: number
  ) => {
    setSelection(Number(newAlignment));
  };
  return (
    <Card sx={{ p: 1, my: 3 }}>
      <Stack>
        <ToggleButtonGroup
          size="medium"
          value={selection}
          exclusive
          onChange={handleChange}
          sx={{ boxShadow: 3 }}
          aria-label="Platform"
        >
          <ToggleButton
            sx={{
              width: "100%",
              color: getColor(selection === FEEDSELECTION.FEED),
              backgroundColor: getBg(selection === FEEDSELECTION.FEED),
            }}
            value={FEEDSELECTION.FEED}
          >
            Feed
          </ToggleButton>
          <ToggleButton
            sx={{
              width: "100%",
              color: getColor(selection === FEEDSELECTION.HIGHROLLER),
              backgroundColor: getBg(selection === FEEDSELECTION.HIGHROLLER),
            }}
            value={FEEDSELECTION.HIGHROLLER}
          >
            HighRollers
          </ToggleButton>
          <ToggleButton
            sx={{
              width: "100%",
              color: getColor(selection === FEEDSELECTION.MYBETS),
              backgroundColor: getBg(selection === FEEDSELECTION.MYBETS),
            }}
            value={FEEDSELECTION.MYBETS}
          >
            My bets
          </ToggleButton>
        </ToggleButtonGroup>
        <Stack sx={{ p: 1, pt: 0 }}>
          {selection === FEEDSELECTION.FEED && (
            <FeedTable feed={allGameFeed || []} isEven={isEven} />
          )}

          {selection === FEEDSELECTION.HIGHROLLER && (
            <FeedTable
              feed={allHighRollerFeed || []}
              isEven={highRollerIsEven}
            />
          )}

          {selection === FEEDSELECTION.MYBETS && (
            <FeedTable feed={myBets || []} isEven={myBetsIsEven} />
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

export default GameFeed;
