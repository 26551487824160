import { PLINKORISK } from "../constants";

const engine = {
  engineGravity: 1.6,
};

const world = {
  width: 600,
  height: 600,
};

export const config = {
  engine,
  world,
};

export const multiplierss = {
  Low: [
    [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6],
    // [5.6, 2.1, 1.1, 1, 0.5, 1, 1.1, 2.1, 5.6, 1],
    [10, 3, 1.6, 1.4, 1.1, 1, 0.5, 1, 1.1, 1.4, 1.6, 3, 10],
    [16, 9, 2, 1.4, 1.4, 1.2, 1.1, 1, 0.5, 1, 1.1, 1.2, 1.4, 1.4, 2, 9, 16],
  ],
  Medium: [
    [13, 3, 1.3, 0.7, 0.4, 0.7, 1.3, 3, 13],
    [33, 11, 4, 2, 1.1, 0.6, 0.3, 0.6, 1.1, 2, 4, 11, 33],
    [110, 41, 10, 5, 3, 1.5, 1, 0.5, 0.3, 0.5, 1, 1.5, 3, 5, 10, 41, 110],
  ],
  High: [
    [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29],
    [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 1.2, 8, 24, 170],
    [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000],
  ],
  Crazy: [
    [29, 4, 1.5, 0.3, 0.2, 0.3, 1.5, 4, 29],
    [170, 24, 8.1, 2, 0.7, 0.2, 0.2, 0.2, 0.7, 1.2, 8, 24, 170],
    [1000, 130, 26, 9, 4, 2, 0.2, 0.2, 0.2, 0.2, 0.2, 2, 4, 9, 26, 130, 1000],
  ],
};
export const multiplier = {
  [PLINKORISK.LOW]: [
    [5.6, 2.1, 1.1, 1, 0.4, 1, 1.1, 2.1, 5.6],
    [5.6, 2, 1.4, 1, 0.7, 0.7, 1, 1.4, 2, 5.6],
    [9, 3, 1.4, 1.1, 1, 0.4, 1, 1.1, 1.4, 3, 9],
    [9, 3, 1.7, 1.2, 1, 0.7, 0.7, 1, 1.2, 1.7, 3, 9],
    [10, 3, 1.6, 1.4, 1.1, 1, 0.4, 1, 1.1, 1.4, 1.6, 3, 10],
    [9, 4, 3, 2, 1.2, 0.8, 0.7, 0.7, 0.8, 1.2, 2, 3, 4, 9],
    [7.1, 4, 1.9, 1.4, 1.3, 1.1, 1, 0.4, 1, 1.1, 1.3, 1.4, 1.9, 4, 7.1],
    [15, 8, 3, 2, 1.4, 1.2, 1, 0.6, 0.6, 1, 1.2, 1.4, 2, 3, 8, 15],
    [16, 9, 2, 1.4, 1.3, 1.2, 1.1, 1, 0.4, 1, 1.1, 1.2, 1.3, 1.4, 2, 9, 16],
  ],
  [PLINKORISK.MEDIUM]: [
    [12, 3, 1.2, 0.7, 0.4, 0.7, 1.2, 3, 12],
    [18, 4, 1.7, 0.8, 0.5, 0.5, 0.8, 1.7, 4, 18],
    [19, 5, 2, 1.3, 0.6, 0.4, 0.6, 1.3, 2, 5, 19],
    [24, 6, 3, 1.8, 0.6, 0.5, 0.5, 0.6, 1.8, 3, 6, 24],
    [33, 11, 4, 2, 1, 0.6, 0.3, 0.6, 1, 2, 4, 11, 33],
    [43, 13, 6, 2.6, 1.3, 0.7, 0.4, 0.4, 0.7, 1.3, 2.6, 6, 13, 43],
    [58, 15, 7, 4, 1.7, 1, 0.5, 0.2, 0.5, 1, 1.7, 4, 7, 15, 58],
    [88, 18, 11, 4, 3, 1.3, 0.5, 0.3, 0.3, 0.5, 1.3, 3, 4, 11, 18, 88],
    [110, 41, 10, 4, 3, 1.4, 1, 0.5, 0.3, 0.5, 1, 1.4, 3, 4, 10, 41, 110],
  ],
  [PLINKORISK.HIGH]: [
    [28, 4, 1.4, 0.3, 0.2, 0.3, 1.4, 4, 28],
    [43, 7, 2, 0.5, 0.2, 0.2, 0.5, 2, 7, 43],
    [74, 10, 2.7, 0.9, 0.3, 0.2, 0.3, 0.9, 2.7, 10, 74],
    [120, 12, 5, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 5, 12, 120],
    [170, 23, 8, 2, 0.6, 0.2, 0.2, 0.2, 0.6, 2, 8, 23, 170],
    [260, 27, 11, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 11, 27, 260],
    [420, 53, 18, 5, 1.7, 0.3, 0.2, 0.2, 0.2, 0.3, 1.7, 5, 18, 53, 420],
    [620, 83, 26, 7, 3, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 3, 7, 26, 83, 620],
    [
      1000, 140, 22, 9, 4, 1.8, 0.2, 0.2, 0.2, 0.2, 0.2, 1.8, 4, 9, 22, 140,
      1000,
    ],
  ],
  [PLINKORISK.CRAZY]: [
    [33, 3, 1.5, 0.3, 0.2, 0.3, 1.5, 3, 33],
    [52, 6, 2, 0.5, 0.2, 0.2, 0.5, 2, 6, 52],
    [100, 9, 2.6, 0.8, 0.3, 0.2, 0.3, 0.8, 2.6, 9, 100],
    [150, 15, 3.8, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 3.8, 15, 150],
    [200, 26, 8, 1.7, 0.6, 0.2, 0.2, 0.2, 0.6, 1.7, 8, 26, 200],
    [333, 33, 9, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 9, 33, 333],
    [525, 56, 15, 5, 1.8, 0.3, 0.2, 0.2, 0.2, 0.3, 1.8, 5, 15, 56, 525],
    [888, 108, 32, 5, 2.7, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 2.7, 5, 32, 108, 888],
    [
      10000, 166, 18, 6, 2.4, 0.6, 0.2, 0.2, 0.2, 0.2, 0.2, 0.6, 2.4, 6, 18,
      166, 10000,
    ],
  ],
};

const audioData = [
  [33, 3, 1.5, 0.3, 0.2, 0.3, 1.5, 3, 33],
  [52, 6, 2, 0.5, 0.2, 0.2, 0.5, 2, 6, 52],
  [100, 9, 2.6, 0.8, 0.3, 0.2, 0.3, 0.8, 2.6, 9, 100],
  [150, 15, 3.8, 1.4, 0.4, 0.2, 0.2, 0.4, 1.4, 3.8, 15, 150],
  [200, 26, 8, 1.7, 0.6, 0.2, 0.2, 0.2, 0.6, 1.7, 8, 26, 200],
  [333, 33, 9, 4, 1, 0.2, 0.2, 0.2, 0.2, 1, 4, 9, 33, 333],
  [525, 56, 15, 5, 1.8, 0.3, 0.2, 0.2, 0.2, 0.3, 1.8, 5, 15, 56, 525],
  [888, 108, 32, 5, 2.7, 0.5, 0.2, 0.2, 0.2, 0.2, 0.5, 2.7, 5, 32, 108, 888],
  [
    10000, 166, 18, 6, 2.4, 0.6, 0.2, 0.2, 0.2, 0.2, 0.2, 0.6, 2.4, 6, 18, 166,
    10000,
  ],
];

export const color = [
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ffc000", shadow: "#ab7900" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff2035", shadow: "#a60000" },
    { bg: "#ff302f", shadow: "#a80100" },
    { bg: "#ff6020", shadow: "#aa4b00" },
    { bg: "#ffa00b", shadow: "#ab7900" },
    { bg: "#ffa00b", shadow: "#aa4b00" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff2035", shadow: "#a60004" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff2035", shadow: "#a60000" },
    { bg: "#ff402a", shadow: "#a60000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff8015", shadow: "#a93a00" },
    { bg: "#ffa00b", shadow: "#aa5b00" },
    { bg: "#ff8015", shadow: "#a93a00" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff402a", shadow: "#a60000" },
    { bg: "#ff2035", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff2035", shadow: "#a60000" },
    { bg: "#ff402a", shadow: "#a60000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff8015", shadow: "#a93a00" },
    { bg: "#ffa00b", shadow: "#aa5b00" },
    { bg: "#ffa00b", shadow: "#aa5b00" },
    { bg: "#ff8015", shadow: "#a93a00" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff402a", shadow: "#a60000" },
    { bg: "#ff2035", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ffa808", shadow: "#aa6300" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ffa808", shadow: "#aa6300" },
    { bg: "#ffa808", shadow: "#aa6300" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
  [
    { bg: "#ff003f", shadow: "#a60004" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff9010", shadow: "#aa6300" },
    { bg: "#ffa808", shadow: "#aa6300" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff9010", shadow: "#aa4b00" },
    { bg: "#ff7818", shadow: "#a93000" },
    { bg: "#ff6020", shadow: "#a80100" },
    { bg: "#ff4827", shadow: "#a70000" },
    { bg: "#ff302f", shadow: "#a60000" },
    { bg: "#ff1837", shadow: "#a60000" },
    { bg: "#ff003f", shadow: "#a60004" },
  ],
];
