import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
interface Props {
  expireTime: Date;
}

const CountdownTimer: React.FC<Props> = ({ expireTime }) => {
  const calculateTimeLeft = () => {
    const currentTime = new Date();
    const difference = expireTime.getTime() - currentTime.getTime();

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft as {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents: any = [];

  Object.keys(timeLeft).forEach((interval) => {
    if (!timeLeft[interval as keyof typeof timeLeft]) {
      return;
    }

    timerComponents.push(
      <span key={interval}>
        {timeLeft[interval as keyof typeof timeLeft]} {interval}{" "}
      </span>
    );
  });

  return (
    <div>
      <Typography variant="h6" color="text.primary">
        Active Race time left:{" "}
        {timerComponents.length ? timerComponents : <span>Time's up!</span>}
      </Typography>
    </div>
  );
};

export default CountdownTimer;
