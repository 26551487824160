export const FAQData = [
  {
    question: "How do I deposit?",
    answer: [
      "How do I deposit? Deposits can be made in the exchange section found at the top of the website.",
    ],
  },
  {
    question: "How do I withdraw?",
    answer: [
      `How do I withdraw? WIthdraws can be made in the exchange section found at the top of the website.`,
    ],
  },
  {
    question: "How does duel arena staking work?",
    answer: [
      "Staking is one of the most iconic minigames in OldSchool Runescape. We offer the most innovative in-game experience, automatically updating your site balance in real time!",
    ],
  },
  {
    question: "What are the house edges to the games?",
    answer: [
      "House edge guidelines can be found in the game description of each game on the website. Our game edges range from 0.61% - 5.66%.",
    ],
  },
  {
    question: "How do I play?",
    answer: [
      `To play you need to obtain Runewager tokens. These can be obtained by depositing OSRS/RS3 Gold, Giveaways, Other Promotions.`,
    ],
  },
  {
    question: "How can I self exclude?",
    answer: [
      `We offer the ability to self-exclude. Understanding that gambling is meant for entertainment purposes, we also understand that it can come with its problems. If you would like to be banned, please ask our live-chat about this. There could be other options though if you were open to them. If you ever wanted to re-join the community you would be able to pm us at any time. However, we would add a restriction so that you cannot enter the giveaways as this would entice you to play.`,
      `For requesting unbans, you must email us from the email of their account that was banned. Any unban requests from emails that are different to the email of the account will be considered invalid`,
      `Please email support@runewager.com from the email of the account that contains your website account or contact us through the Discord`,
    ],
  },
  {
    question: "What Crypto deposits do you accept?",
    answer: [
      `We accept all major Cryptocurrencies including BTC, LTC & ETH. Other coins are available, please choose the Other option when creating a Crypto Deposit and discuss with the Cashier which Cryptocurrency you are looking to deposit.`,
    ],
  },
  {
    question: "I have found a bug/exploit",
    answer: [
      `Please report all bugs to us via the Discord Ticket System. This includes the steps to replicate what you have found and any other information you deem relevant. Providing that the bugs/exploits that you find are made aware to us in a professional manner, as long as we are not already aware of the issue then we will reward you based upon the severity once a fix has been deployed. Rewards will be discussed between the Team before being handed out.`,
    ],
  },
  {
    question: "How can I gain access to a staking account?",
    answer: [
      `Upon deposit of 50m or more, we will provide a free staking account. All of our staking takes place in-game, Runescape. Simply login and all the supplies needed to stake will be provided prior (tentacle whip and dds).`,
    ],
  },
];
