import React, { useContext } from "react";
import { Box, Typography, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { theme } from "../theme";

interface ProgressBarProps {
  percents: number;
  caption?: string;
  rank?: string;
  duration?: number;
  delay?: number;
  easing?: string; // You can define a more specific type for the easing
  barWidth?: number;
  barHeight?: number;
  progressColor?: string;
  baseColor?: string;
}

const MotionBox = styled(motion.div)(({ theme }) => ({
  // Add styles if needed
}));

export const ProgressBar: React.FC<ProgressBarProps> = ({
  percents,
  caption,
  rank,
  duration = 2,
  delay = 0.25,
  easing = "easeOutBounce",
  barWidth = "100%",
  barHeight = 28,
}) => {
  // const percentsOffset = (percents - 100) * (barWidth / 100);

  const transition = {
    duration,
    delay,
    ease: easing,
    type: "spring",
  };

  const variants = {
    enter: {
      x: `100%`,
    },
    animate: {
      x: [`-100%`, `-${percents}%`],
      transition,
    },
  };

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      sx={{ position: "relative" }}
    >
      {rank && (
        <Box
          right={theme.spacing(1)}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-end"
          px={1}
        >
          <Typography
            variant="caption"
            textAlign="left"
            sx={{
              color: theme.palette.text.primary,
              width: "100%",
              textTransform: "capitalize",
            }}
          >
            {rank}
          </Typography>
        </Box>
      )}
      <Box
        width={barWidth}
        height={barHeight}
        bgcolor={theme.palette.primary.main}
        position="relative"
        borderRadius={2}
        sx={{ border: "1px solid #32C932" }}
        overflow="hidden"
      >
        <MotionBox
          variants={variants}
          initial="enter"
          animate={"animate"}
          exit="enter"
          style={{
            backgroundColor: "#32c932",
            position: "absolute",
            top: 0,
            right: 0,
            width: "100%",
            height: "100%",
          }}
        />

        {caption && (
          <Box
            position="absolute"
            top={0}
            right={theme.spacing(1)}
            height="100%"
            width="93%"
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            px={1}
          >
            <Typography
              variant="caption"
              textAlign="center"
              sx={{ color: theme.palette.text.primary, width: "100%" }}
            >
              {caption}
            </Typography>
          </Box>
        )}
      </Box>
      {/* <Typography variant="body2" fontWeight="bold" pl={1}>
        {percents}%
      </Typography> */}
    </Box>
  );
};
