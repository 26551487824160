import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { Card } from "../blackjack/constants";
import HiloServices from "../../services/hilo";
import { TOKENTYPE } from "../../types/index.d";

export type PastCard = {
  suit: string;
  rank: string;
  event: HILOACTION;
};
export enum HILOACTION {
  HIGH = "HIGH",
  LOW = "LOW",
  SKIP = "SKIP",
  SAME = "SAME",
}

export enum BLACKGAMESTATE {
  INIT = "INIT",
  START = "START",
  END = "END",
}

interface HiLoModel {
  // user: string;
  _id: string;
  bet: number;
  payout: number;
  clientSeed: string;
  serverSeed: string;
  gameNonce: number;
  currentState: string;
  deck: Card[];
  showCard: Card;
  pastCards: PastCard[];
  multiplier: number;
  isWin: boolean;
  tokenType: TOKENTYPE;
}
interface InitialState {
  game: HiLoModel | null;
  errorMessage: string;
  loading: boolean;
}

// Define initial state
const initialState: InitialState = {
  game: null,
  errorMessage: "",
  loading: false,
};

export const getCurrentHiloGame = createAsyncThunk(
  "hilo/currentGame",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const accessToken = state.user.accessToken;
    try {
      const mine = await HiloServices.getGame(accessToken);
      return mine;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

// Define slice
export const hiloReducer = createSlice({
  name: "hilo",
  initialState,
  reducers: {
    updateHiloGame(state, action: PayloadAction<any>) {
      state.game = action.payload;
      state.errorMessage = "";
      state.loading = false;
    },
    clearHiloGame(state) {
      state.game = null;
    },
    updateHiloErrorMessage(state, action: PayloadAction<string>) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    updateLoading(state) {
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentHiloGame.fulfilled, (state, action) => {
        state.game = action.payload;
        state.loading = false;
      })
      .addCase(getCurrentHiloGame.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCurrentHiloGame.pending, (state) => {
        state.loading = true;
      });
  },
});

export const {
  updateHiloGame,
  updateHiloErrorMessage,
  clearHiloGame,
  updateLoading,
} = hiloReducer.actions;

// Export actions and reducer
export const selectHiloGame = (state: RootState) => state.hilo.game;
export const selectHiloError = (state: RootState) => state.hilo.errorMessage;

export const selectHiloLoading = (state: RootState) => state.hilo.loading;

export default hiloReducer.reducer;
