import { Box, Stack, Typography, Checkbox } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import { theme } from "../theme";
import { useState } from "react";
import { Button } from "../button";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 200, md: 300 },
  zIndex: 10,
  py: 4,
  px: 10,
  borderRadius: 4,
};

interface IModal {
  handleClose: (dontShowConfirmation: boolean) => void;
  open: boolean;
}

const PopUp = ({ handleClose, open }: IModal) => {
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);

  const handleModalClose = () => {
    handleClose(dontShowConfirmation);
  };
  return (
    <ModalComponent open={open} onClose={() => {}} disableScrollLock={true}>
      <Box sx={modalStyle}>
        <Stack gap={3}>
          <Stack gap={2}>
            <Typography variant="h5" color="text.primary">
              Duel Guide
            </Typography>
            <Typography color="text.primary">
              Duels are carried out in-game and you can locate your opponent at
              W506 Duel Arena at the Scoreboards that are closest to the Bank.
              Create your duel, challenge the opponent shown in the duel modal
              and they will set the rules based on the option you have chose on
              the site. Good luck!
            </Typography>
            <Stack direction="row" alignItems="center">
              <Checkbox
                color="error"
                value={dontShowConfirmation}
                onChange={(_e, value: boolean) =>
                  setDontShowConfirmation(value)
                }
                sx={{
                  px: 0,
                  pr: 1,
                  color: theme.palette.error.main,
                }}
              />
              <Typography variant="subtitle1" color="text.primary">
                Do not show again
              </Typography>
            </Stack>
          </Stack>
          <Button onClick={handleModalClose}>Okay</Button>
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default PopUp;
