import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { GAMESTATUS } from "../../constants";
import { TokenIcon } from "../tokenIcon";
import styles from "./index.module.scss";
import BombImg from "../../assets/mines/bomb.svg";
import DiamondImg from "../../assets/mines/diamond.svg";
import { TOKENTYPE } from "../../types/index.d";

interface ITile {
  cellType: string;
  index: number;
  isRevealed: boolean;
  handleClick: () => void;
  inPlay: boolean;
  payout: number;
  gameStatus: GAMESTATUS | null;
  isLastBomb: boolean;
  isRestart: boolean;
  tokenType: TOKENTYPE | null;
}
const tileVariants = {
  initial: {
    y: -50,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
  },
  hoverActive: {
    scale: 1.1,
    cursor: "pointer",
    boxShadow: "rgba(72, 135, 202, 0.5) 0 0 10px 5px",
  },
  hoverInactive: {},
  tap: {
    scale: 0.8,
    backgroundColor: "green",
    boxShadow: "inset rgba(72, 135, 202, 0.8) 0 0 90px 33px",
  },
};

const Tile = React.memo((props: ITile) => {
  const {
    isRevealed,
    handleClick,
    index,
    cellType,
    inPlay,
    payout,
    isLastBomb,
    gameStatus,
    isRestart,
    tokenType,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const canClick = () => {
    if (!isRevealed) {
      handleClick();
    }
  };
  const renderPayout = () => {
    return isHovering && gameStatus === GAMESTATUS.INGAME && !isRevealed ? (
      <Typography
        variant="h6"
        color="text.primary"
        className={styles.hoverText}
        sx={{
          display: { md: "flex", sm: "none", xs: "none" },
          flexDirection: "row",
          alignItems: "center",
          gap: 0.25,
          whiteSpace: "nowrap",
        }}
      >
        <TokenIcon freeCash={tokenType === TOKENTYPE.FREE} />{" "}
        {tokenType === TOKENTYPE.SWEEP && payout > 50000 ? 50000 : payout}
      </Typography>
    ) : null;
  };

  useEffect(() => {
    if (gameStatus === GAMESTATUS.ENDGAME) {
      setIsHovering(false);
    }
  }, [isHovering, gameStatus]);

  const showTiles =
    (isRevealed && gameStatus === GAMESTATUS.INGAME) ||
    (gameStatus === GAMESTATUS.ENDGAME && !isRestart);
  return (
    <motion.div
      variants={tileVariants}
      initial="initial"
      animate="animate"
      transition={!inPlay ? { delay: index * 0.1, type: "spring" } : {}}
      whileHover={inPlay ? "hoverActive" : "hoverInactive"}
      whileTap="whileTap"
      className={styles.tileContainer}
      onClick={canClick}
      key={index}
      data-islastbomb={isLastBomb && showTiles}
      data-gameend={isRevealed && !inPlay && !isLastBomb}
      // data-gameend={isRevealed && cellType === "💎" && !inPlay && !isLastBomb}
      data-filled={showTiles}
      data-idle={!inPlay}
      onHoverStart={() => {
        setIsHovering(true);
      }}
      onHoverEnd={() => {
        setIsHovering(false);
      }}
    >
      <motion.div className={styles.tile} data-idle={!inPlay}>
        <motion.div className={styles.tileContent} data-filled={showTiles}>
          {cellType === "mine" && (
            <motion.img src={BombImg} style={{ width: "100%" }} alt="bomb" />
          )}

          {cellType === "💎" && (
            <motion.img
              src={DiamondImg}
              style={{ width: "100%" }}
              alt="diamond"
            />
          )}
        </motion.div>
      </motion.div>
      {renderPayout()}
    </motion.div>
  );
});

export default Tile;
