import { Container } from "@mui/material";
import GameFeed from "../../../components/gameFeed";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import Keno from "../../../components/keno";
import { GAMES } from "../../../constants/games";

const KenoPage = () => {
  return (
    <Container maxWidth="md">
      <Keno />
      <DescWrapper game={GAMES.KENO} />
      <GameFeed />
    </Container>
  );
};

export default KenoPage;
