import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Transaction from "../../services/transaction";
import { RootState } from "../store";

export interface ITransaction {
  user: string;
  amount: number;
  description: string;
  createdAt: string;
  _id: string;
}

interface TransactionState {
  transactions: ITransaction[];
  errorMessage: string;
  loading: boolean;
}

const initialState: TransactionState = {
  transactions: [],
  errorMessage: "",
  loading: false,
};

export const getAllTransactions = createAsyncThunk(
  "transactions/getAll",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const accessToken = state.user.accessToken;
    try {
      const transaction = await Transaction.allTransaction(accessToken);

      return transaction;
    } catch (error) {
      return thunkAPI.rejectWithValue(
        error instanceof Error ? error.message : "An unknown error occurred"
      );
    }
  }
);

// Slice definition
export const transactionReducer = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    updateTrx(state, action: PayloadAction<ITransaction[]>) {
      state.transactions = action.payload;
    },
    updateErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTransactions.fulfilled, (state, action) => {
        state.transactions = action.payload;
        state.loading = false;
      })
      .addCase(getAllTransactions.rejected, (state, action) => {
        state.errorMessage = action.payload as string;
        state.loading = false;
      })
      .addCase(getAllTransactions.pending, (state) => {
        state.loading = true;
      });
  },
});

// Expose the actions
export const { updateTrx, updateErrorMessage } = transactionReducer.actions;

// Selectors
export const selectAllTransaction = (state: RootState) =>
  state.transaction.transactions;

// Reducer
export default transactionReducer.reducer;
