import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { Stack, Typography, Box, useMediaQuery } from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import {
  selectHiloToggle,
  selectPlayer,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import styles from "./index.module.scss";
import type { AppDispatch } from "../store";
import useSound from "use-sound";
import { useDispatch } from "react-redux";
import { setErrorMesage } from "../slices/mineSlice";
import { DEFAULT_DISABLED_MESSAGE } from "../../constants";
import { BLACKJACKACTION, TOKENTYPE } from "../../types/index.d";
import { isBetGreaterthanSetValue, RoundNumber } from "../../utils";
import { Buttons, HighLowButtons } from "./buttons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import {
  CHECK_BLACKJACK_GAME,
  CHECK_HILO_GAME,
  PAYOUT_HILO_GAME,
  PLAY_BLACKJACK_GAME,
  PLAY_HILO_GAME,
} from "../../constants/socket";
import ChatContext from "../../contexts/chat/context";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import {
  BLACKGAMESTATE,
  calculateHigherProbability,
  calculateLowerProbability,
} from "./constants";
import PlayArea from "./playArea";
import { updateLoading } from "../slices/blackJackslice";
import CardImages from "../../constants/blackJack";
import BackCard from "../../assets/blackjack/blackjack-back.svg";
import {
  HILOACTION,
  clearHiloGame,
  selectHiloError,
  selectHiloGame,
  selectHiloLoading,
} from "../slices/hiloslice";

const preloadImage = (src: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve;
  });
};

const preloadImages = (imagePaths: string[]) => {
  return Promise.all(imagePaths.map(preloadImage));
};

const HiLo = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { player, accessToken } = useAppSelector(selectPlayer);
  const state = useAppSelector(selectHiloGame);
  const errorMessage = useAppSelector(selectHiloError);
  const loading = useAppSelector(selectHiloLoading);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [confirmation, setConfirmation] = useState(false);
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const [title, setTitle] = useState("");
  const [currentBet, setCurrentBet] = useState(0);
  const { socket } = useContext(ChatContext);
  const toggle = useAppSelector(selectHiloToggle);
  const deckRef = useRef<any>();
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const tokenType = useAppSelector(selectTokenSelection);

  const handleCreateGame = (askConfirmation = true) => {
    if (title) {
      dispatch(setErrorMesage(title));
      return;
    }
    if (currentBet > player.balance && tokenType === TOKENTYPE.SWEEP) {
      betFieldRef.current?.focus();
      return;
    }
    if (currentBet > player.freeCash && tokenType === TOKENTYPE.FREE) {
      betFieldRef.current?.focus();
      return;
    }
    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be negative"));
      return;
    }
    if (currentBet === 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be 0"));
      return;
    }
    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet can't be lower than balance"));
      return;
    }

    if (currentBet && currentBet > 0) {
      if (
        tokenType === TOKENTYPE.SWEEP &&
        askConfirmation &&
        currentBet &&
        ((player.isBetConfirmation &&
          isBetGreaterthanSetValue(
            currentBet,
            player.balance,
            player?.betConfirmationValue
          )) ||
          isBetGreaterthanSetValue(currentBet, player.balance, 95))
      ) {
        setConfirmation(true);
        return;
      }
      if (!askConfirmation && dontShowConfirmation) {
        let payload: Partial<User> = {
          isBetConfirmation: false,
          betConfirmationValue: 0,
        };
        dispatch(updateSettings(payload));
      }

      const gameData = {
        currentBet,
        tokenType,
      };
      playBetSound();

      dispatch(clearHiloGame());
      socket?.emit(PLAY_HILO_GAME, {
        accessToken,
        gameData,
      });

      return;
    }
  };

  const isBalanceError = !accessToken;

  const handleUpdate = useCallback(
    (action: HILOACTION) => {
      if (loading) return;
      dispatch(updateLoading());
      socket?.emit(CHECK_HILO_GAME, {
        accessToken,
        action,
      });
    },
    [loading]
  );

  const doLow = () => {
    handleUpdate(HILOACTION.LOW);
  };

  const doHigh = () => {
    handleUpdate(HILOACTION.HIGH);
  };
  const doSkip = () => {
    handleUpdate(HILOACTION.SKIP);
  };
  const doSame = () => {
    handleUpdate(HILOACTION.SAME);
  };
  const handlePayout = () => {
    if (loading) return;
    dispatch(updateLoading());
    socket?.emit(PAYOUT_HILO_GAME, {
      accessToken,
    });
  };
  useEffect(() => {
    const imagePaths = [
      BackCard,
      ...Object.values(CardImages.Clubs),
      ...Object.values(CardImages.Diamonds),
      ...Object.values(CardImages.Hearts),
      ...Object.values(CardImages.Spades),
    ];

    preloadImages(imagePaths as string[]).then(() => {});
  }, []);

  const isButtonDisabled = state?.currentState === BLACKGAMESTATE.START;
  const disableButtons =
    !accessToken || state?.currentState === BLACKGAMESTATE.START;

  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        title="Bet Confirmation"
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          handleCreateGame(false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet || 0,
          player.balance,
          95
        )}
      />
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          HiLo
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
          sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
                <HighLowButtons
                  gameStatus={state?.currentState as BLACKGAMESTATE}
                  disabled={!toggle}
                  isBalanceError={isBalanceError}
                  currentCard={state?.showCard}
                  doHigh={doHigh}
                  doLow={doLow}
                  doSkip={doSkip}
                  doSame={doSame}
                />
              </Stack>
              <Stack
                sx={{
                  display: { md: "none", sm: "block", xs: "block" },
                  width: "100%",
                }}
              >
                <Buttons
                  accessToken={accessToken}
                  gameStatus={state?.currentState as BLACKGAMESTATE}
                  disabled={!toggle || isButtonDisabled}
                  payout={state?.payout || 0}
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                  handlePayout={handlePayout}
                  showPayout={state?.payout !== state?.bet}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  disabled={disableButtons}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.BLACKJACK}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={disableButtons}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  title={title}
                  balance={player.balance}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                    disabled={disableButtons}
                  >
                    x2
                  </Button>
                  <Button
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                    disabled={disableButtons}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>

              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <HighLowButtons
                  gameStatus={state?.currentState as BLACKGAMESTATE}
                  disabled={!toggle}
                  isBalanceError={isBalanceError}
                  currentCard={state?.showCard}
                  doHigh={doHigh}
                  doLow={doLow}
                  doSkip={doSkip}
                  doSame={doSame}
                />
              </Stack>

              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <Buttons
                  accessToken={accessToken}
                  gameStatus={state?.currentState as BLACKGAMESTATE}
                  disabled={!toggle || isButtonDisabled}
                  payout={state?.payout || 0}
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                  handlePayout={handlePayout}
                  showPayout={state?.payout !== state?.bet}
                />
              </Stack>
              {errorMessage && (
                <Typography variant="h6" color="error">
                  {errorMessage}
                </Typography>
              )}

              {!toggle && (
                <Typography variant="h6" color="error">
                  {DEFAULT_DISABLED_MESSAGE}
                </Typography>
              )}
            </Stack>
            <GameOptions game={GAMES.HILO} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
              height: { md: "auto", xs: "30em" },
              position: "relative",
              overflow: "hidden",
            }}
            justifyContent="center"
          >
            <Stack
              justifyContent={{
                md: "center",
                xs: "center",
              }}
              sx={{ height: "100%" }}
            >
              <PlayArea
                dealersHand={state?.pastCards}
                playersHands={
                  state?.pastCards.length
                    ? [...state?.pastCards, state?.showCard]
                    : state?.showCard
                    ? [state?.showCard]
                    : undefined
                }
                deckRef={deckRef}
                isGameEnd={state?.currentState === BLACKGAMESTATE.END}
                currentCard={state?.showCard}
                payout={state?.payout}
                multiplier={state?.multiplier}
                tokenType={state?.tokenType}
                isWin={state?.isWin}
                bet={state?.bet}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default HiLo;
