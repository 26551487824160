import {
  JackpotDocument,
  SlideBet,
  Winner,
} from "../components/slices/slideSlice";

export enum SLIDESTATE {
  COUNTDOWN = "COUNTDOWN",
  ANIMATING = "ANIMATING",
  RESULT = "RESULT",
}

export type SlideGameState = {
  [key in SLIDESTATE]?: {
    timeStamp: number;
    now?: number;
  };
};

export interface SlideIncomingSocket {
  timer: number;
  result: number;
  state: SLIDESTATE;
  gameStates: SlideGameState;
  pastResults?: number[];
  bets: SlideBet[];
  offset?: number;
  jackpot: JackpotDocument;
  jackpots?: JackpotDocument[];
  jackpotWinners?: Winner[];
  currentTimeDb?: string;
}
