import { useMotionValue, useTransform, animate, motion } from "framer-motion";
import { useEffect, useState, useRef } from "react";
import { Stack } from "@mui/material";
import { LimboDocument } from "../slices/limboSlice";
import { theme } from "../theme";
import useSound from "use-sound";
import WinSound from "../../assets/audio/common/win.mp3";
import LossSound from "../../assets/audio/common/loss.mp3";
import AnimationSound from "../../assets/audio/limbo/animation.mp3";
import { selectVolume } from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";

interface IResult {
  game?: LimboDocument | null;
}

export default function Result({ game }: IResult) {
  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) => value.toFixed(2));
  const [textColor, setTextColor] = useState("white"); // Default color
  const volume = useAppSelector(selectVolume);
  const isEnd = useRef(false);

  const [playAnimationSound] = useSound(AnimationSound, {
    volume: volume / 100,
  });
  const [playWinSound] = useSound(WinSound, { volume: volume / 100 });
  const [playLossSound] = useSound(LossSound, { volume: volume / 100 });
  const gameRef = useRef<string>("");
  useEffect(() => {
    if (!game) return;
    if (game?._id === gameRef.current) return;

    gameRef.current = game?._id || "";
    const animation = animate(count, game?.result || 0, {
      duration: 0.4,
      ease: "easeInOut",
      onUpdate: (latest) => {
        isEnd.current = true;
        if (count.get() !== game.result) {
          setTextColor("white");
        }
      },
      onComplete: () => {
        const getColor =
          count.get() >= game.multiplier
            ? theme.palette.success.main
            : theme.palette.error.main;
        setTextColor(getColor);
      },
    });

    return () => {
      animation.stop();
    };
  }, [game]);

  useEffect(() => {
    playAnimationSound();
  }, [game]);

  useEffect(() => {
    if (isEnd.current && textColor !== "white") {
      isEnd.current = false;
      if (theme.palette.success.main === textColor) {
        playWinSound();
      } else {
        playLossSound();
      }
    }
  }, [textColor]);

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        fontSize: "5em",
        color: textColor, // Use the state here
        opacity: game ? 1 : 0,
      }}
    >
      <motion.div>{rounded}</motion.div>
    </Stack>
  );
}
