import configuration from "../config";
import { SESSIONS_ROUTE, USERS_ROUTE } from "../constants";

const post = async (
  email: String,
  password: String,
  userName: String,
  token: string,
  referral?: String,
  referredBy?: String
) => {
  const raw = JSON.stringify({
    email,
    password,
    userName,
    referral,
    referredBy,
    token,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(`${configuration.api.url}/${USERS_ROUTE}`, {
    headers: {
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    credentials: "include",
    method: "POST",
    body: raw,
  });

  // if (response.status === 409) {
  //   throw new Error("Username or Email is already taken");
  // }

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json?.length) {
        throw new Error(json[0].message);
      }
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const logout = async () => {
  const response = await fetch(`${configuration.api.url}/logout`, {
    headers: {
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    credentials: "include",
    method: "POST",
  });

  if (response.status === 409) {
    throw new Error("Username or Email is already taken");
  }

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const login = async (
  userName: String,
  password: String,
  token: string,
  stayLoggedIn: boolean
) => {
  const raw = JSON.stringify({
    password,
    userName,
    token,
    stayLoggedIn,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(`${configuration.api.url}/${SESSIONS_ROUTE}`, {
    headers: {
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    credentials: "include",
    method: "POST",
    body: raw,
  });

  if (response.status === 409) {
    throw new Error("Incorrect Username or Password");
  }

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const resetPassword = async (email: String, token: String) => {
  const raw = JSON.stringify({
    email,
    token,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/resetPassword`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      credentials: "include",
      method: "POST",
      body: raw,
    }
  );

  if (response.status === 409) {
    throw new Error("Incorrect Username or Password");
  }

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const verifyUser = async () => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/authenticateToken`,
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Token verification failed");
  }

  const json = await response.json();
  return json;
};

const getUser = async (accessToken: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/getUser`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Token verification failed");
  }

  const json = await response.json();
  return json;
};

const getAccounts = async (accessToken: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/getAccounts`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error("Token verification failed");
  }

  const json = await response.json();
  return json;
};

const put = async (
  isBetConfirmation: boolean | undefined,
  optInEmail: boolean | undefined,
  isAnonymity: boolean | undefined,
  betConfirmationValue: number | undefined,
  token: string
) => {
  const raw = JSON.stringify({
    isBetConfirmation,
    betConfirmationValue,
    isAnonymity,
    optInEmail,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/settings`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "PUT",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const addFavourite = async (game: string, token: string) => {
  const raw = JSON.stringify({
    game,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/favourite`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "PUT",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const promoCode = async (token: string, code: string, hCaptcha: string) => {
  const raw = JSON.stringify({
    promoCode: code,
    hCaptcha,
  });
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/promoCode`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "POST",
      credentials: "include",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const verificationCode = async (code: string) => {
  const raw = JSON.stringify({
    code: code,
  });
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/verifyUser`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const resetPasswordToken = async (
  password: string,
  verificationCode: string,
  token: string
) => {
  const raw = JSON.stringify({
    password,
    code: verificationCode,
    token,
  });
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/resetPasswordToken`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      method: "POST",
      credentials: "include",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const changePassword = async (
  password: string,
  currentPassword: string,
  accessToken: string,
  token: string
) => {
  const raw = JSON.stringify({
    password,
    currentPassword,
    token,
  });
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/changePassword`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
      credentials: "include",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const tipRain = async (accessToken: string, amount: number) => {
  const raw = JSON.stringify({
    amount,
  });
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/tipRain`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      method: "POST",
      credentials: "include",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const sendVerification = async (token: string, code: string) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/send-verification-email?token=${code}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const generateCode = async (token: string) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/generate-code`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const unLinkUUID = async (token: string) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/discord-unlink`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const claimBonus = async (token: string, code: string) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/bonus?bonusType=${code}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const calimReferralReward = async (token: string) => {
  const response = await fetch(
    `${configuration.api.url}/${USERS_ROUTE}/claimReward`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      method: "GET",
      credentials: "include",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getCurrencies = async (token: string) => {
  const response = await fetch(`${configuration.api.url}/getCurrencies`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    method: "GET",
    credentials: "include",
  });

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const Users = {
  post,
  tipRain,
  verifyUser,
  getUser,
  claimBonus,
  calimReferralReward,
  promoCode,
  verificationCode,
  login,
  put,
  generateCode,
  unLinkUUID,
  sendVerification,
  resetPassword,
  resetPasswordToken,
  changePassword,
  logout,
  getAccounts,
  addFavourite,
  getCurrencies,
};

export default Users;
