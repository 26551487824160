import React, { useState, useEffect, useRef, useContext } from "react";
import { Stack, Typography, MenuItem, Select } from "@mui/material";
import Tile from "./tiles";
import { theme } from "../theme";
import { Button } from "../button";
import { InputFieldStyled } from "../inputField";
import {
  selectMinesToggle,
  selectPlayer,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import styles from "./index.module.scss";
import type { AppDispatch } from "../store";
import useSound from "use-sound";
import { useDispatch } from "react-redux";
import {
  getMineErrorMessage,
  getMineLoading,
  resetGame,
  selectGame,
  setErrorMesage,
  updateLoading,
} from "../slices/mineSlice";
import {
  DEFAULT_DISABLED_MESSAGE,
  GAMESTATUS,
  listOfMines,
} from "../../constants";
import { TILETYPE, TOKENTYPE } from "../../types/index.d";
import {
  getPayout,
  isBetGreaterthanSetValue,
  RoundNumber,
  RoundProfitOnWin,
} from "../../utils";
import { WinModal } from "./winModal";
import { Buttons } from "./buttons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import {
  CHECK_MINES_GAME,
  PAYOUT_MINES_GAME,
  PLAY_MINES_GAME,
} from "../../constants/socket";
import ChatContext from "../../contexts/chat/context";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import OpenDiamondSound from "../../assets/audio/mines/diamond-reveal.mp3";
import OpenMineSound from "../../assets/audio/mines/bomb-reveal.mp3";

interface MinesProps {}

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      backgroundColor: "#292929",
    },
  },
};

const Mines: React.FC<MinesProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { player, accessToken } = useAppSelector(selectPlayer);
  const mineGame = useAppSelector(selectGame);
  const errorMessage = useAppSelector(getMineErrorMessage);
  const loading = useAppSelector(getMineLoading);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [confirmation, setConfirmation] = useState(false);
  const [currentBet, setCurrentBet] = useState(0);
  const [numOfMines, setNumOfMines] = useState<number>(5);
  const [isRestart, setIsRestart] = useState<boolean>(false);
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const [playDiamondSound] = useSound(OpenDiamondSound, {
    volume: volume / 100,
  });
  const [playMineSound] = useSound(OpenMineSound, { volume: volume / 100 });
  const [revealedCells, setRevealedCells] = useState<string[]>([]);
  const [title, setTitle] = useState("");
  const [grid, setGrid] = useState<string[][]>(
    Array(5)
      .fill(0)
      .map(() => Array(5).fill("💎"))
  );
  const tokenType = useAppSelector(selectTokenSelection);

  const { socket } = useContext(ChatContext);
  const openTilesRef = useRef<number>();
  const toggle = useAppSelector(selectMinesToggle);
  const lastCellHitRef = useRef("");
  const {
    mineLocations,
    gameStatus,
    openTiles,
    payout,
    isWin,
    multiplier,
    mines,
    bet,
  } = mineGame;
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  useEffect(() => {
    if (bet && bet !== currentBet) {
      setCurrentBet(bet);
    }
    if (mines && mines !== numOfMines) {
      setNumOfMines(mines);
    }
    // eslint-disable-next-line
  }, [bet, mines]);

  useEffect(() => {
    if (openTiles?.length && gameStatus === GAMESTATUS.INGAME) {
      const tempGrid = grid;
      let tempReveledCell = revealedCells;
      openTiles.forEach((tiles) => {
        const [column, row] = tiles.key.split(",").map(Number);
        if (tiles.type === TILETYPE.MINE) {
          tempGrid[row][column] = TILETYPE.MINE;
        }
        if (!tempReveledCell.includes(tiles.key)) {
          tempReveledCell = [...tempReveledCell, tiles.key];
        }
      });
      setGrid(tempGrid);
      setRevealedCells(tempReveledCell);
      return;
    }
    if (isRestart) return;
    if (mineLocations && gameStatus === GAMESTATUS.ENDGAME) {
      const tempGrid = grid;
      mineLocations.forEach((tiles) => {
        const [column, row] = tiles.split(",").map(Number);
        tempGrid[row][column] = TILETYPE.MINE;
      });
      let tempReveledCell = revealedCells;
      openTiles.forEach((tiles) => {
        const [column, row] = tiles.key.split(",").map(Number);
        if (tiles.type === TILETYPE.MINE) {
          tempGrid[row][column] = TILETYPE.MINE;
        }
        if (!tempReveledCell.includes(tiles.key)) {
          tempReveledCell = [...tempReveledCell, tiles.key];
        }
      });
      setRevealedCells(tempReveledCell);
      setGrid([...tempGrid]);
      return;
    }
    // eslint-disable-next-line
  }, [openTiles, mineLocations, gameStatus]);

  useEffect(() => {
    if (gameStatus === GAMESTATUS.INGAME && isRestart) {
      setIsRestart(false);
    }
    // eslint-disable-next-line
  }, [gameStatus]);

  const handleCellClick = (column: number, row: number): void => {
    if (loading) return;
    dispatch(updateLoading());
    const key = `${column},${row}`;
    lastCellHitRef.current = key;
    socket?.emit(CHECK_MINES_GAME, {
      key,
      accessToken,
    });
  };

  const handleCreateGame = (askConfirmation = true) => {
    if (title) {
      dispatch(setErrorMesage(title));
      return;
    }
    if (currentBet > player.balance && tokenType === TOKENTYPE.SWEEP) {
      betFieldRef.current?.focus();
      return;
    }

    if (bet > player.freeCash && tokenType === TOKENTYPE.FREE) {
      betFieldRef.current?.focus();
      return;
    }

    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be negative"));
      return;
    }
    if (currentBet === 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet cant be 0"));
      return;
    }
    if (currentBet && currentBet < 0) {
      betFieldRef.current?.focus();
      dispatch(setErrorMesage("Bet can't be lower than balance"));
      return;
    }
    if (numOfMines < 0) {
      dispatch(setErrorMesage("Mines should be greater than 0"));
      return;
    }

    if (currentBet && currentBet > 0 && numOfMines > 0) {
      if (
        tokenType === TOKENTYPE.SWEEP &&
        askConfirmation &&
        currentBet &&
        ((player.isBetConfirmation &&
          isBetGreaterthanSetValue(
            currentBet,
            player.balance,
            player?.betConfirmationValue
          )) ||
          isBetGreaterthanSetValue(currentBet, player.balance, 95))
      ) {
        setConfirmation(true);
        return;
      }
      if (!askConfirmation && dontShowConfirmation) {
        let payload: Partial<User> = {
          isBetConfirmation: false,
          betConfirmationValue: 0,
        };
        dispatch(updateSettings(payload));
      }

      setIsRestart(true);
      setRevealedCells([]);
      setGrid(
        Array(5)
          .fill(0)
          .map(() => Array(5).fill("💎"))
      );
      const gameData = { bet: currentBet, numOfMines, tokenType };

      dispatch(updateLoading());
      playBetSound();
      socket?.emit(PLAY_MINES_GAME, {
        accessToken,
        gameData,
      });

      return;
    }
  };

  const handlePayout = () => {
    if (loading) return;
    dispatch(updateLoading());
    socket?.emit(PAYOUT_MINES_GAME, {
      accessToken,
    });
  };

  const isBalanceError = !accessToken;

  const handleRemoveModal = () => {
    if (isWin) {
      dispatch(resetGame());
    }
  };

  const getNextPayout = RoundProfitOnWin(
    getPayout(mines, revealedCells.length + 1) * bet
  );

  const disableButtons = !accessToken || gameStatus === GAMESTATUS.INGAME;
  useEffect(() => {
    if (
      mineGame &&
      mineGame?.openTiles?.length &&
      openTilesRef.current !== mineGame?.openTiles?.length
    ) {
      const isBomb = mineGame?.openTiles.find((tile) => tile.type === "mine");
      if (isBomb) {
        playMineSound();
      } else {
        playDiamondSound();
      }
      openTilesRef.current = mineGame?.openTiles?.length;
    }
  }, [mineGame]);
  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        title="Bet Confirmation"
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          handleCreateGame(false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet || 0,
          player.balance,
          95
        )}
      />
      <Stack gap={2} onClick={() => handleRemoveModal()}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Mines
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack
                sx={{
                  display: { md: "none", sm: "block", xs: "block" },
                  width: "100%",
                }}
              >
                <Buttons
                  accessToken={accessToken}
                  gameStatus={gameStatus}
                  revealedCells={revealedCells}
                  disabled={!toggle}
                  handlePayout={handlePayout}
                  payout={payout}
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                  tokenType={mineGame.tokenType}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  disabled={disableButtons}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.MINES}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={disableButtons}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  title={title}
                  balance={player.balance}
                  multiplier={numOfMines}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                    disabled={disableButtons}
                  >
                    x2
                  </Button>
                  <Button
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                    disabled={disableButtons}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography color="text.primary">Mines</Typography>
                <Select
                  value={numOfMines}
                  onChange={(e) => setNumOfMines(Number(e.target.value))}
                  input={
                    <InputFieldStyled
                      disabled={disableButtons}
                      sx={{
                        "& .MuiInputBase-input": {
                          "&.Mui-disabled": {
                            backgroundColor: "#454545",
                            WebkitTextFillColor: "black",
                          },
                        },
                      }}
                    />
                  }
                  disabled={disableButtons}
                  MenuProps={MenuProps}
                >
                  {listOfMines.map((mine: number) => (
                    <MenuItem value={mine}>{mine}</MenuItem>
                  ))}
                </Select>
              </Stack>
              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <Buttons
                  accessToken={accessToken}
                  gameStatus={gameStatus}
                  revealedCells={revealedCells}
                  handlePayout={handlePayout}
                  payout={payout}
                  disabled={!toggle}
                  isBalanceError={isBalanceError}
                  handleCreateGame={handleCreateGame}
                  tokenType={mineGame.tokenType}
                />
              </Stack>
              {errorMessage && (
                <Typography variant="h6" color="error">
                  {errorMessage}
                </Typography>
              )}
              {!toggle && (
                <Typography variant="h6" color="error">
                  {DEFAULT_DISABLED_MESSAGE}
                </Typography>
              )}
            </Stack>
            <GameOptions game={GAMES.MINES} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
            }}
          >
            <div className={styles.grid}>
              {isWin && (
                <WinModal
                  payout={payout}
                  multiplier={multiplier}
                  tokenType={mineGame.tokenType}
                />
              )}
              {grid.map((row, rowIndex) => (
                <>
                  {row.map((cell, columnIndex) => {
                    const key = `${columnIndex},${rowIndex}`;
                    const isRevealed = revealedCells.includes(key);
                    const checkIfLastCell = lastCellHitRef.current === key;
                    const checkIfBomb = mineLocations?.includes(key);
                    const isLastBomb = checkIfBomb && checkIfLastCell;
                    return (
                      <Tile
                        handleClick={() =>
                          handleCellClick(columnIndex, rowIndex)
                        }
                        index={columnIndex + rowIndex}
                        isRevealed={isRevealed}
                        cellType={cell}
                        inPlay={gameStatus === GAMESTATUS.INGAME || loading}
                        gameStatus={gameStatus}
                        payout={getNextPayout}
                        isRestart={isRestart}
                        tokenType={mineGame.tokenType}
                        isLastBomb={isLastBomb || false}
                      />
                    );
                  })}
                </>
              ))}
            </div>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default Mines;
