export enum KENORISK {
  CLASSIC = "Classic",
  LOW = "Easy",
  MEDIUM = "Medium",
  HIGH = "Hard",
  CRAZY = "Crazy",
}
export const kenoMultipliers = {
  [KENORISK.CLASSIC]: [
    [0, 3.88],
    [0, 1.86, 4.42],
    [0, 0.98, 3.04, 10.2],
    [0, 0.78, 1.76, 4.9, 22.08],
    [0, 0.24, 1.37, 4.02, 16.17, 35.27],
    [0, 0, 0.98, 3.63, 6.87, 16.19, 39.22],
    [0, 0, 0.47, 2.95, 4.42, 13.74, 30.39, 58.85],
    [0, 0, 0, 2.17, 3.93, 12.76, 21.58, 53.95, 68.77],
    [0, 0, 0, 1.53, 2.95, 7.86, 14.74, 43.22, 58.85, 83.37],
    [0, 0, 0, 1.4, 2.05, 4.5, 8, 17, 50, 80, 100],
  ],
  [KENORISK.LOW]: [
    [0.69, 1.81],
    [0, 1.96, 3.72],
    [0, 1.08, 1.35, 25.47],
    [0, 0, 2.16, 7.74, 88.18],
    [0, 0, 1.47, 4.12, 12.74, 293.94],
    [0, 0, 1.08, 1.96, 6.07, 98, 686],
    [0, 0, 1.08, 1.57, 3.43, 14.7, 220.5, 686],
    [0, 0, 1.08, 1.47, 1.96, 5.39, 38.21, 97.98, 784],
    [0, 0, 1.08, 1.27, 1.67, 2.45, 7.35, 49, 245, 980],
    [0, 0, 1.08, 1.15, 1.3, 1.8, 3.5, 13, 50, 250, 1000],
  ],
  [KENORISK.MEDIUM]: [
    [0.39, 2.69],
    [0, 1.76, 5],
    [0, 0, 2.74, 49],
    [0, 0, 1.67, 9.8, 98],
    [0, 0, 1.37, 3.92, 13.75, 383],
    [0, 0, 0, 2.94, 8.82, 176.5, 697],
    [0, 0, 0, 1.96, 6.86, 29.4, 392, 784],
    [0, 0, 0, 1.96, 3.92, 10.8, 65.7, 392, 882],
    [0, 0, 0, 1.96, 2.45, 4.9, 14.7, 98, 490, 980],
    [0, 0, 0, 1.5, 2, 4, 7, 26, 100, 500, 1000],
  ],
  [KENORISK.HIGH]: [
    [0, 3.88],
    [0, 0, 16.75],
    [0, 0, 0, 79.9],
    [0, 0, 0, 9.8, 253.8],
    [0, 0, 0, 4.42, 47.05, 441],
    [0, 0, 0, 0, 10.8, 343, 696],
    [0, 0, 0, 0, 6.86, 88.2, 392, 784],
    [0, 0, 0, 0, 4.9, 19.6, 264.6, 588, 882],
    [0, 0, 0, 0, 3.92, 10.8, 54.92, 490, 784, 980],
    [0, 0, 0, 0, 3.43, 7.8, 13, 62, 500, 800, 1000],
  ],
  [KENORISK.CRAZY]: [
    [0, 3.88],
    [0, 0, 16.8],
    [0, 0, 0, 80],
    [0, 0, 0, 7, 300],
    [0, 0, 0, 3, 29, 1000],
    [0, 0, 0, 0, 11, 230, 4444],
    [0, 0, 0, 0, 8, 25, 1212, 5555],
    [0, 0, 0, 0, 6, 22, 85, 2456, 6666],
    [0, 0, 0, 0, 3.5, 11, 64, 500, 2699, 7777],
    [0, 0, 0, 0, 3.2, 7.5, 13, 70, 500, 1400, 10000],
  ],
};

export const kenoWinChances = [
  [75, 25],
  [55.76923077, 38.46153846, 5.76923077],
  [41.09311741, 44.02834008, 13.66396761, 1.2145749],
  [29.98686946, 44.42499179, 21.41919247, 3.93916183, 0.22978444],
  [21.6571835, 41.64842981, 27.76561987, 7.93303425, 0.95743517, 0.03829741],
  [
    15.46941679, 37.126600028, 32.12878871, 12.6928548, 2.37991027, 0.19695809,
    0.00547106,
  ],
  [
    10.91958832, 31.84879926, 34.3967032, 17.63933498, 4.57316092, 0.58797783,
    0.03379183, 0.00064365,
  ],
  [
    7.61062216, 26.47172926, 34.74414465, 22.23625258, 7.48335423, 1.33037409,
    0.1187834, 0.00468112, 0.00005851,
  ],
  [
    5.232230274, 21.40487483, 33.50328234, 26.05810849, 10.94440557, 2.52563205,
    0.31180643, 0.01909019, 0.00049371, 0.00000366,
  ],
  [
    3.54446314, 16.87839592, 31.07159249, 28.82002782, 14.71022253, 4.23654409,
    0.67893335, 0.05747584, 0.0023093, 0.00003539, 0.00000012,
  ],
];
export function generateRandomNumbers(numberToGenerate = 10) {
  var numbers: number[] = [];
  while (numbers.length < numberToGenerate) {
    var randomNumber = Math.floor(Math.random() * 40) + 1; // Generate random number between 1 and 40
    if (!numbers.includes(randomNumber)) {
      // Check if the number is not already in the array
      numbers.push(randomNumber); // Add the number to the array
    }
  }
  return numbers;
}

export function generateSingleNumber(existingNumbers: number[]): number {
  let randomNumber: number;
  do {
    // Generate a random number between 1 and 40
    randomNumber = Math.floor(Math.random() * 40) + 1;
  } while (existingNumbers.includes(randomNumber)); // Repeat if the number is already in the list
  return randomNumber;
}

export const kenoRiskButtonData = [
  {
    label: KENORISK.CLASSIC,
  },
  {
    label: KENORISK.LOW,
  },
  {
    label: KENORISK.MEDIUM,
  },
  {
    label: KENORISK.HIGH,
  },
  {
    label: KENORISK.CRAZY,
  },
];

export const KENO_AUTO_PLAY_DELAY = 2250;
export const KENO_AUTO_PLAY_TURBO_DELAY = 1400;
