import { Container } from "@mui/material";
import GameFeed from "../../../components/gameFeed";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import Limbo from "../../../components/limbo";
import { GAMES } from "../../../constants/games";

const LimboPage = () => {
  return (
    <Container maxWidth="md">
      <Limbo />
      <DescWrapper game={GAMES.LIMBO} />
      <GameFeed />
    </Container>
  );
};

export default LimboPage;
