import { useEffect, useState, Dispatch, SetStateAction } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import { useAppSelector } from "../store/hooks";
import { selectAllOrders } from "../slices/orderSlice";
import { format } from "date-fns";
import { Button } from "../button";
import { OrderDetails } from "./orderDetails";
import {
  EXCHANGETYPE,
  MODALTYPE,
  ORDERSTATUS,
  ORDERTYPES,
} from "../../types/index.d";
import {
  openModal,
  selectCurrentUser,
  selectPlayer,
  selectUserType,
} from "../slices/userSlice";
import { useDispatch } from "react-redux";
import { hasCashierPermission } from "../../utils";

const columns = [
  {
    id: "tokens",
    label: "Tokens",
    minWidth: 60,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 60,
    align: "right",
  },
  { id: "view", label: "", minWidth: 60 },
];

export default function Order() {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState<any | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(6);
  const userType = useAppSelector(selectUserType);
  const user = useAppSelector(selectCurrentUser);
  const orders = useAppSelector(selectAllOrders);
  const [filter, setFilter] = useState<ORDERTYPES>(ORDERTYPES.DEPOSIT);
  const dispatch = useDispatch();
  const [filteredOrders, setFilteredOrders] = useState(
    orders.filter((order) => order.exchangeType === filter)
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleValue = (key: string, value: string | number) => {
    if (key === "createdAt" && value) {
      const checkDate = new Date(value);
      if (checkDate instanceof Date && !isNaN(checkDate.getTime())) {
        return format(checkDate, "HH:mm, M/d/yy");
      }
    }
    if (key === "currency") {
      return value?.toString().replace("($/m)", "");
    }
    if (key === "amount") {
      return value !== EXCHANGETYPE.DEPOSITCRYPTO ? `${value}m` : value;
    }
    return value;
  };

  useEffect(() => {
    if (orders.length && open) {
      const updatedOrder = orders.find((order) => order._id === open._id);
      setOpen(updatedOrder);
    }
  }, [orders]);

  useEffect(() => {
    if (orders.length) {
      setFilteredOrders(
        orders.filter((order) => order.exchangeType === filter)
      );
    }
  }, [orders, filter]);

  const handleViewOrder = (order: any) => {
    if (user?._id === order.user._id && order.status === ORDERSTATUS.PENDING) {
      dispatch(openModal(MODALTYPE.CRYPTODEPOSIT));
      return;
    }
    if (
      order.type === EXCHANGETYPE.DEPOSITCRYPTO &&
      order.status === ORDERSTATUS.PENDING &&
      !hasCashierPermission(userType)
    ) {
      dispatch(openModal(MODALTYPE.CRYPTODEPOSIT));
      return;
    }
    setOpen(order);
  };

  return (
    <>
      <ToggleButtons setFilter={setFilter} filter={filter} />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#292929" }}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    sx={{
                      borderColor: "#292929",
                      fontWeight: "bold",
                      minWidth: column.minWidth,
                      backgroundColor: "#292929",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order, index) => {
                  return (
                    <TableRow
                      role="checkbox"
                      tabIndex={-1}
                      key={order.id}
                      sx={{
                        backgroundColor: index % 2 ? "#19191A" : "#202020",
                      }}
                    >
                      {columns.map((column) => {
                        const value = order[column.id];
                        if (column.id === "view") {
                          return (
                            <TableCell
                              key={column.id}
                              align={"center"}
                              sx={{ borderColor: "#292929" }}
                            >
                              <Button
                                variantType="success"
                                onClick={() => handleViewOrder(order)}
                              >
                                View
                              </Button>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={column.id}
                            align={"center"}
                            sx={{ borderColor: "#292929" }}
                          >
                            {handleValue(column.id, value)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage=""
          component="div"
          count={filteredOrders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          sx={{
            "& .MuiSelect-select": {
              display: "none !important",
            },
            "& > div.MuiToolbar-root > div.MuiInputBase-root > svg": {
              display: "none !important",
            },
          }}
        />
      </Paper>
      <OrderDetails order={open} handleClose={handleClose} />
    </>
  );
}

const ToggleButtons = ({
  filter,
  setFilter,
}: {
  filter: ORDERTYPES;
  setFilter: Dispatch<SetStateAction<ORDERTYPES>>;
}) => {
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: ORDERTYPES
  ) => {
    if (newAlignment !== null) {
      setFilter(newAlignment);
    }
  };

  return (
    <ToggleButtonGroup
      value={filter}
      exclusive
      onChange={handleAlignment}
      sx={{ mb: 1 }}
    >
      <ToggleButton
        sx={{
          color: getColor(filter === ORDERTYPES.DEPOSIT),
          backgroundColor: getBg(filter === ORDERTYPES.DEPOSIT),
        }}
        value={ORDERTYPES.DEPOSIT}
        aria-label="left aligned"
      >
        Purchases
      </ToggleButton>
      <ToggleButton
        sx={{
          color: getColor(filter === ORDERTYPES.WITHDRAWAL),
          backgroundColor: getBg(filter === ORDERTYPES.WITHDRAWAL),
        }}
        value={ORDERTYPES.WITHDRAWAL}
        aria-label="centered"
      >
        Redeems
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const getBg = (isTrue: boolean) => {
  if (isTrue) {
    return "#292929 !important";
  }
  return "#202020 !important";
};

export const getColor = (isTrue: boolean) => {
  if (isTrue) {
    return "#33EC3D !important";
  }
  return "white !important";
};
