import { GAMESTATUS } from "../../constants";
import { Button } from "../button";
import { useMotionValue, useTransform, animate, motion } from "framer-motion";
import { useEffect } from "react";
import { TokenIcon } from "../tokenIcon";

interface IBetButton {
  gameStatus: GAMESTATUS;
  revealedCells: string[];
  handlePayout: () => void;
  disabled: boolean;
  payout: number;
  isFunCash: boolean;
}

export const BetButton = (props: IBetButton) => {
  const {
    gameStatus,
    revealedCells,
    handlePayout,
    payout,
    disabled,
    isFunCash,
  } = props;

  const count = useMotionValue(0);
  const rounded = useTransform(count, (value) => Number(value.toFixed(4)));

  useEffect(() => {
    const animation = animate(count, payout, {
      duration: 2,
      ease: "easeInOut",
    });

    return animation.stop;
    // eslint-disable-next-line
  }, [payout]);

  const isRevealed = revealedCells.length > 0;
  return (
    <Button
      variantType="success"
      fullWidth
      disabled={
        (gameStatus === GAMESTATUS.INGAME && !revealedCells.length) || disabled
      }
      onClick={() => handlePayout()}
    >
      {isRevealed ? `Payout` : "Payout"}
      {isRevealed && <TokenIcon freeCash={isFunCash} />}
      {isRevealed && <motion.div>{rounded}</motion.div>}
    </Button>
  );
};
