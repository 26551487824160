import { Container, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  claimBonus,
  openModal,
  selectCurrentUser,
} from "../../components/slices/userSlice";
import { AppDispatch } from "../../components/store";
import styles from "./index.module.scss";
import { TokenIcon } from "../../components/tokenIcon";

import RWTokenIcon from "../../assets/rwgoldcoin.svg";

import DepositBg1 from "../../assets/depositBonuses/deposit_bonuses-125.webp";
import DepositBg2 from "../../assets/depositBonuses/deposit-bonuses-100.webp";
import DepositBg3 from "../../assets/depositBonuses/deposit-bonuses-75.webp";
import { Button } from "../../components/button";
import { BONUSES, MODALTYPE } from "../../types/index.d";
import { ConfirmationDialog } from "../../components/confirmationModal";
import { DepositConfirmation } from "./depositConfirmation";
import { useState } from "react";

const depositBonusData = [
  {
    background: DepositBg1,
    bgText: "125%",
    key: BONUSES.FIRSTDEPOSIT,
    title: "First deposit",
    listText: [
      "- Claim a 125% deposit bonus after your first deposit",
      "- To withdraw, you will need to wager 40x of your deposit + bonus amount",
      "- Minimum tokens to be eligible: [TOKEN] 10",
      "- Maximum deposit that will count towards the bonus: [TOKEN] 100",
    ],
  },
  {
    background: DepositBg2,
    bgText: "100%",
    key: BONUSES.SECONDDEPOSIT,
    title: "Second deposit",
    listText: [
      "- Claim a 100% deposit bonus after your second deposit",
      "- To withdraw, you will need to wager 40x of your deposit + bonus amount",
      "- Minimum tokens to be eligible: [TOKEN] 10",
      "- Maximum deposit that will count towards the bonus: [TOKEN] 100",
    ],
  },
  {
    background: DepositBg3,
    bgText: "75%",
    title: "Third deposit",
    key: BONUSES.THIRDDEPOSIT,
    listText: [
      "- Claim a 75% deposit bonus after your third deposit",
      "- To withdraw, you will need to wager 40x of your deposit + bonus amount",
      "- Minimum tokens to be eligible: [TOKEN] 10",
      "- Maximum deposit that will count towards the bonus: [TOKEN] 100",
    ],
  },
];
const rearrangeBonuses = (bonuses: any) => {
  const claimedBonuses = bonuses
    .filter((bonus: any) => bonus?.available < bonus?.total)
    .map((bonus: any) => ({ ...bonus, isClaimed: true }));

  const unclaimedBonuses = bonuses.filter(
    (bonus: any) => !(bonus.available < bonus.total)
  );

  return { unclaimedBonuses, claimedBonuses };

  // return [...unclaimedBonuses, ...claimedBonuses];
};

export const DepositBonusesData = [
  {
    type: BONUSES.FIRSTDEPOSIT,
    requirement: 1,
    reward: 1.25,
  },
  {
    type: BONUSES.SECONDDEPOSIT,
    requirement: 2,
    reward: 1,
  },
  {
    type: BONUSES.THIRDDEPOSIT,
    requirement: 3,
    reward: 0.75,
  },
];

const DepositBonuses = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(selectCurrentUser);
  const [selectedBonus, setSelectedBonus] = useState<any>(null);

  const handleClaim = (bonus: any) => {
    if (bonus.available < 0.01 || !bonus?.available) {
      dispatch(openModal(MODALTYPE.EXCHANGE));
      return;
    }
    setSelectedBonus(null);
    dispatch(claimBonus({ bonusType: bonus.type }));
  };

  const bonusesArr = depositBonusData?.map((bonus) => {
    const isBonus = user.bonuses.find((key) => bonus.key === key.type);
    if (isBonus) {
      return {
        ...bonus,
        ...isBonus,
      };
    }
    return bonus;
  });

  const { unclaimedBonuses, claimedBonuses } = rearrangeBonuses(bonusesArr);

  const multiplier =
    DepositBonusesData.find((bonus) => bonus.type === selectedBonus?.type)
      ?.reward || 0;

  const total =
    selectedBonus?.available / multiplier + selectedBonus?.available;
  const firstDepositLock2DP = (total * 40).toFixed(2);

  return (
    <Container maxWidth="md">
      <DepositConfirmation
        handleConfirm={() => handleClaim(selectedBonus)}
        open={Boolean(selectedBonus)}
        handleCancel={() => setSelectedBonus(null)}
        amount={firstDepositLock2DP}
      />
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Bonuses System
        </Typography>
        <Stack
          gap={2}
          direction={{ md: "column", sm: "column" }}
          sx={{ width: "100%" }}
        >
          {unclaimedBonuses?.length ? (
            <>
              <Typography color="text.primary" variant="h6">
                Available bonuses
              </Typography>

              {unclaimedBonuses.map((depositData: any) => (
                <BonusTile
                  {...depositData}
                  handleClaim={(props: any) => {
                    if (props.available < 0.01 || !props?.available) {
                      dispatch(openModal(MODALTYPE.EXCHANGE));
                      return;
                    }
                    setSelectedBonus(props);
                  }}
                />
              ))}
            </>
          ) : (
            <></>
          )}

          {claimedBonuses?.length ? (
            <>
              <Typography color="text.primary" variant="h6">
                Completed bonuses
              </Typography>
              {claimedBonuses.map((depositData: any) => (
                <BonusTile {...depositData} handleClaim={() => {}} />
              ))}
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export const BonusTile = (props: any) => {
  const {
    title,
    bgText,
    background,
    listText,
    available,
    handleClaim,
    type,
    isClaimed,
  } = props;
  return (
    <Stack
      direction={{ md: "row", sm: "column" }}
      gap={{ md: 4, sm: 2 }}
      key={type}
      sx={{ opacity: isClaimed ? 0.7 : 1 }}
    >
      <Stack
        sx={{
          background: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: { md: "25%", sm: "100%" },
          minHeight: "200px",
        }}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          sx={{ height: "100%" }}
          justifyContent="center"
          alignItems="center"
        >
          <Typography color="text.primary" variant="h2">
            {bgText}
          </Typography>
        </Stack>
      </Stack>
      <Stack gap={1}>
        <Typography
          // sx={{ textDecoration: "underline" }}
          color="text.primary"
          variant="h6"
        >
          {title}
        </Typography>
        <Stack
          sx={{ pl: { md: 2, sm: 0 }, height: "100%" }}
          justifyContent="space-between"
        >
          <Stack>
            {listText.map((text: string) => (
              <Typography color="text.primary">
                {formatMessage(text)}
              </Typography>
            ))}
          </Stack>

          {!isClaimed && (
            <Button variantType="success" onClick={() => handleClaim(props)}>
              {available && available > 0.01 ? (
                <p
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    padding: 0,
                    margin: 0,
                    alignItems: "center",
                  }}
                >
                  Claim &nbsp;{<TokenIcon />}
                  {available}
                </p>
              ) : (
                "Deposit"
              )}
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export const formatMessage = (text: string) => {
  const html = text.replace(
    "[TOKEN]",
    `<img src=${RWTokenIcon}  alt="Token Image" style="width: 1.2em; vertical-align: middle;"/>`
  );
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default DepositBonuses;
