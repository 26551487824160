import { createContext } from "react";
import { Socket } from "socket.io-client";

interface ChatContextProps {
  socket: Socket | null;
  isConnected: boolean;
  chatUserCount: number;
  adminUserCount: number;
}

const defaultContextValue: ChatContextProps = {
  socket: null,
  isConnected: false,
  chatUserCount: 0,
  adminUserCount: 0,
};

// Named export
export const ChatContext = createContext(defaultContextValue);

// Default export (optional, choose one export method)
export default ChatContext;
