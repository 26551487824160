import { Typography, Stack } from "@mui/material";
import styles from "./keno.module.scss";
import DiamondImg from "../../../assets/keno/green.svg";

const KenoGame = ({ game }: { game: any }) => {
  return (
    <div className={styles.grid}>
      {Array(40)
        .fill(1)
        .map((item, index) => {
          const key = index;
          const isSelected = game.selection.includes(index + 1);
          const resultsCompleted = true;
          // const resultsCompleted = game.results.length === 10;
          const isKenoNumber = game.results?.includes(index + 1);
          const isPrevSelection = game.selection?.includes(index + 1);
          return (
            <div
              key={`${item}-${index}`}
              data-incorrect={
                isPrevSelection &&
                !isKenoNumber &&
                isSelected &&
                resultsCompleted
              }
              data-correct={isPrevSelection && isSelected && isKenoNumber}
              data-selected={isSelected}
              data-iskeno={isKenoNumber}
              className={styles.tileContainer}
            >
              <Stack className={styles.tile} alignItems="center">
                {isPrevSelection && isSelected && isKenoNumber ? (
                  <Stack
                    style={{
                      backgroundImage: `url(${DiamondImg})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "2.5em",
                      height: "2.5em",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                        marginBottom: "2px",
                        fontSize: {
                          md: "1.2rem",
                          sm: "1.2rem",
                          xs: "0.8rem",
                        },
                      }}
                      key={key}
                      color="text.primary"
                      variant="h6"
                    >
                      {index + 1}
                    </Typography>
                  </Stack>
                ) : (
                  <Typography
                    sx={{
                      fontSize: {
                        md: "1.2rem",
                        sm: "1.2rem",
                        xs: "0.8rem",
                      },
                    }}
                    key={key}
                    color="text.primary"
                    variant="h6"
                  >
                    {index + 1}
                  </Typography>
                )}
              </Stack>
            </div>
          );
        })}
    </div>
  );
};

export default KenoGame;
