import configuration from "../config";
import { FAIRNESS_ROUTE } from "../constants";

const get = async (token: string, game: string, currentPage: number) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${FAIRNESS_ROUTE}?page=${currentPage}&game=${game}`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "GET",
    }
  );

  if (response.status === 409) {
    throw new Error("Can't get all transactions");
  }

  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }
  const json = await response.json();
  return json;
};

const put = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(`${configuration.api.url}/${FAIRNESS_ROUTE}`, {
    headers: {
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    credentials: "include",
    method: "PUT",
  });

  if (response.status === 409) {
    const json = await response.json();
    if (json) {
      throw new Error(json.message);
    } else {
      throw new Error("Error updating seed");
    }
  }

  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }
  const json = await response.json();
  return json;
};

const Fairness = {
  get,
  put,
};

export default Fairness;
