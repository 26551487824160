import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

export default function SvgPathLoader() {
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();

  useEffect(() => {
    const animateLoader = async () => {
      // Animate cls-2 paths
      await controls3.start({
        pathLength: 1,
        transition: { duration: 0.25, ease: "linear" },
      });
      await controls3.start({
        fillOpacity: 1,
        fill: "url(#gradient-1)",
        transition: { duration: 0.25, ease: "easeInOut" },
      });

      // Animate cls-1 paths
      await controls1.start({
        pathLength: 1,
        transition: { duration: 0.25, ease: "linear" },
      });
      await controls1.start({
        fillOpacity: 1,
        fill: "url(#gradient-0)",
        transition: { duration: 0.25, ease: "easeInOut" },
      });

      // Animate the remaining paths
      await controls2.start({
        pathLength: 1,
        transition: { duration: 0.25, ease: "linear" },
      });
      await controls2.start({
        fillOpacity: 1,
        transition: { duration: 0.25, ease: "easeInOut" },
      });
    };

    animateLoader();
  }, [controls1, controls2, controls3]);
  return (
    <svg width="359px" height="359px" viewBox="45.942 63.842 361.576 362.769">
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1="513.682"
          y1="183.585"
          x2="513.682"
          y2="402.734"
          id="gradient-1"
        >
          <stop offset="0" style={{ stopColor: "rgb(100% 100% 100%)" }} />
          <stop
            offset="1"
            style={{ stopColor: "rgb(56.687% 56.687% 56.687%)" }}
          />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          x1="517.504"
          y1="72.434"
          x2="517.504"
          y2="292.036"
          id="gradient-0"
        >
          <stop offset="0" style={{ stopColor: "rgb(20% 92.549% 23.922%)" }} />
          <stop offset="1" style={{ stopColor: "rgb(0% 54.295% 1.2266%)" }} />
        </linearGradient>
      </defs>
      <g transform="matrix(1, 0, 0, 1, -286.3525390625, 8.240900993347168)">
        <motion.path
          className="cls-1"
          d="M513.68,58.032c-98.999,0-179.551,80.551-179.551,179.551c0,98.55,80.552,179.55,179.551,179.55 c98.55,0,179.55-81,179.55-179.55C693.23,138.584,612.23,58.032,513.68,58.032z M513.68,363.135 c-58.048,0-107.099-39.603-121.5-92.7h41.4l1.352,1.35v34.649h61.648v-44.55l17.1,31.5l7.202,13.05h48.147l3.601-6.751 l3.602,6.751h41.849C595.58,340.635,556.88,363.135,513.68,363.135z M513.68,169.183H408.382 c22.049-34.649,61.199-57.599,105.298-57.599c43.652,0,82.8,22.949,105.301,57.599H513.68z"
          initial={{ pathLength: 0, pathOffset: 1, fillOpacity: 0 }}
          animate={controls2}
        />
        <motion.path
          className="cls-2"
          fill="none"
          stroke="#000000"
          strokeWidth="0.216"
          strokeMiterlimit="10"
          d="M513.68,58.032 c98.55,0,179.55,80.551,179.55,179.551c0,98.55-81,179.55-179.55,179.55c-98.999,0-179.551-81-179.551-179.55 C334.129,138.584,414.681,58.032,513.68,58.032L513.68,58.032z M513.68,111.585c43.652,0,82.8,22.949,105.301,57.599H408.382 C430.431,134.534,469.582,111.585,513.68,111.585L513.68,111.585z M520.882,306.434l-24.302-44.55v44.55h-61.648v-34.649 l-1.352-1.35h-41.4c14.401,53.098,63.452,92.7,121.5,92.7c43.2,0,81.899-22.5,104.4-56.701h-41.849l-3.602-6.751l-3.601,6.751 H520.882z"
          initial={{ pathLength: 0, pathOffset: 1, fillOpacity: 0 }}
          animate={controls2}
        />
        <motion.path
          className="cls-1"
          d="M675.682,206.534l-22.5,41.4c-4.95,72.448-65.701,129.599-139.502,129.599 c-40.5,0-76.95-17.099-102.601-44.55c-19.348-20.7-32.849-47.698-36.447-76.95h36.447h28.351l9.9,9.452v26.55h32.853v-31.052 l-18.451-18.899l14.85-19.8l-21.151-38.699H411.08h-26.999h-19.8h-6.75c-5.851,17.099-9,35.099-9,53.998 c0,52.2,24.302,99.002,62.549,129.15c27.902,22.5,63.901,36.001,102.601,36.001c90.902,0,165.151-74.252,165.151-165.151 C678.832,226.782,677.48,216.434,675.682,206.534z M376.43,207.883h34.649h28.803l8.548,11.252l-8.548,12.149H411.08h-37.799 C373.732,223.185,374.632,215.085,376.43,207.883z"
          initial={{
            pathLength: 0,
            pathOffset: 1,
            fillOpacity: 0,
          }}
          animate={controls3}
        />
        <motion.path
          className="cls-2"
          d="M513.68,72.434c-66.599,0-124.197,39.6-150.3,96.75h27.899c23.853-42.749,69.751-72,122.4-72 c57.15,0,107.102,35.102,128.701,84.601l-41.399,76.05l-11.252-20.251l29.251-53.998h-33.75l-12.601,22.949l-12.601-22.949 h-33.749l29.699,53.998l-11.248,20.251l-40.5-74.249h-33.75l58.948,108.45h30.6l12.601-23.4l12.601,23.4h30.601l53.549-98.551 l2.249-4.049C650.933,121.933,587.932,72.434,513.68,72.434z"
          initial={{
            pathLength: 0,
            pathOffset: 1,
            fillOpacity: 0,
          }}
          animate={controls1}
        />
      </g>
    </svg>
  );
}
