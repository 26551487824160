import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { User } from "./userSlice";
import { TOKENTYPE } from "../../types/index.d";

export interface DuelDocument {
  bet: number;
  payout: number;
  userRsn: string;
  staker: string;
  stakerRsn: string;
  duelType: string;
  expireAt: number;
  createdAt: Date;
  isWin: boolean;
  canCancel?: boolean;
  status: string;
  userName: string;
  userLevel: number;
  stakerLevel: number;
  userStreak: number;
  stakerStreak: number;
  user: string;
  _id: string;
  tokenType?: TOKENTYPE;
}

interface DuelState {
  stakers: User[] | null;
  pastResults: DuelDocument[];
  errorMessage: string;
}

const initialState: DuelState = {
  stakers: null,
  pastResults: [],
  errorMessage: "",
};

// Slice definition
export const duelReducer = createSlice({
  name: "duel",
  initialState,
  reducers: {
    updateStakers(state, action: PayloadAction<User[]>) {
      state.stakers = [...action.payload];
    },
    updateDuelErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    updateAllDuelResults(state, action: PayloadAction<DuelDocument[]>) {
      state.pastResults = [...action.payload];
    },
    updateDuelPastGame(state, action: PayloadAction<DuelDocument>) {
      state.pastResults.push(action.payload);
    },
    addDuelGame(state, action: PayloadAction<DuelDocument>) {
      const getIndex = state.pastResults.findIndex(
        (data) => data._id === action.payload._id
      );

      if (getIndex === -1) {
        state.pastResults.unshift(action.payload);
      } else {
        state.pastResults[getIndex] = action.payload;
      }
    },
  },
});

// Expose the actions
export const {
  updateStakers,
  updateAllDuelResults,
  updateDuelErrorMessage,
  updateDuelPastGame,
  addDuelGame,
} = duelReducer.actions;

// Selectors
export const selectDuelGames = (state: RootState) => state.duel;

// Reducer
export default duelReducer.reducer;
