import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Checkbox, Stack, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/WarningAmber";
import { Button } from "../button";
import { theme } from "../theme";

interface IConfirmationBetDialog {
  open: boolean;
  dontShowConfirmation: boolean;
  setDontShowConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm: () => void;
  handleCancel: () => void;
  text: string;
  title?: string;
  disableCheckbox?: boolean;
}

const dialogPaperStyle = {
  backgroundColor: theme.palette.primary.main, // Change the background color
  borderRadius: 6,
  boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)", // Custom box-shadow
  // Add more custom styles as needed
};

export const ConfirmationBetDialog = ({
  open,
  dontShowConfirmation,
  setDontShowConfirmation,
  handleConfirm,
  handleCancel,
  text,
  disableCheckbox,
  title = "Are you sure you want to continue?",
}: IConfirmationBetDialog) => {
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      disableScrollLock={true}
      PaperProps={{
        style: dialogPaperStyle,
      }}
    >
      <Stack
        sx={{
          bgcolor: theme.palette.primary.main,
          boxShadow: 24,
          py: 1,
          px: { md: 10, sm: 5, xs: 3 },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{ py: 2 }}
          justifyContent="space-between"
        >
          <Typography variant="h5">{title}</Typography>
          <WarningIcon fontSize="large" />
        </Stack>
        <DialogContent sx={{ px: 0 }}>
          <Stack>
            <Typography variant="h6" color="text.primary">
              {text}
            </Typography>
            {!disableCheckbox ? (
              <Stack direction="row" alignItems="center">
                <Checkbox
                  color="error"
                  value={dontShowConfirmation}
                  onChange={(_e, value: boolean) =>
                    setDontShowConfirmation(value)
                  }
                  sx={{
                    px: 0,
                    pr: 1,
                    color: theme.palette.error.main,
                  }}
                />
                <Typography variant="subtitle1">Do not show again</Typography>
              </Stack>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variantType="error" onClick={handleCancel}>
            Cancel
          </Button>
          <Button variantType="success" onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
