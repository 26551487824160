import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Stack,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import { InputFieldStyled, InputFieldWithStartAdornment } from "../inputField";
import styles from "./index.module.scss";
import { isBetGreaterthanSetValue, RoundNumber } from "../../utils";
import {
  openModal,
  selectCurrentUser,
  selectDuelArenaToggle,
  selectId,
  selectToken,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import ChatContext from "../../contexts/chat/context";
import {
  CANCEL_DUEL_GAME,
  PLAY_DUEL_GAME,
  REMOVE_ALLOCATION_DUEL_GAME,
} from "../../constants/socket";
import { MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { BetButton } from "./wagerButtons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import { DUELSTATUS, MatchCard } from "./matchCard";
import { RematchModal } from "./rematchModal";
import {
  DuelDocument,
  selectDuelGames,
  updateDuelErrorMessage,
} from "../slices/duelSlice";
import { DEFAULT_DISABLED_MESSAGE, DUELTYPE } from "../../constants";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import useSound from "use-sound";
import BetSound from "../../assets/audio/common/create-bet.mp3";
import PopUp from "./popUp";

const DuelWager: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useAppSelector(selectToken);
  const player = useAppSelector(selectCurrentUser);
  const { errorMessage, stakers, pastResults } =
    useAppSelector(selectDuelGames);
  const { socket } = useContext(ChatContext);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const userId = useAppSelector(selectId);
  const [duelType, setDuelType] = useState(DUELTYPE.DDS);
  const [userRsn, setUserRsn] = useState("");
  const [userOpponent, setUserOpponent] = useState("");
  const toggle = useAppSelector(selectDuelArenaToggle);
  const [activeGame, setActiveGame] = useState<DuelDocument | null>(null);
  const lastGameRef = useRef<DuelDocument | null>(null);
  const [rematchModal, setRematchModal] = useState(false);
  const [title, setTitle] = useState("");
  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });
  const [currentBet, setCurrentBet] = useState(0);
  const [isPopUp, setIsPopUp] = useState(false);
  const tokenType = useAppSelector(selectTokenSelection);
  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  // Handle changes to the roll over number input

  const playGame = (bet = currentBet, askConfirmation = true) => {
    if (title) {
      setTitle(title);
      return;
    }
    if (
      (bet > player.balance && tokenType === TOKENTYPE.SWEEP) ||
      !bet ||
      bet < 10
    ) {
      betFieldRef.current?.focus();
      dispatch(updateDuelErrorMessage("Please enter a valid bet amount"));
      return;
    }

    if (
      (bet > player.freeCash && tokenType === TOKENTYPE.FREE) ||
      !bet ||
      bet < 10
    ) {
      betFieldRef.current?.focus();
      dispatch(updateDuelErrorMessage("Please enter a valid bet amount"));
      return;
    }
    if (!userOpponent) {
      dispatch(updateDuelErrorMessage("Please select an opponent"));
      return;
    }
    if (!userRsn) {
      dispatch(updateDuelErrorMessage("Please enter your RSN"));
      return;
    }
    if (userRsn.length > 12) {
      dispatch(updateDuelErrorMessage("Please enter a valid RSN"));
      return;
    }
    if (!duelType) {
      dispatch(updateDuelErrorMessage("Please select a duel type"));
      return;
    }

    if (
      tokenType === TOKENTYPE.SWEEP &&
      askConfirmation &&
      ((player.isBetConfirmation &&
        isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          player?.betConfirmationValue
        )) ||
        isBetGreaterthanSetValue(currentBet, player.balance, 95))
    ) {
      setConfirmation(true);
      return;
    }

    if (!askConfirmation && dontShowConfirmation) {
      let payload: Partial<User> = {
        isBetConfirmation: false,
        betConfirmationValue: 0,
      };
      dispatch(updateSettings(payload));
    }

    const gameData = {
      currentBet: bet,
      userOpponent,
      userRsn,
      duelType,
      tokenType,
    };
    playBetSound();
    socket?.emit(PLAY_DUEL_GAME, {
      accessToken,
      gameData,
    });
    dispatch(updateDuelErrorMessage(""));

    setDisableButton(true);
    setTimeout(() => {
      setDisableButton(false);
    }, 750);
  };

  const handleCancel = (duel: DuelDocument) => {
    if (duel.canCancel) {
      socket?.emit(CANCEL_DUEL_GAME, {
        accessToken,
      });
    }
  };

  const handleCloseModal = () => {
    socket?.emit(REMOVE_ALLOCATION_DUEL_GAME, {
      accessToken,
    });
    setRematchModal(false);
  };

  const handleRematch = () => {
    if (lastGameRef.current) {
      setUserOpponent(lastGameRef.current.staker);
      setUserRsn(lastGameRef.current.userRsn);
      setDuelType(lastGameRef.current.duelType as DUELTYPE);
      playGame();
      setRematchModal(false);
    }
  };

  useEffect(() => {
    if (activeGame) {
      const updatedGame = pastResults.find(
        (duel) => duel._id === activeGame._id
      );
      if (
        updatedGame &&
        updatedGame.status === DUELSTATUS.COMPLETE &&
        activeGame.status === DUELSTATUS.ACTIVE
      ) {
        lastGameRef.current = updatedGame;
        setRematchModal(true);
        setActiveGame(null);
      }
      if (updatedGame && updatedGame.status === DUELSTATUS.CANCELLED) {
        setActiveGame(null);
      }
      if (!updatedGame) {
        setActiveGame(null);
      }
    }
    if (!activeGame) {
      const updatedGame = pastResults.find(
        (duel) => duel.user === userId && duel.status === DUELSTATUS.ACTIVE
      );
      if (updatedGame) {
        lastGameRef.current = updatedGame;
        setActiveGame(updatedGame);
      }
    }
  }, [pastResults]);

  const handleClosePopUP = (dontShowConfirmation: boolean) => {
    setIsPopUp(false);
    if (dontShowConfirmation) {
      localStorage.setItem("duel-show-popUp", "false");
    }
  };

  useEffect(() => {
    const duelShowPopUp = localStorage.getItem("duel-show-popUp");
    const show = duelShowPopUp === "false";
    if (!show && !duelShowPopUp && accessToken) {
      setIsPopUp(true);
    }
  }, [accessToken]);

  const handleDoubleBetButton = () => {
    setCurrentBet(
      handleDoubleBet(
        tokenType === TOKENTYPE.FREE ? player?.freeCash : player?.balance || 0,
        currentBet
      )
    );
  };
  return (
    <>
      <PopUp handleClose={handleClosePopUP} open={isPopUp} />
      <RematchModal
        currentBet={currentBet}
        setCurrentBet={setCurrentBet}
        handleDoubleBetButton={handleDoubleBetButton}
        open={rematchModal}
        handleClose={() => handleCloseModal()}
        handleRematch={handleRematch}
        stakerName={lastGameRef?.current?.staker}
      />
      <ConfirmationBetDialog
        open={confirmation}
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        title="Bet Confirmation"
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          playGame(currentBet, false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          95
        )}
      />

      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Duel Arena
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column", xs: "column" }}
          gap={2}
          justifyContent="space-Between"
          sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            gap={{ xs: 1, sm: 2 }}
            sx={{ width: { sm: "100%", md: "33%" } }}
            justifyContent="space-Between"
          >
            <Stack
              sx={{
                p: 3,
                backgroundColor: theme.palette.primary.main,
              }}
              gap={2}
            >
              <Stack gap={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-Between"
                >
                  <Typography color="text.primary">Bet</Typography>
                  <Button
                    onClick={() =>
                      setCurrentBet(
                        RoundNumber(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0
                        )
                      )
                    }
                  >
                    Max
                  </Button>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-Between"
                  gap={1}
                >
                  <BetField
                    game={GAMES.DUEL}
                    betFieldRef={betFieldRef}
                    setCurrentBet={setCurrentBet}
                    disabled={!accessToken}
                    currentBet={currentBet}
                    setTitle={setTitle}
                    title={title}
                    balance={player.balance}
                  />
                  <Stack direction="row" gap={1}>
                    <Button onClick={() => handleDoubleBetButton()}>x2</Button>
                    <Button
                      onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                    >
                      /2
                    </Button>
                  </Stack>
                </Stack>
                <Stack direction={{ sm: "column", xs: "row" }} gap={2}>
                  <Stack gap={{ xs: 1, sm: 2 }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-Between"
                    >
                      <Typography color="text.primary">RSN</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-Between"
                      gap={1}
                      sx={{ width: "100%" }}
                    >
                      <InputFieldStyled
                        type="string"
                        value={userRsn}
                        placeholder="RSN"
                        sx={{
                          backgroundColor: theme.palette.primary.main,
                        }}
                        onChange={(e) => setUserRsn(e.target.value)}
                        inputProps={{
                          min: 0,
                          maxLength: 12,
                        }}
                        disabled={!accessToken}
                      />
                    </Stack>
                  </Stack>
                  <Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-Between"
                    >
                      <Typography color="text.primary">Duel Type</Typography>
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-Between"
                      gap={1}
                    >
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={duelType}
                        onChange={(_e, value) => {
                          setDuelType(value as DUELTYPE);
                        }}
                      >
                        <FormControlLabel
                          value={DUELTYPE.WHIP}
                          control={
                            <Radio
                              sx={{
                                color: "white !important",
                              }}
                            />
                          }
                          label={DUELTYPE.WHIP}
                        />
                        <FormControlLabel
                          value={DUELTYPE.DDS}
                          control={
                            <Radio
                              sx={{
                                color: "white !important",
                              }}
                            />
                          }
                          label={DUELTYPE.DDS}
                        />
                      </RadioGroup>
                    </Stack>
                  </Stack>
                </Stack>

                <Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-Between"
                  >
                    <Typography color="text.primary">
                      Select Opponent
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-Between"
                    gap={1}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      onChange={(_e, value) => {
                        setUserOpponent(value);
                      }}
                      value={userOpponent}
                    >
                      {stakers?.map((staker) => (
                        <FormControlLabel
                          value={staker.userName}
                          control={
                            <Radio
                              sx={{
                                color: "white !important",
                              }}
                            />
                          }
                          label={staker.userName}
                        />
                      ))}
                    </RadioGroup>
                  </Stack>
                </Stack>

                <Stack direction="column" gap={1.25}>
                  <Stack sx={{ display: { md: "block" } }} gap={0.5}>
                    <BetButton
                      accessToken={accessToken}
                      disableButton={
                        disableButton ||
                        !!activeGame?._id ||
                        !stakers?.length ||
                        !toggle
                      }
                      playGame={playGame}
                      handleLogin={handleLogin}
                    />
                    <Typography color="error">{errorMessage}</Typography>
                  </Stack>
                  {!toggle && (
                    <Typography variant="h6" color="error">
                      {DEFAULT_DISABLED_MESSAGE}
                    </Typography>
                  )}
                </Stack>
              </Stack>
              <GameOptions game={GAMES.DUEL} />
            </Stack>
          </Stack>
          <Stack
            sx={{
              width: { md: "80%", sm: "auto" },
              display: { md: "grid", sm: "flex" },
              gridTemplateColumns: "repeat(2, 1fr)",
              mt: -1,
              gridRowGap: "0.5em",
              gridColumnGap: "0.5em",
              p: { md: 1, sm: 0 },
              overflow: "auto",
              alignContent: "start",
            }}
            justifyContent="space-between"
          >
            {pastResults
              ?.filter((duel, i) => duel.status !== "CANCELLED")
              .map((duel) => (
                <MatchCard
                  duel={duel}
                  userId={userId}
                  handleCancel={handleCancel}
                />
              ))}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default DuelWager;
