import {
  Card,
  Stack,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Container,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FAIRNESSGAMES } from ".";
import SliderWithLabel from "../../components/dice/slider";
import { InputFieldStyled } from "../../components/inputField";
import Result from "../../components/limbo/result";
import Coin from "../../components/coinFlip/coin";
import { DICETYPE, TILETYPE } from "../../types/index.d";
import styles from "./index.module.scss";
import MinesTile from "./gamesDisplay/mines";
import KenoGame from "./gamesDisplay/keno";
import { color, multiplier } from "../../components/plinko/config";
import { PLINKORISK } from "../../components/plinko/constants";
import { slideImagesArray } from "../../components/slide/constants";
import { Button } from "../../components/button";
import { useAppSelector } from "../../components/store/hooks";
import {
  selectFairnessLoading,
  updateSeed,
} from "../../components/slices/userSlice";
import { fairnessGame } from "../../constants/games";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../components/store";
import BlackJackGame from "./gamesDisplay/blackjack";
import HILOGame from "./gamesDisplay/hilo/playArea";
import { BLACKGAMESTATE } from "./gamesDisplay/hilo/constants";

export const PastGame = ({
  game,
  selection,
}: {
  game: any;
  selection: FAIRNESSGAMES;
}) => {
  const isLoading = useAppSelector(selectFairnessLoading);
  const dispatch = useDispatch<AppDispatch>();
  if (!game) return null;

  const handleGames = () => {
    switch (selection) {
      case FAIRNESSGAMES.DICE:
        return <DiceGame game={game} />;
      case FAIRNESSGAMES.COIN_FLIP:
        return <CoinFlipGame game={game} />;
      case FAIRNESSGAMES.LIMBO:
        return <LimboGame game={game} />;
      case FAIRNESSGAMES.MINES:
        return <MinesGame game={game} />;
      case FAIRNESSGAMES.KENO:
        return <KenoGame game={game} />;
      case FAIRNESSGAMES.PLINKO:
        return <PlinkoGame game={game} />;
      case FAIRNESSGAMES.SLIDE:
        return <SlideGame game={game} />;
      case FAIRNESSGAMES.BLACKJACK:
        return (
          <BlackJackGame
            dealersHand={game?.dealersHand}
            playersHands={game?.playersHands}
            currentPlayerHandIndex={game?.currentPlayerHandIndex}
            isTurnEnd={game?.isTurnEnd}
            perfectPairSideBetPayout={game?.perfectPairSideBetPayout}
            otherSideBetPayout={game?.otherSideBetPayout}
            insurance={game?.insurance}
          />
        );
      case FAIRNESSGAMES.HILO:
        return (
          <HILOGame
            dealersHand={game?.pastCards}
            playersHands={
              game?.pastCards.length
                ? [...game?.pastCards, game?.showCard]
                : game?.showCard
                ? [game?.showCard]
                : undefined
            }
            isGameEnd={game?.currentState === BLACKGAMESTATE.END}
            currentCard={game?.showCard}
            payout={game?.payout}
            multiplier={game?.multiplier}
            tokenType={game?.tokenType}
            bet={game?.bet}
            isWin={game?.isWin}
          />
        );
    }
  };

  const handleInput = () => {
    switch (selection) {
      case FAIRNESSGAMES.DICE:
        return <DiceInput game={game} />;
      case FAIRNESSGAMES.COIN_FLIP:
        break;
      case FAIRNESSGAMES.LIMBO:
        break;
      case FAIRNESSGAMES.BLACKJACK:
        break;
      case FAIRNESSGAMES.MINES:
        return <MinesInput game={game} />;
      case FAIRNESSGAMES.KENO:
        return <KenoInput game={game} />;
      case FAIRNESSGAMES.PLINKO:
        return <PlinkoInput game={game} />;
    }
  };

  const isSlide = selection === FAIRNESSGAMES.SLIDE;

  const handleChangeSeed = () => {
    dispatch(updateSeed());
  };

  const handleFairnessLink = () =>
    window.open(fairnessGame[selection].link, "_blank", "noopener");
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "stretch",
        justifyContent: "space-around",
        flexDirection: {
          md: "row",
          sm: "column-reverse",
          xs: "column-reverse",
        },
        gap: "0.5em",
      }}
    >
      <Stack sx={{ flex: 1 }}>
        <Card sx={{ p: 2, height: "100%" }}>
          <Stack gap={2}>
            <Stack direction="row" gap={2}>
              {!isSlide && (
                <Button
                  disabled={isLoading}
                  onClick={handleChangeSeed}
                  fullWidth
                  variantType="success"
                >
                  Change Seeds
                </Button>
              )}

              <Button
                onClick={handleFairnessLink}
                fullWidth
                variantType="success"
              >
                3rd Party Verification
              </Button>
            </Stack>
            <Stack>
              <Typography>Game Nonce</Typography>
              <InputFieldStyled
                value={isSlide ? game?.game?.gameNonce : game.gameNonce}
                disabled={true}
              />
            </Stack>
            {!isSlide && (
              <Stack>
                <Typography>Client Seed</Typography>
                <InputFieldStyled value={game.clientSeed} disabled={true} />
              </Stack>
            )}

            <Stack>
              <Typography>Server Seed</Typography>
              <InputFieldStyled
                value={
                  isSlide
                    ? game.game.serverSeed || "Check back in 24 hours"
                    : game.serverSeed || "Rotate seeds to verify"
                }
                disabled={true}
              />
            </Stack>
            <Stack>
              <Typography>Hash Seed</Typography>
              <InputFieldStyled value={game.hashSeed} disabled={true} />
            </Stack>
            {handleInput()}
          </Stack>
        </Card>
      </Stack>

      <div style={{ flex: 1 }}>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          {handleGames()}
        </Card>
      </div>
    </Box>
  );
};

// Input Fields
const DiceInput = ({ game }: { game: any }) => {
  return (
    <>
      <Stack>
        <Typography>Game Mode</Typography>
        <InputFieldStyled
          value={
            game.gameType === DICETYPE.ROLLOVER ? "Roll Over" : "Roll Under"
          }
          disabled={true}
        />
      </Stack>
    </>
  );
};

const MinesInput = ({ game }: { game: any }) => {
  return (
    <>
      <Stack>
        <Typography>Mines</Typography>
        <InputFieldStyled value={game.mines} disabled={true} />
      </Stack>
    </>
  );
};

const KenoInput = ({ game }: { game: any }) => {
  return (
    <>
      <Stack>
        <Typography>Risk</Typography>
        <InputFieldStyled value={game.risk} disabled={true} />
      </Stack>
    </>
  );
};
const PlinkoInput = ({ game }: { game: any }) => {
  return (
    <>
      <Stack>
        <Typography>Risk</Typography>
        <InputFieldStyled value={game.risk} disabled={true} />
      </Stack>
      <Stack>
        <Typography>Lines</Typography>
        <InputFieldStyled value={game.lines} disabled={true} />
      </Stack>
    </>
  );
};

// Games
const DiceGame = ({ game }: { game: any }) => {
  return (
    <Stack sx={{ width: "100%", px: 2, py: { xs: 5, md: 0, sm: 5 } }}>
      <SliderWithLabel
        rollOver={game.roll * 100}
        setRollOver={() => {}}
        game={game}
        gameType={game.gameType}
        isAutoplaying={false}
      />
    </Stack>
  );
};

const LimboGame = ({ game }: { game: any }) => {
  return <Result game={game} />;
};

const CoinFlipGame = ({ game }: { game: any }) => {
  const [coinClass, setCoinClass] = useState("");

  useEffect(() => {
    if (game) {
      setCoinClass(`${game.result}`);
    }
  }, [game]);
  return (
    <Coin
      coinClass={coinClass}
      setCoinClass={setCoinClass}
      setDisableButton={() => {}}
    />
  );
};

const MinesGame = ({ game }: { game: any }) => {
  const [grid, setGrid] = useState<string[][]>(
    Array(5)
      .fill(0)
      .map(() => Array(5).fill("💎"))
  );

  useEffect(() => {
    if (game) {
      const tempGrid = Array(5)
        .fill(0)
        .map(() => Array(5).fill("💎"));
      game.mineLocations.forEach((tiles: any) => {
        const [column, row] = tiles.split(",").map(Number);
        tempGrid[row][column] = TILETYPE.MINE;
      });
      setGrid([...tempGrid]);
    }
  }, [game]);

  if (!game) return null;

  return (
    <Stack sx={{ width: "100%" }}>
      <div className={styles.grid}>
        {grid.map((row, rowIndex) => (
          <>
            {row.map((cell, columnIndex) => {
              const key = `${columnIndex},${rowIndex}`;
              const isRevealed = game.openTiles.find(
                (data: any) => data.key === key
              );
              const checkIfBomb = game.mineLocations?.includes(key);
              const isLastBomb = checkIfBomb && !!isRevealed;
              return (
                <MinesTile
                  index={columnIndex + rowIndex}
                  isRevealed={isRevealed}
                  inPlay={false}
                  cellType={cell}
                  isLastBomb={isLastBomb}
                />
              );
            })}
          </>
        ))}
      </div>
    </Stack>
  );
};

const PlinkoGame = ({ game }: { game: any }) => {
  const { lines, multiplierIndex, risk } = game;
  const currentColor = color[lines - 8][multiplierIndex];
  const mul = multiplier[risk as PLINKORISK][lines - 8][multiplierIndex];
  return (
    <Stack alignItems="center">
      <Card
        sx={{
          background: currentColor.bg,
          boxShadow: `0px 3px 0px ${currentColor.shadow}`,
          p: 4,
        }}
      >
        <Typography variant="h2" color="primary">
          {`${mul >= 1000 ? `${mul / 1000}k` : mul}${mul < 100 ? "x" : ""}`}
        </Typography>
      </Card>
    </Stack>
  );
};

const SlideGame = ({ game }: { game: any }) => {
  return (
    <Stack alignItems="center" sx={{ py: { xs: 2 } }}>
      <img style={{ width: "25%" }} src={slideImagesArray[game.game.result]} />
    </Stack>
  );
};
