export const configuration = {
  api: {
    url: process.env.REACT_APP_API_ENDPOINT || "https://api.runewager.com",

    // url:  "https://api.runewager.com",
  },
  hcaptchaKey: process.env.REACT_APP_HCAPTCHA_KEY || "",
  turnstileKey: process.env.REACT_APP_TURNSTILE_KEY || "",
};

export default configuration;
