import { Stack, Typography } from "@mui/material";
import { Button } from "../../button";
import { useRef, useEffect, useState } from "react";
import Users from "../../../services/user";
import { useAppSelector } from "../../store/hooks";
import { selectPlayer } from "../../slices/userSlice";
import HCaptcha from "../../HCaptcha";

export const VerificationWarning = ({ isDisabled, setIsDisabled }: any) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { accessToken, player } = useAppSelector(selectPlayer);
  const [token, setToken] = useState<any>(null);

  const sendVerificationEmail = async () => {
    if (!token) {
      setMessage("Please complete the HCaptcha");
      return;
    }
    try {
      setLoading(true);
      const res = await Users.sendVerification(accessToken, token);
      if (res) {
        setMessage(res.message);
        setIsDisabled(true);
      }
    } catch (e: any) {
      setMessage(e.message);
    } finally {
      setLoading(false);
    }
  };

  if (isDisabled) {
    return (
      <Stack gap={2}>
        <Typography color="text.primary">
          Verification email was recently sent please try again after 60 seconds
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack gap={2}>
      <Typography color="text.primary">
        Current email is not verified please click the button below to re-send
        the verification email.
      </Typography>

      <HCaptcha onVerify={setToken} />

      {message ? (
        <Typography color="text.primary">{message}</Typography>
      ) : (
        <Button onClick={sendVerificationEmail} disabled={loading}>
          Send Verification Email
        </Button>
      )}
    </Stack>
  );
};
