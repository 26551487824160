import { useState, useEffect, useRef } from "react";
import { multiplier, color } from "./config";
import styles from "./plinko.module.scss";
import { PLINKORISK, LinesType } from "./constants";

type Props = {
  lines: LinesType;
  risk: PLINKORISK;
  pinSize: number;
  activeBlock?: number;
  multiplierHistory: Record<any, any>[];
  leftBallCount: number;
  isAuto: boolean;
};

const GameBoard = ({
  lines,
  risk,
  pinSize,
  activeBlock = -1,
  multiplierHistory,
}: Props) => {
  const [dimentions, setDimentions] = useState({
    width: 0,
    height: 0,
  });
  const boardRef = useRef<any>(null);
  const contentRef = useRef<any>(null);
  const resultRef = useRef<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setDimentions({ width: window.innerWidth, height: window.innerHeight });
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const width = dimentions.width;
    const height = dimentions.height;
    if (width >= 1000) {
      contentRef.current.style.scale = 1;
      boardRef.current.style.height = "auto";
      resultRef.current.style.scale = 1;
      resultRef.current.style.top = "45%";
    } else if (width < 1000 && width >= 424) {
      contentRef.current.style.scale = 0.5;
      boardRef.current.style.height = "380px";
      resultRef.current.style.scale = 0.5;
      resultRef.current.style.top = "20%";
    } else {
      contentRef.current.style.scale = (width - 24) / 760;
      boardRef.current.style.height = `${width - 24}px`;
      resultRef.current.style.scale = 0.5;
      // resultRef.current.style.top = "200%";
    }

    if (height < 900 && height > 700 && width >= 1000) {
      resultRef.current.style.scale = 0.8;
      contentRef.current.style.scale = 0.8;
    } else if (height < 700 && width >= 1000) {
      resultRef.current.style.scale = 0.6;
      contentRef.current.style.scale = 0.6;
    }
  }, [dimentions]);

  return (
    <div className={styles.gameBoard} ref={boardRef}>
      {/* {isAuto && leftBallCount > 0 && (
        <span className="left-ball-count">Balls Left: {leftBallCount}</span>
      )} */}
      <div className="game-content" ref={contentRef}>
        <div
          id="plinko"
          style={{
            marginTop: `${lines === 8 ? -55 : lines === 12 ? -20 : -5}px`,
          }}
        />
        <div
          className={styles.multiplierBox}
          style={{
            gap: `${pinSize * 2}px`,
            paddingLeft: `${pinSize * 2}px`,
            paddingRight: `${pinSize * 2}px`,
          }}
        >
          {multiplier[risk][lines - 8].map((mul: number, index: number) => (
            <div
              style={{
                background: color[lines - 8][index].bg,
                boxShadow: `0px 3px 0px ${color[lines - 8][index].shadow}`,
              }}
              key={String(mul) + String(index)}
              className={activeBlock === index ? styles.highlighted : ""}
            >
              {`${mul >= 1000 ? `${mul / 1000}k` : mul}${mul < 100 ? "x" : ""}`}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.multiplierHistory} ref={resultRef}>
        <div id={styles.multiContainer}>
          {multiplierHistory.map((multiplier, index) => {
            return (
              <button
                key={`${multiplier.mul}${index}${Math.random()}`}
                style={{
                  backgroundColor:
                    color[lines - 8][multiplier.index]?.bg ||
                    color[lines - 8][16 - multiplier.index]?.bg,
                  // opacity: index === 0 ? 0.2 : 1,
                }}
              >
                {multiplier.mul}x
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GameBoard;
