import { Stack, Typography, InputAdornment } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { GAMES } from "../../constants/games";
import { SLIDESTATE } from "../../constants/slide";
import { RoundNumber } from "../../utils";
import { BetField } from "../betField";
import { Button } from "../button";
import { GameOptions } from "../gameOptions";
import { InputFieldStyled } from "../inputField";
import { selectError, selectPastResults } from "../slices/slideSlice";
import { useAppSelector } from "../store/hooks";
import { theme } from "../theme";
import { TokenIcon } from "../tokenIcon";
import { imageData } from "./betButton";
import { imageByType } from "./constants";
import { handleHalfBet } from "../../utils/bets";

export const PastResults = ({
  currentBet,
  setCurrentBet,
  betFieldRef,
  balance,
  handleDoubleBet,
  disabled,
  slideResult,
  gameState,
  setTitle,
  title,
}: any) => {
  const pastResults = useAppSelector(selectPastResults);
  const errorMessage = useAppSelector(selectError);

  const keyString = [...pastResults].splice(0, 10).toString();
  return (
    <Stack
      direction={{ lg: "row", md: "column" }}
      gap={2}
      alignItems={{ lg: "flex-end", md: "flex-start" }}
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <Stack sx={{ backgroundColor: "#19191A", p: 1, borderRadius: "5px" }}>
        <GameOptions game={GAMES.SLIDE} />

        <Stack
          direction="row"
          alignItems="center"
          gap={1}
          sx={{ width: "min-content" }}
        >
          <BetField
            game={GAMES.SLIDE}
            betFieldRef={betFieldRef}
            setCurrentBet={setCurrentBet}
            currentBet={currentBet}
            setTitle={setTitle}
            disabled={disabled}
            title={title}
            balance={balance}
            sx={{
              backgroundColor: theme.palette.primary.main,
              minWidth: { md: "7.5em", xs: "120px" },
              width: { md: "9em", xs: "120px" },
              "& .MuiInputBase-input": {
                paddingLeft: "calc(8% + 20px)",
              },
            }}
          />
          <Stack direction="row" gap={1}>
            <Button
              onClick={() => handleDoubleBet()}
              disabled={disabled}
              sx={{ color: "text.primary" }}
            >
              x2
            </Button>
            <Button
              onClick={() => setCurrentBet(handleHalfBet(currentBet))}
              disabled={disabled}
              sx={{ color: "text.primary" }}
            >
              /2
            </Button>
            <Button
              onClick={() => setCurrentBet(RoundNumber(balance || 0))}
              disabled={disabled}
              sx={{ color: "text.primary" }}
            >
              Max
            </Button>
          </Stack>
        </Stack>
        {errorMessage && <Typography color="error">{errorMessage}</Typography>}
      </Stack>

      <Stack direction="column">
        <Typography color="text.primary">Last 100</Typography>
        <Stack direction="row" gap={1}>
          <AnimatePresence>
            {imageData.map((data) => (
              <Stack
                direction="row"
                alignItems="center"
                key={data.selection}
                gap={0.5}
              >
                <img style={{ width: "1.75em" }} src={data.src} />
                <Typography
                  color="text.primary"
                  fontWeight={500}
                  sx={{
                    color:
                      gameState === SLIDESTATE.RESULT &&
                      slideResult === data.selection
                        ? theme.palette.success.main
                        : theme.palette.text.primary,
                  }}
                >
                  {pastResults &&
                    (pastResults.filter((r) => r === data.selection)?.length ||
                      0)}
                </Typography>
              </Stack>
            ))}
          </AnimatePresence>
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Typography color="text.primary">Roll History</Typography>
        <Stack
          direction="row-reverse"
          gap={0.5}
          sx={{ transition: "all 1s" }}
          justifyContent="flex-end"
        >
          <AnimatePresence mode="wait">
            {[...pastResults]
              .slice(-10)
              .reverse()
              .map((imageType: number, index: number) => {
                if (index < 10) {
                  return (
                    <motion.img
                      style={{ width: "1.75em" }}
                      key={keyString + "-" + index}
                      src={imageByType[imageType]}
                      animate={{ opacity: 1, x: 0 }}
                      initial={{ x: "-100%" }}
                      exit={{ x: "-100%" }}
                      transition={{ duration: 0.5 }}
                    />
                  );
                }
                return null;
              })}
          </AnimatePresence>
        </Stack>
      </Stack>
    </Stack>
  );
};
