import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  SlideGameState,
  SlideIncomingSocket,
  SLIDESTATE,
} from "../../constants/slide";
import { sliderImagesNumber } from "../slide/constants";
import { RootState } from "../store";
import { TOKENTYPE } from "../../types/index.d";

export interface SlideDocument {
  user: string;
  bet: number;
  payout: number;
  result: number;
  createdAt: Date;
  clientSeed: string;
  serverSeed: string;
  gameType: string;
  gameNonce: number;
  multiplier: number;
  winProb: number;
  isMax: boolean;
  isWin: boolean;

  _id: string;
}
export interface Winner {
  user: string;
  value: number;
  userName: string;
  userLevel: number;
  sequenceNumber: number;
}

export interface JackpotDocument {
  amount: number;
  isHit: boolean;
  winners: Winner[];
  sequenceNumber: number;
  updatedAt: string;
  _id: string;
}

export interface SlideBet {
  bet: number;
  selection: number;
  userName: string;
  payout: number;
  userLevel: number;
  offset: number;
  user: string;
  tokenType: TOKENTYPE;
}

interface SlideState {
  slide: SlideDocument | null;
  jackpot: JackpotDocument | null;
  jackpots: JackpotDocument[];
  jackpotWinners: Winner[] | null;
  pastResults: number[];
  offset: number;
  bets: SlideBet[];
  loading: boolean;
  slideTimestamps: SlideGameState | null;
  currentState: SLIDESTATE;
  errorMessage: string;
  timer: number | null;
  result: number | null;
}

const initialState: SlideState = {
  slide: null,
  jackpot: null,
  jackpots: [],
  jackpotWinners: null,
  offset: 0,
  pastResults: [],
  bets: [],
  slideTimestamps: null,
  loading: true,
  currentState: SLIDESTATE.COUNTDOWN,
  errorMessage: "",
  timer: null,
  result: null,
};

// Slice definition
export const slideReducer = createSlice({
  name: "slide",
  initialState,
  reducers: {
    resetSlide(state) {
      state.result = null;
      state.timer = null;
      state.loading = true;
      state.pastResults = [];
    },
    updateSlideGame(state, action: PayloadAction<SlideDocument>) {
      state.errorMessage = "";
      state.slide = action.payload;
    },
    updateJackpots(state, action: PayloadAction<JackpotDocument[]>) {
      state.jackpots = action.payload;
    },
    updateSlideErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    updateSlideBets(state, action: PayloadAction<SlideBet[]>) {
      state.bets = [...action.payload];
      state.errorMessage = "";
    },
    updateJackpot(state, action: PayloadAction<JackpotDocument>) {
      state.jackpot = action.payload;
    },
    updateSlideState(state, action: PayloadAction<SLIDESTATE>) {
      state.currentState = action.payload;
      state.errorMessage = "";
      if (state.result !== null) {
        if (state.pastResults.length >= 100) {
          state.pastResults.shift();
        }
        state.pastResults.push(sliderImagesNumber[state.result]);
      }
    },
    updateSlideTimer(state, action: PayloadAction<SlideIncomingSocket>) {
      state.timer = action.payload.timer;
      state.currentState = action.payload.state;
      state.slideTimestamps = action.payload.gameStates;
      if (action.payload.jackpot) {
        state.jackpot = action.payload.jackpot;
      }
      if (action.payload.pastResults) {
        state.pastResults = action.payload.pastResults;
      }
      if (action.payload.bets) {
        state.bets = [...action.payload.bets];
      }
      state.result = null;
    },
    updateJackpotWinners(state, action: PayloadAction<Winner[]>) {
      state.jackpotWinners = action.payload;
    },
    updateSlideResult(state, action: PayloadAction<SlideIncomingSocket>) {
      state.result = action.payload.result;
      state.slideTimestamps = action.payload.gameStates;
      state.currentState = action.payload.state;
      if (action.payload.pastResults) {
        state.pastResults = action.payload.pastResults;
      }
      if (state.loading) {
        state.loading = false;
      }

      if (action.payload.bets) {
        state.bets = action.payload.bets;
      }
      // if (action.payload.bets) {
      //   console.log("another result", action.payload.bets);
      //   state.bets = [...action.payload.bets];
      // }
      if (action.payload.offset) {
        state.offset = action.payload.offset;
      }
    },
  },
});

// Expose the actions
export const {
  updateSlideState,
  updateSlideTimer,
  updateSlideBets,
  updateSlideResult,
  updateSlideGame,
  updateSlideErrorMessage,
  resetSlide,
  updateJackpot,
  updateJackpotWinners,
  updateJackpots,
} = slideReducer.actions;

// Selectors
export const selectSlideGames = (state: RootState) => state.slide;
export const selectSlideTimer = (state: RootState) => state.slide.timer;
export const selectSlideResult = (state: RootState) => state.slide.result;
export const selecGameState = (state: RootState) => state.slide.currentState;
export const selectTimestamps = (state: RootState) =>
  state.slide.slideTimestamps;

export const selectSlideLoading = (state: RootState) => state.slide.loading;
export const selectPastResults = (state: RootState) => state.slide.pastResults;
export const selectSlideBets = (state: RootState) => state.slide.bets;
export const selectSlideOffset = (state: RootState) => state.slide.offset;
export const selectJackpot = (state: RootState) => state.slide.jackpot;
export const selectJackpots = (state: RootState) => state.slide.jackpots;
export const selectWinners = (state: RootState) => state.slide.jackpotWinners;
export const selectError = (state: RootState) => state.slide.errorMessage;

// Reducer
export default slideReducer.reducer;
