export const listOfLines = [8, 9, 10, 11, 12, 13, 14, 15, 16];
export const DEFAULT_MULTIPLYER = 1.92;
export enum PLINKORISK {
  LOW = "Easy",
  MEDIUM = "Medium",
  HIGH = "Hard",
  CRAZY = "Crazy",
}

export const plinkoRishButtonData = [
  {
    label: PLINKORISK.LOW,
  },
  {
    label: PLINKORISK.MEDIUM,
  },
  {
    label: PLINKORISK.HIGH,
  },
  {
    label: PLINKORISK.CRAZY,
  },
];

export function random(min: number, max: number) {
  const random = Math.random();
  min = Math.round(min);
  max = Math.floor(max);

  return +(random * (max - min) + min).toFixed(4);
}

export type LinesType = 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16;

export const DEFAULT_MAX_BALLS = 20;
export const DEFAULT_AUTO_PLAY_DELAY_IN_MS = 750;
export const DEFAULT_SPACE_BAR_DELAY_IN_MS = 1000;
