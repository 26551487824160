import {
  Card,
  IconButton,
  Stack,
  Typography,
  List,
  Select,
  MenuItem,
  CircularProgress,
  Box,
} from "@mui/material";
import { ChatDrawer, DrawerHeader } from "./chatDrawer";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { theme } from "../theme";
import { formatDistanceToNow, parseISO } from "date-fns";
import styles from "./index.module.scss";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import {
  openModal,
  selectChatDrawer,
  selectToken,
  selectUserName,
  selectUserType,
  updateChatDrawer,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import { MessageInput } from "./inputMessage";
import { selectAllChat, selectOpenChatTo } from "../slices/chatSlice";
import { Button } from "../button";
import { CHATTYPES } from "../../constants/chat";
import {
  DEFAULT_TEXT_WARNING_EXCHANGE_CHAT,
  getOrderData,
  modifiedChatHeader,
} from "../../constants/exchange";
import { InputFieldStyled } from "../inputField";
import { Key, useContext, useEffect, useRef, useState } from "react";
import ChatContext from "../../contexts/chat/context";
import {
  EXCHANGETYPE,
  IMessage,
  MODALTYPE,
  ORDERSTATUS,
  USERTYPE,
} from "../../types/index.d";
import {
  Order,
  getOpenOrder,
  selectOpenOrder,
  selectOrderChat,
  selectOrderType,
  updateStatus,
} from "../slices/orderSlice";
import { TextBubble } from "./textBubble";
import { AdminChat } from "./adminChat";
import { ConfirmationDialog } from "../confirmationModal";
import { hasCashierPermission } from "../../utils";
import { TokenIcon } from "../tokenIcon";
import { RuneScapeIcon } from "../tokenIcon/runeScape";
import RWLogo from "../../assets/runewagerMobile.svg";
import { ChatRain } from "./chatRain";
// import Confetti from "react-confetti";

export const Chat = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [chatSelection, setChatSelection] = useState(CHATTYPES.Community);
  const [adminChatSelection, setAdminChatSelection] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const isChatDrawerOpen = useAppSelector(selectChatDrawer);
  const allChat = useAppSelector(selectAllChat);
  const accessToken = useAppSelector(selectToken);
  const userName = useAppSelector(selectUserName);
  const { chatUserCount, adminUserCount, socket } = useContext(ChatContext);
  const chatLength = useRef(0);
  const orderChatRef = useRef(0);
  const [reRender, setReRender] = useState(false);
  const reRenderRef = useRef<any>();
  const userType = useAppSelector(selectUserType);
  const chatContainerRef = useRef<HTMLUListElement | null>(null);
  const orderChat = useAppSelector(selectOrderChat);
  const handleToggle = (state: boolean) => {
    dispatch(updateChatDrawer(state));
  };
  const currentOrder = useAppSelector(selectOpenOrder);
  // const openOrder = useAppSelector(selectOpenOrder);
  const currentOrderType = useAppSelector(selectOrderType);
  const openChatTo = useAppSelector(selectOpenChatTo);

  const openOrder =
    currentOrder && currentOrder.type !== EXCHANGETYPE.DEPOSITCRYPTO
      ? currentOrder
      : ({} as Order);
  const orderType =
    currentOrder && currentOrder.type !== EXCHANGETYPE.DEPOSITCRYPTO
      ? currentOrderType
      : null;

  const renderTimeDifference = (timestamp: string) => {
    return formatDistanceToNow(parseISO(timestamp), { addSuffix: true });
  };

  useEffect(() => {
    if (chatSelection === CHATTYPES.Community) {
      setTimeout(() => {
        if (chatContainerRef.current) {
          chatContainerRef.current.scrollTop =
            chatContainerRef.current.scrollHeight;
        }
      }, 10);
    }
  }, [chatSelection]);

  useEffect(() => {
    if (
      chatLength.current !== allChat.length &&
      chatSelection === CHATTYPES.Community
    ) {
      chatLength.current = allChat.length;
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }
  }, [allChat]);

  useEffect(() => {
    if (
      orderChatRef.current !== orderChat?.length &&
      (chatSelection === CHATTYPES.DEPOSIT ||
        chatSelection === CHATTYPES.WITHDRAWAL)
    ) {
      orderChatRef.current = orderChat?.length;
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }
  }, [orderChat]);

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  const handleCancelOrder = () => {
    setOpenConfirmationModal(false);
    dispatch(
      updateStatus({ id: openOrder._id, newStatus: ORDERSTATUS.CANCELLED })
    );
  };

  let ChatMenuOptions = [CHATTYPES.Community, orderType as string];
  const isAdmin = hasCashierPermission(userType);
  if (isAdmin) {
    ChatMenuOptions = [CHATTYPES.Community, CHATTYPES.ADMIN];
  }

  useEffect(() => {
    if (orderType) {
      setChatSelection(orderType as CHATTYPES);
    }
    if (openChatTo) {
      setChatSelection(orderType as CHATTYPES);
    }
    if (!orderType) {
      setChatSelection(CHATTYPES.Community);
    }
  }, [orderType, openChatTo]);

  useEffect(() => {
    reRenderRef.current = setInterval(() => {
      setReRender((state) => !state);
    }, 10000);

    return () => {
      clearInterval(reRenderRef.current);
    };
  }, []);

  const isCrypto =
    openOrder?.type === EXCHANGETYPE.DEPOSITCRYPTO ||
    openOrder?.type === EXCHANGETYPE.WITHDRAWCRYPTO;

  const timeRef = useRef<any>();

  useEffect(() => {
    if (openOrder?.type) {
      timeRef.current = setInterval(() => {
        if (openOrder?.type) {
          dispatch(getOpenOrder());
        }
      }, 2000);
    }

    if (!openOrder?.type && timeRef.current) {
      clearInterval(timeRef.current);
    }

    return () => clearInterval(timeRef.current);
  }, [openOrder]);

  return (
    <>
      <ConfirmationDialog
        handleConfirm={handleCancelOrder}
        open={openConfirmationModal}
        handleCancel={() => setOpenConfirmationModal(false)}
        text="Are you sure you want to cancel?"
        title={`${getOrderData(openOrder.type)?.label || ""} ${
          !isCrypto ? "Gold" : ""
        } - ${openOrder?.amount || ""} `}
      />
      <ChatDrawer
        sx={{
          overflow: "none",
          width:
            isAdmin && isChatDrawerOpen && chatSelection === CHATTYPES.ADMIN
              ? "550px !important"
              : "auto",
          "& .MuiDrawer-paper": {
            width:
              isAdmin && isChatDrawerOpen && chatSelection === CHATTYPES.ADMIN
                ? "550px !important"
                : "none",
          },
          height: "100%",
        }}
        open={isChatDrawerOpen}
        variant="permanent"
        anchor="right"
      >
        <DrawerHeader
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#19191A",
            height:
              orderType || isAdmin ? { md: "14em", xs: "15.5em" } : "10.5em",
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%", py: 1 }}
          >
            <Stack direction="row" alignItems="center" gap={1}>
              <div className={styles.dot} />
              {chatSelection === CHATTYPES.Community ? (
                <Typography variant="h5">{chatUserCount} Online</Typography>
              ) : (
                <Typography variant="h5">
                  {adminUserCount} Admins Online
                </Typography>
              )}
            </Stack>
            <IconButton
              onClick={() => handleToggle(!isChatDrawerOpen)}
              sx={{ color: "white" }}
            >
              <ChevronRightIcon />
            </IconButton>
          </Stack>
          {(orderType || isAdmin) && (
            <Box
              sx={{
                p: 1,
                py: 0.5,
                minHeight: { xs: "2.5em", md: "auto" },
                zIndex: 100,
                width: "100%",
              }}
            >
              <Select
                fullWidth
                value={chatSelection}
                inputProps={{ MenuProps: { disableScrollLock: true } }}
                onChange={(e) => setChatSelection(e.target.value as CHATTYPES)}
                input={
                  <InputFieldStyled
                    sx={{
                      "& .MuiInputBase-input": {
                        "&.Mui-disabled": {
                          backgroundColor: "#777d85",
                        },
                      },
                    }}
                  />
                }
              >
                {ChatMenuOptions.map((selection: string) => (
                  <MenuItem value={selection} key={selection}>
                    {modifiedChatHeader(openOrder?.type || "", selection)}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          <ChatRain />
        </DrawerHeader>

        <Stack sx={{ overflow: "hidden" }}>
          {socket && socket?.connected === false && (
            <Stack
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack alignItems="center" gap={2}>
                <Typography variant="h6">Reconnecting...</Typography>
                <CircularProgress sx={{ color: "#33EC3D" }} />
              </Stack>
            </Stack>
          )}
          <Stack
            sx={{
              bgcolor: "#19191A",
              height: "100%",
              position: "relative",
              filter:
                socket && socket?.connected === false ? "blur(8px)" : "none",
            }}
            justifyContent="space-between"
          >
            {/* <Confetti numberOfPieces={1000} gravity={0.025} /> */}

            <List
              className={styles.messageContainer}
              ref={chatContainerRef}
              sx={{
                height: "88vh",
                overflowY:
                  chatSelection !== CHATTYPES.ADMIN ? "scroll" : "hidden",
                overflowX: "hidden",
                py: 0,
              }}
            >
              {chatSelection === CHATTYPES.Community && (
                <>
                  {allChat?.map((message: IMessage, index: number) => (
                    <TextBubble
                      id={message._id}
                      userId={message.user}
                      userType={userType}
                      userName={userName}
                      index={index}
                      key={index}
                      message={message}
                      renderTimeDifference={renderTimeDifference}
                      rank={message.rank}
                      handleToggle={handleToggle}
                    />
                  ))}
                </>
              )}

              {(chatSelection === CHATTYPES.WITHDRAWAL ||
                chatSelection === CHATTYPES.DEPOSIT) && (
                <Stack sx={{ mt: 1 }}>
                  <Stack direction="column" sx={{ ml: 2, mb: 1 }}>
                    <Typography>Order ID: {openOrder._id}</Typography>

                    <Typography>
                      Type: {getOrderData(openOrder.type)?.label || ""}
                    </Typography>
                    {isCrypto && (
                      <Typography>Crypto: {openOrder.cryptoType}</Typography>
                    )}

                    {!isCrypto && (
                      <Typography>
                        In-game Username: {openOrder.inGameUserName}
                      </Typography>
                    )}

                    <Stack direction="row" gap={0.2} alignItems="center">
                      <Typography
                        sx={{ display: "flex", gap: 0.2, alignItems: "center" }}
                      >
                        Sweep Cash: <TokenIcon /> {openOrder.tokens}
                      </Typography>
                    </Stack>
                    {chatSelection === CHATTYPES.DEPOSIT && (
                      <Stack direction="row" gap={0.2} alignItems="center">
                        <Typography
                          sx={{
                            display: "flex",
                            gap: 0.2,
                            alignItems: "center",
                          }}
                        >
                          Fun Cash: <TokenIcon freeCash /> {openOrder.freeToken}
                        </Typography>
                      </Stack>
                    )}
                    <Stack direction="row" gap={0.2} alignItems="center">
                      <Typography
                        sx={{ display: "flex", gap: 0.2, alignItems: "center" }}
                      >
                        {getOrderData(openOrder.type)?.currency.replace(
                          "($/m)",
                          ""
                        ) || ""}{" "}
                        {!isCrypto && "Gold"}: {!isCrypto && <RuneScapeIcon />}
                        {!isCrypto ? `${openOrder.amount}m` : openOrder.amount}
                      </Typography>
                    </Stack>

                    {openOrder.type === EXCHANGETYPE.DEPOSITCRYPTO && (
                      <>
                        <Typography>
                          Payment Status: {openOrder.payment_status}
                        </Typography>
                        <Typography>
                          Payment Amount: {openOrder.pay_amount}
                        </Typography>
                        <Typography>Payment Address:</Typography>
                        <Typography fontSize={"0.7rem"}>
                          {openOrder.pay_address}
                        </Typography>
                      </>
                    )}
                  </Stack>
                  <Stack direction="column" alignItems="center" sx={{ mb: 1 }}>
                    {chatSelection === CHATTYPES.DEPOSIT && (
                      <Button
                        variantType="error"
                        sx={{ width: "90%" }}
                        onClick={() => setOpenConfirmationModal(true)}
                      >
                        Cancel
                      </Button>
                    )}
                    <Typography
                      textAlign="center"
                      color="error"
                      sx={{ textWrap: "balance", whiteSpace: "break-spaces" }}
                      fontWeight={600}
                    >
                      {DEFAULT_TEXT_WARNING_EXCHANGE_CHAT}
                    </Typography>
                  </Stack>
                  <TextBubble
                    userName={"Admin"}
                    index={99}
                    message={
                      {
                        avatar: RWLogo,
                        createdAt: openOrder.createdAt,
                        userName: "Automated Message",
                        text: "Welcome to your Exchange. A cashier will be with you shortly.",
                      } as IMessage
                    }
                    renderTimeDifference={renderTimeDifference}
                  />
                  {orderChat?.map((message, index) => (
                    <TextBubble
                      userName={userName}
                      index={index}
                      message={message}
                      rank={message.rank}
                      renderTimeDifference={renderTimeDifference}
                    />
                  ))}
                </Stack>
              )}

              {chatSelection === CHATTYPES.ADMIN && (
                <AdminChat
                  selectedChat={adminChatSelection}
                  setSelectedChat={setAdminChatSelection}
                  userName={userName}
                />
              )}
            </List>
            <Stack
              justifyContent={accessToken ? "flex-end" : "center"}
              sx={{
                backgroundColor: "#19191A",
                height: "fit-content",
                pt: 1,
                pb: accessToken ? 0 : 1,
                px: 1,
              }}
            >
              {accessToken ? (
                <MessageInput
                  selectedChat={adminChatSelection}
                  orderId={openOrder._id}
                  chatType={chatSelection}
                />
              ) : (
                <Button onClick={handleLogin} fullWidth>
                  Login to Chat
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ChatDrawer>
    </>
  );
};
