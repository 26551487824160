import { Card, Stack, Typography } from "@mui/material";
import { TokenIcon } from "../tokenIcon";

export const SideBet = (props: {
  payout: number;
  typeOfBet: string;
  isFunCash: boolean;
}) => {
  const { typeOfBet, payout, isFunCash } = props;
  return (
    <Card
      sx={{
        py: { md: 2, xs: 0.5 },
        px: { md: 1, xs: 0.5 },
        alignItems: "flex-start",
        userSelect: "text",
        background: "#202020",
        boxShadow: "inset 0 0 5px 0px rgba(0, 255, 0, 0.75)",
        textAlign: "center",
        width: { md: "12em", xs: "12em" },
      }}
    >
      <Typography variant="caption">
        {typeOfBet.replaceAll("_", " ")}
      </Typography>
      <Stack direction="row" justifyContent="center" gap={0.1}>
        <TokenIcon freeCash={isFunCash} />
        <Typography sx={{ color: "#32c932" }}>{payout}</Typography>
      </Stack>
    </Card>
  );
};
