import { Box, Card, Typography, Container, Stack } from "@mui/material";
import NotFoundSVG from "../../assets/runewagerMobile.svg";
import { styled } from "@mui/material/styles";
import { Button } from "../../components/button";

const MainContent = styled(Box)(
  ({ theme }) => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

function Status404() {
  return (
    <>
      <Container maxWidth="md">
        <Box textAlign="center">
          <img alt="404" height={180} src={NotFoundSVG} />
          <Typography color="text.primary" variant="h3" sx={{ my: 2 }}>
            Page Not Found
          </Typography>
        </Box>
        <Stack justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
          <Button
            sx={{ width: "max-content", px: 3 }}
            href="/"
            variantType="success"
          >
            Go to homepage
          </Button>
        </Stack>
      </Container>
    </>
  );
}

export default Status404;
