import { Box, Stack, Typography } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import { theme } from "../theme";
import NotFoundSVG from "../../assets/runewagerMobile.svg";
import { useAppSelector } from "../store/hooks";
import { selectIsBlocked } from "../slices/userSlice";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: "50%", md: "40%" },
  zIndex: 10,
  py: 4,
  px: 10,
  borderRadius: 4,
  backdropFilter: "blur(10px)",
};

const GeoBlock = () => {
  const open = useAppSelector(selectIsBlocked);
  return (
    <ModalComponent
      open={open}
      onClose={() => {}}
      disableScrollLock={true}
      sx={{ backdropFilter: "blur(5px)" }}
    >
      <Box sx={modalStyle}>
        <Stack gap={3}>
          <Typography
            color="text.primary"
            variant="h3"
            sx={{ my: 2 }}
            textAlign="center"
          >
            Region Blocked
          </Typography>
          <img alt="404" height={200} src={NotFoundSVG} />

          <Stack justifyContent="center" alignItems="center" sx={{ mt: 4 }}>
            <Typography color="text.primary" sx={{ my: 2 }}>
              Unfortunately Runewager is not available in your region
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default GeoBlock;
