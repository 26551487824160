import configuration from "../config";
import { FEED_ROUTE } from "../constants";

const getBets = async (token: string) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${FEED_ROUTE}/my-bets`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "GET",
    }
  );

  if (response.status === 409) {
    throw new Error("Can't get your bets");
  }

  if (!response.ok) {
    let errorMessage = "Invalid Response";

    try {
      const errorResponse = await response.json();
      errorMessage = errorResponse.message || errorMessage;
    } catch (e) {
      console.log(e);
      console.error("Error parsing error response as JSON:", e);
    }
    throw new Error(errorMessage);
  }
  const json = await response.json();
  return json;
};

const Feed = {
  getBets,
};

export default Feed;
