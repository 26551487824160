import configuration from "../config";
import { BLACKJACK_ROUTE } from "../constants";

const getGame = async (token: string) => {
  const response = await fetch(
    `${configuration.api.url}/${BLACKJACK_ROUTE}/getGame`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "GET",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const BlackJackServices = {
  getGame,
};

export default BlackJackServices;
