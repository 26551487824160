import configuration from "../config";
import { MINE_ROUTE } from "../constants";

const post = async (bet: number, numOfMines: number, token: string) => {
  const raw = JSON.stringify({
    bet,
    numOfMines,
  });
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const response = await fetch(
    `${configuration.api.url}/${MINE_ROUTE}/createGame`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "POST",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getTile = async (key: string, token: string) => {
  const raw = JSON.stringify({
    key,
  });
  const response = await fetch(
    `${configuration.api.url}/${MINE_ROUTE}/checkCell`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "POST",
      body: raw,
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getPayout = async (token: string) => {
  const response = await fetch(
    `${configuration.api.url}/${MINE_ROUTE}/payout`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "GET",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const getGame = async (token: string) => {
  const response = await fetch(
    `${configuration.api.url}/${MINE_ROUTE}/getGame`,
    {
      headers: {
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      method: "GET",
    }
  );

  if (!response.ok) {
    if (response.status !== 404) {
      const json = await response.json();
      if (json) {
        throw new Error(json?.message);
      }
      throw new Error("Invalid Response");
    }
    return null;
  }

  const json = await response.json();
  return json;
};

const MineServices = {
  post,
  getTile,
  getPayout,
  getGame,
};

export default MineServices;
