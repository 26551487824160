import { Stack, Typography } from "@mui/material";
import { InputField, InputFieldStyled } from "../inputField";
import { Formik, Form } from "formik";
import { initialValues, validationSchema } from "./constants";
import {
  registerUser,
  selectPlayer,
  setErrorMessage,
} from "../slices/userSlice";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "../store";
import { useAppSelector } from "../store/hooks";
import { Button } from "../button";
// import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useRef, useEffect, useState, ChangeEvent } from "react";
import HCaptcha from "../HCaptcha";
import { useTurnstile } from "react-turnstile";

const Register = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, errorMessage } = useAppSelector(selectPlayer);
  const [token, setToken] = useState<any>(null);
  const hCaptchaRef = useRef<any>();
  const referredBy = localStorage.getItem("referredBy");
  const turnstile = useTurnstile();
  const [referredByLocal, setReferredByLocal] = useState(
    referredBy ? JSON.parse(referredBy) : ""
  );

  useEffect(() => {
    if (errorMessage && token) {
      setToken(null);
      turnstile.reset();
    }
  }, [errorMessage]);

  const submitHandler = async (values: {
    email: string;
    userName: string;
    password: string;
    referral: string;
  }) => {
    if (!token) {
      dispatch(setErrorMessage("Please complete the HCaptcha"));
      return;
    }

    const { email, password, userName, referral } = values;
    const userData = {
      email,
      password,
      userName,
      referral,
      token,
      referredBy: referredByLocal ? referredByLocal : "",
    };
    dispatch(registerUser(userData));
  };

  const handleReferredChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setReferredByLocal(e.target.value);
    localStorage.setItem("referredBy", JSON.stringify(e.target.value));
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, submitForm, handleSubmit, errors }) => (
          <Form>
            <Stack gap={1.5}>
              <Stack direction="column">
                <Typography fontWeight={500} color="text.primary">
                  Username
                </Typography>
                <InputField
                  inputProps={{ maxLength: 12 }}
                  id="userName"
                  placeholder="Username"
                  name="userName"
                  type="userName"
                />
              </Stack>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography fontWeight={500} color="text.primary">
                  Password
                </Typography>
                <InputField
                  fullWidth
                  type="password"
                  name="password"
                  placeholder="Password"
                />
              </Stack>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography fontWeight={500} color="text.primary">
                  Confirm Password
                </Typography>
                <InputField
                  name="reEnterPassword"
                  label="Confirm Password"
                  type="password"
                  placeholder="Confirm Password"
                />
              </Stack>
              <Stack direction="column">
                <Typography fontWeight={500} color="text.primary">
                  Email
                </Typography>
                <InputField
                  name="email"
                  label="Email"
                  placeholder="Email"
                  type="email"
                />
              </Stack>
              <Stack direction="column" sx={{ mb: 2 }}>
                <Typography fontWeight={500} color="text.primary">
                  Referred by
                </Typography>
                <InputFieldStyled
                  name="referredBy"
                  placeholder="Referred By"
                  type="referredBy"
                  onChange={(e) => handleReferredChange(e)}
                  value={referredByLocal}
                  inputProps={{ maxLength: 12 }}
                />
              </Stack>
            </Stack>
            <HCaptcha onVerify={setToken} forceNormal />
            <Typography
              color="text.primary"
              fontWeight={300}
              sx={{ fontSize: 12, my: 1 }}
            >
              From signing up to our website you are confirming that you are 18
              years of age or older and agree to our <b>Terms of Service</b>,{" "}
              <b>Rules & Polices</b>
            </Typography>

            <Button
              disabled={loading || !token}
              variantType="success"
              fullWidth
              type="submit"
            >
              Complete Registration
            </Button>
            {errorMessage && (
              <Typography fontWeight={500} color="error" sx={{ mt: 1 }}>
                {errorMessage}
              </Typography>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Register;
