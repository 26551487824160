import redIcons from "../../assets/coinFlip/fire.svg";
import blackIcons from "../../assets/coinFlip/water.svg";
import { theme } from "../theme";
import { COINFLIP } from "../../types/index.d";

export const coinFlipImages = [
  {
    src: redIcons,
    selection: COINFLIP.HEADS,
    color: "#770000",
    hover: "#cc0000",
    boxShadow: "0 5px 15px #cc0000",
    textColor: theme.palette.text.primary,
  },
  {
    src: blackIcons,
    selection: COINFLIP.TAILS,
    color: "#0d116c",
    hover: "#191fca",
    boxShadow: "0 5px 15px #191fca",
    textColor: theme.palette.text.primary,
  },
];

export const DEFAULT_MULTIPLYER = 1.92;
