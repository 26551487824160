import { Stack, Typography } from "@mui/material";
import { InputField } from "../inputField";
import { Formik, Form } from "formik";
import {
  resetPassword,
  selectPlayer,
  setErrorMessage,
} from "../slices/userSlice";
import { useDispatch } from "react-redux";
import type { AppDispatch } from "../store";
import { useAppSelector } from "../store/hooks";
import { Button } from "../button";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import HCaptcha from "../HCaptcha";
import { useTurnstile } from "react-turnstile";

const initialValues = {
  email: "",
};

export const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email must be valid")
    .max(255, "Email should be less than 255 characters")
    .required("Email is required"),
});

const ForgetPassword = ({
  setIsForgetPassword,
}: {
  setIsForgetPassword: (vale: boolean) => void;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, errorMessage, passwordResetMessage } =
    useAppSelector(selectPlayer);
  const [token, setToken] = useState<any>(null);
  const turnstile = useTurnstile();

  const submitHandler = async (values: { email: string }) => {
    if (!token) {
      dispatch(setErrorMessage("Please complete the HCaptcha"));
      return;
    }

    const { email } = values;
    const userData = { email, token };
    dispatch(resetPassword(userData));
  };

  useEffect(() => {
    if (errorMessage && token) {
      setToken(null);
      turnstile.reset();
    }
  }, [errorMessage]);

  if (passwordResetMessage) {
    return (
      <Stack>
        <Typography sx={{ py: 2 }} color="text.primary">
          Password reset instructions have been sent to your email
        </Typography>
        <Button
          color="error"
          fullWidth
          onClick={() => setIsForgetPassword(false)}
        >
          Back
        </Button>
      </Stack>
    );
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, submitForm, handleSubmit, errors }) => (
          <Form>
            <Stack gap={1.5}>
              <Stack direction="column" sx={{ width: "100%" }}>
                <Typography fontWeight={500} color="text.primary">
                  Email
                </Typography>
                <InputField
                  fullWidth
                  type="email"
                  name="email"
                  placeholder="Email"
                />
              </Stack>
              <HCaptcha onVerify={setToken} />
            </Stack>

            {errorMessage && (
              <Typography sx={{ py: 2 }} fontWeight={500} color="error">
                {errorMessage}
              </Typography>
            )}

            <Stack gap={1} sx={{ mt: 2 }}>
              <Button
                disabled={loading}
                variantType="success"
                fullWidth
                type="submit"
              >
                Send Email
              </Button>
              <Button
                color="error"
                fullWidth
                onClick={() => setIsForgetPassword(false)}
              >
                Back
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ForgetPassword;
