import {
  Box,
  Card,
  Typography,
  Container,
  Stack,
  IconButton,
  Collapse,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { FAQData } from "./constant";

const CollapsableCard = ({ question, answer }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <Card sx={{ p: 2 }} onClick={() => setOpen(!open)} key={question}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        sx={{ width: "100%" }}
      >
        <Typography>{question}</Typography>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div style={{ width: "100%", height: "1px", background: "#DBDBDB" }} />
        {answer.map((text: string, index: number) => (
          <Typography key={index} sx={{ py: 1.5 }}>
            {text}
          </Typography>
        ))}
      </Collapse>
    </Card>
  );
};

const FAQPage = () => {
  return (
    <Container maxWidth="md">
      <Typography
        className="heading"
        sx={{ mb: 2 }}
        variant="h1"
        color="text.primary"
      >
        FAQ
      </Typography>
      <Stack gap={2}>
        {FAQData.map(({ question, answer }) => (
          <CollapsableCard key={question} question={question} answer={answer} />
        ))}
      </Stack>
    </Container>
  );
};

export default FAQPage;
