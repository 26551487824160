import { Container } from "@mui/material";
import Slots from "../../../components/slots";
import { useState } from "react";

const SlotsPage = () => {
  const [isTheater, setIsTheater] = useState<any>(false);

  return (
    <Container maxWidth={isTheater ? "xl" : "md"} sx={{ p: isTheater ? 0 : 2 }}>
      <Slots setIsTheater={setIsTheater} isTheater={isTheater} />
      {/* <DescWrapper game={GAMES.DICE} />
      <GameFeed /> */}
    </Container>
  );
};

export default SlotsPage;
