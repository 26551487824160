import { PLINKORISK } from "../constants";

const plinko8Targets = [
  [283.1355, 292.5076, 284.21],
  [
    290.0645, 290.3709, 292.651, 283.3442, 296.8909, 291.8639, 291.6406,
    282.9505, 292.6367, 283.3058, 304.8751, 300.4603, 304.5054, 283.9874,
    289.946, 284.2835, 305.0123, 283.1599, 282.8678, 319.3149, 289.9914,
    290.1963, 283.3143, 304.2879, 291.6212, 289.9773, 290.322,
  ],
  [
    317.4278, 301.0633, 287.9698, 317.7701, 317.7486, 292.4856, 290.6532,
    318.9244, 289.0812, 298.0337, 288.3861, 296.3759, 282.9404, 286.848,
    319.1673, 305.037, 297.3332, 285.7881, 319.4557, 295.1947, 306.2156,
    287.0649, 306.3059, 283.7498, 291.5763, 289.3597, 295.1996, 304.5655,
    295.2149, 299.0202,
  ],
  [
    291.1217, 292.3867, 285.2477, 291.3337, 282.6892, 313.8337, 314.2069,
    288.5057, 295.8742, 314.092, 312.9509, 305.3319, 280.1927, 318.8263,
    284.6848, 305.4248, 284.9917, 317.9802, 290.8795, 291.0492, 312.3795,
    291.3649, 285.1301, 303.5131, 300.8562, 285.5754, 299.9046, 294.7369,
    284.4541, 300.5238,
  ],
  [
    298.2801, 284.8628, 297.1145, 296.2185, 318.1411, 285.0089, 291.8203,
    305.8478, 291.9701, 308.2239, 291.4466, 306.5109, 306.2659, 284.7566,
    301.8672, 286.3255, 302.1453, 306.9693, 305.5328, 313.0953, 298.6436,
    286.9194, 291.8984, 308.5099, 281.8831, 312.8043, 315.8737, 280.0599,
    302.5144, 286.3621,
  ],
  [
    296.6219, 316.2071, 309.2055, 294.3755, 306.4729, 294.7028, 294.2525,
    302.486, 311.2362, 301.684, 294.9322, 303.1673, 296.4723, 309.3158,
    310.5573, 309.2135, 286.0986, 281.5187, 293.036, 301.4433, 303.1233,
    284.2554, 317.3255, 287.1915, 306.8164, 313.3986, 305.7312, 315.2806,
    315.5184, 307.641,
  ],
  [
    303.6018, 316.315, 311.1625, 280.5938, 281.0206, 310.9235, 307.3964,
    280.6425, 311.1591, 316.3698, 310.8682, 280.7513, 314.7004, 311.7935,
    295.7911, 302.1131, 309.3965, 314.5501, 302.7524, 306.7913, 298.5257,
    315.1916, 310.6849, 319.4973, 301.7513, 312.5706, 307.7015, 310.6403,
    303.2921, 319.5069,
  ],
  [
    299.8885, 301.3468, 302.6716, 307.4414, 295.1227, 308.3308, 309.563,
    316.7191, 315.7213, 306.8808, 309.9086, 307.3414, 309.6646, 307.4175,
    299.266, 280.6738, 307.3111, 309.7864, 307.4282,
  ],
  [
    303.0274, 301.6988, 302.0935, 303.0867, 315.8159, 316.8681, 316.6365,
    316.5251,
  ],
];

const plinko9Targets = [
  [
    293.862, 289.4086, 293.6094, 292.905, 289.2893, 298.2038, 293.5323,
    295.9787, 292.7241, 295.4933, 292.9142,
  ],
  [
    289.259, 294.6625, 287.5831, 297.7259, 287.7076, 295.661, 288.2055, 289.558,
    292.3929, 287.6795, 296.4201, 295.4994, 295.2977, 293.5247, 288.1592,
    296.0239, 289.3842, 294.6417, 295.226, 287.6034, 289.5993, 295.8685,
    296.3519, 287.6047, 298.1196, 292.7834, 288.0927, 290.8864, 296.4698,
    290.8137,
  ],
  [
    286.3122, 288.1084, 294.329, 287.6136, 289.0226, 302.7545, 295.4738,
    288.3479, 297.8372, 297.3698, 287.9905, 291.7966, 287.4499, 290.3667,
    283.9434, 289.1904, 287.0736, 288.5017, 295.7237, 318.2971, 296.0126,
    289.9217, 300.6565, 298.6606, 297.3842, 288.2273, 293.7148, 291.4373,
    288.6327, 292.9804,
  ],
  [
    299.4846, 316.8149, 301.3996, 299.974, 309.4844, 313.3741, 291.2412,
    314.794, 293.2415, 296.957, 293.8122, 288.1309, 297.8097, 287.8661,
    314.7005, 317.3148, 309.2466, 287.4762, 318.2771, 294.8658, 287.1203,
    319.4992, 298.3932, 286.8845, 288.4908, 285.0654, 286.4231, 319.4488,
    316.4682, 294.8642,
  ],
  [
    308.4512, 302.6175, 292.6093, 317.7759, 316.4162, 284.381, 308.2828,
    281.9782, 297.4106, 313.8099, 312.9459, 296.872, 284.3352, 295.6839,
    315.3533, 284.799, 283.8693, 304.9217, 297.8286, 284.1641, 313.4217,
    308.2729, 299.492, 280.3958, 299.7383, 317.5827, 291.6562, 317.857,
    316.9126, 291.9702,
  ],
  [
    307.9947, 280.7535, 314.2925, 300.8494, 313.7176, 299.9524, 281.1614,
    284.6376, 315.1254, 314.0926, 298.617, 305.164, 295.031, 296.6678, 307.3971,
    280.0084, 281.7818, 315.8328, 284.7784, 304.2932, 317.5553, 307.9761,
    312.6285, 315.5247, 315.806, 299.5934, 319.3708, 319.6774, 285.5968,
    315.8512,
  ],
  [
    307.9293, 297.2022, 306.2891, 316.058, 306.6509, 283.1482, 281.0409,
    310.9844, 304.2651, 306.2277, 280.498, 310.3677, 282.0488, 285.5821,
    318.9053, 308.1844, 282.2937, 308.6273, 302.603, 303.958, 306.7738,
    305.0489, 300.1201, 300.6235, 306.6429, 312.8889, 307.9721, 290.3155,
    284.7946, 310.2587,
  ],
  [
    312.0102, 306.1, 297.1225, 303.4265, 312.3436, 310.958, 303.4922, 311.6473,
    311.9867, 312.0882, 302.2419, 304.1722, 308.9711, 307.9205, 312.0353,
    312.9246, 282.1663, 312.2657, 302.0457, 316.964, 304.0939, 283.5073,
    311.2057, 312.4015, 311.7752, 305.0179, 313.0391, 312.3778, 312.1749,
    305.4009,
  ],
  [
    305.5143, 310.8541, 303.8383, 310.8741, 304.1883, 304.2338, 304.0757,
    306.3349, 305.618, 306.9918, 312.5455, 310.8911, 305.3435, 305.2933,
    306.4736, 306.1977, 303.7379, 310.7655, 312.2881, 303.7846, 305.3319,
    308.5587, 307.1192, 307.2017, 307.0697, 310.047, 305.9728, 305.0352,
    311.9674, 304.0848,
  ],
  [
    305.5759, 303.8496, 306.0518, 307.2314, 304.0293, 304.5392, 304.0307,
    304.06, 307.0812, 307.2801, 303.5696, 306.3636, 310.7804, 303.6056,
    305.8663, 311.0732, 305.9267, 307.247, 304.0879, 303.5272, 307.3261,
    305.977, 306.1564, 311.7048,
  ],
];

const plinko10Targets = [
  [293.4622, 294.6063, 287.3998, 293.4481, 292.2822],
  [
    291.2055, 293.6408, 293.4355, 290.3312, 287.521, 287.5905, 291.5905, 296.75,
    293.8671, 319.5762, 289.7394, 294.2816, 287.4252, 294.136, 293.4107,
    293.2439, 290.2323, 292.2774, 291.2634, 289.7523, 291.5934, 291.3353,
    315.306, 289.2912, 291.7381, 286.0602, 289.7533, 291.7778,
  ],
  [
    289.0331, 287.1775, 287.4566, 287.8088, 293.4388, 292.5179, 316.249,
    289.8333, 314.7357, 285.661, 303.4065, 319.5893, 289.9107, 291.7394,
    291.8893, 292.9697, 286.4614, 315.3909, 280.9146, 286.6408, 314.6521,
    316.714, 315.4267, 291.3466, 314.3826, 292.9266, 317.6195, 285.4637,
    291.2411, 300.8837,
  ],
  [
    300.586, 288.7458, 286.6382, 303.6078, 291.6196, 287.3155, 296.029,
    298.7371, 292.8206, 281.4771, 289.4053, 293.6321, 315.2256, 302.2418,
    291.9155, 304.117, 289.5268, 287.8477, 319.6827, 290.2621, 297.9998,
    293.0078, 287.5872, 289.9606, 287.6695, 303.3707, 295.1045, 297.3355,
    301.4043, 291.56,
  ],
  [
    314.7055, 314.6131, 288.5717, 289.5758, 282.0387, 303.4078, 290.0895,
    295.2352, 315.9351, 286.2509, 303.6591, 314.2423, 301.1871, 282.5687,
    286.3687, 295.1844, 317.0809, 318.6448, 280.0445, 281.6622, 290.3418,
    291.489, 303.4492, 290.2763, 285.5425, 287.8728, 305.8197, 281.7602,
    318.8836, 300.2432,
  ],
  [
    283.0956, 292.124, 286.5994, 290.2945, 310.5578, 304.4279, 307.2118,
    309.1559, 287.7005, 316.5042, 301.4523, 301.495, 280.4325, 298.7474,
    285.3773, 295.2281, 315.7584, 318.2828, 285.5429, 301.7102, 285.6688,
    317.9506, 285.88, 289.1881, 306.1626, 317.4778, 299.8248, 315.7245,
    283.5661, 280.8153,
  ],
  [
    304.1541, 306.0104, 280.2984, 304.2646, 297.6471, 300.6078, 297.8145,
    318.4644, 284.6577, 310.1736, 304.9907, 284.5642, 305.522, 307.2842,
    296.7862, 286.2866, 284.5027, 284.6811, 317.3708, 317.9396, 310.8021,
    304.089, 295.618, 319.7993, 288.4776, 307.5735, 313.5967, 285.1766,
    304.3364, 318.8572,
  ],
  [
    300.4137, 317.9823, 318.5318, 307.1982, 308.7205, 307.7251, 306.9108,
    283.9722, 308.4057, 312.7054, 282.8666, 285.0448, 309.5915, 317.4432,
    313.7305, 299.264, 312.5681, 306.6791, 318.4378, 313.2716, 295.9999,
    310.1192, 296.02, 281.9808, 318.4343, 302.5203, 280.2582, 300.9902,
    308.5443, 302.7329,
  ],
  [
    308.1629, 305.3332, 309.6287, 283.1626, 313.2294, 310.6724, 304.9593,
    302.9702, 304.7597, 280.4019, 283.3294, 284.1744, 312.8342, 298.2405,
    284.1736, 308.8121, 296.4224, 313.613, 310.2924, 283.203, 306.4736,
    283.2623, 314.5313, 299.9361, 309.61, 283.2741, 308.9506, 306.993, 310.6822,
    309.2946,
  ],
  [
    310.2403, 310.1252, 308.262, 310.6511, 306.2055, 309.3604, 303.1031,
    308.2476, 311.756, 306.2579, 306.482, 308.179, 306.2285, 311.2475, 301.484,
    313.0002, 308.9549, 309.0955, 312.4437, 284.7216, 306.4525, 307.0983,
    284.4483, 308.8019, 310.7797, 312.6452, 313.3697,
  ],
  [306.7928, 306.5486, 309.3678, 310.8211, 306.5399, 302.9905, 307.9735],
];

const plinko11Targets = [
  [290.5837, 293.6194, 293.6012],
  [
    292.848, 290.5666, 290.2875, 290.9094, 293.6158, 292.4235, 294.588,
    286.8183, 290.2248, 290.2186, 292.8483, 293.0223, 290.8251, 287.1301,
    294.4146, 292.8711,
  ],
  [
    289.5387, 292.4284, 283.9879, 295.1842, 290.5022, 293.764, 287.4969,
    292.4262, 302.7077, 299.0902, 294.4344, 291.5971, 289.7975, 302.081,
    299.649, 293.802, 293.7617, 287.8484, 286.6345, 294.4644, 292.1091,
    290.2048, 290.6141, 292.3499, 292.1331, 301.9524, 289.6967, 289.926,
    290.8195, 287.7474,
  ],
  [
    294.3043, 285.3357, 281.2699, 295.8456, 291.4738, 283.6805, 292.0738,
    296.6144, 284.1668, 280.5687, 293.8839, 282.1114, 285.2157, 294.9824,
    300.3307, 295.7939, 292.3071, 313.6701, 293.8859, 291.2917, 293.8005,
    292.007, 292.8616, 298.6518, 301.7323, 296.5776, 289.1131, 302.7588,
    317.8481,
  ],
  [
    294.4974, 304.0468, 310.5343, 292.468, 310.8656, 289.8401, 284.9319,
    288.1519, 286.7479, 311.7816, 285.7743, 302.37, 308.4695, 314.4578,
    306.6559, 311.2471, 296.2472, 315.1506, 295.0954, 294.2223, 308.8072,
    308.7909, 291.133, 314.9897, 302.1838, 317.3721, 293.5018, 315.7813,
    296.1681, 310.5766,
  ],
  [
    280.7793, 297.0385, 295.4702, 304.4333, 294.948, 280.1775, 313.3027, 315.03,
    284.4652, 317.6552, 283.3736, 305.1881, 290.7014, 286.7144, 304.4963,
    292.261, 289.5925, 315.3213, 282.8738, 308.1397, 284.0539, 288.7239,
    301.834, 296.1778, 311.2653, 285.2012, 288.1312, 290.8564, 314.7071,
    292.3714,
  ],
  [
    307.0562, 304.4614, 297.8451, 282.7378, 314.5267, 303.4349, 303.8992,
    295.6153, 281.786, 315.9684, 317.4703, 299.9679, 307.6053, 289.4491,
    283.8268, 311.893, 300.1854, 298.506, 306.835, 285.1, 317.5452, 301.4068,
    312.0328, 288.2378, 318.185, 313.5576, 310.5156, 308.0177, 282.7785,
    303.3057,
  ],
  [
    316.2812, 287.7627, 303.6154, 299.6744, 311.3611, 301.8916, 309.9116,
    303.8922, 283.1518, 317.5712, 301.8478, 301.0147, 280.3935, 295.4999,
    312.9432, 315.7073, 314.3236, 305.6273, 306.4219, 292.8994, 314.8632,
    299.3859, 319.6127, 314.1796, 296.8468, 316.2535, 316.1371, 308.6879,
    297.6489, 298.2054,
  ],
  [
    310.1315, 297.232, 284.5494, 308.9126, 293.3403, 284.7174, 300.9732,
    281.3641, 309.0777, 282.1454, 299.5966, 309.076, 286.5885, 282.25, 312.9071,
    281.365, 285.4261, 313.2596, 285.2815, 307.413, 310.8199, 289.3458,
    303.7304, 313.2114, 285.5561, 280.0713, 300.7046, 306.0745, 288.5362,
    282.5948,
  ],
  [
    281.8921, 305.1323, 309.0726, 306.4279, 307.3509, 301.5961, 305.5626,
    308.5911, 310.4571, 312.0637, 298.5116, 315.489, 309.274, 305.5352,
    312.3284, 305.8936, 282.7273, 308.1004, 281.9402, 309.8739, 311.8612,
    308.7207, 308.176, 284.9903, 296.8431, 316.3893, 309.6946, 318.8737,
    315.8986, 306.9309,
  ],
  [
    307.8285, 309.4168, 312.1482, 309.705, 305.1672, 306.4153, 305.3481,
    306.1248, 305.3292, 309.7516, 306.5075,
  ],
  [306.3793, 309.3784],
];
const plinko12Targets = [
  [290.4525, 280.7904],
  [
    304.6392, 300.3261, 292.0107, 291.244, 290.7813, 292.168, 290.4965,
    283.2478, 313.9885, 300.8425, 290.762, 290.6336, 295.9978, 304.3784,
    292.7206, 292.519, 280.7877, 288.0138, 291.063, 315.2788, 292.9731, 291.677,
    294.0551, 296.2932, 292.5627, 293.9108, 292.5426, 280.5666, 293.307,
    290.4624, 291.6396, 293.3193, 291.1546, 290.45, 283.2664, 290.5075,
    304.6599, 292.8384, 293.8468, 291.0222,
  ],
  [
    291.2962, 288.0206, 293.366, 313.9399, 292.3126, 290.7372, 293.5757,
    294.2048, 293.3921, 280.7222, 304.6719, 292.1909, 290.799, 290.5608,
    293.3234, 300.0626, 292.2922,
  ],
  [
    295.1037, 288.5862, 291.5046, 280.831, 312.8948, 288.8948, 292.9609,
    292.7271, 317.2202, 290.879, 282.7697, 300.3982, 292.8311, 289.1511,
    314.7814, 292.6516, 291.2205, 288.2007, 282.4263, 306.2541, 288.4251,
    293.3437, 293.3657, 317.7197, 287.9342, 281.944, 302.8376, 288.5869,
    280.5814, 288.6852,
  ],
  [
    317.9958, 291.4254, 300.4429, 294.7972, 314.6884, 296.1221, 297.3832,
    293.4802, 301.5186, 317.6453, 315.9651, 281.4088, 283.789, 294.1829,
    299.2939, 291.7808, 311.0081, 308.5161, 286.3343, 280.3128, 317.1131,
    314.4871, 295.0945, 281.847, 288.3045, 304.1338, 300.0602, 291.8769,
    289.721, 305.3373,
  ],
  [
    283.8484, 288.7323, 291.5564, 282.4668, 283.62, 289.1132, 317.8868,
    297.7351, 287.3883, 283.2237, 317.8171, 299.1174, 301.228, 303.5242,
    281.9045, 292.0821, 288.4521, 280.0707, 313.3797, 295.5301, 316.2511,
    295.1659, 280.2678, 317.5175, 300.6359, 287.5824, 297.8846, 287.8616,
    314.077, 283.1644,
  ],
  [
    281.5372, 281.5942, 316.7323, 295.3402, 314.45, 280.3454, 302.5202,
    293.9767, 289.51, 311.7784, 300.0216, 301.9044, 299.0064, 306.4891,
    286.8132, 287.3032, 281.8234, 303.649, 313.6787, 305.7359, 294.0395,
    295.8723, 300.157, 287.3951, 305.787, 305.0529, 298.7012, 299.1077,
    315.4546, 293.9488,
  ],
  [
    280.2887, 299.0959, 281.7169, 305.8519, 285.202, 298.2786, 297.5515,
    280.6303, 312.4097, 313.929, 316.2988, 309.7406, 289, 288.6983, 283.4919,
    311.4501, 286.299, 303.0041, 312.9626, 293.0718, 318.9964, 281.9081,
    312.357, 296.4011, 314.3767, 312.1415, 286.2223, 296.4998, 297.5357,
    318.0655,
  ],
  [
    315.9806, 316.0412, 288.1286, 285.8405, 308.7243, 300.9012, 297.7756,
    312.8394, 299.8656, 305.8209, 300.827, 300.1207, 299.5587, 306.9213,
    298.1496, 313.7307, 318.7334, 298.6162, 308.1182, 318.0833, 298.2007,
    281.0626, 298.8521, 317.5748, 286.8767, 304.4809, 285.1042, 282.2613,
    296.5452, 306.7804,
  ],
  [
    285.9154, 302.3974, 286.798, 307.1871, 316.1998, 312.5248, 283.3719,
    293.7989, 305.1477, 297.1733, 284.8687, 303.8612, 313.2667, 293.7341,
    312.3049, 284.7382, 319.5868, 287.4853, 285.5379, 307.52, 295.35, 310.4246,
    308.6399, 303.5573, 308.6332, 308.3081, 319.2054, 298.0446, 288.5418,
    306.8025,
  ],
  [
    309.8434, 312.1105, 318.8429, 306.0755, 297.1493, 286.7733, 319.6297,
    282.9166, 312.5734, 298.2281, 306.1607, 316.1142, 283.4991, 309.8776,
    309.884,
  ],
  [308.9781, 303.8411, 309.0727, 310.4341, 309.094, 309.2968],
  [307.3341, 319.7367, 307.3917, 308.3318],
];

const plinko13Targets = [
  [
    293.783, 293.785, 283.3656, 283.3481, 283.4256, 293.8227, 293.6679,
    293.6243,
  ],
  [
    298.3459, 290.1162, 283.3655, 288.7934, 293.784, 290.01, 283.4901, 282.7658,
    318.5738, 288.665, 293.963, 283.4449, 289.7792, 303.0108, 294.2566,
    308.5653, 289.9799, 293.9032, 308.5058, 289.9174, 293.6787, 318.4986,
    283.3507, 293.4476, 315.3723, 283.6592, 289.9787, 293.2252, 289.8018,
    280.2969, 317.0651, 282.786, 283.3447, 290.2921,
  ],
  [
    289.5469, 307.6378, 302.9952, 283.4134, 296.3815, 295.2813, 290.2282,
    282.7677, 283.6647, 283.4831, 288.6095, 293.3034, 282.8029, 315.6156,
    317.0663, 294.2151, 282.9513, 283.3368, 302.7284, 308.5122, 283.3417,
    290.3693, 302.9493, 290.0111, 290.2151, 293.4242, 293.5106, 283.4509,
    288.8352, 301.9864, 283.3457, 288.7774, 291.2557, 289.9443, 318.5548,
    285.9526, 288.8068, 289.677, 304.1203, 290.334, 313.0743, 283.3326,
  ],
  [
    293.2227, 300.0841, 299.8157, 293.4561, 284.0543, 297.8363, 293.7673,
    292.4003, 282.9897, 289.5797, 293.3522, 309.4398, 307.9029, 288.6407,
    315.2895, 308.5243, 308.1764, 293.9356, 304.7821, 306.954, 293.7222,
    294.2267, 293.4582, 315.9083, 311.9782, 314.9275, 307.4271, 289.7794,
    289.7955, 307.6346,
  ],
  [
    288.6462, 291.6744, 293.5132, 301.1977, 298.4951, 319.3315, 297.5637,
    303.0332, 290.1288, 313.7636, 297.4059, 297.4083, 319.0885, 291.1991,
    299.8888, 293.0082, 308.8234, 281.3678, 289.0403, 291.203, 293.4997,
    302.9902, 284.701, 289.7705, 299.8234, 305.4437, 287.7884, 298.2764,
    319.661, 294.4009,
  ],
  [
    315.7313, 282.854, 294.7474, 286.5172, 317.3221, 285.4917, 281.5958, 300.41,
    303.2577, 319.7548, 298.6497, 302.8693, 294.3796, 306.8708, 308.0923,
    298.1062, 284.0407, 284.9351, 312.1495, 311.6555, 307.5775, 309.4982,
    302.4879, 285.9406, 296.2069, 290.1502, 282.8116, 300.9898, 317.2742,
    307.1835,
  ],
  [
    306.8989, 313.0249, 284.4006, 303.9625, 304.7873, 314.5508, 293.7297,
    284.4152, 315.6488, 307.2493, 301.771, 298.037, 294.7106, 280.0991,
    299.4805, 294.1154, 312.3764, 315.1147, 280.3168, 302.3389, 293.1845,
    281.773, 290.5763, 302.1711, 304.1646, 295.6188, 302.5889, 319.5305,
    288.5069, 284.0052,
  ],
  [
    319.7565, 316.9908, 290.2359, 286.5616, 281.6943, 307.9004, 302.8589,
    289.621, 297.315, 280.29, 289.2538, 312.5521, 319.6899, 297.0553, 303.5375,
    319.8107, 301.8451, 288.9165, 315.7707, 299.2973, 288.5447, 285.0957,
    301.703, 302.0959, 314.8434, 305.0706, 287.5112, 305.1083, 280.2904,
    284.6033,
  ],
  [
    301.211, 296.4266, 298.8538, 295.0389, 311.3758, 284.996, 291.3411,
    319.8011, 317.3656, 282.8517, 315.3086, 307.1726, 300.0371, 303.3981,
    310.1156, 299.5724, 296.6578, 284.0992, 281.8989, 294.5572, 309.5605,
    315.06, 314.572, 290.5444, 316.8699, 289.3227, 316.4898, 299.4977, 283.7024,
    298.1397,
  ],
  [
    299.5721, 316.6128, 298.8956, 313.9733, 310.1535, 287.5524, 288.1321,
    311.0538, 290.5171, 281.5064, 318.6395, 297.6455, 281.3301, 301.0953,
    316.3609, 283.8199, 316.5519, 282.53, 304.6524, 286.9293, 314.6555,
    298.5288, 314.9656, 291.3259, 308.7987, 298.5377, 306.8436, 282.5541,
    304.5307, 315.4235,
  ],
  [
    280.9585, 282.0988, 311.3873, 288.2581, 304.3581, 301.6845, 299.1154,
    307.0626, 309.6772, 304.8334, 312.1357, 291.5772, 310.6531, 298.4301,
    313.8976, 305.22, 316.5069, 304.2, 283.2357, 296.6206, 295.2517, 298.5061,
    299.7605, 310.6598, 309.7217, 299.3394, 305.4112, 287.9345, 310.3095,
    304.7074,
  ],
  [
    299.1211, 305.9758, 316.4945, 310.0258, 291.313, 288.0392, 292.265,
    310.7321, 316.5364, 318.338,
  ],
  [
    311.3291, 309.9917, 313.982, 291.5322, 306.1669, 314.5023, 305.305,
    316.5835, 317.2592, 306.4717, 296.9528, 316.5643, 310.4564, 288.5183,
    317.2253, 316.6509, 310.3147, 316.6534, 310.1747, 291.8594, 306.6957,
    316.6762, 310.2461, 316.6176, 316.3116, 310.2927, 316.6348, 306.7622,
    311.3256, 310.2309,
  ],
  [
    306.4668, 316.6519, 306.1307, 319.7004, 316.3143, 316.5398, 306.396,
    316.5847, 316.3234, 316.3175, 310.2976, 311.3318, 316.5444,
  ],
];

const plinko14Targets = [
  [293.1577, 293.1749, 293.174, 293.7346, 293.8316],
  [
    276.2328, 277.5571, 277.5653, 277.9394, 285.1115, 285.1259, 285.1884,
    285.2691, 325.415, 325.031, 321.194, 320.937, 320.632, 320.54, 317.286,
    317.157, 317.043, 287.0952, 287.2147, 287.3044, 287.3533, 287.3642,
    287.4113, 287.4747, 287.9439, 288.1857, 288.1848, 288.2319, 288.2663,
    288.3352, 289.375, 289.4258, 289.4321, 289.4874, 289.5345, 289.5417,
    289.6024, 292.5888, 293.0372, 293.0481, 293.0644, 293.087, 293.1296,
    293.1269, 293.1459, 293.1558, 293.154, 293.1504, 293.1395, 293.1531,
    293.1712, 293.1785, 293.2075, 293.2102, 293.5299, 293.5308, 293.7147,
    293.7183, 293.8596, 293.9267, 293.9493, 293.9883, 294.1477, 294.1567,
    294.1649, 294.1984, 294.6531, 315.6008, 317.289, 293.0704, 293.2077,
    294.6841, 293.0944, 316.7522, 315.624,
  ],
  [
    287.2148, 311.9832, 316.6623, 293.4231, 293.708, 301.9666, 301.5202,
    288.3349, 294.0148, 285.1255, 309.3643, 303.218, 294.1448, 294.161,
    287.4101, 281.9606, 318.695, 293.0852, 290.1367, 294.1037, 285.1871,
    301.3814, 292.2087, 318.7187, 304.1753, 300.9432, 293.5972, 287.731, 294.25,
    286.4333,
  ],
  [
    285.2253, 281.4222, 315.7242, 316.0951, 286.8607, 288.6303, 316.705,
    303.5929, 318.9738, 293.2365, 301.9416, 314.703, 315.7592, 287.7761,
    313.6693, 301.0857, 307.3623, 292.9765, 293.4358, 281.9622, 281.656,
    310.0896, 281.3425, 293.922, 316.2425, 289.3667, 318.4861, 293.3476,
    309.5248, 318.7025,
  ],
  [
    290.499, 301.2922, 313.3642, 282.3678, 302.5486, 311.1892, 315.6313,
    308.5733, 287.3962, 303.117, 307.7511, 310.206, 311.0902, 318.3976,
    285.1238, 282.2952, 298.8818, 285.8723, 289.9989, 290.5501, 293.8796,
    307.7144, 301.8519, 319.5267, 301.4162, 281.382, 308.7913, 307.318,
    307.2917, 287.0833,
  ],
  [
    307.3174, 282.8971, 296.0322, 280.7355, 294.7017, 289.4041, 285.8739,
    300.4369, 289.6347, 315.7055, 292.1887, 295.1569, 316.5509, 294.6458,
    284.988, 307.0905, 297.5091, 300.4812, 281.9316, 305.8556, 292.9726,
    285.185, 281.093, 287.7222, 303.3326, 316.6805, 312.2391, 319.8262,
    300.8429, 316.92,
  ],
  [
    285.1719, 295.805, 317.1729, 307.9384, 280.6795, 314.146, 289.4123,
    301.1436, 287.0915, 283.9378, 302.0695, 287.8491, 280.1117, 312.9332,
    302.7203, 319.1552, 288.9968, 291.499, 284.5544, 291.4968, 296.833,
    316.0927, 281.463, 318.1984, 312.3995, 311.1253, 316.1338, 305.057,
    300.5583, 315.6407,
  ],
  [
    292.8928, 311.9088, 300.4641, 284.0089, 297.3277, 311.4513, 302.9265,
    316.8923, 291.0044, 300.9161, 313.7919, 311.6163, 281.5081, 301.1179,
    301.5269, 313.4214, 291.6163, 297.3614, 299.2227, 285.9231, 289.033,
    286.5843, 317.4915, 285.7131, 284.7105, 314.37, 298.297, 280.0566, 313.9965,
    289.0632,
  ],
  [
    282.8154, 303.9158, 296.1941, 287.8868, 302.5979, 317.17, 304.7827,
    282.3979, 285.6634, 317.8015, 284.1736, 283.4099, 305.3957, 283.7429,
    314.8074, 304.8107, 301.8086, 318.5951, 318.8816, 289.0931, 299.2714,
    311.0527, 304.4781, 292.5906, 289.1781, 281.6529, 287.8235, 314.6258,
    314.0535, 310.5424,
  ],
  [
    301.5188, 297.5761, 295.8873, 315.2912, 297.6567, 282.1549, 297.0105,
    284.1538, 300.784, 291.5453, 317.1716, 309.9229, 282.9344, 283.3597,
    319.2753, 282.8777, 318.1369, 286.9156, 309.8616, 295.3278, 284.6035,
    305.7417, 304.0639, 288.2791, 303.9926, 301.6242, 302.504, 316.2896,
    290.3656, 307.0948,
  ],
  [
    318.2872, 305.9016, 312.0684, 309.7292, 312.2102, 296.4172, 290.0747,
    285.3474, 292.786, 297.8543, 308.5556, 306.8543, 307.5967, 309.4587,
    318.6179, 313.5557, 292.1626, 282.2435, 318.1022, 305.8601, 299.3724,
    281.2907, 281.2899, 298.3315, 291.9098, 282.9126, 304.7734, 306.4532,
    305.4599, 284.1822,
  ],
  [
    284.0905, 288.0425, 290.5088, 280.2239, 310.5033, 300.0053, 306.9825,
    297.6076, 280.7008, 282.7483, 309.678, 283.7437, 285.3101, 314.8022,
    305.9086, 290.7332, 297.7626, 280.3469, 318.086, 284.8025, 314.9271,
    303.9831, 304.8474, 305.8454, 312.6883, 306.8313, 305.9955, 318.0916,
    299.721, 297.2163,
  ],
  [
    314.7215, 283.135, 290.1871, 310.5768, 297.3121, 314.9883, 314.9273,
    305.687,
  ],
  [312.6467, 289.9607, 312.5253, 312.7853, 306.8118],
  [277.8189],
];
const plinko15Targets = [
  [280.0033, 285.7811, 285.731],
  [
    288.3359, 288.5044, 295.2637, 309.2072, 309.426, 315.5142, 291.6911,
    290.1599, 283.783, 294.207, 296.5883, 301.0424, 313.5391, 317.4672,
    294.5383, 307.0959, 284.8624,
  ],
  [
    315.2589, 281.2667, 296.5386, 282.7079, 306.8072, 316.4196, 280.3144,
    316.742, 291.9228, 280.1335, 314.6771, 290.1598, 300.1387, 283.6983,
    304.9795, 315.3253, 282.7067, 306.6346, 313.783, 294.8515, 284.2159,
    318.2978, 314.592, 294.3377, 295.1153, 300.4981, 300.5195, 302.492,
    312.6694,
  ],
  [
    295.9634, 294.4675, 295.5975, 296.4473, 307.1429, 297.7981, 280.6222,
    313.5395, 287.9356, 283.5231, 281.399, 297.3481, 314.5507, 304.9302,
    293.9519, 311.164, 318.4914, 299.238, 318.7119, 296.0515, 294.4668,
    302.8952, 293.4358, 315.5105, 313.4806, 285.8172, 288.1463, 290.4221,
    285.1929, 280.569, 307.2593, 315.5493, 287.841, 301.4183, 297.4911, 282.072,
    315.2163, 311.834, 313.4865, 282.0644, 309.0916, 319.5695, 285.1087,
  ],
  [
    312.4369, 295.497, 317.0776, 296.837, 318.8302, 316.4031, 301.3531,
    292.5271, 289.8293, 305.2122, 307.1442, 313.2247, 318.4234, 295.7954,
    282.1656, 317.5565, 311.0276, 317.8709, 283.9858, 297.6542, 297.3587,
    287.7799,
  ],
  [
    314.6702, 315.2523, 315.5854, 299.8996, 298.8278, 295.7977, 306.8523,
    307.4515, 306.2563, 313.3593, 282.7242, 281.945, 310.8016, 285.9428,
    283.1934, 291.9272, 306.8835, 292.7795, 288.1448, 294.1918, 309.6581,
    283.0837, 283.1378, 286.0109, 318.596, 294.5232, 306.9432, 307.9167,
    300.1495, 308.987,
  ],
  [
    316.1413, 317.1291, 309.2539, 302.1244, 292.8264, 304.5218, 296.9543,
    285.3335, 300.5977, 300.2576, 312.5016, 301.9118, 307.5987, 293.2873,
    300.0504, 313.4981, 301.1721, 314.3989, 315.8416, 307.806, 284.3528,
    296.5742, 293.5017, 313.7108, 301.9508, 282.3594, 299.257, 301.5181,
    282.5502, 306.2728,
  ],
  [
    319.0736, 316.4017, 315.0358, 318.28, 306.5351, 300.2911, 280.1067,
    302.1466, 303.5285, 304.4214, 319.4055, 317.0836, 293.4945, 292.9434,
    285.93, 314.0383, 316.7723, 309.6484, 297.1265, 315.8408, 313.419, 284.6953,
    292.2564, 288.891, 284.1536, 310.406, 315.4616, 294.4591, 316.0123,
    301.5759,
  ],
  [
    295.7566, 290.7863, 316.8635, 312.4214, 310.5641, 301.0309, 285.2773,
    295.7867, 291.5697, 283.436, 299.5612, 297.5246, 302.069, 298.2335,
    292.7209, 300.8531, 319.4228, 289.8809, 298.3853, 291.5418, 296.5557,
    299.83, 307.8783, 317.7476, 281.8747, 316.2473, 311.0472, 281.1275,
    292.1611, 281.8855,
  ],
  [
    313.1634, 289.79, 307.4479, 300.2514, 306.051, 304.6943, 291.8659, 308.3318,
    294.1787, 292.4325, 312.031, 295.1394, 281.1098, 308.0188, 295.6336,
    314.5664, 303.7015, 318.6612, 315.6132, 294.7692, 293.7096, 297.252,
    293.3398, 312.8273, 317.2204, 287.6039, 299.9692, 281.4313, 289.2507,
    288.9736,
  ],
  [
    297.6289, 288.7945, 285.0427, 310.2081, 316.9024, 314.8748, 314.8908,
    281.3499, 307.7342, 281.6084, 280.5603, 308.3079, 289.9433, 294.9093,
    290.645, 292.1379, 311.5589, 310.796, 310.3737, 304.2984, 292.9638,
    298.3003, 304.4371, 285.4565, 296.6716, 305.5667, 305.4849, 319.9924,
    283.65, 290.624,
  ],
  [
    316.7012, 317.8614, 304.5, 314.195, 298.5296, 285.1412, 302.2509, 286.1878,
    305.6115, 293.2097, 299.4103, 293.0519, 291.3225, 292.9083, 286.824,
    314.1609, 304.8861, 298.6708, 307.7046, 312.161, 299.5671, 288.7892,
    310.2707,
  ],
  [
    312.1972, 319.9683, 314.2217, 304.7457, 312.1372, 303.386, 284.4151,
    314.2846, 298.0944, 284.6476, 292.7014, 311.4979, 312.063, 281.6336,
    305.5666, 284.5042, 284.8049, 310.5816, 295.8244, 293.0234, 312.2344,
    319.1724, 294.3634, 315.7452, 286.5135, 281.6532, 299.2775, 316.1274,
    284.5502, 283.5943, 291.5736, 308.5127, 306.522, 299.9146, 283.2226,
    314.9005, 299.0994, 308.5696, 281.1504, 285.5512,
  ],
  [
    286.995, 290.6436, 292.7609, 299.5812, 319.8925, 319.8397, 312.1144,
    311.4773, 314.2117, 286.4585, 283.4218, 313.6791, 284.4125, 298.3179,
    293.6338, 284.7654, 281.1713, 305.249, 284.8079, 284.7531, 292.0653,
    283.8107, 312.307, 281.6767, 304.3198, 293.1948, 280.4345, 286.1964,
    303.5122, 306.0086, 285.3685, 311.4179, 317.7545, 308.3027, 298.2704,
    305.5791, 291.573, 305.4551, 311.5208,
  ],
  [
    299.1431, 314.2686, 291.5393, 319.9566, 284.5052, 303.6517, 308.3089,
    299.5308, 292.3823, 283.2604, 298.9061, 319.4898, 311.5496, 305.2922,
    286.4592, 303.3105, 284.7158, 314.2693, 318.9315, 299.2835, 304.7461,
    286.458,
  ],
  [318.721],
];

const plinko16Targets = [
  [326.06],
  [
    294.8138, 294.875, 308.9147, 294.6742, 295.3142, 295.5168, 309.6312,
    313.8576, 303.2726,
  ],
  [
    295.3143, 305.828, 312.1275, 314.6489, 314.3874, 307.8513, 309.071,
    295.1669, 295.8106, 313.5168, 287.9174, 299.6369, 300.8779, 317.9834,
    309.7566, 316.3435, 286.5625, 284.0404, 316.3223, 312.6583, 319.7428,
    302.2485,
  ],
  [
    313.3453, 316.3275, 302.8599, 319.3661, 301.4507, 311.9194, 317.8113,
    296.5151, 317.1334, 312.1573, 295.1824, 308.991,
  ],
  [
    292.4976, 313.3325, 313.1947, 308.8207, 309.4435, 286.5555, 291.5973,
    288.9594, 295.5713, 308.9883, 306.2337, 314.6127, 310.6609, 289.9441,
    287.2163, 303.1214, 319.3167, 312.0952, 309.0031, 280.0554, 288.9912,
    304.1442, 280.0592, 313.9564, 312.5477, 291.707, 295.9664, 293.886, 298.926,
    293.1879,
  ],
  [
    283.5317, 303.7806, 298.2327, 285.6553, 308.3323, 306.1011, 316.3579,
    315.882, 298.5184, 301.5726, 288.4676, 319.6477, 313.7246, 281.6662,
    313.5145, 318.7094, 294.8998, 282.9419, 317.3043, 293.7623, 314.8656,
    294.7228, 312.7161, 313.0782, 319.0392, 294.8551, 301.314, 303.3608,
    295.125, 306.3171,
  ],
  [
    306.932, 293.4981, 310.3182, 289.4808, 282.5273, 285.4579, 288.9096,
    300.8582, 316.7882, 282.5616, 313.075, 285.736, 291.1867, 313.5191,
    313.2529, 307.8067, 311.4444, 283.926, 285.7495, 280.3561, 289.0021,
    302.8939, 283.1771, 296.485, 303.5298, 281.1409, 303.9287, 290.2948,
    283.6713, 289.8098,
  ],
  [
    290.679, 294.505, 307.4717, 304.2679, 280.3048, 297.2807, 312.9158,
    315.5699, 280.2903, 287.3159, 305.8039, 288.531, 288.696, 286.751, 314.6753,
    291.6672, 314.3481, 311.9902, 319.828, 282.9413, 287.142, 309.0583,
    282.9764, 309.2206, 282.8759, 297.6791, 286.7196, 292.2523, 309.4233,
    289.0313,
  ],
  [
    283.7139, 314.1167, 318.3634, 289.3874, 286.7318, 307.8805, 293.7893,
    283.0063, 319.9688, 292.1319, 318.7647, 299.282, 311.3147, 307.0983, 309.51,
    284.8359, 286.7126, 290.7615, 285.4212, 285.8465, 307.764, 307.4687,
    304.9114, 299.5547, 292.5742, 305.0768, 307.4456, 293.7026, 289.8768,
    316.0655,
  ],
  [
    314.3939, 309.3343, 303.3321, 288.1292, 304.2276, 303.0095, 296.5244,
    292.0744, 305.0743, 311.6247, 283.7108, 312.3464, 300.2428, 293.7693,
    285.9535, 317.7484, 313.9546, 306.9273, 317.8421, 312.5844, 309.7854,
    315.8574, 298.3143, 287.2936, 294.6106, 295.5872, 289.6929, 305.2089,
    294.4971, 301.528,
  ],
  [
    293.4111, 301.9072, 317.0731, 290.2934, 302.8523, 287.0451, 300.8302,
    306.8197, 284.6196, 280.6537, 302.8354, 297.7464, 284.12, 287.8602,
    291.3698, 284.8396, 309.6696, 305.3592, 301.6039, 312.5756, 291.3616,
    313.3177, 285.3651, 312.4191, 288.1128, 311.8929, 306.6361, 287.542,
    284.009, 292.625,
  ],
  [
    304.0197, 282.1475, 304.7658, 293.3801, 285.5847, 289.1091, 296.6011,
    294.3832, 289.6415, 291.327, 316.2936, 293.9315, 307.3609, 295.8535,
    311.1224, 293.555, 299.3256, 305.146, 304.4924, 283.0328, 313.7089,
    310.7315, 299.8475, 309.7031, 285.0252, 287.9004, 283.9315, 297.8605,
    286.2759, 307.5874,
  ],
  [
    306.8577, 285.9522, 305.1168, 285.7522, 308.5829, 305.3791, 299.0934,
    312.6045, 304.1373, 318.011, 299.5464, 297.6267, 284.1214, 285.3185,
    301.0901, 305.2865, 296.7127, 315.8882, 285.6105, 296.4863, 298.1247,
    313.2205, 292.6704, 312.8256, 291.0067, 299.0598, 298.9164, 293.4547,
    285.1643, 302.012,
  ],
  [
    292.2281, 312.7793, 296.8632, 280.7326, 285.3641, 287.5035, 305.3018,
    311.8411, 281.0517, 307.422, 308.2855, 317.3945, 308.5297,
  ],
  [
    285.1401, 297.8756, 290.3925, 297.8767, 291.3682, 299.2586, 284.513,
    315.761, 313.5514, 306.9012, 286.8634, 284.4331, 308.1044, 283.4197,
    300.035, 307.4219, 301.629, 284.0547, 300.3179, 300.9885, 305.363, 299.5744,
    302.8159, 284.733, 294.2882, 280.3385, 294.1592, 283.613, 296.4618,
  ],
  [283.0535, 286.6577, 291.8286, 304.5125, 298.1228, 283.9448, 304.5858],
  [292.8066],
];

const plinkoPinPositions = [
  [...plinko8Targets],
  [...plinko9Targets],
  [...plinko10Targets],
  [...plinko11Targets],
  [...plinko12Targets],
  [...plinko13Targets],
  [...plinko14Targets],
  [...plinko15Targets],
  [...plinko16Targets],
];

export const getRandomPinPosition = (
  lines: number,
  multiplierIndex: number
) => {
  const targetIndex = plinkoPinPositions[lines - 8][multiplierIndex];
  return targetIndex[Math.floor(Math.random() * targetIndex.length)];
};

export const ballColors = {
  [PLINKORISK.LOW]: "#FFC000",
  [PLINKORISK.MEDIUM]: "#FF6020",
  [PLINKORISK.HIGH]: "#FF302F",
  [PLINKORISK.CRAZY]: "#FF003F",
};
