import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Chip,
  Typography,
  Stack,
  Card as MUICard,
} from "@mui/material";
import Card from "./card";
import * as HILO from "./constants";
import { motion } from "framer-motion";
import { TOKENTYPE } from "../../../../types/index.d";
import { InputFieldWithStartAdornment } from "../../../../components/inputField";
import { RoundNumber } from "../../../../utils";
import { theme } from "../../../../components/theme";

interface PlayAreaProps {
  dealersHand: HILO.Card[] | undefined;
  playersHands: any[] | undefined;
  isGameEnd: boolean | undefined;
  currentCard: HILO.Card | undefined;
  multiplier?: number;
  payout?: number;
  tokenType?: TOKENTYPE;
  bet?: number;
  isWin: boolean;
}
const AnimatedStack = motion(Stack);

const HILOGame: React.FC<PlayAreaProps> = (props) => {
  const playerCardsRef = useRef<any>();
  const [animatedIndex, setAnimatedIndex] = useState(-1);

  const deckRef = useRef<any>();
  const {
    dealersHand: dealersHandOriginal,
    playersHands,
    currentCard,
    isGameEnd,
    payout,
    multiplier,
    tokenType,
    bet,
    isWin,
  } = props;
  const lastCardRef = useRef<any>(null);

  return (
    <Box sx={{ height: "20em" }}>
      <Grid
        container
        direction="column"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Stack
          sx={{ position: "absolute", top: { md: "10%", xs: "10%" } }}
          ref={deckRef}
        >
          {currentCard ? (
            <Card card={currentCard} hide={false} isMain index={2} />
          ) : null}
        </Stack>

        {playersHands?.length ? (
          <Stack
            sx={{
              position: "absolute",
              bottom: "5%",
              px: 2,
              width: "94%",
              // overflow: "hidden",
            }}
            gap={2}
          >
            <Box
              sx={{
                display: "flex",
                direction: "row",
                gap: 1,
                overflowX: playersHands?.length > 4 ? "scroll" : "none",
              }}
              ref={playerCardsRef}
            >
              {playersHands?.length &&
                playersHands.map((card: HILO.Card, index: number) => {
                  const isLastCard = index === playersHands?.length - 1;
                  const prevMul = playersHands[index - 1]
                    ? playersHands[index - 1]?.multiplier
                    : undefined;

                  const prevAction = playersHands[index - 1]
                    ? playersHands[index - 1]?.event
                    : undefined;
                  return (
                    <AnimatedStack
                      key={index}
                      initial={{
                        x: "1000px",
                        position: "fixed",
                      }}
                      animate={{ x: 0, position: "relative" }}
                      transition={{ duration: 0.5 }}
                      onAnimationStart={() => {
                        if (isLastCard && lastCardRef?.current) {
                          playerCardsRef.current.scrollLeft =
                            playerCardsRef.current.scrollWidth;
                        }
                      }}
                      onAnimationComplete={() => {
                        setAnimatedIndex(index);

                        if (isLastCard && lastCardRef?.current) {
                          playerCardsRef.current.scrollLeft =
                            playerCardsRef.current.scrollWidth;
                        }
                      }}
                      style={{
                        position: "relative",
                        zIndex: playersHands.length - index,
                        // display: "inline-block",
                      }}
                      ref={isLastCard ? lastCardRef : null}
                    >
                      <Card
                        card={card}
                        hide={false}
                        lastCard={isLastCard}
                        index={index}
                        isGameEnd={isGameEnd}
                        prevMul={prevMul}
                        prevAction={prevAction}
                        isWin={isWin}
                      />
                    </AnimatedStack>
                  );
                })}
            </Box>
          </Stack>
        ) : null}
      </Grid>
    </Box>
  );
};

export default HILOGame;
