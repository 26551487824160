import { EXCHANGETYPE, IExchangeDataType, ORDERTYPES } from "../types/index.d";
import { CHATTYPES } from "./chat";
import BTCIcon from "../assets/crypto/btc-logo.svg";
import ETHIcon from "../assets/crypto/ethereum-eth-logo.svg";
import LTCIcon from "../assets/crypto/litecoin-ltc-logo.svg";
import USDTIcon from "../assets/crypto/tether-usdt-logo.svg";
import USDCIcon from "../assets/crypto/usd-coin-usdc-logo.svg";
import SOLIcon from "../assets/crypto/solana-sol-logo.svg";
import HelpIcon from "../assets/navIcons/help-icon.svg";

export enum CryptoType {
  BTC = "btc",
  ETH = "eth",
  LTC = "ltc",
  USDT = "usdttrc20",
  USDC = "usdc",
  SOL = "sol",
  OTHERS = "Others",
}

export const CryptoOptions = [
  {
    img: BTCIcon,
    text: "BTC",
    type: CryptoType.BTC,
  },
  {
    img: ETHIcon,
    text: "ETH",
    type: CryptoType.ETH,
  },
  {
    img: LTCIcon,
    text: "LTC",
    type: CryptoType.LTC,
  },
  {
    img: SOLIcon,
    text: "SOL",
    type: CryptoType.SOL,
  },
  {
    img: USDTIcon,
    text: "USDT",
    type: CryptoType.USDT,
  },
  {
    img: USDCIcon,
    text: "USDC",
    type: CryptoType.USDC,
  },

  // {
  //   img: HelpIcon,
  //   text: "Other",
  //   type: CryptoType.OTHERS,
  // },
];

export const exchangeData: IExchangeDataType[] = [
  {
    label: "Purchase 07",
    buttonLabel: "07",
    type: EXCHANGETYPE.DEPOSITOSRS,
    exchangeType: ORDERTYPES.DEPOSIT,
    currency: "07",
  },
  {
    label: "Purchase RS3",
    buttonLabel: "RS3",
    type: EXCHANGETYPE.DEPOSITRS3,
    exchangeType: ORDERTYPES.DEPOSIT,
    currency: "RS3",
  },
  {
    label: "Purchase Crypto",
    buttonLabel: "Crypto",
    type: EXCHANGETYPE.DEPOSITCRYPTO,
    exchangeType: ORDERTYPES.DEPOSIT,
    currency: "USD $",
  },
  {
    label: "Redeem 07",
    buttonLabel: "07",

    type: EXCHANGETYPE.WITHDRAWOSRS,
    exchangeType: ORDERTYPES.WITHDRAWAL,

    currency: "07",
  },
  {
    label: "Redeem RS3",
    buttonLabel: "RS3",

    type: EXCHANGETYPE.WITHDRAWRS3,
    exchangeType: ORDERTYPES.WITHDRAWAL,

    currency: "RS3",
  },
  {
    label: "Redeem Crypto",
    buttonLabel: "Crypto",
    type: EXCHANGETYPE.WITHDRAWCRYPTO,
    exchangeType: ORDERTYPES.WITHDRAWAL,
    currency: "USD $",
  },
];

export const DEFAULT_TEXT_WARNING_EXCHANGE_CHAT =
  "Make sure to confirm all trades within this ticket. Beware of imposters";

export const modifiedChatHeader = (type: string, key: string) => {
  if (key === ORDERTYPES.WITHDRAWAL) {
    const orderType = getOrderData(type);
    return `${orderType?.label}`;
  }
  if (key === ORDERTYPES.DEPOSIT) {
    const orderType = getOrderData(type);
    return `${orderType?.label}`;
  }
  if (key === CHATTYPES.ADMIN) {
    return "Cashiering";
  }

  return key;
};

export const getOrderData = (type: string) => {
  return exchangeData.find((data) => type === data.type);
};
