import { createSlice, configureStore, PayloadAction } from "@reduxjs/toolkit";
import { updateSeed } from "./userSlice";

interface Notification {
  message: string;
  variant?: string;
  options?: Record<string, any>;
  isDismissed: boolean;
  key: string;
}

const initialState: Notification[] = [];

export const notificationReducer = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    showNotification: {
      reducer: (state, action: PayloadAction<Notification>) => {
        state.push(action.payload);
      },
      prepare: (
        message: string,
        variant = "success",
        options: Record<string, any> = {}
      ) => ({
        payload: {
          message,
          options,
          variant,
          isDismissed: false,
          key:
            options.key ||
            new Date().getTime().toString() + Math.random().toString(),
        },
      }),
    },
    dismissNotification: (state, action: PayloadAction<{ key: string }>) => {
      return state.map((n) =>
        n.key === action.payload.key ? { ...n, isDismissed: true } : n
      );
    },
    clearNotifications: (state) => {
      return state.map((n) => ({ ...n, isDismissed: true }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSeed.rejected, (state, action) => {
        state.push({
          message: action.payload as string,
          isDismissed: false,
          options: {},
          variant: "error",
          key: new Date().getTime().toString() + Math.random().toString(),
        });
      })
      .addCase(updateSeed.fulfilled, (state, action) => {
        state.push({
          message: "Client & Server Seeds have been changed",
          isDismissed: false,
          options: {},
          variant: "success",
          key: new Date().getTime().toString() + Math.random().toString(),
        });
      });
  },
});

export const { showNotification, dismissNotification, clearNotifications } =
  notificationReducer.actions;

// Export selectors
export const selectNotifications = (state: { notifications: Notification[] }) =>
  state.notifications;

export default notificationReducer.reducer;
