import { Box, Stack, Typography, CircularProgress } from "@mui/material";
import ModalComponent from "@mui/material/Modal";
import { MODALTYPE } from "../../types/index.d";
import Register from "../register";
import Login from "../login";
import ExchangeModal from "../exchange";
import SelectionComponent from "../selectionComponent";
import { theme } from "../theme";
import { Button } from "../button";

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: theme.palette.primary.main,
  boxShadow: 24,
  width: { xs: 200, md: 300 },
  zIndex: 10,
  py: 4,
  px: 10,
  borderRadius: 4,
};

interface IModal {
  handleClose: () => void;
  open: boolean;
  modalType?: MODALTYPE;
  isLoading: boolean;
  message: string;
}

const VerificationModal = ({
  handleClose,
  open,
  isLoading,
  message,
}: IModal) => {
  return (
    <ModalComponent open={open} onClose={() => {}} disableScrollLock={true}>
      <Box sx={modalStyle}>
        <Stack gap={3}>
          <Typography variant="h3" color="text.primary">
            Verify Email
          </Typography>
          {isLoading ? (
            <Stack justifyContent="center" alignItems="center">
              <CircularProgress sx={{ color: "#33EC3D" }}></CircularProgress>
            </Stack>
          ) : (
            <Stack gap={2}>
              <Typography color="text.primary">{message}</Typography>
              <Button onClick={handleClose}>Close</Button>
            </Stack>
          )}
        </Stack>
      </Box>
    </ModalComponent>
  );
};

export default VerificationModal;
