import React from "react";
import { Card as MuiCard } from "@mui/material";
import styles from "./index.module.scss";
// import BackCard from "../../../assets/blackjack/blackjack-back.svg";
import BackCard from "../../../../assets/blackjack/blackjack-back.svg";
import CardImages from "../../../../constants/blackJack";
interface CardProps {
  card: {
    suit: string;
    rank: string;
  } | null;
  hide: boolean;
  border?: string;
}

const Card: React.FC<CardProps> = ({ card, hide, border }) => {
  const isHide = card && !hide && card?.rank !== "U";
  const cardImagePath =
    card && !hide && card?.rank !== "U"
      ? CardImages[card.suit][card.rank]
      : BackCard;
  const topAndBottom = card === null || hide ? "" : card.rank;
  const cardClass = topAndBottom !== "" ? styles.root : styles.rootBack;
  return (
    <MuiCard
      className={cardClass}
      style={{
        perspective: 1000,
        position: "relative",
        height: "6.5em",
        background: "transparent",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          background: "transparent",
        }}
      >
        <img
          alt={card ? `${card.suit} ${card.rank}` : "Back"}
          src={cardImagePath}
          style={{ border: border, borderRadius: "7px" }}
          className={styles.cardImg}
        />
      </div>
    </MuiCard>
  );
};

export default Card;
