import { createTheme } from "@mui/material";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1100,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#202020",
          color: "#fffff",
        },
      },
    },

    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: "#19191A",
        },
        elevation0: {
          boxShadow: "none",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          color: "#ffff",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          py: 0,
          color: "#3e4148",
          "&.Mui-checked": {
            color: "#ba8110",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        "#__next": {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          height: "100%",
          width: "100%",
        },
        // "@global": {
        //   'input[type="number"]': {
        //     "-moz-appearance": "textfield" /* Firefox */,
        //     appearance: "none" /* Remove default arrows */,
        //     marginRight:
        //       "1px" /* Add some margin to align with other inputs if needed */,
        //     width: "calc(100% - 32px)" /* Adjust width as needed */,
        //   },
        // },
        // input: {
        //   '&[type="number"]': {
        //     "-moz-appearance": "textfield" /* Firefox */,
        //     appearance: "none" /* Remove default arrows */,
        //     marginRight:
        //       "1px" /* Add some margin to align with other inputs if needed */,
        //     width: "calc(100% - 32px)" /* Adjust width as needed */,
        //   },
        // },
      },
    },
  },
  palette: {
    primary: {
      main: "rgba(25, 25, 26,1)",
      light: "#202020",
      contrastText: "#ffffff",
    },
    secondary: {
      // main: "#a4adbb",
      // main: "#ff0800",
      main: "#282c34",

      contrastText: "#000000",
    },
    action: {
      active: "#ffffff",
    },
    error: {
      main: "#D92324",
    },
    warning: {
      main: "#ffa000",
    },
    info: {
      main: "#33EC3D",
    },
    success: {
      main: "#32c932",
      light: "#39e639",
    },
    background: {
      default: "#19191a",
      paper: "#424242",
    },
    text: {
      primary: "#ffffff",
      secondary: "#19191A",
      disabled: "#78828a",
    },
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    button: {
      fontWeight: 600,
    },
    fontFamily: '"Exo"',
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.95rem",
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontWeight: 700,
      fontSize: "3.5rem",
      lineHeight: 1.375,
    },
    h2: {
      fontWeight: 700,
      fontSize: "3rem",
      lineHeight: 1.375,
    },
    h3: {
      fontWeight: 700,
      fontSize: "2.25rem",
      lineHeight: 1.375,
    },
    h4: {
      fonteight: 600,
      fontSize: "2rem",
      lineHeight: 1.375,
    },
    h5: {
      fontWeight: 600,
      fontSize: "21px",
      lineHeight: 1.375,
    },
    h6: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: 1.375,
    },
  },
});
