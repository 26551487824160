import React from "react";
import { Card as MuiCard, Typography, Stack } from "@mui/material";
import styles from "./index.module.scss";
import BackCard from "../../assets/blackjack/blackjack-back.svg";
import CardImages from "../../constants/blackJack";
import { motion, AnimatePresence } from "framer-motion";
import { HILOACTION } from "../slices/hiloslice";

import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import { theme } from "../theme";
import { RoundNumber } from "../../utils";

interface CardProps {
  card: {
    suit: string;
    rank: string;
    event?: HILOACTION;
    multiplier?: number;
  } | null;
  hide: boolean;
  isMain?: boolean;
  lastCard?: boolean;
  index: number;
  isGameEnd?: boolean;
  prevAction?: HILOACTION;
  prevMul?: number;
  isWin?: boolean;
}

const ActionImage = ({ action }: { action: HILOACTION }) => {
  switch (action) {
    case HILOACTION.SKIP:
      return (
        <KeyboardDoubleArrowRightIcon
          sx={{ color: theme.palette.primary.main }}
        />
      );
    case HILOACTION.LOW:
      return (
        <KeyboardDoubleArrowDownIcon
          sx={{ color: theme.palette.primary.main }}
        />
      );
    case HILOACTION.HIGH:
      return (
        <KeyboardDoubleArrowUpIcon sx={{ color: theme.palette.primary.main }} />
      );
    case HILOACTION.SAME:
      return <DragHandleIcon sx={{ color: theme.palette.primary.main }} />;

    default:
      return null;
  }
};

const Card: React.FC<CardProps> = ({
  card,
  hide,
  isMain,
  lastCard,
  index,
  isGameEnd,
  prevMul,
  isWin,
  prevAction,
}) => {
  const cardImagePath =
    card && !hide && card?.rank !== "U"
      ? CardImages[card.suit][card.rank]
      : BackCard;

  return (
    <Stack gap={0.5}>
      <MuiCard
        className={styles.root}
        sx={{
          perspective: 1000,
          position: "relative",
          height: { md: isMain ? "11em" : "8em", xs: isMain ? "9em" : "6em" },
          background: "transparent",
          // width: "100%",
        }}
      >
        <AnimatePresence initial={false}>
          <motion.div
            key={hide ? "back" : "front"}
            initial={{ rotateY: hide ? 0 : 180, background: "transparent" }}
            animate={{ rotateY: 0 }}
            exit={{ rotateY: hide ? 0 : -180 }}
            transition={{ duration: 0.25 }}
            style={{
              position: "absolute",
              height: "100%",
              background: "transparent",
            }}
          >
            <img
              alt={card ? `${card.suit} ${card.rank}` : "Back"}
              src={cardImagePath}
              style={{ borderRadius: "7px" }}
              className={styles.cardImg}
            />
            {card?.event && !lastCard ? (
              <MuiCard
                className={styles.multiplierPosition}
                sx={{
                  p: { md: 1, xs: 0.5 },
                  bgcolor: "white",
                  fontSize: { md: "auto", xs: "0.75em" },
                }}
              >
                <ActionImage action={card?.event} />
              </MuiCard>
            ) : null}
          </motion.div>
        </AnimatePresence>
      </MuiCard>
      {card?.multiplier || prevMul || index === 0 ? (
        <MuiCard
          sx={{
            p: 0.25,
            bgcolor:
              prevAction === HILOACTION.SKIP && index !== 0
                ? "#ffa000"
                : theme.palette.success.main,
          }}
        >
          <Typography color="text.secondary" textAlign="center">
            {index === 0
              ? "Start Card"
              : prevAction === HILOACTION.SKIP
              ? "Skipped"
              : `x${RoundNumber(prevMul ? prevMul : 0)}`}
          </Typography>
        </MuiCard>
      ) : null}

      {/* {!lastCard && index === 1 && prevMul ? (
        <MuiCard sx={{ p: 0.25, bgcolor: theme.palette.success.main }}>
          <Typography color="text.secondary" textAlign="center">
            x{RoundNumber(prevMul)}
          </Typography>
        </MuiCard>
      ) : null} */}

      {/* {lastCard && !isGameEnd && !card?.multiplier && !isMain && prevMul ? (
        <MuiCard sx={{ p: 0.25, bgcolor: theme.palette.success.main }}>
          <Typography color="text.secondary" textAlign="center">
            x{RoundNumber(prevMul)}
          </Typography>
        </MuiCard>
      ) : null} */}

      {lastCard && isGameEnd && !card?.multiplier && !isWin ? (
        <MuiCard sx={{ p: 0.25, bgcolor: theme.palette.error.main }}>
          <Typography color="text.secondary" textAlign="center">
            x0
          </Typography>
        </MuiCard>
      ) : null}

      {/* {lastCard && isGameEnd && !card?.multiplier && isWin && prevMul ? (
        <MuiCard sx={{ p: 0.25, bgcolor: theme.palette.success.main }}>
          <Typography color="text.secondary" textAlign="center">
            x{RoundNumber(prevMul)}
          </Typography>
        </MuiCard>
      ) : null} */}
    </Stack>
  );
};

export default Card;
