import { RoundNumber, getPayout } from "../../utils";
import { InputFieldWithStartAdornment } from "../inputField";
import { theme } from "../theme";
import { Tooltip } from "@mui/material";
import { useEffect } from "react";
import { GAMES } from "../../constants/games";
import { PLINKORISK } from "../plinko/constants";
import { multiplier as multiplierArr } from "../plinko/config";
import { useAppSelector } from "../store/hooks";
import { selectBalance, selectTokenSelection } from "../slices/userSlice";
import { TOKENTYPE } from "../../types/index.d";

const betLimits: any = {
  [GAMES.MINES]: {
    min: 0.1,
    max: 25000,
  },
  [GAMES.BLACKJACK]: {
    min: 0.1,
    max: 6250,
  },
  [GAMES.DUEL]: {
    min: 10,
    max: 25000,
  },
  [GAMES.COIN_FLIP]: {
    min: 0.1,
    max: 25000,
  },
  [GAMES.DICE]: {
    min: 0.1,
    max: 25000,
    maxPayout: 50000,
  },
  [GAMES.LIMBO]: {
    min: 0.1,
    max: 25000,
    maxPayout: 50000,
  },
  [GAMES.PLINKO]: {
    min: 0.1,
    max: {
      [PLINKORISK.LOW]: 3125,
      [PLINKORISK.MEDIUM]: 416.66,
      [PLINKORISK.HIGH]: 50,
      [PLINKORISK.CRAZY]: 5,
    },
    maxPayout: 50000,
  },
  [GAMES.KENO]: {
    min: 0.1,
    max: 25000,
    maxPayout: 50000,
  },
  [GAMES.SLIDE]: {
    min: 0.1,
    maxPayout: 50000,
  },
};

export const BetField = ({
  betFieldRef,
  currentBet = 0,
  setCurrentBet,
  disabled = false,
  setTitle,
  title,
  balance,
  game,
  multiplier = 0,
  risk,
  maxPayout,
  ...otherProps
}: any) => {
  const playerBalance = useAppSelector(selectBalance);
  const tokenSelection = useAppSelector(selectTokenSelection);
  const limits = betLimits[game];

  const handleBlur = () => {
    if (currentBet < limits.min) {
      setTitle(`Min bet is ${limits.min}`);
      return;
    }
    if (tokenSelection === TOKENTYPE.FREE) {
      setTitle("");
      return;
    }
    if (game === GAMES.MINES) {
      const getMaxBet = getPayout(multiplier, 1);
      if (currentBet > limits.max) {
        setTitle(`Max bet is ${limits.max}`);
        return;
      }
      if (50000 / getMaxBet <= currentBet) {
        setTitle(`Max bet is ${RoundNumber(50000 / getMaxBet)}`);
        return;
      }
    }
    if (
      game === GAMES.DUEL ||
      game === GAMES.COIN_FLIP ||
      game === GAMES.BLACKJACK
    ) {
      if (currentBet > limits.max) {
        setTitle(`Max bet is ${limits.max}`);
        return;
      }
    }

    if (game === GAMES.DICE || game === GAMES.LIMBO) {
      if (currentBet * multiplier > limits.maxPayout) {
        const maxBet = RoundNumber(limits.maxPayout / multiplier);
        setTitle(`Max bet with a ${multiplier}x is ${maxBet}`);
        return;
      }
    }
    if (game === GAMES.PLINKO && risk && multiplier) {
      const highestMultiplier =
        multiplierArr[risk as PLINKORISK][multiplier - 8][0];
      const myBet = highestMultiplier * currentBet;
      if (myBet > limits.maxPayout) {
        setTitle(
          `Max bet is ${RoundNumber(limits.maxPayout / highestMultiplier)}`
        );
        return;
      }
    }

    if (game === GAMES.KENO) {
      if (maxPayout > limits.maxPayout) {
        const maxBet = RoundNumber(limits.maxPayout / multiplier);
        setTitle(`Max bet with a ${multiplier}x is ${maxBet}`);
        return;
      }
    }

    if (currentBet > playerBalance) {
      setTitle("Bet can't exceed your balance");
      return;
    }
    setTitle("");
  };

  useEffect(() => {
    handleBlur();
  }, [multiplier, risk, currentBet, playerBalance, tokenSelection]);
  return (
    <Tooltip
      title={title}
      open={Boolean(title)}
      arrow
      PopperProps={{
        sx: {
          zIndex: 500,
        },
      }}
    >
      <InputFieldWithStartAdornment
        value={!currentBet ? null : currentBet}
        isFreeCash={tokenSelection === TOKENTYPE.FREE}
        type="number"
        placeholder="0.1"
        pattern="[0-9]*[.]?[0-9]*"
        onBlur={handleBlur}
        error={Boolean(title) && !disabled}
        disabled={disabled}
        name="bettingField"
        onKeyDown={(e: any) => {
          if (
            !(e.key >= "0" && e.key <= "9") &&
            !(e.ctrlKey && e.key === "v") &&
            !(e.key === "Backspace" || e.key === ".")
          ) {
            e.preventDefault();
          }

          if (
            e.key === "e" ||
            e.key === "E" ||
            e.key === "-" ||
            e.key === "+"
          ) {
            e.preventDefault();
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCurrentBet(RoundNumber(Number(e.target.value)))
        }
        inputRef={betFieldRef}
        sx={{
          backgroundColor: theme.palette.primary.main,
          "& .MuiInputBase-input": {
            paddingLeft: "calc(1em + 20px)",
          },
        }}
        {...otherProps}
        inputProps={{
          min: 0,
        }}
      />
    </Tooltip>
  );
};
