// MyComponent.tsx
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import {
  selectNotifications,
  clearNotifications,
} from "../slices/notificationSlice";

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const notifications = useSelector(selectNotifications);

  useEffect(() => {
    notifications.forEach((notification) => {
      if (!notification.isDismissed) {
        enqueueSnackbar(notification.message, {
          variant: notification.variant as any,
        });
        // Optionally, you can dispatch an action to mark the notification as dismissed
        dispatch(clearNotifications());
      }
    });
  }, [notifications, dispatch, enqueueSnackbar]);

  return null;
  // return <div>{/* Your component JSX */}</div>;
};

export default Notifications;
