import {
  Stack,
  Typography,
  IconButton,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import { InputFieldStyled } from "../../inputField";
import { selectPlayer } from "../../slices/userSlice";
import { useAppSelector } from "../../store/hooks";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "../../button";
import Users from "../../../services/user";
import { theme } from "../../theme";
import { useRef, useEffect, useState } from "react";
import configuration from "../../../config";
import { DiscordButton } from "./discordButton";
import WarningIcon from "@mui/icons-material/Warning";
import { VerificationWarning } from "./verificationToolTip";
import ChangePasswordModal from "./changePasswordModal";
import HCaptcha from "../../HCaptcha";
import { useTurnstile } from "react-turnstile";

export const Profile = () => {
  const { player, accessToken } = useAppSelector(selectPlayer);
  const [showEmail, setShowEmail] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [token, setToken] = useState<any>(null);
  const turnstile = useTurnstile();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handlePrmoCode = async () => {
    if (!token) {
      setIsError(true);
      setMessage("Please complete the HCaptcha");
      return;
    }

    try {
      setIsError(false);
      setLoading(true);
      const res = await Users.promoCode(accessToken, promoCode, token);
      if (res) {
        setIsError(false);
        setMessage(res.message);
      }
    } catch (e: any) {
      setMessage(e.message);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (message && token) {
      setToken(null);
      turnstile.reset();
    }
  }, [message]);

  useEffect(() => {
    if (isDisabled) {
      setTimeout(() => {
        setIsDisabled(false);
      }, 60 * 1000);
    }
  }, [isDisabled]);

  return (
    <Stack gap={2}>
      <ChangePasswordModal
        open={passwordModal}
        handleClose={() => setPasswordModal(false)}
      />
      <Stack gap={0.5}>
        <Typography>Email</Typography>
        <Stack direction="row" gap={1}>
          <InputFieldStyled
            type={showEmail ? "text" : "password"}
            value={player.email}
            sx={{ width: { sm: "50%", xs: "100%" } }}
          />
          <IconButton
            onClick={() => setShowEmail(!showEmail)}
            sx={{ color: "white" }}
          >
            {showEmail ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
          {!player.isVerified && (
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <div>
                <Tooltip
                  arrow={true}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  open={open}
                  title={
                    <VerificationWarning
                      isDisabled={isDisabled}
                      setIsDisabled={setIsDisabled}
                    />
                  }
                >
                  <IconButton
                    onClick={handleTooltipOpen}
                    sx={{ color: "white" }}
                  >
                    <WarningIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          )}
        </Stack>
      </Stack>

      <Stack gap={0.5}>
        <Typography>Password</Typography>
        <Stack direction="row" gap={1}>
          <InputFieldStyled
            type={"password"}
            disabled={true}
            value={player.email}
            sx={{ width: { sm: "50%", xs: "100%" } }}
          />
          <IconButton
            onClick={() => setPasswordModal(true)}
            sx={{ color: "white" }}
          >
            <EditIcon />
          </IconButton>
        </Stack>
      </Stack>

      <Stack gap={0.5}>
        {/* <Typography>Promo Code</Typography>
        <Stack direction="row" gap={1} sx={{ mb: 1 }}>
          <InputFieldStyled
            type={"string"}
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            sx={{ width: { sm: "50%", xs: "100%" } }}
          />
          <Button
            onClick={() => handlePrmoCode()}
            variantType={"success"}
            disabled={!promoCode || loading}
          >
            Submit
          </Button>
        </Stack>
        <HCaptcha onVerify={setToken} />
        {message && (
          <Typography
            sx={{
              color: isError
                ? theme.palette.error.main
                : theme.palette.success.main,
            }}
          >
            {message}
          </Typography>
        )} */}

        <DiscordButton />
      </Stack>
    </Stack>
  );
};
