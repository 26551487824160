import bronzeImg from "../assets/icons/bronze.png";
import ironImg from "../assets/icons/iron.png";
import steelImg from "../assets/icons/steel.png";
import blackImg from "../assets/icons/black.png";
import mithrilImg from "../assets/icons/mithril.png";
import adamantImg from "../assets/icons/adamant.png";
import runeImg from "../assets/icons/rune.png";
import graniteImg from "../assets/icons/granite.png";
import dragonImg from "../assets/icons/dragon.png";
import barrowsImg from "../assets/icons/barrows.png";
import torvaImg from "../assets/icons/torva.png";

import eliteImg from "../assets/icons/elite.png";
import maxedImg from "../assets/icons/maxed.png";
import unrankedImg from "../assets/icons/unranked.png";

export const userRankData = [
  {
    name: "Unranked",
    level: 0,
    rankUp: 0,
    color: "white",
    imgSrc: unrankedImg,
  },
  {
    name: "Bronze",
    level: 1,
    rankUp: 1,
    color: "#b46d3b",
    imgSrc: bronzeImg,
  },
  {
    name: "Iron",
    level: 2,
    rankUp: 83,
    color: "#707070",
    imgSrc: ironImg,
  },
  {
    name: "Steel",
    level: 3,
    rankUp: 388,
    color: "#c6bdbd",
    imgSrc: steelImg,
  },
  {
    name: "Black",
    level: 4,
    rankUp: 1154,
    color: "#0f0101",
    imgSrc: blackImg,
  },
  {
    name: "Mithril",
    level: 5,
    rankUp: 4470,
    color: "#71368A",
    imgSrc: mithrilImg,
  },
  {
    name: "Adamant",
    level: 6,
    rankUp: 13363,
    color: "#1F8B4C",
    imgSrc: adamantImg,
  },
  {
    name: "Rune",
    level: 7,
    rankUp: 37224,
    color: "#3498DB",
    imgSrc: runeImg,
  },
  {
    name: "Granite",
    level: 8,
    rankUp: 101333,
    color: "#FFFFFF",
    imgSrc: graniteImg,
  },
  {
    name: "Dragon",
    level: 9,
    rankUp: 273742,
    color: "#ff0000",
    imgSrc: dragonImg,
  },
  {
    name: "Barrows",
    level: 10,
    rankUp: 737627,
    color: "#ffaf00",
    imgSrc: barrowsImg,
  },
  {
    name: "Torva",
    level: 11,
    rankUp: 1986068,
    color: "#979C9F",
    imgSrc: torvaImg,
  },
  {
    name: "Elite",
    level: 12,
    rankUp: 5346332,
    color: "#ff66ff",
    imgSrc: eliteImg,
  },
  {
    name: "Maxed",
    level: 13,
    rankUp: 13034431,
    color: "#66ff66",
    imgSrc: maxedImg,
  },
];
