import { useDispatch } from "react-redux";
import { GAMESTATUS } from "../../constants";
import { MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { Button } from "../button";
import { openModal } from "../slices/userSlice";
import { AppDispatch } from "../store";
import { BetButton } from "./betButton";

interface IButtons {
  accessToken: string;
  gameStatus: GAMESTATUS | null;
  revealedCells: string[];
  handlePayout: () => void;
  payout: number;
  disabled: boolean;
  isBalanceError: boolean;
  handleCreateGame: () => void;
  tokenType: TOKENTYPE | null;
}
export const Buttons = ({
  accessToken,
  gameStatus,
  revealedCells,
  handlePayout,
  payout,
  isBalanceError,
  handleCreateGame,
  disabled,
  tokenType,
}: IButtons) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  if (!accessToken) {
    return (
      <Button onClick={() => handleLogin()} fullWidth>
        Login
      </Button>
    );
  }

  const isFunCash = tokenType === TOKENTYPE.FREE;
  return (
    <>
      {gameStatus === GAMESTATUS.INGAME ? (
        <BetButton
          gameStatus={gameStatus}
          revealedCells={revealedCells}
          disabled={disabled}
          handlePayout={handlePayout}
          payout={payout}
          isFunCash={isFunCash}
        />
      ) : (
        <Button
          variantType="success"
          disabled={isBalanceError || disabled}
          fullWidth
          onClick={() => handleCreateGame()}
        >
          {"Bet"}
        </Button>
      )}
    </>
  );
};
