import { Box } from "@mui/material";
import { useEffect } from "react";
import { Button } from "../button";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { openModal, selectBalance, selectFreeCash } from "../slices/userSlice";
import { BLACKGAMESTATE } from "./constants";
import { useAppSelector } from "../store/hooks";
import DoubleImage from "../../assets/blackjack/double.svg";
import SplitImage from "../../assets/blackjack/split.svg";
import HitImage from "../../assets/blackjack/hit.svg";
import StandImage from "../../assets/blackjack/stand.svg";

interface GameProgressButtonProps {
  onClickNext: () => void;
  isTheLastGame?: boolean;
}

export const BlackJackButtons = (props: any) => {
  const { canHit, canStand, canSplit, canDouble, bet, disable, tokenType } =
    props;
  const balance = useAppSelector(selectBalance);
  const freeCash = useAppSelector(selectFreeCash);

  // useEffect(() => {
  //   function click(event: any) {
  //     if (canHit && event.key === "h") {
  //       props.onClickHit();
  //     } else if (canStand && event.key === "s") {
  //       props.onClickStand();
  //     } else if (canSplit && event.key === "p") {
  //       props.onClickSplit();
  //     } else if (canDouble && event.key === "d") {
  //       props.onClickDouble();
  //     }
  //   }

  //   document.body.addEventListener("keydown", click);
  //   return () => {
  //     document.body.removeEventListener("keydown", click);
  //   };
  // }, [canHit, canStand, canSplit, canDouble]);

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      flexWrap="wrap"
      mb={3}
      mt={2}
      sx={{
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
        width: "100%",
      }}
    >
      {canHit && (
        <Box m={1}>
          <Button
            sx={{ width: "8em" }}
            disabled={disable}
            onClick={props.onClickHit}
          >
            <img
              style={{ width: "1.25em", marginRight: "1em" }}
              src={HitImage}
              alt="hit icon"
            />
            HIT
          </Button>
        </Box>
      )}
      {canStand && (
        <Box m={1}>
          <Button
            sx={{ width: "8em" }}
            disabled={disable}
            onClick={props.onClickStand}
          >
            <img
              style={{ width: "1.25em", marginRight: "1em" }}
              src={StandImage}
              alt="stand icon"
            />
            STAND
          </Button>
        </Box>
      )}
      {canSplit && (
        <Box m={1}>
          <Button
            sx={{ width: "8em" }}
            disabled={
              tokenType === TOKENTYPE.FREE
                ? bet > freeCash
                : bet > balance || disable
            }
            onClick={props.onClickSplit}
          >
            <img
              style={{ width: "1.5em", marginRight: "1em" }}
              src={SplitImage}
              alt="split icon"
            />
            SPLIT
          </Button>
        </Box>
      )}
      {canDouble && (
        <Box m={1}>
          <Button
            sx={{ width: "8em" }}
            disabled={
              tokenType === TOKENTYPE.FREE
                ? bet > freeCash
                : bet > balance || disable
            }
            onClick={props.onClickDouble}
          >
            <img
              style={{ width: "1.5em", marginRight: "1em" }}
              src={DoubleImage}
              alt="double icon"
            />
            DOUBLE
          </Button>
        </Box>
      )}
    </Box>
  );
};

interface IButtons {
  accessToken: string;
  gameStatus: BLACKGAMESTATE | null;
  payout: number;
  disabled: boolean;
  isBalanceError: boolean;
  handleCreateGame: () => void;
}
export const Buttons = ({
  accessToken,
  gameStatus,
  payout,
  isBalanceError,
  handleCreateGame,
  disabled,
}: IButtons) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  if (!accessToken) {
    return (
      <Button onClick={() => handleLogin()} fullWidth>
        Login
      </Button>
    );
  }
  return (
    <>
      <Button
        variantType="success"
        disabled={
          isBalanceError || disabled || gameStatus === BLACKGAMESTATE.START
        }
        fullWidth
        onClick={() => handleCreateGame()}
      >
        {"Bet"}
      </Button>
    </>
  );
};
