import { Stack, SxProps, Theme } from "@mui/system";
import RuneScapeCoin from "../../assets/runeScapeCoin.png";

interface RuneScapeCoin {
  width?: number;
  sx?: SxProps<Theme> | undefined;
}
export const RuneScapeIcon = ({ width = 20, sx }: RuneScapeCoin) => {
  const style = sx ? sx : { width: `${width}px` };
  return (
    <Stack sx={style}>
      <img src={RuneScapeCoin} style={{ width: "100%" }} />
    </Stack>
  );
};
