import { Stack, Typography, InputAdornment, Grid } from "@mui/material";
import { DICEMODE } from "../../types/index.d";
import { InputFieldStyled } from "../inputField";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import { Button } from "../button";
import { plinkoRishButtonData, PLINKORISK } from "./constants";

export interface IAutoPlayStates {
  numberOfGames: number;
}

interface IAutoButtons {
  playType: DICEMODE;
  autoPlayStates: IAutoPlayStates;
  setAutoPlayStates: (states: IAutoPlayStates) => void;
  isAutoPlaying: boolean;
}

enum SELECTIONSTATUS {
  RESET = "rest",
  VALUE = "value",
}

export default function AutoButtons({
  playType,
  autoPlayStates,
  setAutoPlayStates,
  isAutoPlaying,
}: IAutoButtons) {
  const updateField = (key: string, value: number | null) => {
    let currentValue = value;
    if (currentValue !== null && currentValue < 0) {
      return;
    }
    if (key === "numberOfGames" && currentValue && currentValue > 999999999) {
      currentValue = 999999999;
    }
    setAutoPlayStates({
      ...autoPlayStates,
      [key]: currentValue,
    });
  };

  return (
    <Stack
      gap={0.5}
      sx={{ display: playType === DICEMODE.AUTO ? "flex" : "none" }}
    >
      <Stack alignItems="flex-start">
        <Typography color="text.primary">Number of Bets</Typography>
        <InputFieldStyled
          inputProps={{
            min: 0,
          }}
          endAdornment={
            <InputAdornment
              position="start"
              sx={{
                display: autoPlayStates.numberOfGames && "none",
                ml: 0.5,
                color: "white",
              }}
            >
              <AllInclusiveIcon sx={{ width: { md: "auto", xs: "18px" } }} />
            </InputAdornment>
          }
          disabled={isAutoPlaying}
          fullWidth
          type="number"
          value={
            !autoPlayStates.numberOfGames ? null : autoPlayStates.numberOfGames
          }
          onChange={(e: { target: { value: any } }) =>
            updateField("numberOfGames", Number(e.target.value))
          }
        />
      </Stack>
    </Stack>
  );
}

export const GameTypeButtons = ({
  playType,
  setPlayType,
  isAutoPlaying,
}: {
  playType: DICEMODE;
  setPlayType: (type: DICEMODE) => void;
  isAutoPlaying: boolean;
}) => {
  const handleClick = (type: DICEMODE) => {
    setPlayType(type);
  };
  return (
    <Stack direction="row" gap={2}>
      <Button
        variantType={playType === DICEMODE.MANUAL ? "selected" : "selection"}
        onClick={() => handleClick(DICEMODE.MANUAL)}
        disabled={isAutoPlaying}
      >
        Manual
      </Button>
      <Button
        variantType={playType === DICEMODE.AUTO ? "selected" : "selection"}
        onClick={() => handleClick(DICEMODE.AUTO)}
        disabled={isAutoPlaying}
      >
        Auto
      </Button>
    </Stack>
  );
};

export const RiskButtons = ({
  playType,
  setPlayType,
  isAutoPlaying,
  disabled,
}: {
  playType: PLINKORISK;
  setPlayType: (type: PLINKORISK) => void;
  isAutoPlaying: boolean;
  disabled: boolean;
}) => {
  const handleClick = (type: PLINKORISK) => {
    setPlayType(type);
  };
  return (
    <Stack gap={1}>
      <Typography color="text.primary">Risk</Typography>
      <Grid container rowSpacing={2} columnSpacing={2}>
        {plinkoRishButtonData.map((data) => (
          <Grid xs={6} item key={data.label}>
            <Button
              variantType={playType === data.label ? "selected" : "selection"}
              onClick={() => handleClick(data.label)}
              disabled={isAutoPlaying || disabled}
              fullWidth
            >
              {data.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export const GameTypeButtonReset = ({
  status,
  setStatus,
  isAutoPlaying,
}: {
  status: SELECTIONSTATUS;
  setStatus: (type: SELECTIONSTATUS) => void;
  isAutoPlaying: boolean;
}) => {
  return (
    <Stack direction="row" gap={0.5} sx={{ width: "100%" }}>
      <Button
        variantType={
          status === SELECTIONSTATUS.RESET ? "selected" : "selection"
        }
        onClick={() => setStatus(SELECTIONSTATUS.RESET)}
        sx={{ fontSize: "12px", p: 0.25 }}
        fullWidth
        disabled={isAutoPlaying}
      >
        Reset
      </Button>
      <Button
        disabled={isAutoPlaying}
        variantType={
          status === SELECTIONSTATUS.VALUE ? "selected" : "selection"
        }
        onClick={() => setStatus(SELECTIONSTATUS.VALUE)}
        sx={{ fontSize: "12px", p: 0.25 }}
        fullWidth
      >
        Increase by:
      </Button>
    </Stack>
  );
};

interface IBetButton {
  accessToken: string;
  disableButton: boolean;
  playGame: () => void;
  handleLogin: () => void;
  playType: DICEMODE;
  stopPlaying: () => void;
  isAutoPlaying: boolean;
}

export const BetButton = ({
  accessToken,
  disableButton,
  playGame,
  playType,
  handleLogin,
  stopPlaying,
  isAutoPlaying,
}: IBetButton) => {
  if (isAutoPlaying) {
    return (
      <Button fullWidth onClick={() => stopPlaying()} variantType="error">
        Stop Playing
      </Button>
    );
  }
  if (accessToken) {
    return (
      <Stack direction="row" gap={1}>
        <Button
          fullWidth
          disabled={disableButton}
          onClick={() => playGame()}
          variantType="success"
        >
          {playType === DICEMODE.MANUAL ? "Bet" : "Start Autoplay"}
        </Button>
      </Stack>
    );
  }
  if (!accessToken) {
    return (
      <Button onClick={() => handleLogin()} fullWidth>
        Login
      </Button>
    );
  }
  return <></>;
};
