import React, { useState, useEffect, useContext, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import { theme } from "../theme";
import { Button } from "../button";
import { ProfitOnWinInput } from "../inputField";
import styles from "./index.module.scss";
import {
  isBetGreaterthanSetValue,
  RoundNumber,
  RoundProfitOnWin,
} from "../../utils";
import {
  openModal,
  selectCoinFlipToggle,
  selectCurrentUser,
  selectLevelUpModal,
  selectToken,
  selectTokenSelection,
  selectVolume,
  updateSettings,
  User,
} from "../slices/userSlice";
import { useAppSelector } from "../store/hooks";
import ChatContext from "../../contexts/chat/context";
import { COINFLIP, DICEMODE, MODALTYPE, TOKENTYPE } from "../../types/index.d";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import AutoButtons, {
  BetButton,
  GameTypeButtons,
  IAutoPlayStates,
} from "./buttons";
import { ConfirmationBetDialog } from "../confirmationBetDialog";
import Coin from "./coin";
import { coinFlipImages, DEFAULT_MULTIPLYER } from "./constants";
import { PLAY_COIN_FLIP_GAME } from "../../constants/socket";
import {
  resetCoinFlipGame,
  selectCoinFlipGames,
} from "../slices/coinFlipSlice";
import { DEFAULT_DISABLED_MESSAGE, SELECTIONSTATUS } from "../../constants";
import { GAMES } from "../../constants/games";
import { GameOptions } from "../gameOptions";
import { BetField } from "../betField";
import { handleDoubleBet, handleHalfBet } from "../../utils/bets";
import useSound from "use-sound";
import BetSound from "../../assets/audio/common/create-bet.mp3";

const CoinFlip: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useAppSelector(selectToken);
  const player = useAppSelector(selectCurrentUser);
  const { errorMessage, pastResults, coinFlip } =
    useAppSelector(selectCoinFlipGames);
  const { socket } = useContext(ChatContext);
  const betFieldRef = useRef<HTMLInputElement | null>(null);
  const [disableButton, setDisableButton] = useState(false);
  const [playType, setPlayType] = useState<DICEMODE>(DICEMODE.MANUAL);
  const [confirmation, setConfirmation] = useState(false);
  const [dontShowConfirmation, setDontShowConfirmation] = useState(false);
  const [coinClass, setCoinClass] = useState("");
  const selectionRef = useRef<COINFLIP | null>(null);
  const betRef = useRef<number>(0);
  const isLevelUp = useAppSelector(selectLevelUpModal);
  const [autoPlayStates, setAutoPlayStates] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });
  const toggle = useAppSelector(selectCoinFlipToggle);
  const [autoPlayStatesRef, setAutoPlayStatesRef] = useState<IAutoPlayStates>({
    numberOfGames: 0,
    onWin: 0,
    onLoss: 0,
    stopNetGain: 0,
    stopNetLoss: 0,
    winStatus: SELECTIONSTATUS.RESET,
    lossStatus: SELECTIONSTATUS.RESET,
  });
  const [title, setTitle] = useState("");

  const [isAutoPlaying, setIsAutoPlaying] = useState(false);
  const tokenType = useAppSelector(selectTokenSelection);
  const tokenTypeRef = useRef(tokenType);

  const [currentBet, setCurrentBet] = useState(0);

  const volume = useAppSelector(selectVolume);
  const [playBetSound] = useSound(BetSound, { volume: volume / 100 });

  const handleLogin = () => {
    dispatch(openModal(MODALTYPE.LOGIN));
  };

  useEffect(() => {
    return () => {
      dispatch(resetCoinFlipGame());
    };
  }, []);
  // Handle changes to the roll over number input

  const playGame = (
    selection: COINFLIP | null,
    bet = currentBet,
    askConfirmation = true
  ) => {
    if (title) {
      setTitle(title);
      return;
    }
    if (!toggle) {
      setIsAutoPlaying(false);
      return;
    }
    selectionRef.current = selection;
    if ((bet > player.balance && tokenType === TOKENTYPE.SWEEP) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }

    if ((bet > player.freeCash && tokenType === TOKENTYPE.FREE) || !bet) {
      betFieldRef.current?.focus();
      if (isAutoPlaying) {
        setIsAutoPlaying(false);
      }
      return;
    }

    if (
      tokenType === TOKENTYPE.SWEEP &&
      !isAutoPlaying &&
      askConfirmation &&
      ((player.isBetConfirmation &&
        isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          player?.betConfirmationValue
        )) ||
        isBetGreaterthanSetValue(currentBet, player.balance, 95))
    ) {
      setConfirmation(true);
      return;
    }

    if (!askConfirmation && dontShowConfirmation) {
      let payload: Partial<User> = {
        isBetConfirmation: false,
        betConfirmationValue: 0,
      };
      dispatch(updateSettings(payload));
    }

    const gameData = {
      currentBet: bet,
      selection,
      tokenType,
    };
    tokenTypeRef.current = tokenType;

    if (playType === DICEMODE.MANUAL) {
      playBetSound();
      socket?.emit(PLAY_COIN_FLIP_GAME, {
        accessToken,
        gameData,
      });
      setDisableButton(true);
    }

    if (playType === DICEMODE.AUTO) {
      if (!isAutoPlaying) {
        setAutoPlayStatesRef({
          ...autoPlayStates,
          stopNetGain: 0,
          stopNetLoss: 0,
        });
        betRef.current = currentBet;
      }
      setIsAutoPlaying(true);
      playBetSound();
      socket?.emit(PLAY_COIN_FLIP_GAME, {
        accessToken,
        gameData,
      });
      setDisableButton(true);
    }
  };

  useEffect(() => {
    if (coinFlip) {
      setCoinClass(`${coinFlip.result}`);
    }
  }, [coinFlip]);

  useEffect(() => {
    if (pastResults.length && isAutoPlaying) {
      const getLastGame = pastResults[pastResults.length - 1];
      let shouldContinue = isLevelUp ? false : true;
      let newBet = currentBet;
      let letLoss = autoPlayStatesRef.stopNetLoss;
      let netGain = autoPlayStatesRef.stopNetLoss;

      //Stop when token changes
      if (tokenTypeRef.current !== tokenType) {
        shouldContinue = false;
      }

      // Check Number of Games
      if (autoPlayStates.numberOfGames) {
        if (autoPlayStatesRef.numberOfGames > 1) {
          setAutoPlayStatesRef({
            ...autoPlayStatesRef,
            numberOfGames: autoPlayStatesRef.numberOfGames - 1,
          });
        } else {
          shouldContinue = false;
        }
      }

      if (autoPlayStates.onWin && getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onWin / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onWin / 100)).toFixed(2)
        );
      }

      if (
        autoPlayStates.winStatus === SELECTIONSTATUS.RESET &&
        getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (
        autoPlayStates.lossStatus === SELECTIONSTATUS.RESET &&
        !getLastGame.isWin
      ) {
        setCurrentBet(betRef.current);
        newBet = betRef.current;
      }

      if (autoPlayStates.onLoss && !getLastGame.isWin) {
        setCurrentBet((prev) =>
          parseFloat((prev * (1 + autoPlayStates.onLoss / 100)).toFixed(2))
        );
        newBet = parseFloat(
          (currentBet * (1 + autoPlayStates.onLoss / 100)).toFixed(2)
        );
      }

      // Net Loss
      if (!getLastGame.isWin && autoPlayStates.stopNetLoss) {
        letLoss = letLoss + getLastGame.bet;
        if (letLoss >= autoPlayStates.stopNetLoss) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetLoss: letLoss,
          stopNetGain: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      // Net Gain
      if (getLastGame.isWin && autoPlayStates.stopNetGain) {
        netGain = netGain + (getLastGame.payout - getLastGame.bet);
        if (netGain >= autoPlayStates.stopNetGain) {
          shouldContinue = false;
        }
        setAutoPlayStatesRef({
          ...autoPlayStatesRef,
          stopNetGain: netGain,
          stopNetLoss: autoPlayStatesRef.stopNetGain - getLastGame.bet,
        });
      }

      if (
        shouldContinue &&
        ((currentBet <= player.balance &&
          getLastGame.tokenType === TOKENTYPE.SWEEP) ||
          (currentBet <= player.freeCash &&
            getLastGame.tokenType === TOKENTYPE.FREE))
      ) {
        playGame(selectionRef.current, newBet);
      } else {
        setIsAutoPlaying(false);
      }
    }
  }, [pastResults]);

  // const handleDoubleBet = () => {
  //   const isBalance = player?.balance || 0;
  //   const toBet =
  //     currentBet * 2 > isBalance ? RoundNumber(isBalance) : currentBet * 2;
  //   setCurrentBet(toBet);
  // };

  useEffect(() => {
    if (errorMessage && isAutoPlaying) {
      setIsAutoPlaying(false);
    }
  }, [errorMessage, isAutoPlaying]);

  return (
    <>
      <ConfirmationBetDialog
        open={confirmation}
        setDontShowConfirmation={setDontShowConfirmation}
        dontShowConfirmation={dontShowConfirmation}
        title="Bet Confirmation"
        text={`Are you sure you want to bet ${currentBet} tokens?`}
        handleConfirm={() => {
          setConfirmation(false);
          playGame(selectionRef.current, currentBet, false);
        }}
        handleCancel={() => {
          setDontShowConfirmation(false);
          setConfirmation(false);
        }}
        disableCheckbox={isBetGreaterthanSetValue(
          currentBet,
          player.balance,
          95
        )}
      />
      <Stack gap={2}>
        <Typography
          className={styles.heading}
          variant="h1"
          color="text.primary"
        >
          Coin Flip
        </Typography>
        <Stack
          direction={{ md: "row", sm: "column-reverse", xs: "column-reverse" }}
          gap={2}
          justifyContent="space-Between"
          sx={{ minHeight: { md: "74vh" } }}
        >
          <Stack
            sx={{
              width: { md: "40%", sm: "auto" },
              p: 3,
              backgroundColor: theme.palette.primary.main,
            }}
            gap={2}
            justifyContent="space-between"
          >
            <Stack gap={2}>
              <Stack sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
                <GameTypeButtons
                  playType={playType}
                  setPlayType={setPlayType}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack sx={{ display: { md: "none", sm: "block", xs: "block" } }}>
                <BetButton
                  accessToken={accessToken}
                  disableButton={disableButton || !toggle}
                  playGame={playGame}
                  playType={playType}
                  handleLogin={handleLogin}
                  stopPlaying={() => setIsAutoPlaying(false)}
                  isAutoPlaying={isAutoPlaying}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
              >
                <Typography color="text.primary">Bet</Typography>
                <Button
                  onClick={() =>
                    setCurrentBet(
                      RoundNumber(
                        tokenType === TOKENTYPE.FREE
                          ? player?.freeCash
                          : player?.balance || 0
                      )
                    )
                  }
                  disabled={disableButton}
                >
                  Max
                </Button>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-Between"
                gap={1}
              >
                <BetField
                  game={GAMES.COIN_FLIP}
                  betFieldRef={betFieldRef}
                  setCurrentBet={setCurrentBet}
                  disabled={!accessToken || isAutoPlaying}
                  currentBet={currentBet}
                  setTitle={setTitle}
                  title={title}
                  balance={player.balance}
                />
                <Stack direction="row" gap={1}>
                  <Button
                    disabled={disableButton}
                    onClick={() =>
                      setCurrentBet(
                        handleDoubleBet(
                          tokenType === TOKENTYPE.FREE
                            ? player?.freeCash
                            : player?.balance || 0,
                          currentBet
                        )
                      )
                    }
                    sx={{ color: "text.primary" }}
                  >
                    x2
                  </Button>
                  <Button
                    onClick={() => setCurrentBet(handleHalfBet(currentBet))}
                    disabled={disableButton}
                    sx={{ color: "text.primary" }}
                  >
                    /2
                  </Button>
                </Stack>
              </Stack>
              <Stack direction="column" gap={1.25}>
                <AutoButtons
                  playType={playType}
                  autoPlayStates={autoPlayStates}
                  setAutoPlayStates={setAutoPlayStates}
                  isAutoPlaying={isAutoPlaying}
                  currentNumberOfGames={autoPlayStatesRef.numberOfGames}
                />
                <Stack
                  gap={1.25}
                  sx={{
                    display: playType === DICEMODE.MANUAL ? "flex" : "none",
                  }}
                >
                  <Typography color="text.primary">Profit on Win</Typography>
                  <ProfitOnWinInput
                    value={RoundProfitOnWin(
                      currentBet * DEFAULT_MULTIPLYER - currentBet
                    )}
                    name="profit-on-win"
                  />
                </Stack>

                <Stack
                  sx={{ display: { md: "block", sm: "none", xs: "none" } }}
                >
                  <BetButton
                    accessToken={accessToken}
                    disableButton={disableButton || !toggle}
                    playGame={playGame}
                    playType={playType}
                    handleLogin={handleLogin}
                    stopPlaying={() => setIsAutoPlaying(false)}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                <Stack
                  sx={{ display: { md: "none", sm: "block", xs: "block" } }}
                >
                  <GameTypeButtons
                    playType={playType}
                    setPlayType={setPlayType}
                    isAutoPlaying={isAutoPlaying}
                  />
                </Stack>
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
                {!toggle && (
                  <Typography variant="h6" color="error">
                    {DEFAULT_DISABLED_MESSAGE}
                  </Typography>
                )}
              </Stack>
            </Stack>
            <GameOptions game={GAMES.COIN_FLIP} />
          </Stack>
          <Stack
            sx={{
              p: 3,
              backgroundColor: theme.palette.primary.main,
              width: { md: "80%", sm: "auto" },
            }}
            gap={{ md: 0, sm: 4, xs: 4 }}
            justifyContent="space-between"
          >
            <Stack
              direction="row-reverse"
              gap={1}
              justifyContent={{ md: "flex-start", xs: "space-between" }}
              sx={{ height: "2em" }}
            >
              {pastResults.length ? (
                <>
                  {[...pastResults]?.reverse().map((result, index) => {
                    if (index > 4) return null;
                    const getImgSrc = coinFlipImages.find(
                      (data) => data.selection === result.result
                    );
                    return (
                      <Stack
                        key={result._id}
                        sx={{
                          bgcolor: result.isWin ? "green" : "red",
                          backgroundBlendMode: "hard-light",
                          width: "2.5em",
                          height: "2.5em",
                          borderRadius: "50%",
                          p: 0.25,
                        }}
                      >
                        {getImgSrc && <img src={getImgSrc.src} />}
                      </Stack>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </Stack>
            <Coin
              coinClass={coinClass}
              setCoinClass={setCoinClass}
              setDisableButton={setDisableButton}
              coinFlip={coinFlip}
            />
            <Stack></Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default CoinFlip;
