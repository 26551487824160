import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface CoinFlipDocument {
  user: string;
  bet: number;
  payout: number;
  result: string;
  createdAt: Date;
  clientSeed: string;
  serverSeed: string;
  gameNonce: number;
  selection: string;
  isMax: boolean;
  isWin: boolean;
  _id: string;
  tokenType: string;
}

interface CoinFlipState {
  coinFlip: CoinFlipDocument | null;
  pastResults: CoinFlipDocument[];
  errorMessage: string;
}

const initialState: CoinFlipState = {
  coinFlip: null,
  pastResults: [],
  errorMessage: "",
};

// Slice definition
export const coinFlipReducer = createSlice({
  name: "coinFlip",
  initialState,
  reducers: {
    updateCoinFlipGame(state, action: PayloadAction<CoinFlipDocument>) {
      state.errorMessage = "";
      state.coinFlip = action.payload;
      // state.pastResult.push(action.payload);
    },
    resetCoinFlipGame(state) {
      state.coinFlip = null;
    },
    updateCoinFlipErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    updateCoinFlipPastGame(state, action: PayloadAction<CoinFlipDocument>) {
      state.pastResults.push(action.payload);
    },
  },
});

// Expose the actions
export const {
  updateCoinFlipGame,
  updateCoinFlipErrorMessage,
  updateCoinFlipPastGame,
  resetCoinFlipGame,
} = coinFlipReducer.actions;

// Selectors
export const selectCoinFlipGames = (state: RootState) => state.coinFlip;

// Reducer
export default coinFlipReducer.reducer;
