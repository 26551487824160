import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface LimboDocument {
  user: string;
  bet: number;
  payout: number;
  result: number;
  createdAt: Date;
  clientSeed: string;
  serverSeed: string;
  gameType: string;
  gameNonce: number;
  multiplier: number;
  winProb: number;
  isMax: boolean;
  isWin: boolean;
  _id: string;
  tokenType: string;
}

interface LimboState {
  limbo: LimboDocument | null;
  pastResult: LimboDocument[];
  errorMessage: string;
}

const initialState: LimboState = {
  limbo: null,
  pastResult: [],
  errorMessage: "",
};

// Slice definition
export const limboReducer = createSlice({
  name: "limbo",
  initialState,
  reducers: {
    updateLimboGame(state, action: PayloadAction<LimboDocument>) {
      state.errorMessage = "";
      state.limbo = action.payload;
    },
    updateLimboErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    },
    resetLimboGame(state) {
      state.limbo = null;
    },
    updateLimboPastGame(state, action: PayloadAction<LimboDocument>) {
      state.pastResult.push(action.payload);
    },
  },
});

// Expose the actions
export const {
  updateLimboGame,
  updateLimboErrorMessage,
  updateLimboPastGame,
  resetLimboGame,
} = limboReducer.actions;

// Selectors
export const selectLimboGames = (state: RootState) => state.limbo;

// other selectors

// Reducer
export default limboReducer.reducer;
