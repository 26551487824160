import { Container } from "@mui/material";
import Dice from "../../../components/dice";
import GameFeed from "../../../components/gameFeed";
import { DescWrapper } from "../../../components/gameFeed/descWrapper";
import { GAMES } from "../../../constants/games";
import BlackJack from "../../../components/blackjack";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../components/store";
import { useAppSelector } from "../../../components/store/hooks";
import { selectToken } from "../../../components/slices/userSlice";
import { useEffect } from "react";
import { getCurrentHiloGame } from "../../../components/slices/hiloslice";
import HiLo from "../../../components/hilo";

const HiLoPage = () => {
  const dispatch = useDispatch<AppDispatch>();
  const accessToken = useAppSelector(selectToken);

  useEffect(() => {
    if (accessToken) {
      dispatch(getCurrentHiloGame());
    }
  }, [dispatch, accessToken]);

  return (
    <Container maxWidth="md">
      {/* <HiLo /> */}
      <HiLo />
      <DescWrapper game={GAMES.HILO} />
      <GameFeed />
    </Container>
  );
};

export default HiLoPage;
